<ng-container [formGroup]="offer" *ngIf="installations && installation">
    <h4 [innerHtml]="'proposal-subtitle-offer' | cmsText | async"></h4>

    <div class="mb-1">
        <span
            class="fw-bold"
            [innerHTML]="
                'proposal-optimal-number-of-panels'
                    | cmsText : [{ optimalAmount: optimalAmount }]
                    | async
            "
        >
        </span>
    </div>
    <div class="mb-4">
        <app-proposal-panel-explanation
            [registration]="this.registration"
            [noPanelsBasedOnUsage]="optimalAmount"
        ></app-proposal-panel-explanation>
    </div>
    <div class="mb-0">
        <div class="row">
            <div class="col-xl-5 col-lg-6 col-sm-7 mb-3">
                <label>
                    {{ 'proposal-calculate-offer-title' | cmsText | async }}
                </label>
                <span class="input-group mx-1">
                    <button
                        class="btn btn-primary rounded-start"
                        type="button"
                        (click)="chooseLessPanels()"
                        [disabled]="
                            config.minNumberOfPanels >=
                            installation.numberOfPanels
                        "
                    >
                        -
                    </button>
                    <input
                        size="2"
                        type="text"
                        inputmode="numeric"
                        [min]="config.minNumberOfPanels"
                        [max]="registration.product.numberOfPanelsThatFitOnRoof"
                        class="form-control text-center"
                        id="numberOfPanels"
                        formControlName="numberOfPanels"
                        readonly
                    />
                    <button
                        class="btn btn-primary"
                        type="button"
                        (click)="chooseMorePanels()"
                        [disabled]="
                            installation.numberOfPanels >=
                            config.maxNumberOfPanels
                        "
                    >
                        +
                    </button>
                    <span class="input-group-text">{{
                        contentTranslatorService.solarPanelsPluralSingular(
                            registration.product.chosenNumberOfPanels
                        )
                            | cmsText
                            | async
                    }}</span>
                </span>
                <div
                    *ngIf="numberOfPanels.value === config.minNumberOfPanels"
                    class="mt-2 small"
                >
                    <i class="fa fa-info mx-1"></i
                    >{{ 'chosen-number-of-panels-min-info' | cmsText | async }}
                </div>
                <div
                    *ngIf="numberOfPanels.value === config.maxNumberOfPanels"
                    class="mt-2 small"
                >
                    <i class="fa fa-info mx-1"></i
                    >{{ 'chosen-number-of-panels-max-info' | cmsText | async }}
                </div>
            </div>
            <div class="col-12">
                <div>
                    <app-register-warning
                        *ngIf="installation?.switchToSingleMeter"
                        warningKey="proposal-switch-to-single-meter-warning"
                    ></app-register-warning>
                    <app-register-warning
                        *ngIf="showWarningForExceedingOptimalPanels"
                        warningKey="chosen-number-of-panels-warning-more-than-optimal"
                        [cmsParams]="chosenAmountOfPanelsNotOptimalCmsMetadata"
                    >
                    </app-register-warning>
                    <app-register-warning
                        *ngIf="showWarningForBelowOptimalPanels"
                        warningKey="chosen-number-of-panels-warning-less-than-optimal"
                        [cmsParams]="chosenAmountOfPanelsNotOptimalCmsMetadata"
                    >
                    </app-register-warning>
                    <app-register-warning
                        *ngIf="showWarningForExceedingRoofSize"
                        warningKey="chosen-number-of-panels-warning-max-for-roofsize"
                        [cmsParams]="{
                            numberOfPanelsThatFitOnRoof:
                                registration.product.numberOfPanelsThatFitOnRoof
                        }"
                    >
                    </app-register-warning>
                </div>
            </div>
        </div>
    </div>
    <div class="border-0 mt-2 mb-2">
        <app-content
            [key]="
                'proposal-product-description-' +
                registration.proposal.supplier.code
            "
        ></app-content>
    </div>
    <div class="card bg-highlight-one border-0 mb-1">
        <div class="card-body">
            <div class="row pr-3 pl-md-0 pl-3">
                <div class="col-md-6">
                    <h5 class="text-muted">
                        {{ 'offer-price-incl-btw-label' | cmsText | async }}:
                    </h5>
                    <span class="fw-bold h3">
                        {{
                            'amount-eur'
                                | cmsText
                                    : [
                                          {
                                              amount:
                                                  installation.price
                                                  | roundToNearest : '1'
                                          }
                                      ]
                                | async
                        }}
                    </span>
                    <span class="small">
                        {{
                            'proposal-inclusive-btw-text' | cmsText | async
                        }}</span
                    >
                </div>
                <div
                    class="col-md-6 bg-white p-3 rounded border-0 mt-md-0 mt-3"
                >
                    <i class="fa fa-lightbulb-o me-2" aria-hidden="true"></i>
                    <span
                        [innerHTML]="'btw-price-text' | cmsText | async"
                    ></span>
                    <app-spnl-tooltip
                        cmsKey="proposal-btw-price-tooltip"
                    ></app-spnl-tooltip>
                </div>
            </div>
        </div>
    </div>
    <hr />
    <div class="row mt-3">
        <div class="col-12">
            <h4
                [innerHtml]="
                    'proposal-is-interested-in-parallel-header'
                        | cmsText
                        | async
                "
            ></h4>
            <p
                [innerHTML]="
                    'proposal-is-interested-in-parallel-calculation-notice'
                        | cmsText
                            : [
                                  {
                                      supplierNameCalculation:
                                          registration?.proposal?.supplier?.code
                                  }
                              ]
                        | async
                "
            ></p>

            <div class="alert alert-info" role="alert">
                <div style="white-space: nowrap">
                    <input
                        type="checkbox"
                        class="form-check-input me-1 mt-1 pt0"
                        style="display: inline-flex"
                        id="isInterestedInParallelInstallation"
                        formControlName="isInterestedInParallelInstallation"
                        (change)="chooseParallelInstallation($event)"
                    />
                    <div style="display: inline-flex">
                        <label
                            for="isInterestedInParallelInstallation"
                            style="white-space: normal"
                            class="mt-1"
                        >
                            {{
                                'proposal-is-interested-in-parallel'
                                    | cmsText
                                    | async
                            }}
                            <app-spnl-tooltip
                                cmsKey="proposal-is-interested-in-parallel-tooltip"
                            >
                            </app-spnl-tooltip>
                        </label>
                    </div>
                </div>
            </div>
            <app-register-warning
                *ngIf="isInterestedInParallel"
                warningKey="proposal-is-interested-in-parallel-explanation-with-subtotal"
                [cmsParams]="{
                    supplierName: registration?.proposal?.supplier?.name,
                    pricePerPannel: addedPricePerPannelForParallel,
                    subtotalForParallel: subtotalForParallel
                }"
            >
            </app-register-warning>
        </div>
    </div>
</ng-container>
