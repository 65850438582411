import { AfterViewInit, Component, OnInit } from '@angular/core';
import { QueryParamsHandling } from '@angular/router';
import { PaymentStatus } from '@common/model/payment';
import { RegisterStep } from '@spnl/model/register-step.enum';
import { Registration } from '@spnl/model/registration';
import { DigitalEventService } from '@spnl/services/digital-event-queue.service';
import {
    OnContinue,
    RegisterLocationService,
    Substep,
} from '@spnl/services/register-location.service';
import { RegisterStepService } from '@spnl/services/register-step.service';
import { RegisterStoreService } from '@spnl/services/register-store.service';
import { first } from 'rxjs/operators';
import { DeviceTypeService } from '@country/nl/services/device-type.service';

@Component({
    selector: 'app-register-overview-payment-success',
    templateUrl: './payment-success.component.html',
})
export class PaymentSuccessComponent implements OnInit, AfterViewInit {
    isMobileDevice: boolean;
    
    get registration(): Registration {
        return this.registerStore.registration;
    }

    constructor(
        private registerStore: RegisterStoreService,
        private digitalEventService: DigitalEventService,
        private registerLocationService: RegisterLocationService,
        private stepService: RegisterStepService,
        private deviceTypeService: DeviceTypeService,
    ) {}

    ngOnInit(): void {
        this.registerStore.registrationLoaded$.pipe(first()).subscribe(() => {
            if (this.registration.paymentStatus !== PaymentStatus.Successful) {
                const step = this.stepService.getLatestAllowedStep(
                    this.registration,
                );
                this.registerLocationService.goTo(step, new Substep.None());
            }
        });
        this.isMobileDevice = this.deviceTypeService.isMobileDevice();
    }

    ngAfterViewInit(): void {
        this.digitalEventService.push('betaling afgerond pagina');
    }

    goToOverview(): void {
        const queryParamsHandlingRemove: QueryParamsHandling = '';

        this.registerLocationService.goTo(
            RegisterStep.Overview,
            new Substep.None(),
            OnContinue.Next,
            queryParamsHandlingRemove,
        );
    }
}
