import { Component, Input } from '@angular/core';
import { Registration } from '@spnl/model/registration';

@Component({
    selector: 'app-supplier-logo',
    template: ` <img
        class="img-fluid w-50 mx-auto d-block"
        [attr.src]="supplierLogo | cmsImageSrc | async"
        [attr.alt]="supplierLogo | cmsImageAlt | async"
    />`,
})
export class SupplierLogoComponent {
    @Input()
    public registration: Registration;

    get supplierLogo(): string {
        return `proposal-logo-${this.supplierCode}`;
    }

    private get supplierCode(): string {
        return this.registration.proposalMade
            ? this.registration.proposal.supplier.code
            : '';
    }
}
