<footer class="bg-secondary">
    <div class="container-fluid py-5">
        <div class="container-fluid border-top py-3">
            <div class="container">
                <div
                    class="d-flex justify-content-between align-items-center flex-column flex-md-row border-bottom py-2"
                >
                    <h5 class="ms-0 ms-md-3 mb-0"></h5>
                    <ul class="nav justify-content-center">
                        <li *ngFor="let link of links" class="nav-item">
                            <a
                                class="nav-link"
                                [routerLink]="link.url"
                                target="_blank"
                            >
                                {{ link.text | cmsText | async }}
                            </a>
                        </li>
                    </ul>
                </div>
                <div
                    class="d-flex text-center justify-content-center align-items-center justify-content-md-between flex-column flex-md-row mx-3 mt-3"
                >
                    <div class="d-flex"></div>
                    <div class="d-flex mt-3 mt-md-0">
                        <p class="mb-0 small text-white">
                            &copy; iChoosr {{ year }} alle rechten voorbehouden.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
