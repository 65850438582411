<app-hero headerKey="faq-title"></app-hero>

<div class="fluid-container bg-highlight-one py-5">
    <div class="container">
        <div class="card shadow border-0 mb-1">
            <div class="card-body">
                <div class="row">
                    <div class="col-md mb-3">
                        <label for="categoryId">
                            <app-content key="category-label"></app-content>
                        </label>
                        <select
                            id="categoryId"
                            name="categoryId"
                            class="form-control"
                            [(ngModel)]="categoryId"
                            (ngModelChange)="categoryChange($event)"
                            [disabled]="categoryDisabled"
                        >
                            <option
                                *ngFor="let category of categories"
                                [value]="category.id"
                            >
                                {{ category.getHeader(content) | async }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md mb-3">
                        <label for="searchTerm">
                            <app-content key="search-label"></app-content>
                        </label>
                        <input
                            class="form-control"
                            id="searchTerm"
                            name="searchTerm"
                            (keyup)="search($event)"
                            [(ngModel)]="searchTerm"
                        />
                    </div>
                </div>

                <ng-container *ngIf="currentItems$ | async as currentItemsSync">
                    <ngb-accordion
                        #accordion
                        [closeOthers]="false"
                        [activeIds]="activeIds"
                    >
                        <ng-container *ngFor="let item of currentItemsSync">
                            <ngb-panel id="{{ item.id }}">
                                <ng-template ngbPanelTitle>
                                    <div class="d-flex text-start">
                                        <span
                                            class="accordion-header-text"
                                            [highlight]="searchTerm"
                                            [innerHTML]="
                                                item.getContent(content)
                                                    | async
                                                    | faqheader
                                            "
                                        ></span>
                                    </div>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <div
                                        [highlight]="searchTerm"
                                        [innerHTML]="
                                            item.getContent(content)
                                                | async
                                                | faqcontent
                                        "
                                    ></div>
                                </ng-template>
                            </ngb-panel>
                        </ng-container>
                    </ngb-accordion>

                    <div
                        class="alert alert-warning"
                        [innerHTML]="
                            'search-no-results-message' | cmsText | async
                        "
                        *ngIf="currentItemsSync?.length === 0"
                    ></div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<div class="fluid-container">
    <div class="py-5 bg-highlight-two text-center">
        <app-cta-register-or-rooftest></app-cta-register-or-rooftest>
    </div>
</div>
