<div class="text-center mt-4">
    <h3 *ngIf="!error">
        <p *ngIf="!success">{{ 'rollover-started' | cmsText | async }}</p>
        <p *ngIf="!canRollover">
            {{ 'rollover-already-completed' | cmsText | async }}
        </p>
        <p *ngIf="success && canRollover">
            {{ 'rollover-success' | cmsText | async }}
        </p>
    </h3>
    <a
        *ngIf="
            success &&
                id &&
                contentService.valueExists('rollover-go-to-registration')
                | async
        "
        [routerLink]="'../../../register/' + id"
        class="btn btn-primary my-4"
        id="goToRegistrationButton"
    >
        {{ 'rollover-go-to-registration' | cmsText | async }}
    </a>
    <div *ngIf="error">
        <h3 class="text-danger">Error</h3>
        <div class="alert alert-danger">
            <h4>{{ 'rollover-failed' | cmsText | async }}</h4>
            {{ error }}
        </div>
    </div>
</div>

<div *ngIf="!busy" class="text-center mt-4">
    <div *ngIf="error">
        <div class="alert alert-danger">
            <h4>Error</h4>
            {{ error }}
        </div>
    </div>
</div>
