import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from '@common/app/app.config';
import { Observable } from 'rxjs';
import { AuctionOffer } from '../model/offer';

@Injectable({
    providedIn: 'root'
})
export class AuctionService {
    url: string;
    constructor(
        protected http: HttpClient,
        @Inject(API_URL) protected baseUrl: string) {
        this.url = this.baseUrl + 'auctions/';
    }

    public getAllOffers(auctionId: string): Observable<AuctionOffer> {
        const url = this.url + auctionId + '/offers';
        return this.http.get<AuctionOffer>(url);
    }

    public hasSeminars$(auctionId: string): Observable<boolean> {
        const url = this.url + auctionId + '/has-seminars';
        return this.http.get<boolean>(url);
    }
}
