<div class="fluid-container bg-highlight-one py-5">
    <div class="container">
        <div class="row">
            <div class="col-md-8 mb-3">
                <div class="card shadow border-0 mb-1">
                    <div class="card-body">
                        <div class="col">
                            <app-register-stepper
                                *ngIf="showStepper"
                                [registration]="registration"
                            >
                            </app-register-stepper>
                        </div>
                        <div class="row">
                            <div class="col-md-12" *ngIf="showStatusMessage">
                                <app-register-status-message></app-register-status-message>
                            </div>
                            <div class="col-md-12" *ngIf="!showStatusMessage">
                                <app-register-error
                                    *ngIf="showAuctionFinishedError"
                                    errorKey="auction-finished-warning"
                                ></app-register-error>

                                <router-outlet></router-outlet>

                                <div
                                    *ngIf="
                                        serverValidations?.length || serverError
                                    "
                                    class="alert alert-danger"
                                >
                                    <h4>
                                        <app-content
                                            key="server-error-title"
                                        ></app-content>
                                    </h4>
                                    <span>
                                        <app-content
                                            key="server-error-description"
                                        ></app-content>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mt-5 mt-md-0 px-4 px-md-0">
                <app-proposal-sidebar-supplier-logo
                    *ngIf="
                        activeStep === RegisterStep.Proposal &&
                        registration?.proposal?.supplier
                    "
                    [supplier]="registration?.proposal?.supplier"
                    [auctionId]="registration?.auctionId"
                >
                </app-proposal-sidebar-supplier-logo>
                <app-user-card
                    [registrationNumber]="registration.number"
                ></app-user-card>
                <app-proposal-video
                    *ngIf="
                        activeStep === RegisterStep.Proposal &&
                        !registration.proposalAccepted &&
                        registration?.proposal?.supplier
                    "
                >
                </app-proposal-video>

                <app-proposal-personal-details
                    *ngIf="
                        activeStep === RegisterStep.Proposal &&
                        !registration.proposalAccepted &&
                        registration?.proposal?.supplier
                    "
                    [registration]="registration"
                >
                </app-proposal-personal-details>

                <app-proposal-attachments
                    *ngIf="
                        activeStep === RegisterStep.Proposal &&
                        registration?.proposal?.supplier
                    "
                    [supplier]="registration?.proposal?.supplier"
                >
                </app-proposal-attachments>
                <div class="shadow">
                    <app-contact-options
                        [communityCode]="communityCode"
                        [showIntroduction]="true"
                        (analyticsEventEmitter)="analyticsEvent($event)"
                        [contactPhoneCmsKey]="'contact-phone'"
                        [showFaqLink]="true"
                        [openInNewTab]="true"
                        [useArrow]="true"
                    >
                    </app-contact-options>
                </div>
            </div>
        </div>
    </div>
</div>
