import { Injectable } from '@angular/core';
import { ActivatedRoute, QueryParamsHandling, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

import { CommunityService } from '@common/services/community.service';
import { ContentService } from '@common/services/content.service';
import { PendingChangesService } from '@common/services/pending-changes.service';
import { FlowData } from '@common/services/register-flow-calculator.service.base';
import { DestroyableBase } from '@spnl/components/destroyable/destroyable.component';
import { RegisterStep } from '@spnl/model/register-step.enum';
import { Product } from '@spnl/model/registration';
import { RoofStep } from '@spnl/model/roof-step.model';
import { RegisterAnalyticsService } from './register-analytics.service';
import { RegisterStepService } from './register-step.service';
import { RegisterStoreService } from './register-store.service';
import { RoofDetailsStepService } from './roof-details-step.service';

export enum OnContinue {
    Next,
    BackToReferrerStep,
}

export interface Substep {
    getRoofStep(product: Product): RoofStep | undefined;
}

export namespace Substep {
    export class First implements Substep {
        getRoofStep(): RoofStep {
            return RoofStep.LivingSituation;
        }
    }

    export class Latest implements Substep {
        getRoofStep(product: Product): RoofStep {
            return RoofDetailsStepService.getLatestStep(product);
        }
    }

    export class Exact implements Substep {
        constructor(private step: RoofStep) {}

        getRoofStep(): RoofStep {
            return this.step;
        }
    }

    export class None implements Substep {
        getRoofStep(): undefined {
            return undefined;
        }
    }
}

@Injectable()
export class RegisterLocationService extends DestroyableBase {
    private _enteredFlowFromLink: boolean;
    private _preventLoosingChangesMessage: string;
    private _onContinue = OnContinue.Next;

    public constructor(
        private contentService: ContentService,
        private analytics: RegisterAnalyticsService,
        private pendingChangesService: PendingChangesService,
        private registerStore: RegisterStoreService,
        private stepService: RegisterStepService,
        private route: ActivatedRoute,
        private router: Router,
        private communityService: CommunityService,
    ) {
        super();
        this.contentService
            .value('prevent-loosing-changes-message')
            .pipe(takeUntil(this.destroyed$))
            .subscribe((text) => (this._preventLoosingChangesMessage = text));
    }

    get onContinue(): OnContinue {
        return this._onContinue;
    }

    get enteredFlowFromLink(): boolean {
        return this._enteredFlowFromLink;
    }

    enterFlowFromLink(): void {
        this._enteredFlowFromLink = true;
    }

    stepUpdatedByRoute(step: string): void {
        // TODO: move the logic to check for allowd steps to a route guard
        // Make sure the requested step is allowed for this registration
        this.registerStore.registrationLoaded$.pipe(take(1)).subscribe(() => {
            const stepIsAllowed = this.stepService.stepIsAllowed(
                this.registerStore.registration,
                step,
            );
            if (stepIsAllowed) {
                window.scrollTo(0, 0);
                this.registerStore.finishSubmitting();
                this.registerStore.activeStep = step as RegisterStep;
                this.registerStore.stepHistory.push(step as RegisterStep);
                this.logToAnalytics();
            } else {
                const latestAllowedStep = this.stepService.getLatestAllowedStep(
                    this.registerStore.registration,
                );
                this.goTo(latestAllowedStep, new Substep.Latest());
            }
        });
    }

    goTo(
        step: RegisterStep,
        substep: Substep,
        onContinue: OnContinue = OnContinue.Next,
        queryParamsHandling: QueryParamsHandling = 'preserve',
    ): void {
        if (
            this.pendingChangesService.pendingChanges &&
            !confirm(this._preventLoosingChangesMessage)
        ) {
            return; // Cancel navigation
        }

        this._onContinue = onContinue;

        const roofStep =
            step === RegisterStep.Product
                ? substep.getRoofStep(this.registerStore.registration.product)
                : undefined;

        this.buildRoute(step, roofStep).subscribe((urlParams) => {
            this.router.navigate(urlParams, { queryParamsHandling });
        });
    }

    buildRoute(step: RegisterStep, roofStep: RoofStep): Observable<string[]> {
        return this.communityService.communityCode$.pipe(
            take(1),
            map((communityCode) => {
                const stepUri = RegisterStep.getUri(
                    step,
                    this.registerStore.registration,
                );
                const route = [communityCode, 'register', stepUri];

                if (step === RegisterStep.Product && roofStep !== undefined) {
                    // Substeps are zero-based, so we increment them by one for the URI
                    route.push(`${roofStep + 1}`);
                }

                return route;
            }),
        );
    }

    logToAnalytics(): void {
        const flowData: FlowData = {
            registration: this.registerStore.registration,
            goToStep: this.registerStore.activeStep,
            startedWithStep: this.registerStore.startedWithStep,
            enteredFlowFromLink: this.enteredFlowFromLink,
            queryParamMap: this.route.snapshot.queryParamMap,
        };
        this.analytics.logPageView(flowData);
    }

    goToErrorPage(sourceStep: RegisterStep): void {
        this.communityService.communityCode$
            .pipe(take(1))
            .subscribe((communityCode) =>
                this.router.navigate([communityCode, 'error-step', sourceStep]),
            );
    }
}
