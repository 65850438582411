import { Component, OnDestroy, OnInit } from '@angular/core';
import { DeviceTypeService } from '@country/nl/services/device-type.service';
import { filter } from 'rxjs/operators';
import { ContentService } from '@common/services/content.service';
import { SocialLinkGeneratorService } from '@common/services/social-link-generator.service';

@Component({
    selector: 'app-tell-a-friend-landing-page',
    templateUrl: './tell-a-friend-landing-page.component.html',
    styleUrls: ['./tell-a-friend-landing-page.component.scss'],
})
export class TellAFriendLandingPageComponent implements OnInit, OnDestroy {
    isMobileDevice: boolean;

    constructor(
        private deviceTypeService: DeviceTypeService,
        private contentService: ContentService,
        private socialLinkGeneratorService: SocialLinkGeneratorService,
    ) {}

    ngOnInit(): void {
        this.isMobileDevice = this.deviceTypeService.isMobileDevice();
    }

    getEmailLink() {
        let emailSubject: string;
        let emailBody: string;

        if (this.isMobileDevice) {
            emailSubject = this.getCmsValueFromContentService(
                'email-message-header-landing-mobile',
            );
            emailBody = this.getCmsValueFromContentService(
                'email-message-body-landing-mobile',
            );
        } else {
            emailSubject = this.getCmsValueFromContentService(
                'email-message-header-offer-landingpage',
            );
            emailBody = this.getCmsValueFromContentService(
                'email-message-body-offer-landingpage-desktop',
            );
        }
        return this.socialLinkGeneratorService.getEmailLinkString(
            emailBody,
            emailSubject,
        );
    }

    getWhatsAppLink() {
        let whatsappBody: string;

        if (this.isMobileDevice) {
            whatsappBody = this.getCmsValueFromContentService(
                'whatsapp-message-landing-mobile',
            );
            return this.socialLinkGeneratorService.getMobileWhatsappLinkString(
                whatsappBody,
            );
        } else {
            whatsappBody = this.getCmsValueFromContentService(
                'whatsapp-message-offer-landingpage-desktop',
            );
            return this.socialLinkGeneratorService.getDesktopWhatsappLinkString(
                whatsappBody,
            );
        }
    }

    getCmsValueFromContentService(cmsKey: string): string {
        let returnValue = '';
        this.contentService
            .get(cmsKey)
            .pipe(filter((cmsValue) => cmsValue && cmsValue.value))
            .subscribe((cmsValue) => {
                returnValue = cmsValue.value;
            });
        return returnValue;
    }

    ngOnDestroy(): void {}
}
