<div class="container" *ngIf="registration as registration">
    <app-loading-overlay *ngIf="isBusy"></app-loading-overlay>
    <h3
        class="my-3"
        [innerHTML]="'contact-preferences-header' | cmsText | async"
    ></h3>
    <p
        [innerHTML]="
            'contact-preferences-text'
                | cmsText : [{ community: communityCode }]
                | async
        "
    ></p>
    <hr class="my-3" />
    <form class="my-3" *ngIf="form$ | async as frm" [formGroup]="frm">
        <div
            class="businessPreferences mb-5"
            *ngIf="businessPreferencesForm$ | async as businessForm"
        >
            <div
                class="container"
                [ngClass]="{
                    currentBusinessPreferences:
                        businessLine.key === this.business
                }"
                [ngClass]="{
                    otherBusinessPreferences: businessLine.key !== this.business
                }"
                *ngFor="let businessLine of businessForm.controls | keyvalue"
                [formGroup]="toFormGroup(businessLine.value)"
            >
                <div
                    class="mt-3"
                    *ngFor="
                        let businessPreference of toFormGroup(
                            businessLine.value
                        ).controls | keyvalue
                    "
                >
                    <div>
                        <input
                            class="switch my-auto col-2"
                            type="checkbox"
                            id="{{ businessPreference.key + businessLine.key }}"
                            formControlName="{{ businessPreference.key }}"
                            (change)="
                                onChangePreference(
                                    businessPreference.key,
                                    toFormControl(businessPreference.value),
                                    businessLine.key
                                )
                            "
                        />
                        <label
                            class="col-10"
                            [for]="businessPreference.key + businessLine.key"
                            [innerHtml]="
                                convertToCmsFormat(
                                    businessPreference.key +
                                        '-' +
                                        businessLine.key
                                )
                                    | cmsText
                                    | async
                            "
                        >
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <app-error-alert
            cmsKey="error-saving-preference"
            *ngIf="showSavePreferenceError"
        >
        </app-error-alert>
        <app-content
            key="contact-preferences-cancellation-explanation"
        ></app-content>
        <app-content
            key="contact-preferences-cancellation-not-possible"
            *ngIf="registration.decisionComplete"
        ></app-content>
        <div class="my-3 container" [formGroup]="registrationDataForm">
            <input
                id="updateCancellation"
                formControlName="isNotCancelled"
                class="switch my-auto col-2"
                (change)="onUpdateCancellation($event.target)"
                type="checkbox"
            />
            <label
                class="col-10"
                for="updateCancellation"
                [class.disabled]="registration.decisionComplete"
            >
                {{ 'cancel-registration-toggle-label' | cmsText | async }}
            </label>
        </div>

        <div class="mt-3">
            <p
                [innerHTML]="
                    'contact-preferences-outro'
                        | cmsText : [{ community: communityCode }]
                        | async
                "
                routeTransformer
                [routeTransformerArgs]="[]"
            ></p>
        </div>
    </form>
    <hr class="my-3" />
    <footer class="mt-3 text-end">
        <button
            type="button"
            (click)="back()"
            class="btn btn-primary px-5 float-end"
            id="BackBtn"
        >
            {{ 'back-to-app-button' | cmsText | async }}
        </button>
    </footer>
</div>
