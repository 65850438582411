<div [formGroup]="form">
    <div class="mb-3">
        <div>
            <label for="doesKnowEnergyUsage">
                {{
                    'roof-energy-do-you-know-your-annual-electricity-consumption-question'
                        | cmsText
                        | async
                }}
                <app-spnl-tooltip
                    cmsKey="roof-energy-do-you-know-your-annual-electricity-consumption-question-tooltip-body"
                ></app-spnl-tooltip>
            </label>

            <div class="form-check">
                <input
                    type="radio"
                    name="doesUserKnowEnergyUsage"
                    [ngClass]="{
                        'form-check-input': true,
                        'is-invalid': showErrorsForKnowsEnergyUsage
                    }"
                    id="doesUserKnowEnergyUsageYes"
                    formControlName="doesUserKnowEnergyUsage"
                    [value]="true"
                />
                <label
                    class="custom-control-label"
                    for="doesUserKnowEnergyUsageYes"
                >
                    {{ 'yes' | cmsText | async }}
                </label>
            </div>
            <div class="form-check">
                <input
                    type="radio"
                    name="doesUserKnowEnergyUsage"
                    [ngClass]="{
                        'form-check-input': true,
                        'is-invalid': showErrorsForKnowsEnergyUsage
                    }"
                    id="doesUserKnowEnergyUsageNo"
                    formControlName="doesUserKnowEnergyUsage"
                    [value]="false"
                />
                <label
                    class="custom-control-label"
                    for="doesUserKnowEnergyUsageNo"
                >
                    {{ 'no' | cmsText | async }}
                </label>
            </div>
        </div>
    </div>

    <div *ngIf="showEnergyUsageQuestion" class="mb-3 form-check-inline">
        <label for="electricityConsumptionPerYear">
            {{ 'energy-consumption-single' | cmsText | async }}
        </label>
        <div class="row">
            <div class="input-group col-md-5">
                <input
                    type="text"
                    inputmode="numeric"
                    [ngClass]="{
                        'form-control': true,
                        'is-invalid': showErrorsForConsumption
                    }"
                    id="electricityConsumptionPerYear"
                    formControlName="electricityConsumptionPerYear"
                />
                <span class="input-group-text">kWh</span>
            </div>
        </div>
        <app-register-input-errors
            [shouldShowErrors]="showErrorsForConsumption"
            [field]="electricityConsumptionPerYear"
            label="energy-consumption-single"
        >
        </app-register-input-errors>
    </div>
    <app-register-warning
        *ngIf="showWarningForConsumption"
        warningKey="energy-consumption-single-warning"
    >
    </app-register-warning>

    <div *ngIf="showHouseholdMemberQuestion" class="mb-3">
        <label>
            {{
                'roof-energy-how-many-people-in-household-question'
                    | cmsText
                    | async
            }}
        </label>

        <div class="form-check">
            <input
                type="radio"
                name="howManyPeopleInHousehold"
                [ngClass]="{
                    'form-check-input': true,
                    'is-invalid': showErrorsForHowManyPeopleInHousehold
                }"
                id="howManyPeopleInHouseholdOne"
                formControlName="howManyPeopleInHousehold"
                [value]="1"
            />
            <label
                class="custom-control-label"
                for="howManyPeopleInHouseholdOne"
            >
                {{ 'one-person-in-household' | cmsText | async }}
            </label>
        </div>
        <div class="form-check">
            <input
                type="radio"
                name="howManyPeopleInHousehold"
                [ngClass]="{
                    'form-check-input': true,
                    'is-invalid': showErrorsForHowManyPeopleInHousehold
                }"
                id="howManyPeopleInHouseholdTwo"
                formControlName="howManyPeopleInHousehold"
                [value]="2"
            />
            <label
                class="custom-control-label"
                for="howManyPeopleInHouseholdTwo"
            >
                {{ 'two-person-in-household' | cmsText | async }}
            </label>
        </div>

        <div class="form-check">
            <input
                type="radio"
                name="howManyPeopleInHousehold"
                [ngClass]="{
                    'form-check-input': true,
                    'is-invalid': showErrorsForHowManyPeopleInHousehold
                }"
                id="howManyPeopleInHouseholdThree"
                formControlName="howManyPeopleInHousehold"
                [value]="3"
            />
            <label
                class="custom-control-label"
                for="howManyPeopleInHouseholdThree"
            >
                {{ 'three-person-in-household' | cmsText | async }}
            </label>
        </div>
        <div class="form-check">
            <input
                type="radio"
                name="howManyPeopleInHousehold"
                [ngClass]="{
                    'form-check-input': true,
                    'is-invalid': showErrorsForHowManyPeopleInHousehold
                }"
                id="howManyPeopleInHouseholdFour"
                formControlName="howManyPeopleInHousehold"
                [value]="4"
            />
            <label
                class="custom-control-label"
                for="howManyPeopleInHouseholdFour"
            >
                {{ 'four-person-in-household' | cmsText | async }}
            </label>
        </div>
        <div class="form-check">
            <input
                type="radio"
                name="howManyPeopleInHousehold"
                [ngClass]="{
                    'form-check-input': true,
                    'is-invalid': showErrorsForHowManyPeopleInHousehold
                }"
                id="howManyPeopleInHouseholdFive"
                formControlName="howManyPeopleInHousehold"
                [value]="5"
            />
            <label
                class="custom-control-label"
                for="howManyPeopleInHouseholdFive"
            >
                {{ 'five-person-in-household' | cmsText | async }}
            </label>
        </div>
    </div>

    <div
        class="mb-3"
        *ngIf="showEnergyUsageQuestion || showHouseholdMemberQuestion"
    >
        <label>
            {{
                'roof-select-future-energy-usage-items-question'
                    | cmsText
                    | async
            }}
            <app-spnl-tooltip
                cmsKey="roof-select-future-energy-usage-items-question-tooltip-body"
            ></app-spnl-tooltip>
        </label>
        <div class="form-check">
            <input
                type="checkbox"
                class="form-check-input"
                id="isInterestedInAirconditioning"
                formControlName="isInterestedInAirconditioning"
            />
            <label
                class="custom-control-label"
                for="isInterestedInAirconditioning"
            >
                <span
                    [innerHTML]="
                        'user-is-interested-in-air-conditioning'
                            | cmsText
                            | async
                    "
                ></span>
            </label>
        </div>
        <div class="form-check">
            <input
                type="checkbox"
                class="form-check-input"
                id="isInterestedInElectricCar"
                formControlName="isInterestedInElectricCar"
            />
            <label class="custom-control-label" for="isInterestedInElectricCar">
                <span
                    [innerHTML]="
                        'user-is-interested-in-electric-car' | cmsText | async
                    "
                ></span>
            </label>
        </div>
        <div class="form-check">
            <input
                type="checkbox"
                class="form-check-input"
                id="isInterestedInElectricOven"
                formControlName="isInterestedInElectricOven"
            />
            <label
                class="custom-control-label"
                for="isInterestedInElectricOven"
            >
                <span
                    [innerHTML]="
                        'user-is-interested-in-electric-oven' | cmsText | async
                    "
                ></span>
            </label>
        </div>
        <div class="form-check">
            <input
                type="checkbox"
                class="form-check-input"
                id="isInterestedInHeatPump"
                formControlName="isInterestedInHeatPump"
            />
            <label class="custom-control-label" for="isInterestedInHeatPump">
                <span
                    [innerHTML]="
                        'user-is-interested-in-heat-pump' | cmsText | async
                    "
                ></span>
            </label>
        </div>
    </div>

    <app-register-warning
        *ngIf="showFutureEnergyUsageWarning"
        warningKey="future-energy-usage-warning"
        [cmsParams]="{
            futureExpectedElectricityConsumptionPerYear:
                futureExpectedElectricity
        }"
    >
    </app-register-warning>
</div>
