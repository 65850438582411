import { Component } from '@angular/core';

import { CommunityService } from '@common/services/community.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
})
export class FooterComponent {
    year = new Date().getFullYear();
    links = this.generateFooterLinkData();

    get communityCode(): string {
        return this.communityService.communityCode;
    }

    constructor(private communityService: CommunityService) {}

    generateFooterLinkData(): { text: string; url: string[] }[] {
        const prefix = 'footer-links';
        const links = [
            'terms-and-conditions',
            'privacy-statement',
            'cookie-policy',
            'disclaimer',
        ];

        return links.map((link) => ({
            text: `${prefix}-${link}`,
            url: [
                '/',
                ...(this.communityCode ? [this.communityCode] : []),
                link,
            ],
        }));
    }
}
