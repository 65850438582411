import { Component, Input } from '@angular/core';
import { RoofStep } from '@spnl/model/roof-step.model';
import { RegisterStepService } from '@spnl/services/register-step.service';
import { RegisterStoreService } from '@spnl/services/register-store.service';
import { RoofDetailsStepService } from '@spnl/services/roof-details-step.service';

@Component({
    selector: 'app-step-title',
    templateUrl: './step-title.component.html',
})
export class StepTitleComponent {
    @Input()
    titleKey: string;

    constructor(
        private registerStore: RegisterStoreService,
        private registerStepService: RegisterStepService,
    ) {}

    get currentStep(): number {
        return this.registerStore.activeRoofStep + 1;
    }

    get stepCount(): number {
        if (this.registerStepService.isInAlternativeFlow) {
            return RoofDetailsStepService.allSteps.length + 1;
        } else {
            return RoofDetailsStepService.allSteps.length;
        }
    }

    get currentStepKey(): string {
        return RoofStep.headerKeys[this.registerStore.activeRoofStep];
    }

    get shouldShowAllSteps(): boolean {
        return this.registerStore.hasNoActiveRoofStep();
    }
}
