import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentCanDeactivate } from '@common/infrastructure/pending-changes.guard';
import { CommunityService } from '@common/services/community.service';
import { KenalyticsService } from '@common/services/kenalytics.service';
import { PendingChangesService } from '@common/services/pending-changes.service';
import { VwoStoreService } from '@common/services/vwo-store.service';
import { RegisterStep } from '@spnl/model/register-step.enum';
import { Registration } from '@spnl/model/registration';
import { RegisterAnalyticsService } from '@spnl/services/register-analytics.service';
import { RegisterEffectsService } from '@spnl/services/register-effects.service';
import { RegisterStoreService } from '@spnl/services/register-store.service';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DestroyableBase } from '../destroyable/destroyable.component';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    providers: [RegisterEffectsService],
})
export class RegisterComponent
    extends DestroyableBase
    implements OnInit, ComponentCanDeactivate
{
    get registration(): Registration {
        return this.registerStore.registration;
    }
    get activeStep(): string {
        return this.registerStore.activeStep;
    }
    get serverValidations(): string[] {
        return this.registerStore.serverValidations;
    }
    get serverError(): HttpErrorResponse | undefined {
        return this.registerStore.serverError;
    }

    // Suppress linting, leaving this as is for legacy reasons (not worth the risk of refactoring)
    RegisterStep = RegisterStep;

    get communityCode() {
        return this.communityService.communityCode;
    }

    get showStatusMessage(): boolean {
        return this.registerStore.hasRequestedDuplicateLinks();
    }

    get showStepper(): boolean {
        return this.registerStore.showStepper();
    }

    get showAuctionFinishedError(): boolean {
        const pagesNotToShowError = [RegisterStep.DecisionMade];
        return (
            this.registration?.auction?.finished &&
            !pagesNotToShowError.includes(this.registerStore.activeStep) &&
            this.registration?.status != 'Accepted'
        );
    }

    constructor(
        private communityService: CommunityService,
        private route: ActivatedRoute,
        private analytics: RegisterAnalyticsService,
        private pendingChangesService: PendingChangesService,
        private registerStore: RegisterStoreService,
        private registerEffects: RegisterEffectsService,
        private kenalyticsService: KenalyticsService,
        private vwoStoreService: VwoStoreService,
    ) {
        super();
        registerEffects.trackStateAndSubstateChanges();
    }

    ngOnInit(): void {
        const start = this.route.snapshot.queryParamMap.get('start') || null;
        if (start === RegisterStep.Product) {
            this.registerStore.startedWithStep = start;
        }
        this.registerEffects.loadRegistration();
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.pendingChangesService.pendingChanges;
    }

    analyticsEvent(action: string): void {
        this.analytics.logEvent(this.registration, action);
    }
}
