<ng-container *ngIf="!loading">
    <h3 class="my-3">{{ cmsHeaderKey | cmsText | async }}</h3>
    <hr class="my-3" />

    <ng-container *ngIf="showPaymentIntroduction">
        <div class="row">
            <div class="col-sm-12">
                <p class="alert alert-info">
                    {{ 'payment-status-nopayment' | cmsText | async }}
                </p>
            </div>
        </div>

        <span
            [innerHTML]="
                'payment-nodeposit-description'
                    | cmsText : [{ supplierName: registration.supplierName }]
                    | async
            "
        ></span>
    </ng-container>

    <app-register-deposit-failed
        *ngIf="depositFailed"
    ></app-register-deposit-failed>

    <app-register-payment></app-register-payment>
    <app-faq-category categoryKey="deposit-page"></app-faq-category>
</ng-container>

<app-loading-spinner *ngIf="loading"></app-loading-spinner>
