import { Injectable } from '@angular/core';
import { CommunityService } from '@common/services/community.service';
import { RegisterStep } from '@spnl/model/register-step.enum';
import { Registration } from '@spnl/model/registration';
import { StepContext } from '@spnl/model/step-context';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Flow, Flows } from './register-flows';
import { RegisterStateService } from './register-state.service';
import { RegisterStoreService } from './register-store.service';

@Injectable()
export class RegisterStepService {
    _completed = false;
    _revertExclusionClicked = false;

    get wasCompletedInSession(): boolean {
        return !!this._completed;
    }

    set wasCompletedInSession(value: boolean) {
        this._completed = value;
        this.getSessionStorageKey('completed')
            .pipe(take(1))
            .subscribe((key) =>
                sessionStorage.setItem(key, JSON.stringify(!!this._completed)),
            );
    }

    get revertExclusionClickedInSession(): boolean {
        return !!this._revertExclusionClicked;
    }

    set revertExclusionClickedInSession(value: boolean) {
        this._revertExclusionClicked = value;
        this.getSessionStorageKey('revert-exclusion')
            .pipe(take(1))
            .subscribe((key) =>
                sessionStorage.setItem(
                    key,
                    JSON.stringify(!!this._revertExclusionClicked),
                ),
            );
    }

    private getSessionStorageKey(name: string): Observable<string> {
        return this.communityService.communityCode$.pipe(
            map(
                (communityCode) => `registration-spnl-${communityCode}-${name}`,
            ),
        );
    }

    constructor(
        private registerStore: RegisterStoreService,
        private communityService: CommunityService,
        private registerStateService: RegisterStateService,
    ) {
        this.getSessionStorageKey('completed')
            .pipe(take(1))
            .subscribe(
                (key) =>
                    (this._completed = JSON.parse(sessionStorage.getItem(key))),
            );

        this.getSessionStorageKey('revert-exclusion')
            .pipe(take(1))
            .subscribe(
                (key) =>
                    (this._revertExclusionClicked = JSON.parse(
                        sessionStorage.getItem(key),
                    )),
            );
    }

    get activeFlowSteps(): RegisterStep[] {
        return this.activeFlow(this.registerStore.registration).flowSteps;
    }

    get isInAlternativeFlow(): boolean {
        return this.registerStore.startedWithStep === RegisterStep.Product;
    }

    get isInStepBeforeWelcome(): boolean {
        const steps = this.activeFlowSteps;
        const welcomeStepIndex = steps.indexOf(RegisterStep.Welcome);
        const stepBeforeWelcome = steps[welcomeStepIndex - 1];
        return this.registerStore.activeStep === stepBeforeWelcome;
    }

    activeFlow(registration: Registration): Flow {
        if (registration.excluded) {
            return this.isInAlternativeFlow
                ? Flows.excludedAlternative
                : Flows.excluded;
        }

        if (registration.cancelled) {
            return Flows.cancelled;
        }

        if (registration.isInInterestedIndividualFlow) {
            return Flows.interestedIndividual;
        }

        if (this.isInAlternativeFlow) {
            return Flows.defaultAlternative;
        }

        if (
            this.registerStateService.registrationIsClosed(registration) &&
            registration.status != 'Accepted'
        ) {
            return Flows.closed;
        }

        return Flows.default;
    }

    previousStep(registration: Registration): RegisterStep {
        const activeFlow = this.activeFlow(registration);
        const currentStepIndex = activeFlow.flowSteps.indexOf(
            this.registerStore.activeStep,
        );
        return activeFlow.flowSteps[currentStepIndex - 1];
    }

    nextStep(registration: Registration): RegisterStep {
        const activeFlow = this.activeFlow(registration);
        const currentStepIndex = activeFlow.flowSteps.indexOf(
            this.registerStore.activeStep,
        );
        const nextStep = activeFlow.flowSteps[currentStepIndex + 1];

        if (currentStepIndex === -1 || !nextStep) {
            // The flow changed and the new flow does not have the current/next step
            return this.getLatestAllowedStep(registration);
        }

        return nextStep;
    }

    stepIsAllowed(registration: Registration, step: string): boolean {
        const ctx = new StepContext(registration);
        return this.activeFlow(registration).isAllowed(step, ctx);
    }

    getLatestAllowedStep(registration: Registration): RegisterStep {
        const ctx = new StepContext(registration);
        return this.activeFlow(ctx.registration).latestAllowedStep(ctx);
    }

    // TODO SPNL-273: Refactor how inRegistrationFlow is used throughout the app
    inRegistrationFlow(registration: Registration): boolean {
        const noRegistration = !registration;
        const subscriptionIncomplete = !registration.subscriptionComplete;
        const isNotComplete = noRegistration || subscriptionIncomplete;
        const isNotCompleteOrWasCompletedInSession =
            isNotComplete || this.wasCompletedInSession;

        return isNotCompleteOrWasCompletedInSession;
    }
}
