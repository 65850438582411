import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { RegisterStep } from '@spnl/model/register-step.enum';
import { Registration } from '@spnl/model/registration';
import { RoofStep } from '@spnl/model/roof-step.model';
import { SolarInstallations } from '@spnl/model/solar-installations';
import { StatusMessage } from '@spnl/model/status-message';

// This class is a poor man's store, until we are ready to use ngrx
@Injectable()
export class RegisterStoreService {
    private _submitting = false;
    private _activeStep: RegisterStep;
    private _activeRoofStep: RoofStep;
    private _stepHistory: RegisterStep[] = [];
    private _registrationLoaded$ = new ReplaySubject<void>(1);

    public startedWithStep: RegisterStep;
    public statusMessage: StatusMessage;
    public registration = new Registration();
    public registrationLoaded$ = this._registrationLoaded$.asObservable();
    public installations: SolarInstallations;

    public serverValidations: string[] = [];
    public serverError: HttpErrorResponse | undefined;

    get stepHistory(): RegisterStep[] {
        return this._stepHistory;
    }

    get previousStep(): RegisterStep {
        return this.stepHistory[this.stepHistory.length - 2];
    }

    get submitting(): boolean {
        return this._submitting;
    }

    startSubmitting(): void {
        this._submitting = true;
    }

    finishSubmitting(): void {
        this._submitting = false;
    }

    get activeStep(): RegisterStep {
        return this._activeStep;
    }

    set activeStep(value: RegisterStep) {
        this._activeStep = value;
    }

    get activeRoofStep(): RoofStep {
        return this._activeRoofStep;
    }

    set activeRoofStep(value: RoofStep) {
        this._activeRoofStep = value;
    }

    get shouldShowAllRoofSteps(): boolean {
        return this.activeRoofStep === null;
    }

    hasVisitedPage(page: RegisterStep): boolean {
        return this.stepHistory.some((s) => s === page);
    }

    hasNoActiveRoofStep(): boolean {
        return this.activeRoofStep === null;
    }

    hasRequestedDuplicateLinks(): boolean {
        return !!this.statusMessage;
    }

    registrationLoaded(registration: Registration): void {
        Object.assign(this.registration, registration);
        this._registrationLoaded$.next();
    }

    showStepper(): boolean {
        switch (this.activeStep) {
            case RegisterStep.Person:
                return !this.hasRequestedDuplicateLinks();
            case RegisterStep.Product:
            case RegisterStep.Welcome:
                return true;
            case RegisterStep.Overview:
            case RegisterStep.Proposal:
            case RegisterStep.DecisionMade:
                return false;
        }
    }
}
