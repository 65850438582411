import { Component, Input } from '@angular/core';
import { CommunityMember, Registration } from '@spnl/model/registration';
import { SolarInstallation } from '@spnl/model/solar-installation';
import { ContentTranslatorService } from '@spnl/services/content-translator.service';
import { InstallationService } from '@spnl/services/installation.service';
import { RegisterAnalyticsService } from '@spnl/services/register-analytics.service';

@Component({
    selector: 'app-register-proposal-installation',
    templateUrl: './proposal-installation.component.html',
})
export class ProposalInstallationComponent {
    @Input()
    registration: Registration;

    @Input()
    premiumPanelsChosen: boolean;

    @Input()
    numberOfPanelsChosen: number;

    @Input()
    displayAsCard: boolean;

    @Input()
    showProposalDetails: boolean;

    @Input()
    higherWattPeakAddOnEnabled: boolean;

    constructor(
        public contentTranslatorService: ContentTranslatorService,
        private installationService: InstallationService,
        private analytics: RegisterAnalyticsService,
    ) {}

    get installation(): SolarInstallation {
        return this.installationService.installation;
    }

    get supplierCode(): string {
        return this.registration.proposalMade
            ? this.registration.proposal.supplier.code
            : '';
    }

    get supplierProductImage(): string {
        return `proposal-product-image-${this.supplierCode}`;
    }

    get supplierProductSpecsFile(): string {
        return this.higherWattPeakAddOnEnabled
            ? `proposal-product-specs-pdf-${this.supplierCode}-wp-add-on`
            : `proposal-product-specs-pdf-${this.supplierCode}`;
    }

    get showCommunityMembershipDisclaimer(): boolean {
        return (
            this.registration.product.communityMember !== CommunityMember.Yes
        );
    }

    get communityMembershipPrice(): string {
        return this.registration.product.isHomeOwnerAssociationMember
            ? 'proposal-installation-community-membership-homeowner-association-price'
            : 'proposal-installation-community-membership-price';
    }

    analyticsEvent(action: string): void {
        this.analytics.logEvent(this.registration, action);
    }
}
