import { AfterViewInit, Component } from '@angular/core';
import { Registration } from '@spnl/model/registration';
import { DigitalEventService } from '@spnl/services/digital-event-queue.service';
import { RegisterStoreService } from '@spnl/services/register-store.service';

@Component({
    selector: 'app-register-email-confirmed',
    templateUrl: './email-confirmed.component.html',
})
export class EmailConfirmedComponent implements AfterViewInit {
    get registration(): Registration {
        return this.registerStore.registration;
    }

    get faqItems(): string[] {
        return [
            'why-participate',
            'supplier-qualification',
            'yield',
            'faq-prosumer-tariff',
        ];
    }

    get cmsKeyForProposal(): string {
        return this.registration.proposalMade
            ? 'overview-next-steps-post-auction-registration-text'
            : 'overview-next-steps-text';
    }

    constructor(
        private registerStore: RegisterStoreService,
        private digitalEventService: DigitalEventService,
    ) {}

    ngAfterViewInit() {
        this.digitalEventService.push('overview e-mail bevestigd');
    }
}
