<ng-container *ngIf="!loading">
    <ng-container
        *ngIf="
            !auctionFinished && !afterPaymentDeadline;
            else paymentNoLongerPossible
        "
    >
        <app-register-deposit></app-register-deposit>
    </ng-container>

    <ng-template #paymentNoLongerPossible>
        <span
            [innerHTML]="'payment-auction-finished-text' | cmsText | async"
        ></span>
    </ng-template>
</ng-container>

<div *ngIf="loading">
    <app-loading-spinner size="large"></app-loading-spinner>
</div>
