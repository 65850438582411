import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-register-text-error',
    templateUrl: './text-error.component.html',
})
export class RegisterTextErrorComponent {
    @Input()
    errorKey: string;
    @Input()
    cmsParams = {};
}
