<app-hero
    headerKey="cancel-header"
    heroImageKey="hero-image-secondary"
></app-hero>

<div class="fluid-container bg-highlight-one pt-2 pb-5">
    <div class="container">
        <app-registration-bar
            *ngIf="registration"
            [registration]="registration"
        >
        </app-registration-bar>

        <div class="card shadow border-0 mb-1">
            <div class="card-body">
                <app-cancel-error
                    *ngIf="showLoadError"
                    errorKey="cancel-load-error"
                >
                </app-cancel-error>

                <ng-container *ngIf="showOverview">
                    <app-cancel-overview-title [registration]="registration">
                    </app-cancel-overview-title>

                    <app-cancel-error
                        *ngIf="cancellationApproved"
                        errorKey="cancel-already-cancelled"
                    >
                    </app-cancel-error>

                    <ng-container *ngIf="showSuccess || cancellationRequested">
                        <app-cancel-success></app-cancel-success>
                        <app-cancel-next-steps></app-cancel-next-steps>
                    </ng-container>
                </ng-container>

                <div class="row mt-3" *ngIf="showForm">
                    <app-cancel-form
                        class="col-md-8"
                        [registration]="registration"
                        [supplier]="supplier"
                        (successfulSubmit)="onSuccessfulSubmit()"
                    >
                    </app-cancel-form>

                    <app-cancel-sidebar
                        class="col-md-4"
                        [registration]="registration"
                        [supplier]="supplier"
                    >
                    </app-cancel-sidebar>
                </div>
            </div>
        </div>
    </div>
</div>
