import { Component, OnInit, Inject } from '@angular/core';
import { COOKIE_POLICY_URL, BUSINESS } from '../../app/app.config';
import { CommunityService } from '../../services/community.service';

@Component({
    selector: 'app-cookie-policy',
    template: `
    <div class="container">
    <h2 class="mt-5 mb-3">{{ 'cookie-policy-title' | cmsText | async }}</h2>
    <p [innerHtml]="'cookie-policy-message' | cmsText | async"></p>
    <p id="scriptTag"></p>
    </div>
    `,
})

export class CookiePolicyComponent implements OnInit {

    constructor(
        @Inject(COOKIE_POLICY_URL) protected cookiePolicyUrl: string,
        @Inject(BUSINESS) protected business: string,
        protected communityService: CommunityService
    ) { }

    ngOnInit() {
        if (this.cookiePolicyUrl && this.cookiePolicyUrl.length > 0) {
            const src = this.determineCookiePolicyUrl();
            const node = document.createElement('script');
            node.id = 'CookiePolicy';
            node.src = src;
            node.type = 'text/javascript';
            node.async = true;
            document.getElementById('scriptTag').appendChild(node);
        }
    }

    private determineCookiePolicyUrl(): string {
        if (this.business === 'spbe') {
            switch (this.communityService.communityCode) {
                case 'oost-vlaanderen':
                    return 'https://consent.cookiebot.com/bb35d961-ea97-4b9d-8157-dfcb982a814f/cd.js';
                case 'hbvl':
                case 'ichoosr': return 'https://consent.cookiebot.com/bb35d961-ea97-4b9d-8157-dfcb982a814f/cd.js';
                default: return this.cookiePolicyUrl;
            }
        } else {
            return this.cookiePolicyUrl;
        }
    }
}
