import {
    AfterViewInit,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';

import {
    applyFormValue,
    ControlWithErrors,
    makeFormModel,
    shouldShowErrorsFor,
} from '@common/infrastructure/form-tools';
import { DestroyableBase } from '@spnl/components/destroyable/destroyable.component';
import { RoofStepFormGroup } from '@spnl/forms/roof-form-tools';
import { RegisterStep } from '@spnl/model/register-step.enum';
import { Registration } from '@spnl/model/registration';
import { DigitalEventService } from '@spnl/services/digital-event-queue.service';
import { EnergyUsageMonth } from 'enjp/app/model/registration';

@Component({
    selector: 'app-register-roof-energy',
    templateUrl: './roof-energy.component.html',
})
export class RoofEnergyComponent
    extends DestroyableBase
    implements OnInit, OnDestroy, AfterViewInit {
    @Input()
    registration: Registration;

    @Input()
    parentForm: UntypedFormGroup;

    form: RoofStepFormGroup;
    showWarningForConsumption = false;

    electricityConsumptionPerYear: ControlWithErrors;
    howManyPeopleInHousehold: ControlWithErrors;
    doesUserKnowEnergyUsage: ControlWithErrors;
    isInterestedInAirconditioning: ControlWithErrors;
    isInterestedInElectricCar: ControlWithErrors;
    isInterestedInElectricOven: ControlWithErrors;
    isInterestedInHeatPump: ControlWithErrors;
    futureExpectedElectricityConsumptionPerYear: number;


    readonly additionalElectricityAirconditioning = 500;
    readonly additionalElectricityElectricCar = 3000;
    readonly additionalElectricityElectricOven = 200;
    readonly additionalElectricityHeatPump = 3000;

    readonly electricityConsumptionForOnePerson = 2000;
    readonly electricityConsumptionForTwoPerson = 2800;
    readonly electricityConsumptionForThreePerson = 3500;
    readonly electricityConsumptionForFourPerson = 4500;
    readonly electricityConsumptionForFivePerson = 5000;

    constructor(
        private fb: UntypedFormBuilder,
        private digitalEventService: DigitalEventService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.electricityConsumptionPerYear = this.fb.control('', [
            Validators.required,
            Validators.min(1),
            Validators.pattern('\\d*'),
        ]);
        this.form = this.fb.group({
            electricityConsumptionPerYear: this.electricityConsumptionPerYear,
            howManyPeopleInHousehold: this.howManyPeopleInHousehold,
            isInterestedInAirconditioning: this.isInterestedInAirconditioning,
            isInterestedInElectricOven: this.isInterestedInElectricOven,
            isInterestedInElectricCar: this.isInterestedInElectricCar,
            isInterestedInHeatPump: this.isInterestedInHeatPump,
            doesUserKnowEnergyUsage: this.doesUserKnowEnergyUsage
        }) as RoofStepFormGroup;

        this.howManyPeopleInHousehold = this.form.get('howManyPeopleInHousehold');
        this.isInterestedInAirconditioning = this.form.get('isInterestedInAirconditioning');
        this.isInterestedInElectricOven = this.form.get('isInterestedInElectricOven');
        this.isInterestedInElectricCar = this.form.get('isInterestedInElectricCar');
        this.isInterestedInHeatPump = this.form.get('isInterestedInHeatPump');
        this.doesUserKnowEnergyUsage = this.form.get('doesUserKnowEnergyUsage');

        this.form.onSubmit = this.onSubmit.bind(this);
        this.parentForm.addControl('roof-energy', this.form);

        const model = makeFormModel(this.form, this.registration.product, []);
        this.form.reset(model);

        this.electricityConsumptionPerYear.valueChanges
            .pipe(takeUntil(this.destroyed$), debounceTime(500))
            .subscribe((value) => {
                value = parseFloat(value);
                this.showWarningForConsumption =
                    !!value || value === 0
                        ? value < 1100 || value > 100000
                        : false;
            });

            this.howManyPeopleInHousehold.valueChanges
            .pipe(takeUntil(this.destroyed$), debounceTime(500))
            .subscribe((value) => {

                value = parseFloat(value);
                this.electricityConsumptionPerYear.setValue(this.getCurrentElectricityUsageBasedOnPeopleInHouseHold());
            });
    }

    ngAfterViewInit(): void {
        this.digitalEventService.push('verbruik');
    }

    get showErrorsForConsumption(): boolean {
        return shouldShowErrorsFor(this.electricityConsumptionPerYear);
    }

    get showErrorsForHowManyPeopleInHousehold(): boolean{

        return false;
    }

    get showErrorsForKnowsEnergyUsage(): boolean{
        return !!this.doesUserKnowEnergyUsage?.value == null;
    }

    get showHouseholdMemberQuestion(): boolean{
        return this.doesUserKnowEnergyUsage?.value === false;
    }

    get showEnergyUsageQuestion(): boolean{
        return this.doesUserKnowEnergyUsage?.value === true;
    }

    get showFutureEnergyUsageWarning(): boolean{
        return this.isInterestedInAirconditioning?.value === true ||
        this.isInterestedInElectricCar?.value === true ||
        this.isInterestedInElectricOven?.value === true ||
        this.isInterestedInHeatPump?.value === true;
    }

    get futureExpectedElectricity(): number{
        if (this.doesUserKnowEnergyUsage?.value == null) {return 0}
        let futureEnergy = 0;

        if (this.doesUserKnowEnergyUsage.value)
        {
            futureEnergy = parseInt(this.electricityConsumptionPerYear.value);
        }
        else
        {
           futureEnergy = this.getCurrentElectricityUsageBasedOnPeopleInHouseHold();
        }

        if(this.isInterestedInAirconditioning.value)
        futureEnergy+= this.additionalElectricityAirconditioning;
        if(this.isInterestedInElectricOven.value)
        futureEnergy+= this.additionalElectricityElectricOven;
        if(this.isInterestedInElectricCar.value)
        futureEnergy+= this.additionalElectricityElectricCar;
        if(this.isInterestedInHeatPump.value)
        futureEnergy+= this.additionalElectricityHeatPump;

        return futureEnergy;
    }

    private getCurrentElectricityUsageBasedOnPeopleInHouseHold():number
    {
        switch(this.howManyPeopleInHousehold.value)
        {
         case 1: return this.electricityConsumptionForOnePerson;
         case 2: return this.electricityConsumptionForTwoPerson;
         case 3: return this.electricityConsumptionForThreePerson;
         case 4: return this.electricityConsumptionForFourPerson;
         case 5: return this.electricityConsumptionForFivePerson;
         default:  return 0;
        }
    }

    onSubmit(): void {
        this.form.markAllAsTouched();

        const roundedValue = Math.round(
            this.electricityConsumptionPerYear.value,
        );
        if (!isNaN(roundedValue)) {
            this.electricityConsumptionPerYear.setValue(roundedValue);
        }

        if (this.form.valid) {
            this.registration.product.futureExpectedElectricityConsumptionPerYear = this.futureExpectedElectricity;
            applyFormValue(this.form, this.registration.product, []);
        }
    }

    ngOnDestroy(): void {
        this.parentForm?.removeControl('roof-energy');
        super.ngOnDestroy();
    }
}
