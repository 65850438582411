import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { AppCommonModule } from '@common/app-common.module';
import { CtaRegisterOrRooftestComponent } from './cta-register-or-rooftest/cta-register-or-rooftest.component';
import { CtaRegisterComponent } from './cta-register/cta-register.component';

@NgModule({
    imports: [CommonModule, BrowserModule, RouterModule, AppCommonModule],
    declarations: [CtaRegisterComponent, CtaRegisterOrRooftestComponent],
    exports: [CtaRegisterComponent, CtaRegisterOrRooftestComponent],
})
export class CallToActionModule {}
