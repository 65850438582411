<div [formGroup]="form">
    <div class="mb-3">
        <label for="isHomeOwner">
            {{ 'is-home-owner-label' | cmsText | async }}
        </label>
        <div class="form-check">
            <input
                type="radio"
                name="isHomeOwner"
                class="form-check-input"
                [ngClass]="{
                    'is-invalid': showErrorsForIsHomeOwner
                }"
                id="isHomeOwnerTrue"
                formControlName="isHomeOwner"
                [value]="true"
            />
            <label class="custom-control-label" for="isHomeOwnerTrue">
                {{ 'is-home-owner-yes' | cmsText | async }}
            </label>
        </div>
        <div class="form-check">
            <input
                type="radio"
                name="isHomeOwner"
                class="form-check-input"
                [ngClass]="{
                    'is-invalid': showErrorsForIsHomeOwner
                }"
                id="isHomeOwnerFalse"
                formControlName="isHomeOwner"
                [value]="false"
                (click)="
                    pushRoofNotSuitableAnalyticsEvent('not_home_owner_click')
                "
            />
            <label class="custom-control-label" for="isHomeOwnerFalse">
                {{ 'is-home-owner-no' | cmsText | async }}
            </label>
        </div>
        <app-register-input-errors
            [shouldShowErrors]="showWarningForIsHomeOwner"
            [field]="isHomeOwner"
            label="question"
        >
        </app-register-input-errors>
        <app-register-error
            *ngIf="showErrorsForIsHomeOwner"
            errorKey="home-owner-error-unsupported-with-exclude-link"
        >
            <app-register-exclude-btn
                (click)="onExcludeRegistration('not_home_owner_exit')"
            >
            </app-register-exclude-btn>
        </app-register-error>
    </div>

    <div class="mb-3">
        <label for="isHomeOwnerAssociationMember">
            {{ 'is-home-owner-association-member-label' | cmsText | async }}
        </label>
        <div class="form-check">
            <input
                type="radio"
                name="isHomeOwnerAssociationMember"
                [ngClass]="{
                    'form-check-input': true,
                    'is-invalid': showErrorsForIsHomeOwnerAssociationMember
                }"
                id="isHomeOwnerAssociationMemberTrue"
                formControlName="isHomeOwnerAssociationMember"
                [value]="true"
                (click)="
                    pushRoofNotSuitableAnalyticsEvent(
                        'home_owner_association_click'
                    )
                "
            />
            <label
                class="custom-control-label"
                for="isHomeOwnerAssociationMemberTrue"
            >
                {{ 'yes' | cmsText | async }}
            </label>
        </div>
        <div class="form-check">
            <input
                type="radio"
                name="isHomeOwnerAssociationMember"
                [ngClass]="{
                    'form-check-input': true,
                    'is-invalid': showErrorsForIsHomeOwnerAssociationMember
                }"
                id="isHomeOwnerAssociationMemberFalse"
                formControlName="isHomeOwnerAssociationMember"
                [value]="false"
            />
            <label
                class="custom-control-label"
                for="isHomeOwnerAssociationMemberFalse"
            >
                {{ 'no' | cmsText | async }}
            </label>
        </div>
        <app-register-input-errors
            [shouldShowErrors]="showWarningForIsHomeOwnerAssociationMember"
            [field]="isHomeOwnerAssociationMember"
            label="question"
        >
        </app-register-input-errors>
        <app-register-error
            *ngIf="showErrorsForIsHomeOwnerAssociationMember"
            errorKey="home-owner-association-error-unsupported-with-exclude-link"
        >
            <app-register-exclude-btn
                (click)="onExcludeRegistration('vve_exit')"
            >
            </app-register-exclude-btn>
        </app-register-error>
    </div>

    <div class="mb-3">
        <label for="currentSolarPanelSituation">
            {{ 'solar-panel-situation-label' | cmsText | async }}
        </label>
        <div class="form-check">
            <input
                type="radio"
                name="currentSolarPanelSituation"
                [ngClass]="{
                    'form-check-input': true
                }"
                id="solarPanelSituationNoPanels"
                formControlName="currentSolarPanelSituation"
                [value]="Decision.NoCurrentSystemInstalled"
            />
            <label
                class="custom-control-label"
                for="solarPanelSituationNoPanels"
            >
                {{ 'solar-panel-situation-no-panels-label' | cmsText | async }}
            </label>
        </div>
        <div class="form-check">
            <input
                type="radio"
                name="currentSolarPanelSituation"
                [ngClass]="{
                    'form-check-input': true
                }"
                id="solarPanelSituationExpand"
                formControlName="currentSolarPanelSituation"
                [value]="Decision.WantToExpandCurrentSystem"
            />
            <label class="custom-control-label" for="solarPanelSituationExpand">
                {{ 'solar-panel-situation-expand-label' | cmsText | async }}
            </label>
        </div>
        <div class="form-check">
            <input
                type="radio"
                name="currentSolarPanelSituation"
                [ngClass]="{
                    'form-check-input': true
                }"
                id="solarPanelSituationReplace"
                formControlName="currentSolarPanelSituation"
                [value]="Decision.WantToReplaceCurrentSystem"
            />
            <label
                class="custom-control-label"
                for="solarPanelSituationReplace"
            >
                {{ 'solar-panel-situation-replace-label' | cmsText | async }}
            </label>
        </div>
        <app-register-input-errors
            [shouldShowErrors]="showWarningForSolarPanelSituation"
            [field]="currentSolarPanelSituation"
            label="question"
        >
        </app-register-input-errors>
    </div>
</div>
