<div class="card shadow my-3">
    <h5
        class="card-header border-0"
        [innerHTML]="'proposal-register-details-title' | cmsText | async"
    ></h5>
    <div class="card-body mb-0">
        <app-proposal-contact-data [registration]="registration">
        </app-proposal-contact-data>

        <hr />

        <app-proposal-roof-data [registration]="registration">
        </app-proposal-roof-data>
    </div>
</div>
