import { DecimalPipe } from '@angular/common';
import { PipeTransform, Pipe } from '@angular/core';
import { isNullOrUndefined } from '@common/util';

@Pipe({ name: 'roundToNearest' })
export class RoundToNearestPipe extends DecimalPipe implements PipeTransform {
    // See: https://stackoverflow.com/a/64806953
    // For why these overloads are needed.
    override transform(
        value: number | string,
        digitsInfo?: string,
        locale?: string,
    ): string | null;
    override transform(
        value: null | undefined,
        digitsInfo?: string,
        locale?: string,
    ): null;
    override transform(
        value: number,
        toNearest: string,
        digitsInfo?: string,
        locale?: string,
    ): string {
        if (isNullOrUndefined(value)) {
            return null;
        }
        if (isNullOrUndefined(toNearest)) {
            return null;
        }

        const roundedValue =
            Math.floor(value / Number(toNearest) + 0.5) * Number(toNearest);
        return super.transform(roundedValue, digitsInfo, locale);
    }
}
