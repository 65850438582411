import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_URL } from '@common/app/app.config';
import { Registration } from '@spnl/model/registration';
import { CancelDelivery } from '@spnl/model/cancel-delivery.model';
import { PaymentMethod } from '@spnl/model/payment-method.enum';
import { Seminar } from '@spnl/model/seminar';
import { Observable } from 'rxjs';
import { CancelRegistration } from '@common/model/cancel-registration';

@Injectable()
export class RegistrationApiService {
    private readonly url: string;
    private readonly baseUrl: string;

    constructor(private http: HttpClient, @Inject(API_URL) baseUrl: string) {
        this.url = baseUrl + 'registrations';
        this.baseUrl = baseUrl;
    }

    participateInSeminar(
        registration: Registration,
        seminarId: string,
    ): Observable<void> {
        const url =
            this.url + '/' + registration.id + '/participate-in-seminar';
        return this.http.put<void>(url, { seminarId });
    }

    unsubscribeFromSeminar(registration: Registration): Observable<void> {
        return this.http.delete<void>(
            `${this.url}/${registration.id}/unsubscribe-from-seminar`,
        );
    }

    seminars(registration: Registration): Observable<Seminar[]> {
        return this.http.get<Seminar[]>(
            `${this.url}/${registration.id}/seminars`,
        );
    }

    whoInitiatedTheCancellation(): Observable<string[]> {
        const url = `${this.baseUrl}registrations/delivery/who-initiated-cancellation`;
        return this.http.get<string[]>(url);
    }

    allowedCancellationReasons(): Observable<string[]> {
        const url = `${this.baseUrl}registrations/allowed-cancellation-reasons-for-consumer`;
        return this.http.get<string[]>(url);
    }

    allowedCancellationSubreasons(
        cancellationReason: string,
    ): Observable<string[]> {
        const url = `${this.baseUrl}registrations/allowed-cancellation-subreasons`;
        return this.http.post<string[]>(url, { cancellationReason });
    }

    requestDeliveryCancellation(
        registrationId: string,
        cancelDelivery: CancelDelivery,
    ): Observable<void> {
        const url = `${this.baseUrl}registrations/${registrationId}/delivery/request-cancellation-as-consumer`;
        return this.http.post<void>(url, cancelDelivery);
    }

    sendLinkToRegistration(registrationId: string): Observable<void> {
        const url = `${this.baseUrl}registrations/${registrationId}/send-participant-link`;
        return this.http.post<void>(url, {});
    }

    updatePaymentMethod(
        registrationId: string,
        paymentMethod: PaymentMethod,
    ): Observable<void> {
        const url = `${this.baseUrl}registrations/${registrationId}/update-payment-method`;
        return this.http.put<void>(url, { paymentMethod });
    }

    toggleCancellation(
        registrationId: string,
        cancelRegistration: CancelRegistration,
    ): Observable<void> {
        const url = `${this.baseUrl}registrations/${registrationId}/cancel`;
        return this.http.post<void>(url, { cancelRegistration });
    }

    setProposalVisited(registrationId: string): Observable<void> {
        const url = `${this.baseUrl}registrations/${registrationId}/set-proposal-visited`;
        return this.http.put<void>(url, null);
    }

    rolloverToTargetAuction(
        registrationId: string,
        auctionCode: string,
    ): Observable<string> {
        const url = `${this.url}/${registrationId}/rollover-to-target-auction/${auctionCode}`;
        return this.http.post<string>(url, null);
    }

    checkForRolloverToTargetAuction(
        registrationId: string,
        auctionCode: string,
    ): Observable<string> {
        const url = `${this.url}/${registrationId}/check-for-rollover-to-target-auction/${auctionCode}`;
        return this.http.post<string>(url, null);
    }

    revertDecision(registration: Registration): Observable<void> {
        return this.http.post<void>(
            `${this.url}/${registration.id}/revert-decision-as-user`,
            null,
        );
    }
}
