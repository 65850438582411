import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaymentStatus } from '@common/model/payment';
import { VwoService } from '@common/services/vwo.service';
import { RegisterStep } from '@spnl/model/register-step.enum';
import { Referrer, ReferrerService } from '@spnl/services/referrer.service';
import { RegisterStoreService } from '@spnl/services/register-store.service';

@Component({
    selector: 'app-register-overview',
    templateUrl: './overview.component.html',
})
export class OverviewComponent implements AfterViewInit {
    get registration() {
        return this.registerStore.registration;
    }

    get showInterestedIndividualPage(): boolean {
        return this.registration.isInInterestedIndividualFlow;
    }

    get showDepositPendingPage(): boolean {
        const showOverview =
            this.route?.snapshot?.queryParamMap?.get('showOverview') || null;

        return (
            showOverview !== 'true' &&
            this.registration.paymentStatus === PaymentStatus.Pending
        );
    }

    get showPaymentSuccessPage(): boolean {
        return (
            this.referrerService.referrer === Referrer.Buckaroo &&
            this.registration.paymentStatus === PaymentStatus.Successful
        );
    }

    get showOverviewDetails(): boolean {
        const showOverview =
            this.route?.snapshot?.queryParamMap?.get('showOverview') || null;

        return (
            showOverview === 'true' ||
            (this.referrerService.referrer !== Referrer.Buckaroo &&
                this.registration.paymentStatus === PaymentStatus.Successful)
        );
    }

    constructor(
        private vwoService: VwoService,
        private registerStore: RegisterStoreService,
        private referrerService: ReferrerService,
        private route: ActivatedRoute,
    ) {}

    ngAfterViewInit(): void {
        this.vwoService.push(RegisterStep.Overview);
    }
}
