import { Component, Input } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { Router } from '@angular/router';
import { User } from '@common/model/user';
import { AuthService } from '@common/services/auth.service';
import { DestroyableBase } from '@spnl/components/destroyable/destroyable.component';
import { Registration } from '@spnl/model/registration';
import { RegisterStoreService } from '@spnl/services/register-store.service';
import { RegistrationService } from '@spnl/services/registration.service';

@Component({
    selector: 'app-user-card',
    templateUrl: 'user-card.component.html',
})
export class UserCardComponent extends DestroyableBase {
    user: User;
    @Input() registrationNumber: string;

    get isBackofficeUser(): boolean {
        return this.user && this.user.role !== 'participant';
    }

    get isParticipant(): boolean {
        return this.user && this.user.role === 'participant';
    }

    get communityCode(): string {
        return this.registerStore.registration.community.code;
    }

    constructor(
        private authService: AuthService,
        private registerStore: RegisterStoreService,
        private registrationService: RegistrationService,
        private router: Router,
    ) {
        super();

        authService.identity$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((identity) => (this.user = identity));
    }

    logout(): void {
        this.authService.logout();
        this.registerStore.registration = new Registration();
        this.registrationService.saveToSessionStorage(new Registration());
        this.router.navigate(['/']);
    }
}
