import { Injectable } from '@angular/core';
import { Flow } from '@common/model/data-layer';
import { PaymentStatus } from '@common/model/payment';
import {
    FlowData,
    RegisterFlowCalculatorBase,
} from '@common/services/register-flow-calculator.service.base';
import { RegisterStep } from '@spnl/model/register-step.enum';

@Injectable()
export class RegisterFlowCalculator extends RegisterFlowCalculatorBase {
    flow(data: FlowData): Flow {
        const isInInterestedIndividualFlow = !data.registration.auction;
        data.startedWithStep = data.enteredFlowFromLink
            ? 'link'
            : data.startedWithStep
            ? data.startedWithStep
            : RegisterStep.Person;

        if (isInInterestedIndividualFlow) {
            data.startedWithStep = 'interested-individual';
        }

        if (
            data.queryParamMap.get('returnfrom') === 'buckaroo' &&
            data.registration.paymentStatus === PaymentStatus.Cancelled
        ) {
            return { start: data.startedWithStep, action: 'depositCancelled' };
        }
        if (
            data.queryParamMap.get('returnfrom') === 'buckaroo' &&
            data.registration.paymentStatus === PaymentStatus.Failed
        ) {
            return { start: data.startedWithStep, action: 'depositFailed' };
        }
        if (
            data.queryParamMap.get('returnfrom') === 'buckaroo' &&
            data.registration.paymentStatus === PaymentStatus.Pending
        ) {
            return { start: data.startedWithStep, action: 'depositPending' };
        }
        if (
            data.queryParamMap.get('returnfrom') === 'buckaroo' &&
            data.registration.paymentStatus === PaymentStatus.Successful
        ) {
            return { start: data.startedWithStep, action: 'depositMade' };
        }

        return super.flow(data);
    }
}
