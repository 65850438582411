export class Supplier {
    id: string;
    code: string;
    name: string;
    iban: string;

    constructor(dto: any = {}) {
        Object.assign(this, dto);
    }
}
