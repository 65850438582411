<ng-container *ngIf="!loading">
    <h3 class="my-3">{{ headerCmsKey | cmsText | async }}</h3>
    <hr class="my-3" />

    <app-hero
        heroImageKey="hero-image-overview"
        heroImageClass="hero-image-medium mb-3"
    ></app-hero>

    <div class="alert alert-warning">
        <span [innerHTML]="statusCmsKey | cmsText | async"></span>
    </div>

    <span [innerHTML]="descriptionCmsKey | cmsText | async"></span>

    <div class="mt-3">
        <a
            class="btn btn-primary px-5 mt-2 mb-2"
            href="https://www.eigenhuis.nl/diensten/"
        >
            {{ 'excluded-veh-navigation-text' | cmsText | async }}
        </a>
    </div>
</ng-container>

<div *ngIf="loading">
    <app-loading-spinner size="large"></app-loading-spinner>
</div>
