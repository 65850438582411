<ng-container *ngIf="showSubscriptionCompletePage">
    <h3 class="my-3">{{ 'overview-welcome-title' | cmsText | async }}</h3>
    <hr class="my-3" />

    <app-hero
        heroImageKey="hero-image-overview"
        heroImageClass="hero-image-medium mb-3"
    ></app-hero>

    <h6>{{ 'overview-success-status-title' | cmsText | async }}</h6>
    <p>{{ 'overview-success-status-text' | cmsText | async }}</p>

    <div class="alert alert-info" role="alert">
        <h6>
            <i class="fa fa-envelope"></i>
            {{ 'overview-confirm-email-title' | cmsText | async }}
        </h6>
        {{ 'overview-confirm-email-text' | cmsText | async }}
    </div>
    <app-register-survey-cta
        [registrationNumber]="registration.number"
        [auctionCode]="registration.auction?.code"
        [registrationStatus]="registration.status"
        [supplierName]="registration.supplierName"
        cmsKey="survey-cta-body-completed"
    >
    </app-register-survey-cta>

    <app-register-overview-next-steps
        [registration]="registration"
        nextStepsCmsKey="overview-next-steps-text"
    >
    </app-register-overview-next-steps>
    <hr class="my-3" />

    <app-register-faq [faqItems]="faqItems" *ngIf="faqItems"></app-register-faq>

    <div class="mb-3 row mt-3">
        <div class="col-sm-4 text-start">
            <button
                type="button"
                class="btn btn-outline-secondary"
                (click)="goBack()"
            >
                {{ 'go-back-button' | cmsText | async }}
            </button>
        </div>
    </div>
</ng-container>

<app-register-email-confirmed *ngIf="showEmailConfirmedPage">
</app-register-email-confirmed>
