import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { YoutubeService } from '@common/services/youtube.service';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[youtubemovie]',
})
export class YoutubeMovieDirective implements OnInit {
    @Input('youtubemovie') public input: string;

    constructor(
        private el: ElementRef,
        private youtubeService: YoutubeService,
    ) {}

    ngOnInit() {
        const videoId = this.input.includes('youtube')
            ? /youtube-([a-zA-Z0-9\-_]+)/.exec(this.input)?.[1] // for if we are changing the cms keys from {{youtube-id}} to just id
            : this.input;

        if (!videoId) return;

        this.youtubeService
            .setupPlayerWithThumbnail(this.el.nativeElement, videoId)
            .subscribe();
    }
}
