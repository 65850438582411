import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { applyFormValue } from '@common/infrastructure/form-tools';
import { Community } from '@common/model/community';
import { CommunityService } from '@common/services/community.service';
import { ContentService } from '@common/services/content.service';
import { KenalyticsService } from '@common/services/kenalytics.service';
import { PendingChangesService } from '@common/services/pending-changes.service';
import { VwoStoreService } from '@common/services/vwo-store.service';
import { DestroyableBase } from '@spnl/components/destroyable/destroyable.component';
import { RoofStepFormGroup } from '@spnl/forms/roof-form-tools';
import { PaymentMethod } from '@spnl/model/payment-method.enum';
import { Product, Registration } from '@spnl/model/registration';
import { take, takeUntil } from 'rxjs/operators';
import { DigitalEventService } from '@spnl/services/digital-event-queue.service';
import { ExclusionService } from './exclusion.service';
import { RegisterAnalyticsService } from './register-analytics.service';
import {
    OnContinue,
    RegisterLocationService,
    Substep,
} from './register-location.service';
import { RegisterStepService } from './register-step.service';
import { RegisterStoreService } from './register-store.service';
import { RegistrationService } from './registration.service';
import { RegistrationApiService } from './registration-api.service';

@Injectable()
export class RegisterSaveService extends DestroyableBase {
    private community: Community;

    constructor(
        private stepService: RegisterStepService,
        private registerStore: RegisterStoreService,
        private locationService: RegisterLocationService,
        private registrationService: RegistrationService,
        private registrationApiService: RegistrationApiService,
        private pendingChangesService: PendingChangesService,
        private contentService: ContentService,
        private kenalyticsService: KenalyticsService,
        private analytics: RegisterAnalyticsService,
        private digitalEventService: DigitalEventService,
        private vwoStoreService: VwoStoreService,
        communityService: CommunityService,
    ) {
        super();
        communityService.community$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((c) => (this.community = c));
    }

    private get registration(): Registration {
        return this.registerStore.registration;
    }

    async excludeAndContinue(
        form: RoofStepFormGroup,
        product: Product,
    ): Promise<void> {
        applyFormValue(form, product, []);

        if (this.registration.hasValidContact) {
            this.analytics.logEvent(this.registration, 'Registration excluded');
            this.registration.excludedReason =
                ExclusionService.getExcludedReason(form.getExclusionContext());
            this.registration.excluded = true;
            await this.saveAndRehydrate();
            this.stepService.revertExclusionClickedInSession = false;
        }
    }

    async noSaveAndContinue(): Promise<void> {
        this.continue();
    }

    async saveAndContinue(): Promise<void> {
        if (this.stepService.isInStepBeforeWelcome) {
            if (!this.registration.subscriptionComplete) {
                this.stepService.wasCompletedInSession = true;
                this.digitalEventService.push('welkomstpagina');
            }
            this.registration.subscriptionComplete =
                !this.registration.excluded;
        }

        await this.save();
        await this.rehydrate();

        this.continue();
    }

    async saveAndRehydrate(): Promise<void> {
        await this.save();
        await this.rehydrate();

        this.registerStore.finishSubmitting();
    }

    async updatePaymentMethod(
        paymentMethod: PaymentMethod,
    ): Promise<boolean | void> {
        await this.registrationApiService
            .updatePaymentMethod(this.registration.id, paymentMethod)
            .toPromise()
            .catch((error) => {
                this.handleError(error);
                return false;
            });

        await this.rehydrate();

        return true;
    }

    async setProposalVisited(registrationId: string): Promise<void> {
        try {
            await this.registrationApiService
                .setProposalVisited(registrationId)
                .toPromise();
        } catch (error) {
            this.handleError(error);
        }
    }

    async toggleCancellation(
        registrationId: string,
        cancelled: boolean,
    ): Promise<void> {
        try {
            await this.registrationApiService
                .toggleCancellation(registrationId, { cancelled })
                .toPromise();
        } catch (error) {
            this.handleError(error);
        }

        await this.rehydrate();
    }

    private async save(): Promise<void> {
        this.registerStore.startSubmitting();
        this.registerStore.serverError = undefined;
        this.registerStore.serverValidations = [];

        try {
            if (!this.registration.id) {
                await this.doCreate();
            } else {
                await this.registrationService
                    .update(this.registration)
                    .toPromise();
            }
        } catch (error) {
            this.handleError(error);
        }
    }

    private async rehydrate(): Promise<void> {
        const registration = this.registerStore.registration;
        const recalculated = await this.registrationService
            .get(registration.id)
            .toPromise();
        this.reloadCmsIfAuctionChanged(registration, recalculated);
        this.registrationService.saveToSessionStorage(recalculated);
        this.pendingChangesService.pendingChanges = false;
        this.registerStore.registrationLoaded(recalculated);
    }

    private continue() {
        switch (this.locationService.onContinue) {
            case OnContinue.Next:
                const nextStep = this.stepService.nextStep(this.registration);
                this.locationService.goTo(nextStep, new Substep.First());
                break;
            case OnContinue.BackToReferrerStep:
                // Sometimes it happens that the referrer step no longer is allowed!
                const targetStep = this.stepService.stepIsAllowed(
                    this.registration,
                    this.registerStore.previousStep,
                )
                    ? this.registerStore.previousStep
                    : this.stepService.nextStep(this.registration);
                this.locationService.goTo(targetStep, new Substep.Latest());
                break;
        }
    }

    private reloadCmsIfAuctionChanged(
        registration: Registration,
        recalculated: Registration,
    ) {
        if (registration.auction && !recalculated.auction) {
            this.contentService.load(null, true);
        } else if (!registration.auction && recalculated.auction) {
            this.contentService.load(recalculated.auction.id);
        } else if (
            registration.auction &&
            recalculated.auction &&
            registration.auction.id !== recalculated.auction.id
        ) {
            this.contentService.load(recalculated.auction.id);
        }
    }

    private async doCreate(): Promise<void> {
        this.registration.communityId = this.community.id;
        const id = await this.registrationService
            .add(this.registration)
            .toPromise();
        this.registration.id = id;
        this.kenalyticsService.postUtmCodes(this.registration.id);

        this.vwoStoreService.distinctVariantName$
            .pipe(take(1), takeUntil(this.destroyed$))
            .subscribe((variantName) => {
                this.kenalyticsService.postAbTest(
                    this.registration.id,
                    'VwoSpnlVariantTest',
                    variantName,
                );
            });
    }

    private handleError(error: HttpErrorResponse) {
        this.registerStore.finishSubmitting();
        if (error.status === 400) {
            let validationErrorRoot = error.error;
            if (error.error && error.error.errors) {
                validationErrorRoot = error.error.errors;
            }

            if (
                typeof validationErrorRoot === 'string' ||
                validationErrorRoot instanceof String
            ) {
                this.registerStore.serverValidations.push(
                    validationErrorRoot as string,
                );
            } else {
                for (const property in validationErrorRoot) {
                    if (validationErrorRoot.hasOwnProperty(property)) {
                        this.registerStore.serverValidations.push(
                            validationErrorRoot[property].join(', '),
                        );
                    }
                }
            }
            console.error(this.registerStore.serverValidations);
        } else {
            this.registerStore.serverError = error;
            console.error(this.registerStore.serverError);
        }
    }
}
