import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'shuffle'
})

export class ShufflePipe implements PipeTransform {
    transform(value: any[], { keepFirstInPlace = false, keepLastInPlace = false } = {}): any[] {
        if (value === null || value.length === 0) { return []; }

        const start = keepFirstInPlace ? 1 : 0;
        // Array.slice end param is exclusive
        const end = value.length - (keepLastInPlace ? 1 : 0);
        let shuffled = [];

        if (keepFirstInPlace) {
            shuffled.push(value[0]);
        }

        shuffled = shuffled.concat(
            value.slice(start, end)
                .map(a => [Math.random(), a])
                .sort((a, b) => a[0] - b[0])
                .map(a => a[1])
        );

        if (keepLastInPlace) {
            shuffled.push(value[value.length - 1]);
        }

        return shuffled;
    }
}
