import { AfterViewInit, Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CommunityService } from '@common/services/community.service';
import { VwoService } from '@common/services/vwo.service';
import {
    getExclusionContextOfVisibleSteps,
    RoofStepFormGroup,
} from '@spnl/forms/roof-form-tools';
import { Registration } from '@spnl/model/registration';
import { RoofStep } from '@spnl/model/roof-step.model';
import { ContentTranslatorService } from '@spnl/services/content-translator.service';
import { ExclusionService } from '@spnl/services/exclusion.service';
import { RegisterStepService } from '@spnl/services/register-step.service';
import { RegisterStoreService } from '@spnl/services/register-store.service';
import { RoofDetailsLocationService } from '@spnl/services/roof-details-location.service';

@Component({
    selector: 'app-register-roof-details',
    templateUrl: './roof-details.component.html',
})
export class RoofDetailsComponent implements AfterViewInit {
    form: UntypedFormGroup;

    RoofStep = RoofStep;

    get registration(): Registration {
        return this.registerStore.registration;
    }

    get shouldShowAllSteps(): boolean {
        return this.registerStore.shouldShowAllRoofSteps;
    }

    get startedWithProduct(): boolean {
        return this.registerStepService.isInAlternativeFlow;
    }

    get anySubStepIsExcluded(): boolean {
        // The form context only considers visible steps, so we use the registration context as a fallback
        const formContext = getExclusionContextOfVisibleSteps(this.form);
        const registrationContext =
            ExclusionService.getExclusionContextBasedOnProduct(
                this.registration.product,
            );
        const combinedContext = { ...registrationContext, ...formContext };
        return ExclusionService.getExcludedReason(combinedContext) !== null;
    }

    get shouldShowRoofYield(): boolean {
        // After a real proposal is made we want to hide the estimated proposal
        return !this.registration.proposalMade;
    }

    constructor(
        public contentTranslatorService: ContentTranslatorService,
        public communityService: CommunityService,
        private vwoService: VwoService,
        private registerStore: RegisterStoreService,
        private registerStepService: RegisterStepService,
        private roofDetailsLocationService: RoofDetailsLocationService,
        private fb: UntypedFormBuilder,
    ) {
        this.form = this.fb.group({});
    }

    ngAfterViewInit(): void {
        this.vwoService.push('productdata');
    }

    onSubmitStep(): void {
        Object.keys(this.form.controls).forEach((key) => {
            const subForm = this.form.controls[key] as RoofStepFormGroup;
            subForm.onSubmit();
        });

        const excludedOnFullProductStep =
            this.shouldShowAllSteps && this.anySubStepIsExcluded;
        if (excludedOnFullProductStep) {
            return;
        }

        if (this.form.valid) {
            if (!this.anySubStepIsExcluded) {
                this.registration.excluded = false;
                this.registration.excludedReason = null;
            }

            this.roofDetailsLocationService.goToNextStep(this.registration);
        }
    }

    shouldShowStep(step: RoofStep): boolean {
        if (this.shouldShowAllSteps) {
            return true;
        }

        return this.registerStore.activeRoofStep === step;
    }
}
