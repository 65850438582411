<app-register-overview-interested-individual
    *ngIf="showInterestedIndividualPage"
>
</app-register-overview-interested-individual>

<app-register-deposit-pending
    *ngIf="showDepositPendingPage"
></app-register-deposit-pending>

<app-register-overview-payment-success *ngIf="showPaymentSuccessPage">
</app-register-overview-payment-success>

<app-register-overview-deposit-paid *ngIf="showOverviewDetails">
</app-register-overview-deposit-paid>
