<div class="card shadow mb-3 border-0" *ngIf="isBackofficeUser">
    <h5 class="card-header bg-primary text-white">
        {{ 'logged-in-as-backoffice-user' | cmsText | async }}
    </h5>
    <div class="card-body">
        <p class="mb-0 nls_protected">
            <i class="fa fa-user text-warning me-2"></i>
            <span>{{ user.email }}</span>
        </p>
    </div>
</div>

<div class="card shadow mb-3 border-0" *ngIf="registrationNumber">
    <h5 class="card-header border-0">
        {{ 'registration-number-card-header' | cmsText | async }}
    </h5>
    <div class="card-body">
        <p class="mb-0">
            <span *ngIf="isParticipant">
                <i class="fa fa-lock text-warning me-2"></i>
                <app-spnl-tooltip
                    cmsKey="logged-in-as-participant-title"
                ></app-spnl-tooltip>
            </span>
            {{ 'registration-number-label' | cmsText | async }}
            <strong id="registrationNumber" class="nls_protected">{{
                registrationNumber
            }}</strong>
            <a
                [routerLink]="['/', communityCode, 'contact-voorkeuren']"
                queryParamsHandling="merge"
                class="d-block"
            >
                <i class="fa fa-arrow-right me-2" aria-hidden="true"></i>
                {{ 'overview-preferences-link' | cmsText | async }}
            </a>
        </p>

        <span *ngIf="isParticipant" class="float-end mt-2">
            <a [routerLink]="[]" (click)="logout()">
                <i class="fa fa-sign-out" aria-hidden="true"></i>
                {{ 'logout-call-to-action' | cmsText | async }}
            </a>
        </span>
    </div>
</div>
