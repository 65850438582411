<h2 class="col-sm-12 text-white">
    <span [innerHTML]="roofTitleKey | cmsText | async"></span>
</h2>
<ng-container *ngIf="hasCommunity">
    <div class="col-12 text-center">
        <button
            class="btn btn-primary mt-3 px-5"
            [routerLink]="['/', community.code, 'register']"
            [disabled]="disableFlow"
        >
            <span [innerHTML]="registerButtonKey | cmsText | async"></span>
            <i class="fa fa-chevron-right ms-2"></i>
        </button>
        <button
            *ngIf="showRoofTest"
            class="btn btn-secondary mt-3 px-5 ms-2"
            [routerLink]="['/', community.code, 'register']"
            [queryParams]="{ start: RegisterStep.Product }"
            [disabled]="disableFlow"
        >
            <span [innerHTML]="roofButtonKey | cmsText | async"></span>
            <i class="fa fa-chevron-right ms-2"></i>
        </button>
    </div>
</ng-container>
