import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommunityService } from '@common/services/community.service';
import { MonitoringService } from '@common/services/monitoring.service';
import { RegisterStep } from '@spnl/model/register-step.enum';
import { Registration } from '@spnl/model/registration';
import {
    RegisterLocationService,
    Substep,
} from '@spnl/services/register-location.service';
import { RegisterStepService } from '@spnl/services/register-step.service';
import { RegisterStoreService } from '@spnl/services/register-store.service';
import { RegistrationService } from '@spnl/services/registration.service';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { DestroyableBase } from '../destroyable/destroyable.component';

@Component({
    selector: 'app-register-redirect',
    templateUrl: './register-redirect.component.html',
})
export class RegisterRedirectComponent
    extends DestroyableBase
    implements OnInit, OnDestroy {
    constructor(
        private route: ActivatedRoute,
        private locationService: RegisterLocationService,
        private registrationService: RegistrationService,
        private stepService: RegisterStepService,
        private router: Router,
        private communityService: CommunityService,
        private registerStore: RegisterStoreService,
        private monitoringService: MonitoringService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.route.params.pipe(take(1)).subscribe((params) => {
            const id = params['id'];
            const url = location.href;

            if (id) {
                this.monitoringService.logEvent('register-redirect-by-id', {
                    id,
                    url,
                });
                this.redirectById(id);
            } else {
                this.monitoringService.logEvent(
                    'register-redirect-by-session-storage',
                    { url },
                );
                this.redirectBySessionStorage();
            }
        });
    }

    private redirectBySessionStorage() {
        this.registrationService
            .loadFromSessionStorage()
            .pipe(takeUntil(this.destroyed$))
            .subscribe((storedRegistration) => {
                this.monitoringService.logEvent(
                    'register-redirect-by-session-storage-executing',
                    { id: storedRegistration?.id },
                );

                const start =
                    this.route.snapshot.queryParamMap.get('start') || null;
                if (start === RegisterStep.Product) {
                    this.registerStore.startedWithStep = start;
                }

                const registration = storedRegistration
                    ? storedRegistration
                    : new Registration();

                this.communityService.community$
                    .pipe(takeUntil(this.destroyed$))
                    .subscribe((community) => {
                        this.monitoringService.logEvent(
                            'register-redirect-by-session-storage-via-community',
                            { id: registration?.id },
                        );
                        registration.auction = community.targetAuction;
                        this.goToStepAfterLoad(registration);
                    });
            });
    }

    private redirectById(id: string) {
        this.locationService.enterFlowFromLink();
        this.registrationService
            .get(id)
            .pipe(
                switchMap((r) =>
                    this.registrationService.saveToSessionStorage(r),
                ),
                takeUntil(this.destroyed$),
            )
            .subscribe(
                (r) => {
                    this.monitoringService.logEvent(
                        'register-redirect-by-id-after-get',
                        { id: r?.id },
                    );
                    this.goToStepAfterLoad(r);
                },
                (_) => {
                    this.redirectToErrorPage();
                },
            );
    }

    private goToStepAfterLoad(registration: Registration) {
        this.monitoringService.logEvent('register-redirect-goToStepAfterLoad', {
            id: registration?.id,
            nr: registration?.number,
        });
        this.registerStore.registrationLoaded(registration);
        const step = this.stepService.getLatestAllowedStep(registration);
        this.locationService.goTo(step, new Substep.Latest());
    }

    private redirectToErrorPage() {
        this.communityService.communityCode$
            .pipe(take(1))
            .subscribe((communityCode) =>
                this.router.navigate([communityCode, 'error']),
            );
    }
}
