import { Component, Input } from '@angular/core';
import { RegisterStep } from '@spnl/model/register-step.enum';
import { Registration } from '@spnl/model/registration';
import {
    RegisterLocationService,
    Substep,
} from '@spnl/services/register-location.service';
import { RegisterStepService } from '@spnl/services/register-step.service';
import { RegisterStoreService } from '@spnl/services/register-store.service';

export class VisibleStep {
    id: RegisterStep;
    header: string;
    icon: string;
}

@Component({
    selector: 'app-register-stepper',
    templateUrl: './register-stepper.component.html',
})
export class RegisterStepperComponent {
    @Input()
    registration: Registration;

    private potentiallyVisibleSteps = [
        { id: RegisterStep.Person, header: 'person-header', icon: 'fa-user' },
        { id: RegisterStep.Product, header: 'product-header', icon: 'fa-home' },
        {
            id: RegisterStep.Welcome,
            header: 'overview-header',
            icon: 'fa-check',
        },
    ];

    get visibleSteps(): VisibleStep[] {
        const orderedSteps = this.stepService.activeFlowSteps
            .map((step) =>
                this.potentiallyVisibleSteps.find((s) => s.id === step),
            )
            .filter((s) => !!s);

        return this.registerStore.registration.isInInterestedIndividualFlow
            ? orderedSteps.filter((step) => step.id !== RegisterStep.Product)
            : orderedSteps;
    }

    get currentStepNumber(): number {
        return this.getStepNumber(this.registerStore.activeStep);
    }

    getStepNumber(id: RegisterStep): number {
        return this.visibleSteps.indexOf(
            this.visibleSteps.find((item) => item.id === id),
        );
    }

    constructor(
        private registerStore: RegisterStoreService,
        private stepService: RegisterStepService,
        private locationService: RegisterLocationService,
    ) {}

    goBackTo(step: RegisterStep): void {
        if (!this.registration) {
            return;
        }

        const hasPrevStep = this.currentStepNumber > this.getStepNumber(step);
        if (!this.registration.isReadonly && hasPrevStep) {
            this.locationService.goTo(step, new Substep.None());
        }
    }
}
