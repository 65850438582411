<div class="container mt-3">
    <div class="d-flex flex-nowrap pt-3">
        <div
            class="step-item"
            *ngFor="let item of visibleSteps"
            [routerLinkActive]="['active']"
            (click)="goBackTo(item.id)"
        >
            <a
                href="javascript:void(0)"
                class="mt-3"
                [ngClass]="{
                    'not-clickable':
                        registration?.isReadonly ||
                        currentStepNumber <= getStepNumber(item.id)
                }"
            >
                <i
                    class="text- fa steps-icon {{ item.icon }}"
                    [ngClass]="{
                        done: currentStepNumber > getStepNumber(item.id),
                        doing: currentStepNumber == getStepNumber(item.id),
                        notdone: currentStepNumber < getStepNumber(item.id)
                    }"
                ></i>
                <p
                    *ngIf="currentStepNumber > getStepNumber(item.id)"
                    class="d-none d-lg-block text-primary stepper-link"
                    [ngClass]="{
                        done: currentStepNumber > getStepNumber(item.id),
                        doing: currentStepNumber == getStepNumber(item.id),
                        notdone: currentStepNumber < getStepNumber(item.id)
                    }"
                >
                    {{ item.header | cmsText | async }}
                </p>
                <p
                    *ngIf="currentStepNumber == getStepNumber(item.id)"
                    class="d-none d-lg-block text-primary stepper-link"
                    [ngClass]="{
                        done: currentStepNumber > getStepNumber(item.id),
                        doing: currentStepNumber == getStepNumber(item.id),
                        notdone: currentStepNumber < getStepNumber(item.id)
                    }"
                >
                    {{ item.header | cmsText | async }}
                </p>
                <p
                    *ngIf="currentStepNumber < getStepNumber(item.id)"
                    class="d-none d-lg-block stepper-link"
                    [ngClass]="{
                        done: currentStepNumber > getStepNumber(item.id),
                        doing: currentStepNumber == getStepNumber(item.id),
                        notdone: currentStepNumber < getStepNumber(item.id)
                    }"
                >
                    {{ item.header | cmsText | async }}
                </p>
            </a>
        </div>
    </div>
</div>
