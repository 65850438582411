<ng-template #tooltipContent let-d="dismiss">
    <div class="modal-header">
        <h5
            class="modal-title"
            id="tooltipModalLabel"
            [innerHtml]="cmsKeyTooltipHeader | cmsText | async"
        ></h5>
        <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="d()"
        ></button>
    </div>
    <div
        class="modal-body"
        [innerHtml]="cmsKeyTooltipBody | cmsText : cmsParamsTooltipBody | async"
    ></div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-outline-primary"
            (click)="d()"
            id="tooltip-close-button"
        >
            {{ 'tooltip-close-button' | cmsText | async }}
        </button>
    </div>
</ng-template>
<div
    class="fake-link"
    [innerHtml]="cmsKeyWord | cmsText : [cmsParamsWord] | async"
    (click)="openModal(tooltipContent)"
></div>
