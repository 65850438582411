import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApprovalStatus } from '@common/model/approval-status';
import { Supplier } from '@common/model/supplier.base';
import { SupplierService } from '@common/services/supplier.service';
import { Registration } from '@spnl/model/registration';
import { RegistrationStoreService } from '@spnl/services/registration-store.service';
import { RegistrationService } from '@spnl/services/registration.service';
import { throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'app-cancel',
    templateUrl: './cancel.component.html',
})
export class CancelComponent implements OnInit {
    registration: Registration;
    supplier: Supplier;

    showSuccess = false;
    showLoadError = false;

    get cancellationRequested(): boolean {
        return (
            this.registration &&
            this.registration.delivery.deliveryCancellationStatus ===
                ApprovalStatus.Requested &&
            !!this.registration.delivery.cancellationRequestedByConsumer
        );
    }

    get cancellationApproved(): boolean {
        return (
            this.registration &&
            this.registration.delivery.deliveryCancellationStatus ===
                ApprovalStatus.Approved
        );
    }

    get showForm(): boolean {
        return (
            this.registration &&
            !this.showSuccess &&
            !this.cancellationApproved &&
            !this.cancellationRequested
        );
    }

    get showOverview(): boolean {
        return this.registration && !this.showForm;
    }

    constructor(
        private registrationService: RegistrationService,
        private registrationStore: RegistrationStoreService,
        private supplierService: SupplierService,
        private route: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        const id = this.route.snapshot.paramMap.get('id');
        this.registrationService
            .get(id)
            .pipe(
                switchMap((r) => {
                    if (r.supplierId) {
                        this.registration = r;
                        this.registrationStore.setCurrentRegistration(r);
                        return this.supplierService.getSupplierById(
                            r.supplierId,
                        );
                    } else {
                        return throwError(
                            'Registration not associated with supplier',
                        );
                    }
                }),
            )
            .subscribe(
                (supplier) => (this.supplier = supplier),
                () => (this.showLoadError = true),
            );
    }

    onSuccessfulSubmit(): void {
        this.showSuccess = true;
        scrollTo(0, 0);
    }
}
