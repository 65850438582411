<h4 class="mb-3">
    {{ header$ | async }}
</h4>

<ngb-accordion
    #accordion
    [closeOthers]="false"
    activeIds=""
    *ngIf="faqItems$ | async as faqItems"
>
    <ngb-panel *ngFor="let item of faqItems; let i = index">
        <ng-template ngbPanelTitle>
            <i class="fa fa-chevron-circle-down m-1"></i>
            <span class="ms-3">{{ item | cmsText | async | faqheader }}</span>
        </ng-template>
        <ng-template ngbPanelContent>
            <div>
                <span [innerHTML]="item | cmsText | async | faqcontent"></span>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
