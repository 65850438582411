import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class ParticipantStatusGuard  {
    constructor(private router: Router) {}

    canActivate(next: ActivatedRouteSnapshot): boolean | Promise<boolean> {
        const participantStatus = next.queryParamMap.get('redirect');
        if (participantStatus === 'unauthorized') {
            const community = next.params.community;
            const registrationId = next.params.id;

            if (!registrationId) {
                this.router.navigate([community, 'error']);
            } else {
                this.router.navigate([
                    community,
                    'unauthorized',
                    registrationId,
                ]);
            }
        }

        return true;
    }
}
