<div class="mb-3" [formGroup]="notesToSupplierGroup">
    <h5>
        {{ 'proposal-supplier-comments-label2' | cmsText | async }}
    </h5>
    <p
        class="my-2"
        [innerHTML]="'proposal-supplier-comments-label' | cmsText | async"
    ></p>
    <textarea
        formControlName="notesToSupplier"
        id="notesToSupplier"
        class="form-control"
        rows="2"
        placeholder="{{
            'proposal-supplier-comments-placeholder' | cmsText | async
        }}"
    ></textarea>
    <span
        *ngIf="notesToSupplier.invalid && notesToSupplier.errors.maxlength"
        class="text-danger"
    >
        <small>{{
            'proposal-notes-to-supplier-error-maxlength' | cmsText | async
        }}</small>
    </span>
</div>
