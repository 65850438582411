import { Component } from '@angular/core';

@Component({
    selector: 'app-loading-overlay',
    template: `
        <div
            class="bg-white position-absolute top-0 left-0 right-0 bottom-0 z-10 opacity-50 rounded"
        ></div>
    `,
})
export class LoadingOverlayComponent {}
