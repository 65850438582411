export enum SeminarType {
    Normal = 'Normal',
    Webinar = 'Webinar',
}

export namespace SeminarType {
    export function description(type: SeminarType): string {
        switch (type) {
            case SeminarType.Normal:
                return 'seminar-type-normal';
            case SeminarType.Webinar:
                return 'seminar-type-webinar';
            default:
                return 'unknown';
        }
    }
}

export class Seminar {
    id: string;
    auctionId: string;
    location: string;
    dateTime: string;
    endTime: string;
    capacity: number;
    supplierId: string;
    locationComments: string;
    registrationsCount: number;
    type: SeminarType;
}
