<div
    class="card my-3 shadow"
    *ngIf="'installation-video' | cmsText | async as video"
>
    <h5
        class="card-header border-0"
        [innerHTML]="'proposal-video-title' | cmsText | async"
    ></h5>
    <div class="ratio ratio-16x9" [youtubemovie]="video"></div>
</div>
