<hr class="my-3" />

<div class="alert alert-info">
    <div class="row">
        <div>
            <h5 class="alert-heading-mobile">
                {{ 'share-auction-title' | cmsText | async }}
            </h5>
            <div class="row pb-2">
                <div class="col-12">
                    <span [innerHtml]="messageCmsKey | cmsText | async"></span>
                </div>
            </div>
            <div class="container pt-2">
                <div *ngIf="includeWhatsapp" class="row my-2">
                    <a
                        [href]="getWhatsAppLink()"
                        class="btn btn-primary rounded-2 text-decoration-none"
                        target="_blank"
                    >
                        <span
                            [innerHtml]="'whatsapp-image' | cmsText | async"
                        ></span>
                    </a>
                </div>
                <div *ngIf="includeEmail" class="row my-2">
                    <a
                        href="{{ emailMessageLink }}"
                        class="btn btn-primary rounded-2 text-decoration-none"
                        target="_blank"
                    >
                        <span
                            [innerHtml]="'email-image' | cmsText | async"
                        ></span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
