<div class="my-3">
    <ng-container *ngIf="!shouldShowAllSteps">
        <h3 class="mt-3">{{ titleKey | cmsText | async }}</h3>
        <span
            [innerHTML]="
                'roof-step-description'
                    | cmsText
                        : [{ currentStep: currentStep, stepCount: stepCount }]
                    | async
            "
        ></span
        >:
        <strong>{{ currentStepKey | cmsText | async }}</strong>
    </ng-container>
    <ng-container *ngIf="shouldShowAllSteps">
        <h3 class="mt-3">{{ 'roof-details-title' | cmsText | async }}</h3>
        <p [innerHTML]="'roof-details-intro-text' | cmsText | async"></p>
    </ng-container>
    <hr />
</div>
