<ng-container *ngIf="shouldShowErrors">
    <app-register-text-error
        *ngIf="hasRequiredErrors && !hasPatternErrors"
        [errorKey]="label + '-error-required'"
        [cmsParams]="cmsParams"
    >
    </app-register-text-error>
    <app-register-text-error
        *ngIf="!hasRequiredErrors && hasPatternErrors"
        [errorKey]="label + '-error-pattern'"
        [cmsParams]="cmsParams"
    >
    </app-register-text-error>
    <ng-container *ngIf="!hasRequiredErrors && !hasPatternErrors">
        <app-register-text-error
            *ngIf="field.errors?.min"
            [errorKey]="label + '-error-min'"
            [cmsParams]="cmsParams"
        >
        </app-register-text-error>
        <app-register-text-error
            *ngIf="field.errors?.max"
            [errorKey]="label + '-error-max'"
            [cmsParams]="cmsParams"
        >
        </app-register-text-error>
        <app-register-text-error
            *ngIf="field.errors?.maxlength"
            [errorKey]="label + '-error-maxlength'"
            [cmsParams]="cmsParams"
        >
        </app-register-text-error>
        <app-register-text-error
            *ngIf="field.errors?.invalidFile"
            [errorKey]="label + '-file-error'"
            [cmsParams]="cmsParams"
        >
        </app-register-text-error>
    </ng-container>
    <ng-content></ng-content>
</ng-container>
