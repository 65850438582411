import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

export enum Referrer {
    DirectLink,
    Buckaroo,
}

@Injectable()
export class ReferrerService {
    get referrer(): Referrer {
        const params = this.route.snapshot.queryParams;
        const source = params['returnfrom'] || params['source'];
        return source === 'buckaroo' ? Referrer.Buckaroo : Referrer.DirectLink;
    }

    constructor(private route: ActivatedRoute) {}
}
