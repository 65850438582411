<app-tooltip-on-word
    [cmsKeyWord]="'proposal-explanation-number-panels-on-form'"
    [cmsKeyTooltipHeader]="'proposal-explanation-number-panels-tooltip-header'"
    [cmsKeyTooltipBody]="'proposal-explanation-number-panels-on-tooltip-body'"
    [cmsParamsTooltipBody]="[
        { noPanelsBasedOnUsage: noPanelsBasedOnUsage },
        { maxPanelsBasedOnRoof: maxPanelsBasedOnRoof },
        { optimialAmountPanels: optimialAmountPanels },
        {
            heatpumpDescription: heatPumpDescription
        },
        {
            heatpumpAmount: heatPumpElectricityAmount
        },
        {
            aircoDescription: aircoDescription
        },
        {
            aircoAmount: aircoElectricityAmount
        },
        {
            electricOvenDescription: electricOvenDescription
        },
        {
            electricOvenAmount: electricOvenElectricityAmount
        },
        {
            electricCarDescription: electricCarDescription
        },
        {
            electricCarAmount: electricCarElectricityAmount
        },
        { currentElectricityUsage: energyConsumption }
    ]"
></app-tooltip-on-word>
