import { Component, Input } from '@angular/core';
import { ExcludedReason } from '../../../model/registration';

@Component({
    selector: 'app-exclude-reason',
    templateUrl: './exclude-reason.component.html',
})
export class ExcludeReasonComponent {
    @Input()
    excluded: boolean;
    @Input()
    excludedReason: ExcludedReason;

    // Legacy reasons:
    ExcludedReason = ExcludedReason;

    public get excludedReasonWarningKey(): string {
        const baseKey = 'registration-excluded';
        switch (this.excludedReason) {
            case ExcludedReason.PostcodeOutOfRange:
                return `${baseKey}-postcode-out-of-range-warning`;
            case ExcludedReason.RoofOrientation:
                return `${baseKey}-roof-orientation-warning`;
            case ExcludedReason.RoofMaterial:
                return `${baseKey}-roof-material-warning`;
            case ExcludedReason.RoofSize:
                return `${baseKey}-roof-size-warning`;
            case ExcludedReason.RoofDoesNotSupportSolarPanels:
                return `${baseKey}-roof-does-not-support-solar-panels-warning`;
            default:
                return `${baseKey}-warning`;
        }
    }
}
