import { Component } from '@angular/core';
import { AuthService } from '../../../../_common/services/auth.service';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
})
export class MenuComponent {
    constructor(public authService: AuthService) {}
}
