import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@common/services/auth.service';
import { GoogleAnalyticsService } from '@common/services/google-analytics.service';
import { FlowData } from '@common/services/register-flow-calculator.service.base';
import { Registration } from '@spnl/model/registration';
import { first } from 'rxjs/operators';
import { RegisterFlowCalculator } from './register-flow-calculator.service';
import { RegisterStoreService } from './register-store.service';

@Injectable()
export class RegisterAnalyticsService {
    constructor(
        private analytics: GoogleAnalyticsService,
        private flowCalculator: RegisterFlowCalculator, // TODO: rename this service
        private registerStore: RegisterStoreService,
        private router: Router,
        private authService: AuthService,
    ) {}

    logEvent(registration: Registration, action: string): void {
        this.authService.identity$.pipe(first()).subscribe((identity) => {
            this.analytics.event(
                { path: this.router.url },
                { category: `${this.registerStore.activeStep}Actions`, action },
                {
                    id: registration.id,
                    status: registration.status,
                    hasEmail: registration.email ? 'true' : 'false',
                    isEmployee: this.isEmployee(identity?.role),
                },
            );
        });
    }

    logPageView(flowData: FlowData): void {
        this.authService.identity$.pipe(first()).subscribe((identity) => {
            this.analytics.pageView(
                { path: this.router.url },
                this.flowCalculator.flow(flowData),
                flowData.registration.id
                    ? {
                          status: flowData.registration.status?.toLocaleLowerCase(),
                          id: flowData.registration.id,
                          hasEmail: flowData.registration.email
                              ? 'true'
                              : 'false',
                          isEmployee: this.isEmployee(identity?.role),
                      }
                    : undefined,
                flowData.registration.auction
                    ? { id: flowData.registration.auction.id }
                    : undefined,
            );
        });
    }

    private isEmployee(role: string): boolean {
        return role !== 'participant';
    }
}
