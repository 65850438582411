<h3 class="my-3 nls_protected">
    {{
        'overview-page-of-contact-title'
            | cmsText : [{ fullName: registration.contact | fullName | async }]
            | async
    }}
</h3>

<hr class="my-3" />

<div class="alert alert-success">
    <span [innerHTML]="statusCmsKey | cmsText | async"></span>
</div>

<app-register-survey-cta
    *ngIf="!isMobileDevice"
    [registrationNumber]="registration.number"
    [auctionCode]="registration.auction?.code"
    [registrationStatus]="registration.status"
    [supplierName]="registration.supplierName"
    cmsKey="survey-cta-body-accepted"
>
</app-register-survey-cta>

<app-share-auction-social-media-cta
    *ngIf="isMobileDevice"
    [messageCmsKey]="'share-auction-social-media-message'"
    [includeWhatsapp]="true"
    [whatsappCmsKey]="'whatsapp-message-offer-accepted'"
    [includeEmail]="true"
    [emailSubjectCmsKey]="'email-message-header-offer-accepted'"
    [emailBodyCmsKey]="'email-message-body-offer-accepted'"
>
</app-share-auction-social-media-cta>

<app-register-overview-next-steps
    [registration]="registration"
    nextStepsCmsKey="overview-depositpaid-next-steps-text"
>
</app-register-overview-next-steps>

<hr class="my-3" />

<app-register-faq [faqItems]="faqItems"></app-register-faq>

<app-register-order-details> </app-register-order-details>

<app-register-overview-change-details [registration]="registration">
</app-register-overview-change-details>
