import {
    AfterViewInit,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import {
    applyFormValue,
    ControlWithErrors,
    makeFormModel,
    shouldShowErrorsFor,
} from '@common/infrastructure/form-tools';
import { DestroyableBase } from '@spnl/components/destroyable/destroyable.component';
import { RoofStepFormGroup } from '@spnl/forms/roof-form-tools';
import { RegisterStep } from '@spnl/model/register-step.enum';
import { Registration, RoofPitch } from '@spnl/model/registration';
import { DigitalEventService } from '@spnl/services/digital-event-queue.service';
import { ExclusionContext } from '@spnl/services/exclusion.service';
import { RegisterSaveService } from '@spnl/services/register-save.service';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { RegisterConfig } from '../../register.config';

@Component({
    selector: 'app-register-roof-size',
    templateUrl: './roof-size.component.html',
})
export class RoofSizeComponent
    extends DestroyableBase
    implements OnInit, OnDestroy, AfterViewInit
{
    @Input()
    registration: Registration;

    @Input()
    parentForm: UntypedFormGroup;

    @Input()
    startedWithProduct: boolean;

    config = RegisterConfig;

    form: RoofStepFormGroup;
    submitRequested = false;
    shouldBeExcluded = false;
    numberOfPanelsThatFitOnRoofOverMax = false;

    knowsNumberOfPanelsThatFitOnRoof: ControlWithErrors;
    roofLength: ControlWithErrors;
    roofWidth: ControlWithErrors;
    numberOfPanelsThatFitOnRoof: ControlWithErrors;
    isMoreRoofsAvailable: ControlWithErrors;

    // Suppress linting, leaving this as is for legacy reasons (not worth the risk of refactoring)
    RoofPitch = RoofPitch;

    constructor(
        private fb: UntypedFormBuilder,
        private registerSaveService: RegisterSaveService,
        private digitalEventService: DigitalEventService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            knowsNumberOfPanelsThatFitOnRoof: [null, Validators.required],
            numberOfPanelsThatFitOnRoof: [
                '',
                [Validators.required, this.numberOfPanelsMinValidator()],
            ],
            roofLength: [
                '',
                [this.requiredForUnknownMaxPanelsFit(), Validators.min(1)],
            ],
            roofWidth: [
                '',
                [this.requiredForUnknownMaxPanelsFit(), Validators.min(1)],
            ],
            isMoreRoofsAvailable: [],
        }) as RoofStepFormGroup;

        this.knowsNumberOfPanelsThatFitOnRoof = this.form.get(
            'knowsNumberOfPanelsThatFitOnRoof',
        );
        this.roofLength = this.form.get('roofLength');
        this.roofWidth = this.form.get('roofWidth');
        this.numberOfPanelsThatFitOnRoof = this.form.get(
            'numberOfPanelsThatFitOnRoof',
        );
        this.isMoreRoofsAvailable = this.form.get('isMoreRoofsAvailable');

        this.form.onSubmit = this.onSubmit.bind(this);
        this.form.getExclusionContext = this.getExclusionContext.bind(this);
        this.parentForm.addControl('roof-size', this.form);

        [
            this.roofLength,
            this.roofWidth,
            this.numberOfPanelsThatFitOnRoof,
            this.knowsNumberOfPanelsThatFitOnRoof,
            this.isMoreRoofsAvailable,
        ].forEach((control) =>
            control.valueChanges
                .pipe(takeUntil(this.destroyed$), debounceTime(750))
                .subscribe(() => {
                    const knowsNumberOfPanelsThatFitOnRoof =
                        this.knowsNumberOfPanelsThatFitOnRoof.value === true;
                    const hasRoofValues =
                        this.roofWidth.value && this.roofLength.value;

                    if (!knowsNumberOfPanelsThatFitOnRoof && hasRoofValues) {
                        this.updateNumberOfPanelsThatFitOnRoof();
                    }

                    const incorrectNumberOfPanels =
                        this.numberOfPanelsThatFitOnRoof.invalid &&
                        !this.numberOfPanelsThatFitOnRoof.errors.required;

                    this.shouldBeExcluded =
                        (hasRoofValues || knowsNumberOfPanelsThatFitOnRoof) &&
                        incorrectNumberOfPanels;
                    this.numberOfPanelsThatFitOnRoofOverMax =
                        this.numberOfPanelsThatFitOnRoof.valid &&
                        this.numberOfPanelsThatFitOnRoof.value >
                            RegisterConfig.maxNumberOfPanels;
                }),
        );

        const model = makeFormModel(this.form, this.registration.product, []);
        this.form.reset(model);
    }

    ngAfterViewInit(): void {
        this.digitalEventService.push('dakoppervlak');
    }

    private updateNumberOfPanelsThatFitOnRoof() {
        this.numberOfPanelsThatFitOnRoof.setValue(
            Math.max(
                this.panelsThatFitOn(
                    this.roofLength.value,
                    this.roofWidth.value,
                ),
                this.panelsThatFitOn(
                    this.roofWidth.value,
                    this.roofLength.value,
                ),
            ),
        );
    }

    public numberOfPanelsMinValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors => {
            const belowMinimum =
                control.value < RegisterConfig.minNumberOfPanels;

            return belowMinimum ? { min: true } : null;
        };
    }

    get showErrorsForKnowsNumberOfPanels(): boolean {
        return shouldShowErrorsFor(this.knowsNumberOfPanelsThatFitOnRoof);
    }

    get showErrorsForNumberOfPanels(): boolean {
        return shouldShowErrorsFor(this.numberOfPanelsThatFitOnRoof);
    }

    get showErrorsForRoofLength(): boolean {
        return shouldShowErrorsFor(this.roofLength);
    }

    get showErrorsForRoofWidth(): boolean {
        return shouldShowErrorsFor(this.roofWidth);
    }

    get showAnotherRoofAvailableMessage(): boolean {
        return !!this.isMoreRoofsAvailable.value;
    }

    private panelsThatFitOn(roofWidth: number, roofLength: number): number {
        const panelWidth = 1.05;
        const panelHeight = 1.7;
        const roofMargin = 0.6;

        const availableRoofWidth = roofWidth - roofMargin;
        const availableRoofLength = roofLength - roofMargin;

        const numberOfPanelsFittingOnRoofWidth = Math.max(
            0,
            Math.floor(availableRoofWidth / panelWidth),
        );
        const numberOfPanelsFittingOnRoofLength = Math.max(
            0,
            Math.floor(availableRoofLength / panelHeight),
        );

        return (
            numberOfPanelsFittingOnRoofWidth * numberOfPanelsFittingOnRoofLength
        );
    }

    private requiredForUnknownMaxPanelsFit(): ValidatorFn {
        return this.requiredWhen(
            () => this.knowsNumberOfPanelsThatFitOnRoof.value === false,
        );
    }

    onExcludeRegistration(): void {
        this.registerSaveService.excludeAndContinue(
            this.form,
            this.registration.product,
        );
    }

    getExclusionContext(): ExclusionContext {
        return {
            numberOfPanelsThatFitOnRoof:
                this.numberOfPanelsThatFitOnRoof?.value,
        };
    }

    onSubmit(): void {
        this.form.markAllAsTouched();

        if (this.knowsNumberOfPanelsThatFitOnRoof.value === true) {
            this.roofLength.setValue(null);
            this.roofWidth.setValue(null);
        } else {
            this.updateNumberOfPanelsThatFitOnRoof();
        }

        if (this.form.valid) {
            applyFormValue(this.form, this.registration.product, []);
        }
    }

    // TODO: Deduplicate
    requiredWhen(predicate: () => boolean): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return this.form && predicate() && control.value === null
                ? { required: true }
                : null;
        };
    }

    ngOnDestroy(): void {
        this.parentForm?.removeControl('roof-size');
        super.ngOnDestroy();
    }
}
