import { RegisterStep } from '@spnl/model/register-step.enum';
import { StepContext } from '@spnl/model/step-context';

class StepDefinition {
    step: RegisterStep;
    allowed: (_: StepContext) => boolean;
}

// A `Flow` represents a collection of steps that a user may see in a given interaction with the app.
// For example, the typical flow you use when arriving at the SPNL page from VEH is defined below
// in `Flows.default`. There are also other flows, like `Flows.excluded`, which become active
// depending on different factors (see `RegisterStepService.activeFlow`).
//
// A flow consists of a series of `flowSteps`, which specify the normal order in which a user
// would see the steps when filling out their registration. It also has a series of `stepDefinitions`,
// which specify the conditions required for a step to be allowed.
//
// It is possible to have a step in the `stepDefinitions`, without adding it to the `flowSteps`.
// This means that, in the normal case, the step will not be shown when clicking on the "Next"
// button in the app. However, components might trigger a navigation to such steps.
export class Flow {
    constructor(
        // The flow name, useful for debugging
        public name: string,
        // The steps that are part of the flow, in the right order
        public flowSteps: RegisterStep[],
        // The steps defined for this flow (not necessarily included in `flowSteps`)
        private stepDefinitions: StepDefinition[],
    ) {}

    isAllowed(step: string, ctx: StepContext): boolean {
        const targetStep = this.stepDefinitions.find((s) => s.step === step);
        return targetStep && targetStep.allowed(ctx);
    }

    latestAllowedStep(ctx: StepContext): RegisterStep {
        const steps = this.flowStepDefinitions();
        return steps.reverse().find((s) => s.allowed(ctx)).step;
    }

    private flowStepDefinitions(): StepDefinition[] {
        return this.flowSteps.map((s) =>
            this.stepDefinitions.find(
                (stepDefinition) => stepDefinition.step === s,
            ),
        );
    }
}

export class Flows {
    private static readonly _sharedSteps: StepDefinition[] = [
        {
            step: RegisterStep.Welcome,
            allowed: (ctx) => ctx.subscriptionComplete && ctx.hasNoProposal,
        },
        {
            step: RegisterStep.Proposal,
            allowed: (ctx) =>
                ctx.subscriptionComplete && ctx.hasUndecidedProposal,
        },
        {
            step: RegisterStep.DecisionMade,
            allowed: (ctx) =>
                (ctx.hasDeclinedProposal || ctx.hasAcceptedProposal) &&
                !ctx.hasSuccessfulPayment &&
                !ctx.hasPendingPayment,
        },
        {
            step: RegisterStep.Overview,
            allowed: (ctx) =>
                ctx.hasAcceptedProposal &&
                (ctx.hasPendingPayment || ctx.hasSuccessfulPayment),
        },
    ];

    static readonly default = new Flow(
        'default',
        [
            RegisterStep.Person,
            RegisterStep.Product,
            ...Flows._sharedSteps.map((s) => s.step),
        ],
        [
            {
                step: RegisterStep.Person,
                allowed: (_ctx) => true,
            },
            {
                step: RegisterStep.Product,
                allowed: (ctx) => ctx.hasValidPersonDetails,
            },
            ...Flows._sharedSteps,
        ],
    );

    static readonly defaultAlternative = new Flow(
        'defaultAlternative',
        [
            RegisterStep.Product,
            RegisterStep.Person,
            ...Flows._sharedSteps.map((s) => s.step),
        ],
        [
            {
                step: RegisterStep.Product,
                allowed: (_ctx) => true,
            },
            {
                step: RegisterStep.Person,
                allowed: (ctx) => ctx.hasValidProduct,
            },
            ...Flows._sharedSteps,
        ],
    );

    static readonly excluded = new Flow(
        'excluded',
        [RegisterStep.Person, RegisterStep.Product],
        [
            {
                step: RegisterStep.Person,
                allowed: (_ctx) => true,
            },
            {
                step: RegisterStep.Product,
                allowed: (_ctx) => true,
            },
        ],
    );

    static readonly excludedAlternative = new Flow(
        'excludedAlternative',
        [RegisterStep.Product, RegisterStep.Person],
        [
            {
                step: RegisterStep.Product,
                allowed: (_ctx) => true,
            },
            {
                step: RegisterStep.Person,
                allowed: (_ctx) => true,
            },
        ],
    );

    static readonly interestedIndividual = new Flow(
        'interestedIndividual',
        [RegisterStep.Person, RegisterStep.Overview],
        [
            {
                step: RegisterStep.Person,
                allowed: (_ctx) => true,
            },
            {
                step: RegisterStep.Overview,
                allowed: (ctx) => ctx.hasMinimalPersonDetails,
            },
        ],
    );

    static readonly closed = new Flow(
        'closed',
        [RegisterStep.Closed],
        [
            {
                step: RegisterStep.Closed,
                allowed: (_ctx) => true,
            },
        ],
    );

    static readonly cancelled = new Flow(
        'cancelled',
        [RegisterStep.Cancelled],
        [
            {
                step: RegisterStep.Cancelled,
                allowed: (_ctx) => true,
            },
        ],
    );
}
