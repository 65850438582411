<div class="row">
    <div class="col-md-12">
        <div class="button-wrapper btn-container-start">
            <button
                type="button"
                class="btn btn-outline-primary px-5 order-sm-first order-last"
                id="prevButton"
                *ngIf="allowPrevious"
                [disabled]="disablePrevious"
                (click)="goBack()"
            >
                <i class="fa fa-chevron-left me-2"></i
                >{{ 'go-back-button' | cmsText | async }}
            </button>
            <button
                id="nextButton"
                type="submit"
                [disabled]="disallowNext"
                class="btn btn-primary px-5 order-sm-last order-first"
            >
                <i
                    *ngIf="submitting && form.valid"
                    class="fa fa-spinner fa-spin me-1"
                ></i>
                {{ 'button-next-label' | cmsText | async
                }}<i class="fa fa-chevron-right ms-2"></i>
            </button>
        </div>
    </div>
</div>
