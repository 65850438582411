import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnalyticsService } from '@common/analytics/services/analytics.service';
import {
    FaqCategory,
    FaqComponentBase,
} from '@common/components/faq/faq.component.base';
import { CommunityService } from '@common/services/community.service';
import { ContentService } from '@common/services/content.service';
import { WINDOW } from '@common/services/window.service';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { FaqAnalyticsService } from '@spnl/services/faq-analytics.service';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-faq',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './faq.component.html',
})
export class FaqComponent extends FaqComponentBase implements OnInit {

    get categoryDisabled(): boolean {
        if (!this.searchTerm) {
            return false;
        }
        return this.searchTerm.length > 0;
    }

    constructor(
        route: ActivatedRoute,
        content: ContentService,
        communityService: CommunityService,
        private analytics: FaqAnalyticsService,
        analyticsService: AnalyticsService,
        @Inject(WINDOW) window: Window,
    ) {
        super(route, content, communityService, analyticsService, window, [
            new FaqCategory('ten-most-asked-questions'),
            new FaqCategory('new-solar-panels-general'),
            new FaqCategory('price-and-return'),
            new FaqCategory('registration-and-participation'),
            new FaqCategory('new-roof-test'),
            new FaqCategory('new-personal-offer'),
            new FaqCategory('supplier-and-installation'),
            new FaqCategory('new-community'),
        ]);

        this.searchTerm$
            .pipe(debounceTime(2000), takeUntil(this.destroyed$))
            .subscribe((term) => this.analytics.logSearchTerm(term));
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    public panelChanged(event: NgbPanelChangeEvent): void {
        if (event.nextState === false) {
            return;
        }

        this.analytics.logQuestionViewed(event.panelId);
    }
}
