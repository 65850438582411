import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ControlWithErrors } from '@common/infrastructure/form-tools';
import { DestroyableBase } from '@spnl/components/destroyable/destroyable.component';
import { Registration } from '@spnl/model/registration';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-proposal-notes-to-supplier',
    templateUrl: './proposal-notes-to-supplier.component.html',
})
export class ProposalNotesToSupplierComponent
    extends DestroyableBase
    implements OnInit {
    @Input()
    form: UntypedFormGroup;

    @Input()
    registration: Registration;

    public notesToSupplierGroup = this.fb.group({
        notesToSupplier: ['', Validators.maxLength(999)],
    });

    get notesToSupplier(): ControlWithErrors {
        return this.notesToSupplierGroup.get('notesToSupplier');
    }

    constructor(private fb: UntypedFormBuilder) {
        super();
    }

    ngOnInit(): void {
        const localStorageKey =
            'registration.decision.notesToSupplier' + this.registration.number;
        const storedNotesToSupplier = localStorage.getItem(localStorageKey);
        this.form.addControl('notesToSupplier', this.notesToSupplierGroup);
        this.notesToSupplier.setValue(
            storedNotesToSupplier ?? this.registration.decision.notesToSupplier,
        );

        this.notesToSupplier.valueChanges
            .pipe(takeUntil(this.destroyed$), debounceTime(500))
            .subscribe((notes) => {
                localStorage.setItem(localStorageKey, notes);
            });
    }
}
