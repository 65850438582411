export enum VehUtmQueryParameter {
    Source = '_veh_utm_source',
    Campaign = '_veh_utm_campaign',
    Content = '_veh_utm_content',
    Medium = '_veh_utm_medium',
    Term = '_veh_utm_term',
    SessionId = '_veh_session_id',
    SessionMedium = '_veh_source_medium',
    SessionReferrer = '_veh_session_referrer',
}
