<hr class="my-3" />
<div class="py-3">
    <h4>{{ 'overview-proposal-title' | cmsText | async }}</h4>
    <div [innerHTML]="'overview-proposal-text' | cmsText | async"></div>
    <app-register-proposal-installation
        [registration]="registration"
        [displayAsCard]="true"
        [numberOfPanelsChosen]="numberOfPanelsChosen"
        [premiumPanelsChosen]="true"
    >
    </app-register-proposal-installation>
    <div class="card mb-1">
        <div class="card-body">
            <span
                [innerHTML]="
                    'overview-proposal-btw-price-text'
                        | cmsText
                            : [
                                  {
                                      proposalPrice:
                                          (registration.proposal.price
                                          | currencyWithPostfix),
                                      vatRate: (vatRate | percent)
                                  }
                              ]
                        | async
                "
            ></span>
        </div>
    </div>
    <app-register-proposal-advantage
        [registration]="registration"
        [premiumPanelsChosen]="true"
        [numberOfPanelsChosen]="numberOfPanelsChosen"
    >
    </app-register-proposal-advantage>
    <div class="card my-3" *ngIf="registration.decision.notesToSupplier">
        <h5 class="card-header border-0">
            {{ 'proposal-notes-to-supplier-header' | cmsText | async }}
        </h5>
        <p class="card-body">{{ registration.decision.notesToSupplier }}</p>
    </div>

    <app-proposal-attachments [supplier]="supplier"></app-proposal-attachments>
</div>
