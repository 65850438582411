import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

export class CustomValidators {
    public static requiredIf(predicate: () => boolean) {
        return (formControl) => {
            if (!formControl.parent) {
                return null;
            }
            if (predicate()) {
                return Validators.required(formControl);
            }
            return null;
        };
    }

    public static matchesPostcodeRange(
        _zipCode: string,
        _communityCode: string,
    ): boolean {
        return true;
    }

    public static phoneValidator(): ValidatorFn {
        // https://murani.nl/blog/2015-09-28/nederlandse-reguliere-expressies/
        // Regex originates from the link above but has been altered based on requirments
        const dutchFixedPhoneNumber =
            /^(((0)[1-9]{2}[0-9][0-9][0-9]{5})|((\+31|0|0031)[1-9][0-9][0-9][0-9]{6}))$/;

        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return null;
            }
            return control.value.match(dutchFixedPhoneNumber) !== null
                ? null
                : { pattern: { value: control.value } };
        };
    }

    public static cellphoneValidator(): ValidatorFn {
        // https://murani.nl/blog/2015-09-28/nederlandse-reguliere-expressies/
        // Regex originates from the link above but has been altered based on requirments
        const dutchMobilePhoneNumber =
            /^(((\+31|0|0031)6){1}[0-9]{1}[0-9]{7})$/;

        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return null;
            }
            return control.value.match(dutchMobilePhoneNumber) !== null
                ? null
                : { pattern: { value: control.value } };
        };
    }
}
