import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentService } from '@common/services/content.service';
import { filter } from 'rxjs/operators';
import { DestroyableBase } from '../destroyable/destroyable.component';

@Component({
    selector: 'app-register-redirect-from-social',
    template: `<div class="mb-3">
        {{ 'loading-message' | cmsText | async }}
    </div>`,
})
export class RedirectFromSocialComponent
    extends DestroyableBase
    implements OnInit
{
    constructor(
        private activatedRoute: ActivatedRoute,
        private contentService: ContentService,
        @Inject(DOCUMENT) private document: Document,
    ) {
        super();

        const source =
            this.activatedRoute.snapshot.paramMap.get('social-source');
        this.redirectWithUtmCodes(source);
    }

    ngOnInit(): void {}

    redirectWithUtmCodes(source: string) {
        let cmsKeyForRedirect = '';
        switch (source) {
            case '1':
                cmsKeyForRedirect = 'email-redirect-offer-delivery-done';
                break;
            case '2':
                cmsKeyForRedirect = 'whatsapp-redirect-offer-delivery-done';
                break;
            case '3':
                cmsKeyForRedirect = 'email-redirect-offer-accepted';
                break;
            case '4':
                cmsKeyForRedirect = 'whatsapp-redirect-offer-accepted';
                break;
            case '5':
                cmsKeyForRedirect = 'whatsapp-redirect-desktop-landing-page';
                break;
            case '6':
                cmsKeyForRedirect = 'email-redirect-desktop-landing-page';
                break;
            case '7':
                cmsKeyForRedirect = 'whatsapp-redirect-mobile-landing-page';
                break;
            case '8':
                cmsKeyForRedirect = 'email-redirect-mobile-landing-page';
                break;
            default:
                cmsKeyForRedirect = 'default-redirect';
                break;
        }

        this.contentService
            .get(cmsKeyForRedirect)
            .pipe(filter((cmsValue) => cmsValue && cmsValue.value))
            .subscribe((cmsValue) => {
                this.document.location.href = cmsValue.value;
            });
    }
}
