<hr class="my-3" />

<div *ngIf="isBLforNLdesign(business); else showOldDesign">
    <div *ngIf="isMobileDevice; else showDesktopVersion">
        <div class="alert alert-info container-mobile">
            <div class="content-mobile">
                <img
                    class="image"
                    [attr.src]="'survey-icon-img' | cmsImageSrc | async"
                    [attr.alt]="'survey-icon-img' | cmsImageAlt | async"
                />
                <div class="description-mobile text-center">
                    <h5>
                        {{ 'survey-cta-title' | cmsText | async }}
                    </h5>
                </div>
            </div>
            <div class="button-container-mobile">
                <a
                    target="_blank"
                    class="btn btn-primary btn-block btn-mobile rounded-3 text-decoration-none"
                    (click)="redirectToSurveyPage()"
                >
                    {{ 'start-questionnaire-mobile' | cmsText | async }}
                </a>
            </div>
        </div>
    </div>
    <ng-template #showDesktopVersion>
        <div class="alert alert-info container">
            <div class="content">
                <img
                    class="image"
                    [attr.src]="'survey-icon-img' | cmsImageSrc | async"
                    [attr.alt]="'survey-icon-img' | cmsImageAlt | async"
                />
                <div class="description">
                    <h5>
                        {{ 'survey-cta-title' | cmsText | async }}
                    </h5>
                    <span>Deel in <strong>3 min</strong> je feedback.</span>
                </div>
            </div>
            <div class="button-container">
                <a
                    target="_blank"
                    class="btn btn-primary rounded-3 text-decoration-none"
                    (click)="redirectToSurveyPage()"
                >
                    <span class="mx-3">
                        {{ 'start-questionnaire' | cmsText | async }}
                    </span>
                </a>
            </div>
        </div>
    </ng-template>
</div>

<ng-template #showOldDesign>
    <div class="alert alert-info">
        <div class="d-flex flex-row">
            <div class="col-2 col-sm-1 me-3 p-0">
                <img
                    [attr.src]="'survey-icon-img' | cmsImageSrc | async"
                    [attr.alt]="'survey-icon-img' | cmsImageAlt | async"
                />
            </div>
            <div class="ms-2">
                <h5 class="alert-heading">
                    {{ 'survey-cta-title' | cmsText | async }}
                </h5>
                <span
                    [innerHtml]="cmsKey | cmsText : [cmsTextVariables] | async"
                ></span>
            </div>
        </div>
    </div>
</ng-template>
