import { Component, Input, OnInit } from '@angular/core';
import { OfferSupplier } from '@common/model/offersupplier.base';
import { Supplier } from '@common/model/supplier.base';
import { SupplierService } from '@common/services/supplier.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-proposal-sidebar-supplier-logo',
    templateUrl: './proposal-sidebar-supplier-logo.component.html',
})
export class ProposalSidebarSupplierLogoComponent implements OnInit {
    @Input()
    supplier: Supplier;
    @Input() auctionId: string;

    public supplierOfferUri$: Observable<string>;

    constructor(private supplierService: SupplierService) {}

    ngOnInit(): void {
        this.supplierOfferUri$ = this.supplierService
            .getSupplierOffer(this.auctionId, this.supplier.id)
            .pipe(map((s) => s.logoImageUri));
    }

    get supplierLogo(): string {
        return `proposal-logo-${this.supplier?.code}`;
    }

    get supplierWhoIsFile(): string {
        return `proposal-who-is-pdf-${this.supplier?.code}`;
    }
}
