<h3 class="my-3">{{ 'overview-welcome-title' | cmsText | async }}</h3>

<hr class="my-3" />

<app-hero
    heroImageKey="hero-image-overview"
    heroImageClass="hero-image-medium mb-3"
>
</app-hero>

<div class="col-sm-12">
    <h6>
        {{
            'overview-success-status-interested-individual-title'
                | cmsText
                | async
        }}
    </h6>
    <p>
        {{
            'overview-success-status-interested-individual-text'
                | cmsText
                | async
        }}
    </p>
</div>

<hr class="my-3" />

<app-register-faq [faqItems]="faqItems"></app-register-faq>

<div class="mb-3 row mt-3">
    <div class="col-sm-4 text-start">
        <button
            type="button"
            class="btn btn-outline-secondary"
            (click)="editPersonDetails()"
        >
            {{ 'go-back-button' | cmsText | async }}
        </button>
    </div>
</div>
