import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-register-exclude-btn',
    templateUrl: './exclude-button.component.html',
})
export class RegisterExcludeButtonComponent {
    @Input()
    disabled = false;
}
