export enum RoofStep {
    // Note: the _numeric_ values are actually used for determining
    // things like order and (dis)allowing next/previous.
    LivingSituation = 0,
    Type = 1,
    Size = 2,
    Objects = 3,
    Energy = 4,
    Yield = 5,
}

export namespace RoofStep {
    export const values = [
        RoofStep.LivingSituation,
        RoofStep.Type,
        RoofStep.Size,
        RoofStep.Objects,
        RoofStep.Energy,
        RoofStep.Yield,
    ];

    export const headerKeys: string[] = [
        'roof-living-situation-header',
        'roof-type-header',
        'roof-size-header',
        'roof-objects-header',
        'roof-energy-header',
        'roof-yield-header',
    ];
}
