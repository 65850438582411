import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ContentService } from '@common/services/content.service';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DestroyableBase } from '../destroyable/destroyable.component';
import { DeviceTypeService } from '@country/nl/services/device-type.service';
import { SocialLinkGeneratorService } from '@common/services/social-link-generator.service';

@Component({
    template: `<div class="mb-3">
        {{ 'loading-message' | cmsText | async }}
    </div>`,
})
export class RedirectToSocialComponent
    extends DestroyableBase
    implements OnInit
{
    public redirectUrl: string;

    constructor(
        private contentService: ContentService,
        private deviceTypeService: DeviceTypeService,
        private activatedRoute: ActivatedRoute,
        private socialLinkGeneratorService: SocialLinkGeneratorService,
        @Inject(DOCUMENT) private document: Document,
    ) {
        super();

        const source =
            this.activatedRoute.snapshot.paramMap.get('social-target');
        this.redirectWithUtmCodes(source);
    }

    ngOnInit(): void {
        this.document.location.href = this.redirectUrl;
    }

    redirectWithUtmCodes(target: string) {
        switch (target) {
            case 'email':
                this.redirectToEmail();
                break;
            case 'whatsapp':
                this.redirectToWhatsapp();
                break;
            default:
                break;
        }
    }

    redirectToEmail() {
        let emailSubject = this.getCmsValueFromContentService(
            'email-message-header-offer-delivery-done',
        );
        let emailBody = this.getCmsValueFromContentService(
            'email-message-body-offer-delivery-done',
        );
        this.redirectUrl = this.socialLinkGeneratorService.getEmailLinkString(emailBody, emailSubject);
    }

    redirectToWhatsapp() {
        let whatsappBody = this.getCmsValueFromContentService(
            'whatsapp-message-offer-delivery-done',
        );

        let isMobileDevice = this.deviceTypeService.isMobileDevice();
        this.redirectUrl = isMobileDevice 
            ? this.socialLinkGeneratorService.getMobileWhatsappLinkString(whatsappBody)
            : this.socialLinkGeneratorService.getDesktopWhatsappLinkString(whatsappBody)
    }

    getCmsValueFromContentService(cmsKey: string): string {
        let returnValue = '';
        this.contentService
            .get(cmsKey)
            .pipe(filter((cmsValue) => cmsValue && cmsValue.value))
            .subscribe((cmsValue) => {
                returnValue = cmsValue.value;
            });
        return returnValue;
    }
}
