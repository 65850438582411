import { PlayerState } from '@common/services/youtube.service';
import { AnalyticsEvent, AnalyticsEventTemplate } from './analytics.event';

type Template = AnalyticsEventTemplate<VideoInteractionEvent>;

type VideoInteractionEventType = PlayerState;

export class VideoInteractionEvent implements AnalyticsEvent {
    event = 'video_interaction' as const;

    video_interaction_type: VideoInteractionEventType;
    video_id: string;

    constructor(template: Template = {}) {
        Object.assign(this, template);
    }

    static create(template: Template = {}): VideoInteractionEvent {
        return new VideoInteractionEvent(template);
    }
}
