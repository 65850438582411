import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Injectable()
export class SocialLinkGeneratorService {

    readonly whatsappBaseLinkMobile : string = 'whatsapp://send?text=';
    readonly whatsappBaseLinkDesktop : string = 'https://web.whatsapp.com/send?text=';

    constructor(private sanitizer: DomSanitizer,
        ) {}

    private bypassSecurityTrustUrl(url:string): SafeUrl {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    private sanitize(safeUrl: SafeUrl) : string{
        return this.sanitizer.sanitize(SecurityContext.URL, safeUrl);
    }

    private returnSafeUrl(url: string): SafeUrl{
        return this.bypassSecurityTrustUrl(url);
    }

    private returnUrlString(url: string): string{
        return this.sanitize(this.bypassSecurityTrustUrl(url));
    }

    public getMobileWhatsappLinkString(body: string) : string {
        const fullLink = `${this.whatsappBaseLinkMobile}${body}`;
        return this.returnUrlString(fullLink);
    }

    public getMobileWhatsappLinkSafeUrl(body: string) : SafeUrl {
        const fullLink = `${this.whatsappBaseLinkMobile}${body}`;
        return this.returnSafeUrl(fullLink);
    }

    public getDesktopWhatsappLinkString(body: string) : string {
        const fullLink = `${this.whatsappBaseLinkDesktop}${body}`;
        return this.returnUrlString(fullLink);
    }

    public getEmailLinkString(emailBody: string, emailSubject: string) : string {
        return `mailto:?to=&body=${encodeURIComponent(emailBody)}&subject=${encodeURIComponent(emailSubject)}`;
    }
}
