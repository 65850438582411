import { Component } from '@angular/core';
import { Registration } from '@spnl/model/registration';
import { RegisterStoreService } from '@spnl/services/register-store.service';

@Component({
    selector: 'app-register-deposit-failed',
    templateUrl: './deposit-failed.component.html',
})
export class DepositFailedComponent {
    get registration(): Registration {
        return this.registerStore.registration;
    }

    constructor(private registerStore: RegisterStoreService) {}
}
