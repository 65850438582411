import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-register-cancel-link',
    templateUrl: './cancel-link.component.html',
})
export class RegisterCancelLinkComponent {
    @Input()
    url: string;
}
