import { Injectable } from '@angular/core';
import { RegisterConfig } from '@spnl/components/register/register.config';
import { SolarInstallation } from '@spnl/model/solar-installation';
import { SolarPanelType } from '@spnl/model/solar-panel-type';
import { RegisterStoreService } from './register-store.service';

@Injectable()
export class InstallationService {
    constructor(private store: RegisterStoreService) {}

    get installation(): SolarInstallation {
        const registration = this.store.registration;
        return this.getInstallation(
            true,
            registration.product.chosenNumberOfPanels,
        );
    }

    getInstallation(
        choosesPremiumPanels: boolean,
        chosenNumberOfPanels: number,
    ): SolarInstallation {
        const installations = this.store.installations;

        if (installations) {
            return choosesPremiumPanels
                ? installations.premiumPanels[
                      chosenNumberOfPanels - RegisterConfig.minNumberOfPanels
                  ]
                : installations.standardPanels[
                      chosenNumberOfPanels - RegisterConfig.minNumberOfPanels
                  ];
        }

        return null;
    }

    choosesPremiumPanels(chosenSolarPanelType: SolarPanelType): boolean {
        return (
            this.store.installations?.premiumSolarPanelType ===
            chosenSolarPanelType
        );
    }
}
