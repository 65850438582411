import { Component, Input, OnInit } from '@angular/core';
import { ContentService } from '@common/services/content.service';
import { Registration } from '@spnl/model/registration';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-proposal-panel-explanation',
    templateUrl: './proposal-panel-explanation.component.html',
})
export class ProposalPanelExplanationComponent implements OnInit {
    @Input()
    registration: Registration;

    @Input()
    noPanelsBasedOnUsage: number;

    get energyConsumption(): string {
        return this.registration?.product?.electricityConsumptionPerYear +' kWh';
    }

    get maxPanelsBasedOnRoof(): number {
        return this.registration?.product?.numberOfPanelsThatFitOnRoof;
    }

    get optimialAmountPanels(): number {
        return Math.min(this.noPanelsBasedOnUsage,this.registration?.product?.numberOfPanelsThatFitOnRoof );
    }

    heatPumpDescription: string ='';
    heatPumpElectricityAmount: string =''

    aircoDescription: string ='';
    aircoElectricityAmount: string =''

    electricOvenDescription: string ='';
    electricOvenElectricityAmount: string =''

    electricCarDescription: string ='';
    electricCarElectricityAmount: string =''

    constructor(
       private contentService: ContentService
    ) {}

    ngOnInit(): void {

        if (this.registration?.product?.isInterestedInHeatPump)
        {
            this.contentService.get('proposal-explanation-heat-pump-electricity-amount').pipe(first()).subscribe((cmsValue)=> this.heatPumpElectricityAmount = cmsValue.value)
            this.contentService.get('proposal-explanation-heat-pump-description').pipe(first()).subscribe((cmsValue)=> this.heatPumpDescription = cmsValue.value)
        }

        if (this.registration?.product?.isInterestedInAirconditioning)
        {
            this.contentService.get('proposal-explanation-airco-electricity-amount').pipe(first()).subscribe((cmsValue)=> this.aircoElectricityAmount = cmsValue.value)
            this.contentService.get('proposal-explanation-airco-description').pipe(first()).subscribe((cmsValue)=> this.aircoDescription = cmsValue.value)
        }

        if (this.registration?.product?.isInterestedInElectricCar)
        {
            this.contentService.get('proposal-explanation-electric-car-electricity-amount').pipe(first()).subscribe((cmsValue)=> this.electricCarElectricityAmount = cmsValue.value)
            this.contentService.get('proposal-explanation-electric-car-description').pipe(first()).subscribe((cmsValue)=> this.electricCarDescription = cmsValue.value)
        }

        if (this.registration?.product?.isInterestedInElectricOven)
        {
            this.contentService.get('proposal-explanation-electric-oven-electricity-amount').pipe(first()).subscribe((cmsValue)=> this.electricOvenElectricityAmount = cmsValue.value)
            this.contentService.get('proposal-explanation-electric-oven-description').pipe(first()).subscribe((cmsValue)=> this.electricOvenDescription = cmsValue.value)
        }

    }


}
