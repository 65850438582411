import {
    AfterViewInit,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import {
    applyFormValue,
    ControlWithErrors,
    makeFormModel,
    shouldShowErrorsFor,
} from '@common/infrastructure/form-tools';
import { DestroyableBase } from '@spnl/components/destroyable/destroyable.component';
import { RoofStepFormGroup } from '@spnl/forms/roof-form-tools';
import { YesNoDontKnow } from '@spnl/model/decision.model';
import { RegisterStep } from '@spnl/model/register-step.enum';
import { Registration } from '@spnl/model/registration';
import { DigitalEventService } from '@spnl/services/digital-event-queue.service';

@Component({
    selector: 'app-register-roof-objects',
    templateUrl: './roof-objects.component.html',
})
export class RoofObjectsComponent
    extends DestroyableBase
    implements OnInit, OnDestroy, AfterViewInit
{
    @Input()
    registration: Registration;

    @Input()
    parentForm: UntypedFormGroup;

    Decision = YesNoDontKnow;

    form: RoofStepFormGroup;
    submitRequested = false;

    hasRoofObjects: ControlWithErrors;
    hasDormer: ControlWithErrors;
    hasRoofShadow: ControlWithErrors;

    get showErrorsForHasRoofObjects(): boolean {
        return shouldShowErrorsFor(this.hasRoofObjects);
    }

    get showErrorsForHasDormer(): boolean {
        return (
            this.form.hasError('hasDormerRequired') && this.hasDormer.touched
        );
    }

    get showErrorsForHasRoofShadow(): boolean {
        return shouldShowErrorsFor(this.hasRoofShadow);
    }

    get showWarningForHasRoofObjects(): boolean {
        return (
            this.hasRoofObjects.value === YesNoDontKnow.Yes ||
            this.hasRoofObjects.value === YesNoDontKnow.DoNotKnow
        );
    }

    get showWarningForHasDormer(): boolean {
        return this.hasDormer.value === true;
    }

    get showWarningsForHasRoofShadow(): boolean {
        return (
            this.hasRoofShadow.value === YesNoDontKnow.Yes ||
            this.hasRoofShadow.value === YesNoDontKnow.DoNotKnow
        );
    }

    get showHasDormer(): boolean {
        return (
            this.hasRoofObjects.value === YesNoDontKnow.Yes ||
            this.hasRoofObjects.value === YesNoDontKnow.DoNotKnow
        );
    }

    constructor(
        private fb: UntypedFormBuilder,
        private digitalEventService: DigitalEventService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.form = this.fb.group(
            {
                hasRoofObjects: [null, Validators.required],
                hasDormer: [null],
                hasRoofShadow: [null, Validators.required],
            },
            {
                validators: [this.hasDormerRequiredValidator],
            },
        ) as RoofStepFormGroup;

        this.hasRoofObjects = this.form.get('hasRoofObjects');
        this.hasDormer = this.form.get('hasDormer');
        this.hasRoofShadow = this.form.get('hasRoofShadow');

        this.form.onSubmit = this.onSubmit.bind(this);
        this.parentForm.addControl('roof-objects', this.form);

        const model = makeFormModel(this.form, this.registration.product, []);
        this.form.reset(model);
    }

    ngAfterViewInit(): void {
        this.digitalEventService.push('dakobjecten');
    }

    onSubmit(): void {
        this.form.markAllAsTouched();

        this.submitRequested = true;
        if (this.form.valid) {
            applyFormValue(this.form, this.registration.product, []);
        }
    }

    ngOnDestroy(): void {
        this.parentForm?.removeControl('roof-objects');
        super.ngOnDestroy();
    }

    private hasDormerRequiredValidator(
        group: UntypedFormGroup,
    ): ValidationErrors | null {
        const shouldBeRequired = [
            YesNoDontKnow.Yes,
            YesNoDontKnow.DoNotKnow,
        ].includes(group.controls.hasRoofObjects.value);
        return group.controls.hasDormer.value === null && shouldBeRequired
            ? { hasDormerRequired: true }
            : null;
    }
}
