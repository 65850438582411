<div [formGroup]="form">
    <div class="mb-3 mb-4">
        <label for="knowsNumberOfPanelsThatFitOnRoof">
            {{ 'roof-surface-knows-max-panels-question' | cmsText | async }}
            <app-spnl-tooltip
                cmsKey="roof-surface-knows-max-panels-tooltip-body"
            ></app-spnl-tooltip>
        </label>
        <div class="form-check">
            <input
                type="radio"
                name="knowsNumberOfPanelsThatFitOnRoof"
                [ngClass]="{
                    'form-check-input': true,
                    'is-invalid': showErrorsForKnowsNumberOfPanels
                }"
                id="knowsNumberOfPanelsThatFitOnRoofYes"
                formControlName="knowsNumberOfPanelsThatFitOnRoof"
                [value]="true"
            />
            <label
                class="custom-control-label"
                for="knowsNumberOfPanelsThatFitOnRoofYes"
            >
                {{ 'yes' | cmsText | async }}
            </label>
        </div>
        <div class="form-check">
            <input
                type="radio"
                name="knowsNumberOfPanelsThatFitOnRoof"
                [ngClass]="{
                    'form-check-input': true,
                    'is-invalid': showErrorsForKnowsNumberOfPanels
                }"
                id="knowsNumberOfPanelsThatFitOnRoofNo"
                formControlName="knowsNumberOfPanelsThatFitOnRoof"
                [value]="false"
            />
            <label
                class="custom-control-label"
                for="knowsNumberOfPanelsThatFitOnRoofNo"
            >
                {{ 'no' | cmsText | async }}
            </label>
        </div>
        <app-register-input-errors
            [shouldShowErrors]="showErrorsForKnowsNumberOfPanels"
            [field]="knowsNumberOfPanelsThatFitOnRoof"
            label="roof-surface-panel-count-question"
        >
        </app-register-input-errors>
    </div>

    <div class="mb-4" *ngIf="knowsNumberOfPanelsThatFitOnRoof.value === false">
        <label>
            {{ 'roof-surface-dimensions-question' | cmsText | async }}
            <app-spnl-tooltip
                cmsKey="roof-surface-dimensions-tooltip-body"
            ></app-spnl-tooltip>
        </label>
        <p *ngIf="registration.product.roofPitch == RoofPitch.Flat">
            {{ 'roof-surface-dimensions-flat-roof-info' | cmsText | async }}
        </p>
        <div class="row align-items-center">
            <div
                class="offset-3 col-6 offset-md-0 col-md-3 text-center"
                *ngIf="registration.product.roofPitch != RoofPitch.Flat"
            >
                <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 99.2 99.2"
                    style="enable-background: new 0 0 99.2 99.2"
                    xml:space="preserve"
                >
                    <path
                        class="roof-surface-dimensions-house"
                        d="M90.3,39.6C90.3,39.6,90.3,39.6,90.3,39.6c0-0.2-0.1-0.3-0.1-0.4c0,0,0,0,0,0L70.4,9.3c-0.2-0.3-0.6-0.5-1-0.4 l-44.8,9.9c0,0,0,0,0,0l0,0c0,0,0,0,0,0c-0.1,0-0.3,0.1-0.4,0.2c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.2,0.2L9.1,39.1
                    c-0.1,0.2-0.2,0.4-0.2,0.6v29.8c0,0.4,0.2,0.7,0.5,0.9l34.8,19.8c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0
                    c0.1,0,0.2,0,0.4-0.1c0,0,0,0,0,0l44.6-19.8c0.4-0.2,0.6-0.5,0.6-0.9L90.3,39.6C90.3,39.7,90.3,39.6,90.3,39.6z M10.9,40l13.8-18.4 l19,33.2v32.7L10.9,68.9V40z M88.3,68.8L45.7,87.8V55.3l42.6-14.2V68.8z"
                    />
                    <path
                        class="roof-surface-dimensions-arrows"
                        d="M71.6,58.9v1.3h-5.1v-8.7h1.6v7.3h3.5V58.9z"
                    />
                    <path
                        class="roof-surface-dimensions-arrows"
                        d="M26.8,35.9c0.5,0,1,0,1.5,0.2c0.4,0.1,0.7,0.2,1,0.5c0.2,0.2,0.4,0.4,0.6,0.7c0.1,0.3,0.2,0.7,0.2,1
                    c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.3c1.2,0.3,1.8,0.9,1.8,1.9
                    c0,0.3-0.1,0.7-0.2,1s-0.4,0.6-0.6,0.8c-0.3,0.2-0.6,0.4-1,0.5s-0.9,0.2-1.4,0.2h-3.3v-8.7L26.8,35.9L26.8,35.9z M25.4,37.2v2.5h1.3
                    c0.4,0,0.9-0.1,1.3-0.3c0.3-0.2,0.4-0.6,0.4-1s-0.1-0.8-0.4-1c-0.4-0.2-0.8-0.3-1.2-0.3L25.4,37.2z M27.1,43.3c0.3,0,0.5,0,0.8-0.1
                    c0.2-0.1,0.3-0.2,0.5-0.3c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.2,0.1-0.3,0.1-0.5s0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4 c-0.1-0.1-0.3-0.2-0.5-0.3s-0.5-0.1-0.7-0.1h-1.6v2.5L27.1,43.3z"
                    />
                    <polygon
                        class="roof-surface-dimensions-arrows"
                        points="42.5,48.9 28.3,23.9 30.5,22.6 24.5,19.1 24.5,26.1 26.5,24.9 40.8,49.9 38.6,51.1 44.7,54.6 44.7,47.6"
                    />
                    <polygon
                        class="roof-surface-dimensions-arrows"
                        points="85.5,44.3 90.1,39.4 83.5,37.8 84.3,40.3 50,51.7 49.1,49.3 44.7,54.6 51.5,55.8 50.7,53.6 84.8,42.2"
                    />
                </svg>
            </div>
            <div
                class="offset-3 col-6 offset-md-0 col-md-3 text-center"
                *ngIf="registration.product.roofPitch == RoofPitch.Flat"
            >
                <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 99.2 99.2"
                    style="enable-background: new 0 0 99.2 99.2"
                    xml:space="preserve"
                >
                    <path
                        class="roof-surface-dimensions-arrows"
                        d="M71.6,58.9v1.3h-5.1v-8.7h1.6v7.3h3.5V58.9z"
                    />
                    <path
                        class="roof-surface-dimensions-arrows"
                        d="M19.4,48.6c0.5,0,1,0,1.5,0.2c0.4,0.1,0.7,0.2,1,0.5c0.2,0.2,0.4,0.4,0.6,0.7c0.1,0.3,0.2,0.7,0.2,1
                c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.3c1.2,0.3,1.8,0.9,1.8,1.9
                c0,0.3-0.1,0.7-0.2,1s-0.4,0.6-0.6,0.8c-0.3,0.2-0.6,0.4-1,0.5s-0.9,0.2-1.4,0.2h-3.3v-8.7L19.4,48.6L19.4,48.6z M18,49.9v2.5h1.3
                c0.4,0,0.9-0.1,1.3-0.3c0.3-0.2,0.4-0.6,0.4-1s-0.1-0.8-0.4-1c-0.4-0.2-0.8-0.3-1.2-0.3L18,49.9z M19.7,56c0.3,0,0.5,0,0.8-0.1
                c0.2-0.1,0.3-0.2,0.5-0.3c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.2,0.1-0.3,0.1-0.5s0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
                c-0.1-0.1-0.3-0.2-0.5-0.3s-0.5-0.1-0.7-0.1h-1.6v2.5L19.7,56z"
                    />
                    <path
                        class="roof-surface-dimensions-house"
                        d="M90.3,39.4C90.2,39.4,90.2,39.4,90.3,39.4c-0.1-0.2-0.1-0.3-0.2-0.4c0,0,0,0,0,0c0-0.1-0.1-0.1-0.2-0.2
                c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0L54.6,21.5c-0.3-0.1-0.5-0.1-0.8,0L9.5,38.8c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0.1-0.1,0.1
                c0,0,0,0,0,0C9.1,39.1,9,39.2,9,39.3c0,0,0,0,0,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0c0,0,0,0,0,0v29.8c0,0.4,0.2,0.7,0.5,0.9
                l34.8,19.8c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.4-0.1c0,0,0,0,0,0l44.6-19.8
                c0.4-0.2,0.6-0.5,0.6-0.9V39.7C90.3,39.6,90.3,39.5,90.3,39.4z M10.9,41.2l32.8,14v32.3L10.9,68.9V41.2z M88.3,68.8L45.7,87.8V55.3 l42.6-14.2V68.8z"
                    />
                    <polygon
                        class="roof-surface-dimensions-arrows"
                        points="39.8,51.5 14.8,40.8 15.7,38.5 9.2,39.3 13.2,44.6 14,42.5 39.1,53.2 38.1,55.3 44.7,54.5 40.7,49.2"
                    />
                    <polygon
                        class="roof-surface-dimensions-arrows"
                        points="85.5,44.3 90.1,39.4 83.5,37.8 84.3,40.3 50,51.7 49.1,49.3 44.7,54.6 51.5,55.8 50.7,53.6 84.8,42.2"
                    />
                </svg>
            </div>
            <div class="col-md-9 pl-5">
                <div class="mb-3 w-50">
                    <label class="form-control-label" for="roofLength">
                        {{ 'length' | cmsText | async }}</label
                    >
                    <div class="input-group">
                        <input
                            data-controlname="roofLength"
                            type="number"
                            formControlName="roofLength"
                            [ngClass]="{
                                'form-control': true,
                                'is-invalid': showErrorsForRoofLength
                            }"
                        />
                        <span class="input-group-text">m</span>
                    </div>
                    <app-register-input-errors
                        [shouldShowErrors]="showErrorsForRoofLength"
                        [field]="roofLength"
                        label="roof-length"
                    >
                    </app-register-input-errors>
                </div>
                <app-register-warning
                    *ngIf="roofLength.value > config.maxRoofLength"
                    warningKey="roof-length-warning-max"
                >
                </app-register-warning>

                <div class="mb-3 w-50">
                    <label class="form-control-label" for="roofWidth">
                        {{ 'width' | cmsText | async }}</label
                    >
                    <div class="input-group">
                        <input
                            data-controlname="roofWidth"
                            type="number"
                            formControlName="roofWidth"
                            [ngClass]="{
                                'form-control': true,
                                'is-invalid': showErrorsForRoofWidth
                            }"
                        />
                        <span class="input-group-text">m</span>
                    </div>
                    <app-register-input-errors
                        [shouldShowErrors]="showErrorsForRoofWidth"
                        [field]="roofWidth"
                        label="roof-width"
                    >
                    </app-register-input-errors>
                </div>
                <app-register-warning
                    *ngIf="roofWidth.value > config.maxRoofWidth"
                    warningKey="roof-width-warning-max"
                >
                </app-register-warning>
            </div>
        </div>
    </div>

    <div class="mb-4" *ngIf="knowsNumberOfPanelsThatFitOnRoof.value === true">
        <label for="numberOfPanelsThatFitOnRoof">
            {{ 'roof-surface-panel-count-question' | cmsText | async }}
        </label>
        <div class="row">
            <div class="col-md-6">
                <input
                    data-controlname="numberOfPanelsThatFitOnRoof"
                    type="number"
                    class="form-control"
                    formControlName="numberOfPanelsThatFitOnRoof"
                    [ngClass]="{
                        'form-control': true,
                        'is-invalid': showErrorsForNumberOfPanels
                    }"
                />
                <app-register-text-error
                    *ngIf="
                        showErrorsForNumberOfPanels &&
                        numberOfPanelsThatFitOnRoof.errors.required
                    "
                    [cmsParams]="{
                        minNumberOfPanels: config.minNumberOfPanels
                    }"
                    errorKey="roof-surface-panel-count-error-required"
                >
                </app-register-text-error>
            </div>
        </div>
    </div>

    <app-register-warning
        *ngIf="numberOfPanelsThatFitOnRoofOverMax"
        warningKey="roof-panel-count-above-max"
        [cmsParams]="{ maxNumberOfPanels: config.maxNumberOfPanels }"
    >
    </app-register-warning>

    <div *ngIf="shouldBeExcluded">
        <app-register-error
            *ngIf="numberOfPanelsThatFitOnRoof?.errors?.min"
            errorKey="roof-surface-panel-count-error-min"
            [cmsParams]="{ minNumberOfPanels: config.minNumberOfPanels }"
        >
            <app-register-exclude-btn
                *ngIf="!startedWithProduct"
                (click)="onExcludeRegistration()"
            >
            </app-register-exclude-btn>
            <app-register-cancel-link
                *ngIf="startedWithProduct"
                url="https://www.eigenhuis.nl/diensten/"
            >
            </app-register-cancel-link>
        </app-register-error>
    </div>

    <div class="form-check">
        <input
            type="checkbox"
            class="form-check-input"
            id="isMoreRoofsAvailable"
            formControlName="isMoreRoofsAvailable"
        />
        <label class="custom-control-label" for="isMoreRoofsAvailable">
            <span
                [innerHTML]="
                    'is-another-roof-available-label' | cmsText | async
                "
            ></span>
        </label>
    </div>

    <app-register-warning
        *ngIf="showAnotherRoofAvailableMessage"
        warningKey="another-roof-available-message"
    >
    </app-register-warning>
</div>
