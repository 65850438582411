import { Component, Input } from '@angular/core';
import { RegisterStep } from '@spnl/model/register-step.enum';
import { Registration } from '@spnl/model/registration';
import { RegisterAnalyticsService } from '@spnl/services/register-analytics.service';
import {
    OnContinue,
    RegisterLocationService,
    Substep,
} from '@spnl/services/register-location.service';

@Component({
    selector: 'app-proposal-roof-data',
    templateUrl: './proposal-roof-data.component.html',
})
export class ProposalRoofDataComponent {
    @Input()
    registration: Registration;

    get energyConsumption(): number {
        return this.registration.product.futureExpectedElectricityConsumptionPerYear;
    }

    get numberOfPanels(): number {
        return this.registration.product.chosenNumberOfPanels;
    }

    constructor(
        private locationService: RegisterLocationService,
        private analytics: RegisterAnalyticsService,
    ) {}

    onEditProduct(): void {
        this.analytics.logEvent(this.registration, 'change-product-data');
        this.locationService.goTo(
            RegisterStep.Product,
            new Substep.None(),
            OnContinue.BackToReferrerStep,
        );
    }
}
