import { Component } from '@angular/core';
import { Registration } from '@spnl/model/registration';
import { RegisterStoreService } from '@spnl/services/register-store.service';

@Component({
    selector: 'app-register-decision-made',
    templateUrl: './decision-made.component.html',
})
export class DecisionMadeComponent {
    get registration(): Registration {
        return this.registerStore.registration;
    }

    get showDeclinedPage(): boolean {
        return this.registration.offerIsDeclined;
    }

    get showAcceptedPage(): boolean {
        return this.registration.proposalAccepted;
    }

    constructor(private registerStore: RegisterStoreService) {}
}
