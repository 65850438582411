import moment from 'moment';

export class RegistrationGroup {
    informDateExternal: string;
    informDateInternal: string;
    acceptDate: string;
    hardAcceptDate: string;
    finalPaymentDate: string;

    constructor(dto: any) {
        Object.assign(this, dto);
    }

    get informDateInternalInThePast(): boolean {
        if (!this.informDateInternal) {
            return false;
        }

        return moment
            .utc(this.informDateInternal, undefined, 'nl-NL')
            .isBefore(moment.utc().startOf('day'));
    }
}
