<div [formGroup]="form">
    <div class="mb-3">
        <label for="hasRoofObjects">
            {{ 'roof-surface-objects-question' | cmsText | async }}
            <app-spnl-tooltip
                cmsKey="roof-surface-objects-question-tooltip-body"
            ></app-spnl-tooltip>
        </label>
        <div class="form-check">
            <input
                type="radio"
                name="hasRoofObjects"
                [ngClass]="{
                    'form-check-input': true,
                    'is-invalid': showErrorsForHasRoofObjects
                }"
                id="hasRoofObjectsYes"
                formControlName="hasRoofObjects"
                [value]="Decision.Yes"
            />
            <label class="custom-control-label" for="hasRoofObjectsYes">
                {{ 'yes' | cmsText | async }}
            </label>
        </div>
        <div class="form-check">
            <input
                type="radio"
                name="hasRoofObjects"
                [ngClass]="{
                    'form-check-input': true,
                    'is-invalid': showErrorsForHasRoofObjects
                }"
                id="hasRoofObjectsNo"
                formControlName="hasRoofObjects"
                [value]="Decision.No"
            />
            <label class="custom-control-label" for="hasRoofObjectsNo">
                {{ 'no' | cmsText | async }}
            </label>
        </div>
        <div class="form-check">
            <input
                type="radio"
                name="hasRoofObjects"
                [ngClass]="{
                    'form-check-input': true,
                    'is-invalid': showErrorsForHasRoofObjects
                }"
                id="hasRoofObjectsDoNotKnow"
                formControlName="hasRoofObjects"
                [value]="Decision.DoNotKnow"
            />
            <label class="custom-control-label" for="hasRoofObjectsDoNotKnow">
                {{ 'dont-know' | cmsText | async }}
            </label>
        </div>
        <app-register-warning
            *ngIf="showWarningForHasRoofObjects"
            warningKey="roof-surface-objects-warning"
        ></app-register-warning>
        <app-register-input-errors
            [shouldShowErrors]="showErrorsForHasRoofObjects"
            [field]="hasRoofObjects"
            label="roof-surface-objects-question"
        >
        </app-register-input-errors>
    </div>

    <div class="mb-3" *ngIf="showHasDormer">
        <label for="hasDormer">
            {{ 'roof-surface-dormer-question' | cmsText | async }}
        </label>
        <div class="form-check">
            <input
                type="radio"
                name="hasDormer"
                [ngClass]="{
                    'form-check-input': true,
                    'is-invalid': showErrorsForHasDormer
                }"
                id="hasDormerYes"
                formControlName="hasDormer"
                [value]="true"
            />
            <label class="custom-control-label" for="hasDormerYes">
                {{ 'yes' | cmsText | async }}
            </label>
        </div>
        <div class="form-check">
            <input
                type="radio"
                name="hasDormer"
                [ngClass]="{
                    'form-check-input': true,
                    'is-invalid': showErrorsForHasDormer
                }"
                id="hasDormerNo"
                formControlName="hasDormer"
                [value]="false"
            />
            <label class="custom-control-label" for="hasDormerNo">
                {{ 'no' | cmsText | async }}
            </label>
        </div>
        <app-register-warning
            *ngIf="showWarningForHasDormer"
            warningKey="roof-surface-dormer-warning"
        ></app-register-warning>
        <ng-container *ngIf="showErrorsForHasDormer">
            <app-register-text-error
                *ngIf="hasDormer.value === null"
                errorKey="roof-surface-dormer-question-error-required"
            >
            </app-register-text-error>
        </ng-container>
    </div>

    <div class="mb-3">
        <label for="hasRoofShadow">
            {{ 'roof-surface-shadow-question' | cmsText | async }}
            <app-spnl-tooltip
                cmsKey="roof-surface-shadow-question-tooltip-body"
            ></app-spnl-tooltip>
        </label>
        <div class="form-check">
            <input
                type="radio"
                name="hasRoofShadow"
                [ngClass]="{
                    'form-check-input': true,
                    'is-invalid': showErrorsForHasRoofShadow
                }"
                id="hasRoofShadowYes"
                formControlName="hasRoofShadow"
                [value]="Decision.Yes"
            />
            <label class="custom-control-label" for="hasRoofShadowYes">
                {{ 'yes' | cmsText | async }}
            </label>
        </div>
        <div class="form-check">
            <input
                type="radio"
                name="hasRoofShadow"
                [ngClass]="{
                    'form-check-input': true,
                    'is-invalid': showErrorsForHasRoofShadow
                }"
                id="hasRoofShadowNo"
                formControlName="hasRoofShadow"
                [value]="Decision.No"
            />
            <label class="custom-control-label" for="hasRoofShadowNo">
                {{ 'no' | cmsText | async }}
            </label>
        </div>
        <div class="form-check">
            <input
                type="radio"
                name="hasRoofShadow"
                [ngClass]="{
                    'form-check-input': true,
                    'is-invalid': showErrorsForHasRoofShadow
                }"
                id="hasRoofShadowDoNotKnow"
                formControlName="hasRoofShadow"
                [value]="Decision.DoNotKnow"
            />
            <label class="custom-control-label" for="hasRoofShadowDoNotKnow">
                {{ 'dont-know' | cmsText | async }}
            </label>
        </div>
        <app-register-warning
            *ngIf="showWarningsForHasRoofShadow"
            warningKey="roof-surface-shadow-warning"
        ></app-register-warning>
        <app-register-input-errors
            [shouldShowErrors]="showErrorsForHasRoofShadow"
            [field]="hasRoofShadow"
            label="roof-surface-shadow-question"
        >
        </app-register-input-errors>
    </div>
</div>
