import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class DeviceTypeService {

    constructor() { }

    isMobileDevice(): boolean
    {
        var ua = navigator.userAgent;
        return (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
                ua,
            )
        ) ;
    }
}
