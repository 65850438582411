<h3 class="my-3">{{ 'payment-depositpending-header' | cmsText | async }}</h3>
<hr class="my-3" />

<div class="row">
    <div class="col-sm-12">
        <div class="alert alert-warning">
            <span>{{ statusCmsKey | cmsText | async }}</span>
        </div>
    </div>
</div>

<span
    [innerHTML]="
        'payment-extra-info-depositpending-text'
            | cmsText : [cmsTextExtraInfo]
            | async
    "
    routeTransformer
    [routeTransformerArgs]="[]"
></span>

<app-register-payment *ngIf="paymentAllowed"></app-register-payment>
<app-faq-category categoryKey="deposit-page"></app-faq-category>
