import { Component, Input, OnInit } from '@angular/core';
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { ControlWithErrors } from '@common/infrastructure/form-tools';
import { DestroyableBase } from '@spnl/components/destroyable/destroyable.component';
import { Registration } from '@spnl/model/registration';
import { SolarInstallation } from '@spnl/model/solar-installation';
import { SolarInstallations } from '@spnl/model/solar-installations';
import { SolarPanelType } from '@spnl/model/solar-panel-type';
import { ContentTranslatorService } from '@spnl/services/content-translator.service';
import { InstallationService } from '@spnl/services/installation.service';
import { RegisterStoreService } from '@spnl/services/register-store.service';
import { SolarApiService } from '@spnl/services/solar-api.service';
import { first } from 'rxjs/operators';
import { RegisterConfig } from '../../register.config';

@Component({
    selector: 'app-proposal-offer',
    templateUrl: './proposal-offer.component.html',
})
export class ProposalOfferComponent extends DestroyableBase implements OnInit {
    @Input() form: UntypedFormGroup;
    @Input() submitRequested: boolean;
    @Input() higherWattPeakAddOnEnabled: boolean;

    config = RegisterConfig;

    offer = this.fb.group({
        numberOfPanels: [
            0,
            [
                Validators.required,
                this.numberOfPanelsMinValidator(),
                Validators.max(this.config.maxNumberOfPanels),
            ],
        ],
        isInterestedInParallelInstallation: false,
    });

    get numberOfPanels(): ControlWithErrors {
        return this.offer.get('numberOfPanels');
    }

    get isInterestedInParallelInstallation(): ControlWithErrors {
        return this.offer.get('isInterestedInParallelInstallation');
    }

    get isInterestedInParallel(): boolean {
        return this.registration.product.isInterestedInParallel;
    }

    get registration(): Registration {
        return this.store.registration;
    }
    get installations(): SolarInstallations {
        return this.store.installations;
    }

    get optimalAmount(): number {
        const optimalAmount = this.installations?.optimalPremiumAmount;

        return Math.min(
            optimalAmount,
            this.registration.product.numberOfPanelsThatFitOnRoof,
        );
    }

    get installation(): SolarInstallation {
        return this.installationService.getInstallation(
            true,
            this.numberOfPanels.value,
        );
    }

    get showPremiumPanelsExtraPrice(): boolean {
        return true;
    }

    get premiumPanelPrice(): number {
        return this.premiumPanelsExtraPrice / this.numberOfPanels?.value;
    }

    get premiumPanelWp(): number {
        return SolarPanelType.getWattPeak(
            this.installations.premiumSolarPanelType,
        );
    }

    get standardPanelWp(): number {
        return SolarPanelType.getWattPeak(
            this.installations.standardSolarPanelType,
        );
    }

    get premiumPanelsExtraPrice(): number {
        const premiumPanelsPrice =
            this.installations.premiumPanels[
                this.numberOfPanels.value - this.config.minNumberOfPanels
            ].price;
        const standardPanelsPrice =
            this.installations.standardPanels[
                this.numberOfPanels.value - this.config.minNumberOfPanels
            ].price;
        return premiumPanelsPrice - standardPanelsPrice;
    }

    get showWarningForExceedingOptimalPanels(): boolean {
        const capacity = this.installations?.capacityOf(
            true,
            this.numberOfPanels.value,
        );

        return (
            this.numberOfPanels.valid &&
            this.numberOfPanels.value > this.optimalAmount &&
            capacity > this.registration.product.electricityConsumptionPerYear
        );
    }

    get showWarningForBelowOptimalPanels(): boolean {
        return (
            this.numberOfPanels.valid &&
            this.numberOfPanels.value < this.optimalAmount
        );
    }

    get showWarningForExceedingRoofSize(): boolean {
        return (
            this.numberOfPanels.valid &&
            this.numberOfPanels.value >
                this.registration.product.numberOfPanelsThatFitOnRoof
        );
    }

    get chosenAmountOfPanelsNotOptimalCmsMetadata(): any {
        return {
            optimalNumberOfPanels: this.optimalAmount,
        };
    }

    get addedPricePerPannelForParallel(): number {
        return this.installations.extraChargePerPanelForParallel;
    }

    get subtotalForParallel(): number {
        return this.numberOfPanels.value * this.addedPricePerPannelForParallel;
    }

    constructor(
        public contentTranslatorService: ContentTranslatorService,
        public solar: SolarApiService,
        private fb: UntypedFormBuilder,
        private store: RegisterStoreService,
        private installationService: InstallationService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.form.addControl('offer', this.offer);

        this.store.registrationLoaded$
            .pipe(first())
            .subscribe(() =>
                this.numberOfPanels.setValue(
                    this.registration.product.chosenNumberOfPanels,
                ),
            );

        this.registration.product.isInterestedInParallel = false;
    }

    chooseMorePanels(): void {
        this.numberOfPanels.setValue(
            Math.min(
                Math.max(
                    this.numberOfPanels.value + 1,
                    this.config.minNumberOfPanels,
                ),
                this.config.maxNumberOfPanels,
            ),
        );
    }

    chooseLessPanels(): void {
        this.numberOfPanels.setValue(
            Math.max(
                Math.min(
                    this.numberOfPanels.value - 1,
                    this.config.maxNumberOfPanels,
                ),
                this.config.minNumberOfPanels,
            ),
        );
    }

    numberOfPanelsMinValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors => {
            return control.value >= this.config.minNumberOfPanels ||
                !this.registration ||
                (this.registration && this.registration.excluded)
                ? null
                : { min: true };
        };
    }

    chooseParallelInstallation(event): void {
        this.registration.product.isInterestedInParallel = event.target.checked;
    }
}
