import { UrlMatchResult, UrlSegment } from '@angular/router';

/*
    This matches the following URI's with specific restrictions on the
    URLparams using Regular Expressions.

    register/:id      <- where id has to be a GUID
    register
*/
export function RegisterRedirectRouteMatcher(
    url: UrlSegment[],
): UrlMatchResult {
    if (url.length === 0) {
        return null;
    }

    if (url[0].toString() === 'register') {
        const param = url.length > 1 ? url[1].toString() : '';
        if (param === '') {
            return { consumed: url, posParams: {} };
        }

        const regGuid =
            /^([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})$/;
        if (regGuid.exec(param)) {
            return { consumed: url, posParams: { id: url[1] } };
        }
    }
    return null;
}
