<h6>{{ 'proposal-your-data-roof-header' | cmsText | async }}</h6>
<ul class="list-unstyled text-muted mb-0">
    <li>
        {{ 'chosen-number-of-panels' | cmsText | async }} {{ numberOfPanels }}
    </li>
    <li>
        {{ 'proposal-energy-consumption' | cmsText | async }}
        {{ energyConsumption | number : '1.0-0' }} kWh
    </li>
    <li *ngIf="registration.product.roofLength">
        {{ 'roof-length' | cmsText | async }}
        {{ registration.product.roofLength }} m
    </li>
    <li *ngIf="registration.product.roofWidth">
        {{ 'roof-width' | cmsText | async }}
        {{ registration.product.roofWidth }} m
    </li>
    <button
        *ngIf="!registration.auction.finished"
        class="btn btn-link text-primary p-0 fw-bold"
        id="changeRoofDataBtn"
        (click)="onEditProduct()"
    >
        <i class="fa fa-arrow-right me-2" aria-hidden="true"></i>
        {{ 'edit-button' | cmsText | async }}
    </button>
</ul>
