import { Injectable, Inject } from '@angular/core';
import { RegistrationBase } from '@common/model/registration.base';
import { Observable, of } from 'rxjs';
import { API_URL } from '@common/app/app.config';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class ProfileService {
    private baseUrl: string;

    constructor(@Inject(API_URL) baseUrl: string, private http: HttpClient) {
        this.baseUrl = baseUrl;
    }

    addOrGetProfileIdForRegistration(
        registration: RegistrationBase,
    ): Observable<string> {
        if (registration.profileId) {
            return of(registration.profileId);
        }

        const url = new URL(
            `registrations/${registration.id}/add-profile`,
            this.baseUrl,
        );
        return this.http.put<string>(url.toString(), {});
    }
}
