<header class="navbar navbar-expand-lg navbar-light border-bottom header-top">
    <div class="container">
        <div class="navbar-brand p-0">
            <img
                id="menuLogo"
                [attr.src]="'menu-logo' | cmsImageSrc | async"
                [attr.alt]="'menu-logo' | cmsImageAlt | async"
                class="my-2 d-inline-block align-top logo"
            />
        </div>
    </div>
</header>
