<div class="card my-3">
    <h5 class="card-header border-0">
        {{ 'personal-details-title' | cmsText | async }}
    </h5>
    <div class="card-body">
        <div class="row nls_protected">
            <div class="col-md-6">
                <h6 class="card-title">
                    {{ 'overview-contact-data' | cmsText | async }}
                </h6>
                <hr class="my-3" />
                <ul class="card-text list-unstyled">
                    <li>
                        {{ 'overview-contact-data-name' | cmsText | async }}
                        {{ registration.contact | fullName | async }}
                    </li>
                    <li>
                        {{ 'overview-contact-data-address' | cmsText | async }}
                        {{ registration.contact.street }}
                        {{ registration.contact.houseNr }}
                        {{ registration.contact.houseNrSuffix }}
                    </li>
                    <li>
                        {{ 'overview-contact-data-place' | cmsText | async }}
                        {{ registration.contact.zip }}
                        {{ registration.contact.city }}
                    </li>
                    <li>
                        {{ 'overview-contact-data-email' | cmsText | async }}
                        {{ registration.email }}
                    </li>
                    <li *ngIf="registration.contact.phone">
                        {{ 'overview-contact-data-tel' | cmsText | async }}
                        {{ registration.contact.phone }}
                    </li>
                    <li *ngIf="registration.contact.cellPhone">
                        {{
                            'overview-contact-data-cell-phone' | cmsText | async
                        }}
                        {{ registration.contact.cellPhone }}
                    </li>
                </ul>
            </div>
            <div class="col-md-6">
                <h6 class="card-title mt-md-0 mt-5">
                    {{ 'additional-personal-details-title' | cmsText | async }}
                </h6>
                <hr class="my-3" />

                <ul class="card-text list-unstyled">
                    <li
                        *ngIf="
                            registration.product.isHomeOwnerAssociationMember
                        "
                    >
                        {{
                            'overview-contact-data-home-owner-association-member-yes'
                                | cmsText
                                | async
                        }}
                    </li>
                    <li
                        *ngIf="
                            !registration.product.isHomeOwnerAssociationMember
                        "
                    >
                        {{
                            'overview-contact-data-home-owner-association-member-no'
                                | cmsText
                                | async
                        }}
                    </li>
                    <li
                        *ngIf="
                            registration.product.communityMember ===
                            CommunityMember.Yes
                        "
                    >
                        {{
                            'overview-contact-data-community-member-yes'
                                | cmsText
                                | async
                        }}
                    </li>
                    <li
                        *ngIf="
                            registration.product.communityMember ===
                            CommunityMember.No
                        "
                    >
                        {{
                            'overview-contact-data-community-member-no'
                                | cmsText
                                | async
                        }}
                    </li>
                </ul>
            </div>
        </div>
        <footer class="mt-3 text-end">
            <button
                *ngIf="!registration?.isReadonly"
                type="button"
                (click)="editPersonDetails()"
                class="btn btn-primary"
                id="PersonDetailsEditBtn"
            >
                {{ 'edit-button' | cmsText | async }}
            </button>
            <div
                *ngIf="registration?.isReadonly"
                [innerHTML]="'overview-registration-readonly' | cmsText | async"
            ></div>
        </footer>
    </div>
</div>

<div class="card my-3" *ngIf="registration.hasRoofDetails">
    <h5 class="card-header border-0">
        {{ 'roof-details-title' | cmsText | async }}
    </h5>
    <div class="card-body">
        <div class="row">
            <div class="col-md-6">
                <h6 class="card-title">
                    {{ 'overview-roof-details-roof-type' | cmsText | async }}
                </h6>
                <hr class="my-3" />
                <ul class="list-unstyled card-text">
                    <li *ngIf="registration.product.roofPitch">
                        {{
                            'roof-inclination-summary-label' | cmsText | async
                        }}:
                        {{
                            contentTranslatorService.forRoofPitch(
                                registration.product.roofPitch
                            )
                                | cmsText
                                | async
                        }}
                    </li>
                    <li *ngIf="registration.hasRoofOrientationDetails">
                        {{
                            'roof-orientation-summary-label' | cmsText | async
                        }}:
                        {{
                            contentTranslatorService.forRoofOrientation(
                                registration.product.roofOrientation
                            )
                                | cmsText
                                | async
                        }}
                    </li>
                    <li *ngIf="registration.product.roofMaterial">
                        {{ 'roof-material-summary-label' | cmsText | async }}:
                        {{
                            contentTranslatorService.forRoofMaterial(
                                registration.product.roofMaterial
                            )
                                | cmsText
                                | async
                        }}
                    </li>
                </ul>
            </div>
            <div
                class="col-md-6"
                *ngIf="
                    registration.product
                        .futureExpectedElectricityConsumptionPerYear
                "
            >
                <h6 class="card-title mt-md-0 mt-5">
                    {{ 'energy-usage-title' | cmsText | async }}
                </h6>
                <hr class="my-3" />
                <ul class="list-unstyled card-text">
                    <li>
                        {{ 'energy-consumption-single' | cmsText | async }}:
                        {{
                            registration.product
                                .futureExpectedElectricityConsumptionPerYear
                        }}
                        KWh
                        <app-spnl-tooltip
                            cmsKey="energy-consumption-tooltip-body"
                        ></app-spnl-tooltip>
                    </li>
                </ul>
            </div>
        </div>

        <div
            class="row"
            *ngIf="
                (registration.product.roofLength &&
                    registration.product.roofWidth) ||
                registration.product.knowsNumberOfPanelsThatFitOnRoof
            "
        >
            <div class="col-md-12 mt-3">
                <h5 class="card-title mt-5">
                    {{ 'roof-surface-dimensions-title' | cmsText | async }}
                </h5>
                <hr class="my-3" />

                <ul
                    *ngIf="
                        !registration.product
                            .knowsNumberOfPanelsThatFitOnRoof &&
                        registration.product.roofLength &&
                        registration.product.roofWidth
                    "
                    class="list-unstyled card-text"
                >
                    <li>
                        {{ 'length' | cmsText | async }}:
                        {{ registration.product.roofLength }} m
                    </li>
                    <li>
                        {{ 'width' | cmsText | async }}:
                        {{ registration.product.roofWidth }} m
                    </li>
                </ul>

                <ul class="card-text list-unstyled">
                    <li
                        *ngIf="
                            registration.product
                                .knowsNumberOfPanelsThatFitOnRoof
                        "
                    >
                        <span
                            [innerHTML]="
                                'overview-roof-details-panel-count'
                                    | cmsText
                                        : [
                                              {
                                                  numberOfPanelsThatFitOnRoof:
                                                      registration.product
                                                          .numberOfPanelsThatFitOnRoof
                                              }
                                          ]
                                    | async
                            "
                        ></span>
                    </li>
                    <li *ngIf="hasRoofShadow">
                        {{ 'overview-roof-details-shadow' | cmsText | async }}
                    </li>
                    <li *ngIf="hasRoofObjects">
                        {{
                            'overview-roof-details-obstructions'
                                | cmsText
                                | async
                        }}
                    </li>
                </ul>
            </div>
        </div>

        <footer class="mt-3 text-end">
            <button
                *ngIf="!registration?.isReadonly"
                type="button"
                (click)="editRoofDetails()"
                class="btn btn-primary"
                id="RoofDetailsEditBtn"
            >
                {{ 'edit-button' | cmsText | async }}
            </button>
            <div
                *ngIf="registration?.isReadonly"
                [innerHTML]="'overview-registration-readonly' | cmsText | async"
            ></div>
        </footer>
    </div>
</div>
