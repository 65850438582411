import { Component } from '@angular/core';
import { Registration } from '@spnl/model/registration';
import { RegisterStepService } from '@spnl/services/register-step.service';
import { RegisterStoreService } from '@spnl/services/register-store.service';

@Component({
    selector: 'app-register-product',
    templateUrl: './product.component.html',
})
export class ProductComponent {
    get registration(): Registration {
        return this.registerStore.registration;
    }

    get showExcludedPage() {
        return (
            this.registration.excluded &&
            !this.stepService.revertExclusionClickedInSession
        );
    }

    constructor(
        private registerStore: RegisterStoreService,
        private stepService: RegisterStepService,
    ) {}
}
