<app-content [key]="carouselHeader" class="ml-2 mb-2"></app-content>
<div class="card w-100">
    <ngb-carousel
        [showNavigationArrows]="showNavigationArrows"
        [showNavigationIndicators]="showNavigationIndicators"
        [imageViewerDirective]="imageViewerDirective"
        [wrap]="wrap"
        [animation]="animation"
        [interval]="interval"
        [keyboard]="keyboard"
        class="{{ carouselClass || 'picsum-img-wrapper' }}"
    >
        <ng-template ngbSlide *ngFor="let image of images">
            <img
                class="{{ imageClass || 'img-fluid' }}"
                [attr.src]="image | cmsImageSrc | async"
                [attr.alt]="image | cmsImageAlt | async"
            />
        </ng-template>
    </ngb-carousel>
</div>
