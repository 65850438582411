import { Component, Input } from '@angular/core';

// NOTE: this component should no longer be used. Mini-FAQs should be created using
// `FaqCategoryComponent`, so their questions can be managed by the business in the CMS.

@Component({
    selector: 'app-register-faq',
    templateUrl: './register-faq.component.html',
})
export class RegisterFaqComponent {
    @Input()
    faqItems: any[];

    itemTitleCmsKey(item: string): string {
        return `overview-faq-${item}-title`;
    }

    itemTextCmsKey(item: string): string {
        return `overview-faq-${item}-text`;
    }
}
