import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-register-input-errors',
    templateUrl: './input-errors.component.html',
})
export class RegisterInputErrorsComponent {
    @Input()
    shouldShowErrors: boolean;
    @Input()
    label: string;
    @Input()
    field: { errors: any };
    @Input()
    cmsParams = {};

    get hasRequiredErrors(): boolean {
        return this.field.errors && this.field.errors.required;
    }

    get hasPatternErrors(): boolean {
        return this.field.errors && this.field.errors.pattern;
    }
}
