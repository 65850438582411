import { AfterViewInit, Component, OnInit } from '@angular/core';
import { PaymentStatus } from '@common/model/payment';
import { Registration } from '@spnl/model/registration';
import { DigitalEventService } from '@spnl/services/digital-event-queue.service';
import { RegisterStateService } from '@spnl/services/register-state.service';
import { RegisterStoreService } from '@spnl/services/register-store.service';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-register-offer-accepted',
    templateUrl: './offer-accepted.component.html',
})
export class OfferAcceptedComponent implements OnInit, AfterViewInit {
    // Will be set to false when registration is loaded
    loading = true;

    get registration(): Registration {
        return this.registerStore.registration;
    }

    get auctionFinished(): boolean {
        return this.registration.auction?.finished;
    }

    get paymentIsClosed(): boolean {
        return this.registerStateService.paymentIsClosed(this.registration);
    }

    get showPaymentIntroduction(): boolean {
        return (
            !this.auctionFinished &&
            !this.paymentIsClosed &&
            !this.depositFailed
        );
    }

    get depositFailed(): boolean {
        return (
            this.registration.paymentStatus === PaymentStatus.Failed ||
            this.registration.paymentStatus === PaymentStatus.Cancelled
        );
    }

    get cmsHeaderKey(): string {
        if (this.auctionFinished) {
            return 'payment-auction-finished-header';
        }
        if (this.paymentIsClosed) {
            return 'payment-after-group-deadline-header';
        }
        if (this.depositFailed) {
            return 'payment-depositfailed-header';
        }

        return 'payment-default-header';
    }

    constructor(
        private registerStore: RegisterStoreService,
        private digitalEventService: DigitalEventService,
        private registerStateService: RegisterStateService,
    ) {}

    ngOnInit(): void {
        this.registerStore.registrationLoaded$
            .pipe(first())
            .subscribe(() => (this.loading = false));
    }

    ngAfterViewInit(): void {
        this.digitalEventService.push('betaalpagina');
    }
}
