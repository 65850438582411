<h3 class="my-3">
    {{
        'overview-page-of-contact-title'
            | cmsText : [{ fullName: registration.contact | fullName | async }]
            | async
    }}
</h3>

<div class="alert alert-success">
    <span
        [innerHTML]="'overview-email-confirmed-title' | cmsText | async"
    ></span>
</div>

<span
    [innerHTML]="
        'overview-email-confirmed-text'
            | cmsText
                : [
                      {
                          informDate:
                              registration.registrationGroup.informDateExternal
                              | date : 'd MMMM'
                      }
                  ]
            | async
    "
></span>

<app-register-survey-cta
    [registrationNumber]="registration.number"
    [auctionCode]="registration.auction.code"
    [registrationStatus]="registration.status"
    [supplierName]="registration.supplierName"
    cmsKey="survey-cta-body-completed"
>
</app-register-survey-cta>

<app-register-overview-next-steps
    [registration]="registration"
    [nextStepsCmsKey]="cmsKeyForProposal"
>
</app-register-overview-next-steps>

<hr class="my-3" />

<app-register-faq [faqItems]="faqItems"></app-register-faq>

<app-register-overview-change-details [registration]="registration">
</app-register-overview-change-details>
