<h3 class="my-3">{{ 'status-proposal-declined' | cmsText | async }}</h3>
<hr class="my-3" />

<app-hero
    heroImageKey="hero-image-overview"
    heroImageClass="hero-image-medium mb-3"
></app-hero>

<div class="alert alert-warning">
    <span
        [innerHTML]="cmsKeyForStatusProposalDeclined | cmsText | async"
    ></span>
</div>

<span [innerHTML]="'proposal-declined-intro-text' | cmsText | async"></span>

<ng-container *ngIf="auctionIsActive && !registration.copiedToNextAuction">
    <button (click)="revertDecision()" class="btn btn-primary px-5 mt-2">
        <app-content key="status-proposal-made-button-text"> </app-content>
    </button>
</ng-container>

<ng-container *ngIf="!auctionIsActive">
    <div class="d-grid">
        <button
            type="button"
            class="btn btn-outline-secondary"
            (click)="goToProposal()"
        >
            {{ 'previous-step-button' | cmsText | async }}
        </button>
    </div>
</ng-container>

<app-register-survey-cta
    [registrationNumber]="registration.number"
    [auctionCode]="registration.auction.code"
    [registrationStatus]="registration.status"
    [supplierName]="registration.supplierName"
    cmsKey="survey-cta-body-declined"
>
</app-register-survey-cta>
