import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import {
    switchMap,
    map,
    filter,
    debounceTime,
    distinctUntilChanged,
} from 'rxjs/operators';

import { RegistrationBase } from '@common/model/registration.base';
import { API_URL } from '@common/app/app.config';
import {
    ProfilePreferences,
    GroupedPreferences,
    ProfilePreference,
} from '@country/nl/models/profile';
import { ProfileService } from '@country/nl/services/profile.service';
import { isNullOrUndefined } from '@common/util';

@Injectable({
    providedIn: 'root',
})
export class PreferencesService {
    private baseUrl: string;

    constructor(
        @Inject(API_URL) baseUrl: string,
        private http: HttpClient,
        private profileService: ProfileService,
    ) {
        this.baseUrl = baseUrl;
    }

    public getPreferencesForRegistration(
        registration: RegistrationBase,
    ): Observable<ProfilePreferences> {
        const switchProfileIdToPreference = switchMap((profileId: string) =>
            this.getPreferencesForProfile(profileId, registration.communityId),
        );

        return this.profileService
            .addOrGetProfileIdForRegistration(registration)
            .pipe(switchProfileIdToPreference);
    }

    public getGroupedPreferencesForRegistration(
        registration: RegistrationBase,
    ): Observable<GroupedPreferences> {
        const groupPreferences = (
            accumulator: GroupedPreferences,
            currentValue: ProfilePreference,
        ) => {
            if (currentValue.registrationId) {
                accumulator.forRegistration.push(currentValue);
            } else if (currentValue.business) {
                const businessKey = currentValue.business.toLocaleLowerCase();
                accumulator.forBusiness[businessKey] =
                    accumulator.forBusiness[businessKey] || [];
                accumulator.forBusiness[businessKey].push(currentValue);
            } else {
                accumulator.forProfile.push(currentValue);
            }
            return accumulator;
        };

        return this.getPreferencesForRegistration(registration).pipe(
            filter((preferences) => !!preferences.preferences),
            map((preferences) =>
                preferences.preferences.reduce(
                    groupPreferences,
                    new GroupedPreferences(),
                ),
            ),
        );
    }

    public savePreferencesForRegistration(
        registration: RegistrationBase,
        preferences: ProfilePreference[],
    ): Observable<any> {
        return this.profileService
            .addOrGetProfileIdForRegistration(registration)
            .pipe(
                debounceTime(500),
                distinctUntilChanged(),
                switchMap((profileId) =>
                    this.savePreferencesForProfile(profileId, preferences),
                ),
            );
    }

    private getPreferencesForProfile(
        profileId: string,
        communityId: string,
    ): Observable<ProfilePreferences> {
        const url = new URL(
            `profiles/${profileId}/preferences/${communityId}`,
            this.baseUrl,
        );
        return this.http.get<ProfilePreferences>(url.toString()).pipe(
            map((profilePreferences) => {
                profilePreferences.preferences =
                    profilePreferences.preferences.filter(
                        (pref) => !isNullOrUndefined(pref.isOptIn),
                    );
                return profilePreferences;
            }),
        );
    }

    private savePreferencesForProfile(
        profileId: string,
        preferences: ProfilePreference[],
    ) {
        const url = new URL(`profiles/${profileId}/preferences/`, this.baseUrl);
        const saveRequests = preferences.map((pref) =>
            this.http.post(url.toString(), pref),
        );
        return forkJoin(saveRequests);
    }
}
