import { Component, OnInit } from '@angular/core';
import {
    RegisterLocationService,
    Substep,
} from '@spnl/services/register-location.service';
import { RegisterSaveService } from '@spnl/services/register-save.service';
import { RegisterStepService } from '@spnl/services/register-step.service';
import { RegisterStoreService } from '@spnl/services/register-store.service';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-register-cancelled',
    templateUrl: './cancelled.component.html',
})
export class CancelledComponent implements OnInit {
    loading = true;

    canRevertCancellation: boolean;

    constructor(
        private registerStore: RegisterStoreService,
        private registerSaveService: RegisterSaveService,
        private registerLocationService: RegisterLocationService,
        private registerStepService: RegisterStepService,
    ) {}

    ngOnInit(): void {
        this.registerStore.registrationLoaded$.pipe(take(1)).subscribe(() => {
            this.canRevertCancellation = !this.registerStore.registration
                .decisionComplete;

            this.loading = false;
        });
    }

    async revertCancellation(): Promise<void> {
        if (this.canRevertCancellation) {
            await this.registerSaveService.toggleCancellation(
                this.registerStore.registration.id,
                false,
            );

            const step = this.registerStepService.getLatestAllowedStep(
                this.registerStore.registration,
            );
            this.registerLocationService.goTo(step, new Substep.None());
        }
    }
}
