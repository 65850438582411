import { Component, Input, OnInit } from '@angular/core';
import { FaqCategory } from '@common/components/faq/faq.component.base';
import { ContentService } from '@common/services/content.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-faq-category',
    templateUrl: './faq-category.component.html',
})
export class FaqCategoryComponent implements OnInit {
    @Input()
    categoryKey: string;

    header$: Observable<string>;
    faqItems$: Observable<string[]>;

    constructor(private content: ContentService) {}

    ngOnInit(): void {
        const category = new FaqCategory(this.categoryKey);
        this.faqItems$ = category.getItemKeys(this.content);
        this.header$ = category.getHeader(this.content);
    }
}
