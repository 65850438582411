import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AddressService {
    protected url = 'https://ichoosr-addresses.azurewebsites.net/api/GetNlAddress';

    constructor(protected http: HttpClient) { }

    getAddress(zipCode: string, houseNr: string): Observable<AddressResponse> {
        return this.http.get<AddressResponse>(`${this.url}?zipCode=${zipCode}&houseNr=${houseNr}`);
    }
}

// TODO: (SPNL-586) Make all properties here lowercase by removing JSON attribute on API model
export interface AddressResponse {
    City: string;
    Street: string;
    province: string;
    municipality: string;
    longitude: number;
    latitude: number;
    HouseNumberAdditions: string;
    AllowManualEntry: boolean;
}
