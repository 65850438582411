import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { Supplier } from '@common/model/supplier.base';
import { SupplierService } from '@common/services/supplier.service';
import { Registration } from '../../model/registration';
import { Seminar } from '../../model/seminar';
import { RegistrationService } from '../../services/registration.service';
import { DestroyableBase } from '../destroyable/destroyable.component';
import { RegistrationApiService } from '@spnl/services/registration-api.service';

@Component({
    selector: 'app-seminar',
    templateUrl: './seminar.component.html',
})
export class SeminarComponent extends DestroyableBase implements OnInit {
    // TODO: Deduplicate this with SpBe (and others)

    registration: Registration;
    seminars: Seminar[];
    seminars$: Observable<Seminar[]>;

    upcomingSeminars: Seminar[] = [];
    pastSeminars: Seminar[] = [];

    supplier: Supplier;

    constructor(
        private registrationService: RegistrationService,
        private registrationApiService: RegistrationApiService,
        protected route: ActivatedRoute,
        private supplierService: SupplierService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.seminars$ = this.route.paramMap.pipe(
            filter((params) => params.has('id')),
            map((params) => params.get('id')),
            switchMap((id: string) => this.registrationService.get(id)),

            tap((theOne) => (this.registration = theOne)),
            switchMap((registration: Registration) =>
                this.registrationApiService.seminars(registration),
            ),

            takeUntil(this.destroyed$),
        );

        this.seminars$
            .pipe(
                filter((seminars) => !!seminars && seminars.length > 0),
                takeUntil(this.destroyed$),
                switchMap((seminars) =>
                    this.supplierService.getSupplierById(
                        seminars[0].supplierId,
                    ),
                ),
            )
            .subscribe((supplier) => (this.supplier = supplier));

        this.seminars$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((seminars) => {
                this.seminars = seminars.sort((a, b) =>
                    a.dateTime.localeCompare(b.dateTime),
                );
                this.upcomingSeminars = this.seminars.filter(
                    (s) => !this.seminarIsInThePast(s),
                );
                // we reverse the sorting as we want past seminars to be descending
                this.pastSeminars = this.seminars
                    .filter((s) => this.seminarIsInThePast(s))
                    .reverse();
            });
    }

    public seminarIsInThePast(seminar: Seminar): boolean {
        return moment.utc(seminar.dateTime).isBefore(moment.utc());
    }
}
