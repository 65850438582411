export class NlAddressTools {
    private static zipPattern = /^[1-9][0-9]{3}?(?!sa|sd|ss)[a-z]{2}$/i;

    public static normalizeDutchZip(input: string): string {
        if (!input) {
            return input;
        }
        input = input.replace(/ /g, '');
        if (input.match(this.zipPattern) != null) {
            if (input.length === 6) {
                input = [input.slice(0, 4), ' ', input.slice(4)].join('');
            }
        }
        return input.toUpperCase();
    }

    public static truthyZip(zip: string): boolean {
        const formatted = zip.replace(/ /g, '');
        return formatted.match(this.zipPattern) !== null;
    }
}
