import { Component, Input } from '@angular/core';
import { Supplier } from '@common/model/supplier.base';
import { RegisterStoreService } from '@spnl/services/register-store.service';

@Component({
    selector: 'app-proposal-attachments',
    templateUrl: './proposal-attachments.component.html',
})
export class ProposalAttachmentsComponent {
    @Input()
    supplier: Supplier;

    get supplierProductSpecsFile(): string {
        return `proposal-product-specs-pdf-${this.supplier.code}`;
    }

    get supplierConditionsFile(): string {
        return `proposal-conditions-documents-pdf-${this.supplier.code}`;
    }

    constructor(private registerStore: RegisterStoreService) {}
}
