<div class="card shadow w-100 mb-3">
    <h5
        class="card-header"
        [innerHTML]="'supplier-logo-title' | cmsText | async"
    ></h5>
    <ul class="list-group list-group-flush">
        <li class="list-group-item">
            <ul class="fa-ul">
                <li>
                    <img
                        class="img-fluid w-100 mx-auto d-block"
                        [attr.src]="this.supplierOfferUri$ | async"
                    />
                </li>
            </ul>
        </li>
        <li class="list-group-item">
            <ul class="fa-ul">
                <li>
                    <a
                        href="{{ supplierWhoIsFile | cmsText | async }}"
                        id="supplierWhoIsLink"
                        target="_blank"
                    >
                        <i class="fa-li fa fa-paperclip" aria-hidden="true"></i>
                        <span
                            id="proposal-who-is-title"
                            [innerHTML]="
                                'proposal-who-is-title'
                                    | cmsText
                                        : [
                                              {
                                                  supplierName:
                                                      this.supplier?.name
                                              }
                                          ]
                                    | async
                            "
                        ></span>
                    </a>
                </li>
            </ul>
        </li>
    </ul>
</div>
