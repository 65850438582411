import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RegisterStep } from '@spnl/model/register-step.enum';

@Component({
    selector: 'app-error-step',
    templateUrl: `./error-step.component.html`,
})
export class ErrorStepComponent implements OnInit {
    errorHeaderKey: string;
    errorMessageKey: string;

    constructor(private route: ActivatedRoute) {}

    ngOnInit(): void {
        let sourceStep = this.route.snapshot.paramMap.get('step');

        const isValidRegisterStep = !!RegisterStep.values.find(
            (step) => step.toLowerCase() === sourceStep,
        );
        if (!isValidRegisterStep) {
            sourceStep = 'default';
        }

        this.errorHeaderKey = `error-page-${sourceStep}-header`;
        this.errorMessageKey = `error-page-${sourceStep}-message`;
    }
}
