/// <reference path="../../youtube.d.ts"/>

import { Injectable } from '@angular/core';
import { VideoInteractionEvent } from '@common/analytics/events/video-interaction.event';
import { AnalyticsService } from '@common/analytics/services/analytics.service';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { filter, first, mapTo, switchMapTo, tap } from 'rxjs/operators';

export type PlayerState =
    | 'loaded'
    | 'started'
    | 'cued'
    | 'playing'
    | 'paused'
    | 'buffering'
    | 'ended'
    | 'error';

export class Player {
    private _ytPlayer: YT.Player;

    public _state$: BehaviorSubject<PlayerState> =
        new BehaviorSubject<PlayerState>(null);

    get state$(): Observable<PlayerState> {
        return this._state$.asObservable();
    }

    get videoId() {
        return this._videoId;
    }

    get element(): HTMLElement {
        return this._element;
    }

    get thumbnailUrl(): string {
        return `https://img.youtube.com/vi/${this.videoId}/sddefault.jpg`;
    }

    constructor(
        private _videoId: string,
        private _element: HTMLElement,
        private options: { autoplay?: boolean } = {},
    ) {}

    loadVideo() {
        let firstPlay = true;
        this._ytPlayer = new window['YT'].Player(this.element as any, {
            videoId: this.videoId,
            playerVars: {
                autoplay: +(this.options?.autoplay ?? false),
                rel: 0,
                showinfo: 0,
                enablejsapi: 1,
                origin: window.location.origin,
            },
            events: {
                onStateChange: (e) => {
                    const state = this.toPlayerState(e?.data);
                    if (firstPlay && state === 'playing') {
                        this._state$.next('started');
                        firstPlay = false;
                    } else {
                        this._state$.next(state);
                    }
                },
                onError: () => {
                    this._state$.next('error');
                },
                onReady: () => {
                    this._state$.next('loaded');
                },
            },
        });
    }

    removeThumbnail() {
        const oldHeight = this.element.offsetHeight;
        this.element.innerHTML = ''; // Remove previous content
        this.element.style.height = `${oldHeight}px`;
    }

    addThumbnail() {
        const element = document.createElement('div');
        element.style.width = '100%';
        element.style.background = `url(${this.thumbnailUrl}) no-repeat center center`;
        element.style.backgroundSize = 'cover';
        element.className =
            'embed-responsive embed-responsive-16by9 ratio ratio-16x9';

        // play button
        const div = document.createElement('div');
        div.classList.add('play-button');
        element.appendChild(div);

        this.element.appendChild(element);
    }

    private toPlayerState(state: number): PlayerState | null {
        const map = {
            '-1': 'loaded',
            '0': 'ended',
            '1': 'playing',
            '2': 'paused',
            '3': 'buffering',
            '5': 'cued',
        } as const;
        return state?.toString() in map ? map[state.toString()] : null;
    }
}

@Injectable({
    providedIn: 'root',
})
export class YoutubeService {
    constructor(private analyticsService: AnalyticsService) {}

    get isInitialized(): boolean {
        return (
            window.hasOwnProperty('YT') && window['YT'].hasOwnProperty('Player')
        );
    }

    init$() {
        return new Observable<void>((subscriber) => {
            if (this.isInitialized) {
                subscriber.next();
                subscriber.complete();
            } else {
                const script = document.createElement('script');
                script.src = 'https://www.youtube.com/iframe_api';
                const firstScriptTag = document.querySelector('script');
                firstScriptTag.parentNode.insertBefore(script, firstScriptTag);

                window['onYouTubeIframeAPIReady'] = () => {
                    subscriber.next();
                    subscriber.complete();
                };
            }
        });
    }

    setupPlayer(
        element: HTMLElement,
        videoId: string,
        autoplay: boolean = false,
    ): Observable<Player> {
        const player = new Player(videoId, element, { autoplay });

        return this.init$().pipe(
            tap(() => {
                player.loadVideo();
                this.watchAnalytics(player);
            }),
            mapTo(player),
        );
    }

    setupPlayerWithThumbnail(
        element: HTMLElement,
        videoId: string,
        autoplay: boolean = true,
    ): Observable<Player> {
        if (!videoId) return EMPTY;

        const player = new Player(videoId, element, { autoplay });
        player.addThumbnail();

        // Play video on first click
        const onclick$ = new Observable<void>((subscriber) => {
            const listener = () => subscriber.next();
            element.addEventListener('click', listener);
            return () => element.removeEventListener('click', listener);
        });

        const firstClick$ = onclick$.pipe(first());

        return firstClick$.pipe(
            switchMapTo(this.init$()),
            tap(() => {
                player.removeThumbnail();
                player.loadVideo();
                this.watchAnalytics(player);
            }),
            mapTo(player),
        );
    }

    private watchAnalytics(player: Player) {
        const analyticsStates: PlayerState[] = [
            'loaded',
            'started',
            'playing',
            'paused',
            'ended',
        ];

        player.state$
            .pipe(filter((s) => !!s && analyticsStates.includes(s)))
            .subscribe((state) => {
                const event = VideoInteractionEvent.create({
                    video_id: player.videoId,
                    video_interaction_type: state,
                });
                this.analyticsService.push(event);
            });
    }
}
