import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { applyFormValue } from '@common/infrastructure/form-tools';
import {
    RoofStepFormGroup,
    shouldBeExcluded,
} from '@spnl/forms/roof-form-tools';
import { Registration } from '@spnl/model/registration';
import { RoofStep } from '@spnl/model/roof-step.model';
import { RegisterStepService } from '@spnl/services/register-step.service';
import { RegisterStoreService } from '@spnl/services/register-store.service';
import { RoofDetailsLocationService } from '@spnl/services/roof-details-location.service';

@Component({
    selector: 'app-roof-form-buttons',
    templateUrl: './roof-form-buttons.component.html',
})
export class RoofFormButtonsComponent {
    @Input()
    form: UntypedFormGroup;

    get submitting(): boolean {
        return this.registerStore.submitting;
    }

    get disallowNext(): boolean {
        const formSubmitting = this.submitting && this.form.valid;
        const onSubStep = !this.registerStore.shouldShowAllRoofSteps;

        return formSubmitting || (shouldBeExcluded(this.form) && onSubStep);
    }

    get disablePrevious(): boolean {
        return this.submitting;
    }

    get allowPrevious(): boolean {
        const inRegistrationFlow = this.registerStepService.inRegistrationFlow(
            this.registration,
        );
        const mayShowSteps = inRegistrationFlow;

        const hasPreviousStep = !!this.registerStepService.previousStep(
            this.registration,
        );
        const hasPreviousRoofStep =
            this.registerStore.activeRoofStep > RoofStep.LivingSituation;

        return (
            !this.submitting &&
            mayShowSteps &&
            (hasPreviousStep || hasPreviousRoofStep)
        );
    }

    private get registration(): Registration {
        return this.registerStore.registration;
    }

    constructor(
        private registerStore: RegisterStoreService,
        private registerStepService: RegisterStepService,
        private roofDetailsLocationService: RoofDetailsLocationService,
    ) {}

    goBack(): void {
        Object.keys(this.form.controls).forEach((key) => {
            const subForm = this.form.controls[key] as RoofStepFormGroup;
            if (subForm.valid) {
                applyFormValue(subForm, this.registration.product, []);
            }
        });

        this.roofDetailsLocationService.goToPreviousStep(this.registration);
    }
}
