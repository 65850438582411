import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
    distinctUntilChanged,
    filter,
    map,
    mergeMap,
    take,
    takeUntil,
    withLatestFrom,
} from 'rxjs/operators';

import { Community } from '@common/model/community';
import { AuthService } from '@common/services/auth.service';
import { CommunityService } from '@common/services/community.service';
import { MonitoringService } from '@common/services/monitoring.service';
import { DestroyableBase } from '@spnl/components/destroyable/destroyable.component';
import { Registration } from '@spnl/model/registration';
import { Observable, of } from 'rxjs';
import { RegisterLocationService } from './register-location.service';
import { RegisterStoreService } from './register-store.service';
import { RegistrationService } from './registration.service';
import { RoofDetailsLocationService } from './roof-details-location.service';

@Injectable()
export class RegisterEffectsService extends DestroyableBase {
    constructor(
        private locationService: RegisterLocationService,
        private roofDetailsLocationService: RoofDetailsLocationService,
        private registrationService: RegistrationService,
        private registerStore: RegisterStoreService,
        private communityService: CommunityService,
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private monitoringService: MonitoringService,
    ) {
        super();
    }

    trackStateAndSubstateChanges(): void {
        this.authService.authEvents$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((evt) => {
                if (evt === 'user_changed') {
                    this.router.navigate(['user-changed']);
                }
            });

        this.routeUpdated(this.getStepAndSubstep());

        this.router.events
            .pipe(
                takeUntil(this.destroyed$),
                filter((evt) => evt instanceof NavigationEnd),
                map(() => this.getStepAndSubstep()),
                distinctUntilChanged(
                    (x, y) => JSON.stringify(x) === JSON.stringify(y),
                ),
            )
            .subscribe((stepAndSubstep) => this.routeUpdated(stepAndSubstep));
    }

    private routeUpdated({ step, substep }) {
        this.locationService.stepUpdatedByRoute(step);
        this.roofDetailsLocationService.substepUpdatedByRoute(substep);
    }

    private getStepAndSubstep() {
        return {
            step: this.route.snapshot.firstChild.url[0].path,
            substep: this.route.snapshot.firstChild.params.substep,
        };
    }

    loadRegistration(): void {
        this.monitoringService.logEvent('register-effects-loadRegistration');

        // If there is a registration in local storage, the server might have a more recent version
        this.loadLocalAndServerRegistration()
            .pipe(withLatestFrom(this.communityService.community$))
            .subscribe(
                ([{ localRegistration, serverRegistration }, community]) => {
                    this.monitoringService.logEvent(
                        'register-effects-loaded-registrations',
                        {
                            localRegistrationId: localRegistration?.id,
                            serverRegistrationId: serverRegistration?.id,
                            serverRegistrationSubscriptionComplete:
                                serverRegistration?.subscriptionComplete,
                        },
                    );
                    if (serverRegistration?.subscriptionComplete) {
                        // Discard our local copy
                        this.registrationService.saveToSessionStorage(
                            serverRegistration,
                        );
                        this.setRegistrationAuction(
                            serverRegistration,
                            community,
                        );
                        this.registerStore.registrationLoaded(
                            serverRegistration,
                        );
                    } else {
                        // Keep the local copy (it will be sync-ed eventually when the form is submitted)
                        localRegistration =
                            localRegistration ?? new Registration();
                        this.setRegistrationAuction(
                            localRegistration,
                            community,
                        );
                        this.registerStore.registrationLoaded(
                            localRegistration,
                        );
                    }
                },
            );
    }

    private loadLocalAndServerRegistration(): Observable<{
        localRegistration: Registration;
        serverRegistration: Registration;
    }> {
        return this.registrationService.loadFromSessionStorage().pipe(
            take(1),
            mergeMap((localRegistration) => {
                this.monitoringService.logEvent(
                    'register-effects-local-registration-initially',
                    { id: localRegistration?.id },
                );

                const serverRegistration$ = localRegistration?.id
                    ? this.registrationService.get(localRegistration.id)
                    : of(null as Registration);

                return serverRegistration$.pipe(
                    map((serverRegistration) => ({
                        localRegistration,
                        serverRegistration,
                    })),
                );
            }),
        );
    }

    private setRegistrationAuction(
        registration: Registration,
        community: Community,
    ) {
        if (!registration.auction) {
            registration.auction = community.targetAuction;
        }
    }
}
