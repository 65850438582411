import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { isNullOrUndefined } from '@common/util';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { CommunityService } from '@common/services/community.service';

@Injectable({
    providedIn: 'root',
})
export class ValidCommunityGuardGuard  {
    constructor(
        private communityService: CommunityService,
        private router: Router,
    ) {}

    canActivate(next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
        return this.communityService.fetchCommunity(next.params.community).pipe(
            map((community) => !isNullOrUndefined(community)),
            catchError(() => of(this.router.parseUrl('/error/community'))),
        );
    }
}
