import { Inject, Injectable } from '@angular/core';
import { CommunityService } from '@common/services/community.service';
import { WINDOW } from '@common/services/window.service';
import { take } from 'rxjs/operators';
import { environment as env } from '../../environments/environment';
import { RegisterStepService } from './register-step.service';
import { RegistrationStoreService } from './registration-store.service';

export class DigitalEventQueueData {
    page_name: string;
    form_name: string; // NOTE: Provided by Digital Power & VEH
    form_campaign: string;
    form_step: string;
    form_step_name: string;
    environment = env.production // NOTE: Provided by Digital Power & VEH
        ? 'prod'
        : 'acc';

    constructor(dto: any = {}) {
        Object.assign(this, dto);
    }
}

export interface IDigitalEventService {
    push(data: any);
}

@Injectable({ providedIn: 'root' })
export class DigitalEventService implements IDigitalEventService {
    private dakcheckFunnelName: string = 'ciz funnel dakcheck';
    private inschrijvenFunnelName: string = 'ciz funnel inschrijven';
    private inschrijvenHmoFunnelName: string = 'ciz funnel inschrijven hmodh';

    constructor(
        @Inject(WINDOW) private window: Window,
        private registerStepService: RegisterStepService,
        private communityService: CommunityService,
        private registrationStoreService: RegistrationStoreService,
    ) {}

    push(formStepName: string): void {
        const digitalEventData: any = this.formDigitalEventData(formStepName);
        (this.window as any).digitalEventQueue =
            (window as any).digitalEventQueue || [];
        (this.window as any).digitalEventQueue.push({
            command: 'ADD EVENT',
            name: 'pageview',
            data: new DigitalEventQueueData(digitalEventData),
        });
    }

    private formDigitalEventData(formStepName: string): any {
        const formName: string = this.getFormName(formStepName);

        let data: any = this.getFormStepAndPageName(formStepName, formName);
        data.form_step_name = formStepName;
        data.form_name = formName;
        this.communityService.community$
            .pipe(take(1))
            .subscribe(
                (community) =>
                    (data.form_campaign = community.targetAuction?.name),
            );
        return data;
    }

    private getFormName(formStepName: string) {
        const registration =
            this.registrationStoreService.getCurrentRegistration();
        const auctionCode = registration?.auction?.code ?? null;

        // Merge forms for steps after confirmation
        switch (formStepName) {
            case 'overview e-mail bevestigd':
            case 'persoonlijk aanbod pagina':
            case 'betaalpagina':
            case 'betaling afgerond pagina':
            case 'overview pagina':
                return this.inschrijvenFunnelName;
        }

        if (auctionCode !== null && auctionCode == 'ciz0') {
            return this.inschrijvenHmoFunnelName;
        }

        return this.registerStepService.isInAlternativeFlow
            ? this.dakcheckFunnelName
            : this.inschrijvenFunnelName;
    }

    private getFormStepAndPageName(
        formStepName: string,
        formName: string,
    ): any {
        const isDakCheck: boolean = formName === this.dakcheckFunnelName;

        switch (formStepName) {
            case 'uw gegevens':
                return {
                    form_step: isDakCheck ? '2' : '1',
                    page_name: 'person',
                };
            case 'woonsituatie':
                return {
                    form_step: isDakCheck ? '1a' : '2a',
                    page_name: 'roof current situation',
                };
            case 'daktype':
                return {
                    form_step: isDakCheck ? '1b' : '2b',
                    page_name: 'roof type',
                };
            case 'dakoppervlak':
                return {
                    form_step: isDakCheck ? '1c' : '2c',
                    page_name: 'roof size',
                };
            case 'dakobjecten':
                return {
                    form_step: isDakCheck ? '1d' : '2d',
                    page_name: 'roof objects',
                };
            case 'verbruik':
                return {
                    form_step: isDakCheck ? '1e' : '2e',
                    page_name: 'roof energy',
                };
            case 'kosten en opbrengsten':
                return {
                    form_step: isDakCheck ? '1f' : '2f',
                    page_name: 'roof yield',
                };
            case 'welkomstpagina':
                return { form_step: '3', page_name: 'welcome' };
            case 'overview e-mail bevestigd':
                return { form_step: '4', page_name: 'email confirmed' };
            case 'persoonlijk aanbod pagina':
                return { form_step: '5', page_name: 'proposal' };
            case 'betaalpagina':
                return { form_step: '6', page_name: 'payment' };
            case 'betaling afgerond pagina':
                return { form_step: '7', page_name: 'payment success' };
            case 'overview pagina':
                return { form_step: '8', page_name: 'deposit paid' };
        }
    }
}
