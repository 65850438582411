import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-register-error',
    templateUrl: './error.component.html',
})
export class RegisterErrorComponent {
    @Input()
    errorKey: string;
    @Input()
    cmsParams = {};
}
