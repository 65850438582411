import { Component, Input } from '@angular/core';
import { Registration } from '@spnl/model/registration';

@Component({
    selector: 'app-register-overview-next-steps',
    templateUrl: './overview-next-steps.component.html',
})
export class OverviewNextStepsComponent {
    @Input()
    registration: Registration;

    @Input()
    nextStepsCmsKey: string;

    get informDateExternal(): string {
        return this.registration.registrationGroup.informDateExternal;
    }
}
