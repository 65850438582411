import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { ControlWithErrors } from '@common/infrastructure/form-tools';
import { Supplier } from '@common/model/supplier.base';
import { CustomValidators } from '@spnl/forms/custom-validators';
import { AssessmentStatus } from '@spnl/model/assessment-status';
import { CancelDelivery } from '@spnl/model/cancel-delivery.model';
import { Registration } from '@spnl/model/registration';
import { RegistrationApiService } from '@spnl/services/registration-api.service';
import { of } from 'rxjs';
import { delay, finalize, switchMap } from 'rxjs/operators';
import { DestroyableBase } from '../destroyable/destroyable.component';

@Component({
    selector: 'app-cancel-form',
    templateUrl: './cancel-form.component.html',
})
export class CancelFormComponent
    extends DestroyableBase
    implements OnInit, OnDestroy
{
    @Input()
    registration: Registration;

    @Input()
    supplier: Supplier;

    @Output()
    successfulSubmit = new EventEmitter();

    whoInitiatedCancellations$ =
        this.registrationApiService.whoInitiatedTheCancellation();
    assessmentStatuses = AssessmentStatus.all;
    cancellationReasons$ =
        this.registrationApiService.allowedCancellationReasons();
    cancellationSubreasons = [];

    submitting = false;
    submitRequested = false;
    showSubmitError = false;
    form: UntypedFormGroup;

    get assessmentStatus(): ControlWithErrors {
        return this.form.get('assessmentStatus');
    }
    get whoInitiatedCancellation(): ControlWithErrors {
        return this.form.get('whoInitiatedCancellation');
    }
    get explanation(): ControlWithErrors {
        return this.form.get('explanation');
    }
    get cancellationReason(): ControlWithErrors {
        return this.form.get('cancellationReason');
    }
    get cancellationSubreason(): ControlWithErrors {
        return this.form.get('cancellationSubreason');
    }
    get wantsToBeInvolvedInFutureAuctions(): ControlWithErrors {
        return this.form.get('wantsToBeInvolvedInFutureAuctions');
    }

    constructor(
        private fb: UntypedFormBuilder,
        private registrationApiService: RegistrationApiService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            assessmentStatus: [null, Validators.required],
            explanation: ['', Validators.required],
            cancellationReason: [null, Validators.required],
            whoInitiatedCancellation: [null, Validators.required],
            wantsToBeInvolvedInFutureAuctions: [null, Validators.required],
            cancellationSubreason: [
                null,
                CustomValidators.requiredIf(
                    () => this.cancellationReasonHasSubreasons,
                ),
            ],
        });
        this.onChanges();
    }

    assessmentStatusKey(status: AssessmentStatus): string {
        return `assessment-status-${status.toLowerCase()}`;
    }

    cancellationReasonKey(reason: string): string {
        return `delivery-cancel-reason-${reason.toLowerCase()}`;
    }

    cancellationSubreasonKey(reason: string): string {
        return `delivery-cancel-subreason-${reason.toLowerCase()}`;
    }

    whoInitiatedCancellationKey(reason: string): string {
        return `delivery-cancel-who-initiated-${reason.toLowerCase()}`;
    }

    get keepLastInPlace(): boolean {
        const reasons = ['PersonalChoice'];
        return reasons.includes(this.cancellationReason.value);
    }

    get cancellationReasonHasSubreasons(): boolean {
        return (
            !this.cancellationReason.value ||
            (this.cancellationReason.value &&
                this.cancellationSubreasons.length > 0)
        );
    }

    onChanges(): void {
        this.cancellationReason.valueChanges
            .pipe(
                switchMap((reason) =>
                    this.registrationApiService.allowedCancellationSubreasons(
                        reason,
                    ),
                ),
            )
            .subscribe((subreasons) => {
                this.cancellationSubreasons = subreasons;
                if (subreasons.length === 0) {
                    this.cancellationSubreason.reset();
                }
            });
    }

    onSubmit(): void {
        this.submitRequested = true;

        if (this.form.valid) {
            this.submitting = true;
            this.showSubmitError = false;

            this.registrationApiService
                .requestDeliveryCancellation(
                    this.registration.id,
                    new CancelDelivery(
                        this.assessmentStatus.value,
                        this.cancellationReason.value,
                        this.cancellationSubreason.value,
                        this.explanation.value,
                        this.whoInitiatedCancellation.value,
                        this.wantsToBeInvolvedInFutureAuctions.value,
                    ),
                )
                .pipe(
                    delay(1000),
                    finalize(() => (this.submitting = false)),
                )
                .subscribe(
                    () => {
                        this.successfulSubmit.emit();
                    },
                    () => {
                        this.showSubmitError = true;
                    },
                );
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
