<ng-container
    *ngIf="!winningOffersAvailableForRegistration; else offerReadyIntroBlock"
>
    <h4
        *ngIf="shouldShowStep(RoofStep.Yield) && !shouldShowAllSteps"
        class="mb-3"
        [innerHtml]="
            (startedWithProduct
                ? 'costs-and-yields-title-alternative-flow'
                : 'costs-and-yields-title'
            )
                | cmsText
                | async
        "
    ></h4>
    <p
        [innerHtml]="
            (startedWithProduct
                ? 'costs-and-yields-intro-text-alternative-flow'
                : 'costs-and-yields-intro-text'
            )
                | cmsText
                | async
        "
    ></p>
</ng-container>
<ng-template #offerReadyIntroBlock>
    <h4
        class="mb-3 mt-3"
        [innerHtml]="'costs-and-yields-title-offer-ready' | cmsText | async"
    ></h4>
    <p
        [innerHtml]="
            'costs-and-yields-intro-text-offer-ready'
                | cmsText : [{ optimalNumberOfPanels: suggestedNumberOfPanels }]
                | async
        "
    ></p>
</ng-template>

<div [formGroup]="form">
    <div *ngIf="showLoading">
        <app-loading-spinner size="large"></app-loading-spinner>
    </div>
    <div *ngIf="installations">
        <div
            *ngIf="
                !winningOffersAvailableForRegistration || registration?.excluded
            "
        >
            <div class="row">
                <div class="col-12">
                    <label for="chosenNumberOfPanels">
                        <strong>
                            {{
                                'choose-number-of-panels-for-registration'
                                    | cmsText
                                    | async
                            }}
                        </strong>
                    </label>
                </div>
                <div class="col-12">
                    <div class="form-inline">
                        <span class="btn-group" role="group">
                            <button
                                class="btn btn-primary rounded-start"
                                type="button"
                                (click)="chooseLessPanels()"
                                [disabled]="disallowChoosingLessPanels"
                            >
                                {{ 'choose-less' | cmsText | async }}
                            </button>
                            <input
                                type="number"
                                inputmode="numeric"
                                [min]="config.minNumberOfPanels"
                                [max]="config.maxNumberOfPanels"
                                class="form-control text-center rounded-0 max-content-width"
                                id="chosenNumberOfPanels"
                                formControlName="chosenNumberOfPanels"
                                readonly
                            />
                            <button
                                class="btn btn-primary"
                                type="button"
                                (click)="chooseMorePanels()"
                                [disabled]="disallowChoosingMorePanels"
                            >
                                {{ 'choose-more' | cmsText | async }}
                            </button>
                            <span
                                class="input-group-text btn btn-outline-secondary rounded-end disabled"
                            >
                                {{
                                    chosenNumberOfPanelsTranslated
                                        | cmsText
                                        | async
                                }}
                            </span>
                        </span>
                    </div>
                </div>
                <div class="col-12">
                    <small>
                        {{
                            'optimal-amount-panels-micro-copy'
                                | cmsText
                                    : [
                                          {
                                              optimalNumberOfPanels:
                                                  suggestedNumberOfPanels
                                          }
                                      ]
                                | async
                        }}
                    </small>
                </div>
                <div class="col-12">
                    <small
                        *ngIf="
                            chosenNumberOfPanels.value ==
                            config.minNumberOfPanels
                        "
                    >
                        {{
                            'minimum-number-of-panels-micro-copy'
                                | cmsText
                                    : [
                                          {
                                              minimumNumberOfPanels:
                                                  config.minNumberOfPanels
                                          }
                                      ]
                                | async
                        }}
                    </small>
                </div>
                <div class="col-12">
                    <small
                        *ngIf="
                            chosenNumberOfPanels.value ==
                            config.maxNumberOfPanels
                        "
                    >
                        {{
                            'maximum-number-of-panels-micro-copy'
                                | cmsText
                                    : [
                                          {
                                              maximumNumberOfPanels:
                                                  config.maxNumberOfPanels
                                          }
                                      ]
                                | async
                        }}
                    </small>
                </div>
            </div>
            <app-register-warning
                *ngIf="showChosenNrOfPanelsMaxWarning"
                warningKey="chosen-number-of-panels-warning-max-for-roofsize"
                [cmsParams]="{
                    numberOfPanelsThatFitOnRoof: numberOfPanelsThatFitOnRoof
                }"
            >
            </app-register-warning>
            <app-register-warning
                *ngIf="showWarningForExceedingSuggestedPanels"
                warningKey="chosen-number-of-panels-warning-more-than-optimal"
                [cmsParams]="{ optimalNumberOfPanels: suggestedNumberOfPanels }"
            >
            </app-register-warning>
            <app-register-warning
                *ngIf="showWarningForBelowSuggestedPanels"
                warningKey="chosen-number-of-panels-warning-less-than-optimal"
                [cmsParams]="{ optimalNumberOfPanels: suggestedNumberOfPanels }"
            >
            </app-register-warning>

            <div class="my-4"></div>
            <ul>
                <li
                    [innerHTML]="
                        'estimated-yield-kwh'
                            | cmsText
                                : [
                                      {
                                          capacity: capacity
                                              ? (capacity | number : '1.0-0')
                                              : '...'
                                      }
                                  ]
                            | async
                    "
                ></li>
                <li
                    [innerHTML]="
                        'estimated-coverage'
                            | cmsText
                                : [
                                      {
                                          coverage:
                                              (consumptionCoverage | percent)
                                      }
                                  ]
                            | async
                    "
                ></li>
                <li
                    [innerHTML]="
                        'yearly-saving'
                            | cmsText
                                : [
                                      {
                                          saving:
                                              yearlySaving | currencyWithPostfix
                                      }
                                  ]
                            | async
                    "
                ></li>
                <p *ngIf="switchToSingleMeter" class="text-warning">
                    {{
                        'switch-over-to-single-meter-warning' | cmsText | async
                    }}
                </p>
            </ul>
            <span
                [innerHtml]="
                    'indicative-price-disclaimer'
                        | cmsText
                            : [
                                  {
                                      indicativePrice:
                                          (price | currencyWithPostfix)
                                  }
                              ]
                        | async
                "
            >
            </span>
        </div>
    </div>
    <app-register-error
        *ngIf="showInvalidRoofAndEnergyDataError"
        errorKey="summary-error-missing-installation-data"
    ></app-register-error>

    <ng-container
        *ngIf="
            !winningOffersAvailableForRegistration;
            else offerReadyClosingBlock
        "
    >
        <h4
            class="mb-3 mt-3"
            [innerHtml]="
                (startedWithProduct
                    ? 'costs-and-yields-closing-title-alternative-flow'
                    : 'costs-and-yields-closing-title'
                )
                    | cmsText
                    | async
            "
        ></h4>
        <p
            [innerHtml]="
                (startedWithProduct
                    ? 'costs-and-yields-closing-text-alternative-flow'
                    : 'costs-and-yields-closing-text'
                )
                    | cmsText
                        : [{ indicativePrice: (price | currencyWithPostfix) }]
                    | async
            "
        ></p>
    </ng-container>
    <ng-template #offerReadyClosingBlock>
        <h4
            class="mb-3 mt-3"
            [innerHtml]="
                'costs-and-yields-closing-title-offer-ready' | cmsText | async
            "
        ></h4>
        <p
            [innerHtml]="
                'costs-and-yields-closing-text-offer-ready'
                    | cmsText
                        : [{ indicativePrice: (price | currencyWithPostfix) }]
                    | async
            "
        ></p>
    </ng-template>
</div>
