import { Injectable } from '@angular/core';
import { Registration } from '@spnl/model/registration';
import moment from 'moment';

@Injectable()
export class RegisterStateService {
    inAcceptanceGracePeriod(registration: Registration): boolean {
        const today = moment().utc();
        const softAcceptDate = moment.utc(
            registration.registrationGroup.acceptDate,
        );
        const hardAcceptDate = moment.utc(
            registration.registrationGroup.hardAcceptDate,
        );

        return today.isBetween(softAcceptDate, hardAcceptDate);
    }

    acceptanceIsClosed(registration: Registration): boolean {
        const today = moment().utc();
        const hardAcceptDate = moment.utc(
            registration.registrationGroup.hardAcceptDate,
        );

        return today.isAfter(hardAcceptDate);
    }

    // If they have accepted and payment is open, they should be able to reach payment
    acceptanceClosedNoDecision(registration: Registration): boolean {
        const noDecision = !registration.decisionComplete;

        return this.acceptanceIsClosed(registration) && noDecision;
    }

    paymentIsClosed(registration: Registration): boolean {
        const today = moment().utc();
        const finalPaymentDate = moment.utc(
            registration.registrationGroup.finalPaymentDate,
        );

        return today.isAfter(finalPaymentDate);
    }

    registrationIsClosed(registration: Registration): boolean {
        const isClosed =
            registration.auction?.finished ||
            this.paymentIsClosed(registration) ||
            this.acceptanceClosedNoDecision(registration);

        return isClosed;
    }
}
