import { Injectable } from '@angular/core';
import {
    NumberOfStories,
    RoofMaterial,
    RoofOrientation,
    RoofPitch,
} from '../model/registration';

@Injectable()
export class ContentTranslatorService {
    public forNumberOfStories(numberOfStories: NumberOfStories): string {
        if (!numberOfStories) {
            return '';
        }

        const cmsKeySuffix = this.toKebabCase(numberOfStories);
        return 'number-of-stories-' + cmsKeySuffix;
    }

    public forRoofPitch(roofPitch: RoofPitch): string {
        if (!roofPitch) {
            return '';
        }

        const cmsKeySuffix = this.toKebabCase(roofPitch);
        return 'roof-pitch-' + cmsKeySuffix;
    }

    public forRoofOrientation(roofOrientation: RoofOrientation): string {
        if (!roofOrientation) {
            return '';
        }

        const cmsKeySuffix = this.toKebabCase(roofOrientation);
        return 'roof-orientation-' + cmsKeySuffix;
    }

    public forRoofMaterial(roofMaterial: RoofMaterial): string {
        if (!roofMaterial) {
            return '';
        }

        const cmsKeySuffix = this.toKebabCase(roofMaterial);
        return 'roof-material-' + cmsKeySuffix;
    }

    public solarPanelsPluralSingular(count: number): string {
        return count === 1 ? 'solar-panels-singular' : 'solar-panels-plural';
    }

    private toKebabCase(value: string): string {
        return value.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
    }
}
