import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-cancel-error',
    template: ` <div class="alert alert-success mt-3" role="alert">
        {{ errorKey | cmsText | async }}
    </div>`,
})
export class CancelErrorComponent {
    @Input()
    errorKey: string;
}
