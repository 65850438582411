import {
    AfterViewInit,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

import {
    applyFormValue,
    ControlWithErrors,
    makeFormModel,
    shouldShowErrorsFor,
} from '@common/infrastructure/form-tools';
import { Community } from '@common/model/community';
import { CommunityService } from '@common/services/community.service';
import { DestroyableBase } from '@spnl/components/destroyable/destroyable.component';
import {
    RoofStepFormGroup,
    shouldBeExcluded,
} from '@spnl/forms/roof-form-tools';
import { RegisterStep } from '@spnl/model/register-step.enum';
import {
    Registration,
    RoofOrientation,
    RoofPitch,
} from '@spnl/model/registration';
import { RoofStep } from '@spnl/model/roof-step.model';
import { SolarInstallations } from '@spnl/model/solar-installations';
import { ContentTranslatorService } from '@spnl/services/content-translator.service';
import { DigitalEventService } from '@spnl/services/digital-event-queue.service';
import { RegisterStepService } from '@spnl/services/register-step.service';
import { RegisterStoreService } from '@spnl/services/register-store.service';
import { RegistrationService } from '@spnl/services/registration.service';
import { SolarApiService } from '@spnl/services/solar-api.service';
import { combineLatest, concat, Observable, of } from 'rxjs';
import { RegisterConfig } from '../../register.config';

@Component({
    selector: 'app-register-roof-yield',
    templateUrl: './roof-yield.component.html',
})
export class RoofYieldComponent
    extends DestroyableBase
    implements OnInit, OnDestroy, AfterViewInit
{
    @Input()
    registration: Registration;

    @Input()
    parentForm: UntypedFormGroup;

    private community: Community;

    config = RegisterConfig;
    form: RoofStepFormGroup;
    submitRequested = false;
    installations: SolarInstallations;
    chosenNumberOfPanels: ControlWithErrors;
    showInstallationError = false;
    numberOfPanelsThatFitOnRoof: number; // internal representation of register.product.numberOfPanelsThatFitOnRoof

    RoofStep = RoofStep;

    futureExpectedElectricityConsumptionPerYear: number; // Latest flat value from registration/form

    get optimalPanels() {
        return this.installations?.optimalPremiumAmount;
    }

    get suggestedNumberOfPanels() {
        return Math.min(this.optimalPanels, this.numberOfPanelsThatFitOnRoof);
    }

    get isRegistrationExcluded(): boolean {
        return this.registration && this.registration.excluded;
    }

    get showInfoForSuitableRoofs(): boolean {
        return (
            this.winningOffersAvailableForRegistration &&
            this.form.valid &&
            !this.isRegistrationExcluded
        );
    }

    get showInvalidRoofAndEnergyDataError(): boolean {
        return this.showInstallationError && !shouldBeExcluded(this.parentForm);
    }

    get isAboveMaximumInSomeWay(): boolean {
        return (
            this.numberOfPanelsThatFitOnRoof > this.config.maxNumberOfPanels ||
            this.suggestedNumberOfPanels > this.config.maxNumberOfPanels
        );
    }

    get isBelowMinimumInSomeWay(): boolean {
        return (
            this.numberOfPanelsThatFitOnRoof < this.config.minNumberOfPanels ||
            this.suggestedNumberOfPanels < this.config.minNumberOfPanels
        );
    }

    get disallowChoosingLessPanels(): boolean {
        return this.config.minNumberOfPanels >= this.chosenNumberOfPanels.value;
    }

    get disallowChoosingMorePanels(): boolean {
        return this.chosenNumberOfPanels.value >= this.config.maxNumberOfPanels;
    }

    get showChosenNrOfPanelsMaxWarning(): boolean {
        return (
            this.numberOfPanelsThatFitOnRoof >= this.config.minNumberOfPanels &&
            this.chosenNumberOfPanels.value >
                this.numberOfPanelsThatFitOnRoof &&
            this.chosenNumberOfPanels.valid
        );
    }

    get suggestedNumberOfPanelsIsCapped() {
        return (
            this.consumptionCoverage < 1 &&
            this.optimalPanels >= this.config.maxNumberOfPanels
        );
    }

    get showWarningForExceedingSuggestedPanels() {
        return (
            this.chosenNumberOfPanels.valid &&
            this.chosenNumberOfPanels.value > this.suggestedNumberOfPanels &&
            this.capacity > this.futureExpectedElectricityConsumptionPerYear
        );
    }

    get showWarningForBelowSuggestedPanels() {
        return (
            this.chosenNumberOfPanels.valid &&
            this.chosenNumberOfPanels.value < this.suggestedNumberOfPanels
        );
    }

    get showErrorsForChosenNumberOfPanels(): boolean {
        return shouldShowErrorsFor(this.chosenNumberOfPanels);
    }

    get winningOffersAvailableForRegistration() {
        return (
            RegistrationService.winningOffersAvailableForRegistration(
                this.community,
                this.registration,
            ) && this.registration.registrationGroup.informDateInternalInThePast
        );
    }

    get switchToSingleMeter(): boolean {
        return (
            this.installations &&
            this.installations.switchToSingleMeterWhen(
                true,
                this.chosenNumberOfPanels.value,
            )
        );
    }

    get price(): number {
        return (
            this.installations &&
            this.installations.priceFor(true, this.chosenNumberOfPanels.value)
        );
    }

    get capacity(): number {
        return (
            this.installations &&
            this.installations.capacityOf(true, this.chosenNumberOfPanels.value)
        );
    }

    get saving(): number {
        return (
            this.installations &&
            this.installations.savingOf(true, this.chosenNumberOfPanels.value)
        );
    }

    get yearlySaving(): string {
        return this.saving ? this.saving.toString() : '...';
    }

    get consumptionCoverage(): number {
        return (
            this.capacity /
            this.registration.product
                .futureExpectedElectricityConsumptionPerYear
        );
    }

    get vatRate(): number {
        return 0;
    }

    get showLoading(): boolean {
        return !this.installations && !this.showInstallationError;
    }

    get auctionDate(): Date | null {
        return this.registration && this.registration.auction.date
            ? new Date(this.registration.auction.date)
            : null;
    }

    get startedWithProduct(): boolean {
        return this.registerStepService.isInAlternativeFlow;
    }

    /*
            Return the latest value for roofPitch.
            This value will come from the registration, or from the formControl if available.
        */
    get roofPitch$(): Observable<RoofPitch> {
        const roofType = this.parentForm.get('roof-type');
        const productRoofPitch$ = of(this.registration.product.roofPitch);
        return roofType
            ? concat(productRoofPitch$, roofType.get('roofPitch').valueChanges)
            : productRoofPitch$;
    }

    /*
        Return the latest value for roofOrientation.
        This value will come from the registration, or from the formControl if available.
    */
    get roofOrientation$(): Observable<RoofOrientation> {
        return of(this.registration.product.roofOrientation);
    }

    /*
        Return the latest value for futureExpectedElectricityConsumptionPerYear.
        This value will come from the registration, or from the formControl if available.
    */
    get futureExpectedElectricityConsumptionPerYear$(): Observable<number> {
        const productFutureExpectedElectricityConsumptionPerYear$ = of(
            this.registration.product
                .futureExpectedElectricityConsumptionPerYear ??
                this.registration.product.electricityConsumptionPerYear,
        );
        return productFutureExpectedElectricityConsumptionPerYear$;
    }

    /*
        Return the latest value for numberOfPanelsThatFitOnRoof.
        This value will come from the registration, or from the formControl if available.
    */
    get numberOfPanelsThatFitOnRoof$(): Observable<number> {
        const roofSize = this.parentForm.get('roof-size');
        const productNumberOfPanelsThatFitOnRoof$ = of(
            this.registration.product.numberOfPanelsThatFitOnRoof,
        );
        return roofSize
            ? concat(
                  productNumberOfPanelsThatFitOnRoof$,
                  roofSize
                      .get('numberOfPanelsThatFitOnRoof')
                      .valueChanges.pipe(map((x) => parseInt(x, 10))),
              )
            : productNumberOfPanelsThatFitOnRoof$;
    }

    get chosenNumberOfPanelsTranslated() {
        return this.contentTranslatorService.solarPanelsPluralSingular(
            this.chosenNumberOfPanels.value,
        );
    }

    get shouldShowAllSteps(): boolean {
        return this.registerStore.shouldShowAllRoofSteps;
    }

    constructor(
        private fb: UntypedFormBuilder,
        public communityService: CommunityService,
        private contentTranslatorService: ContentTranslatorService,
        private solar: SolarApiService,
        private registerStepService: RegisterStepService,
        private digitalEventService: DigitalEventService,
        private registerStore: RegisterStoreService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            chosenNumberOfPanels: [
                null,
                [
                    Validators.required,
                    this.chosenNumberOfPanelsMinValidator(),
                    Validators.max(this.config.maxNumberOfPanels),
                ],
            ],
        }) as RoofStepFormGroup;
        this.chosenNumberOfPanels = this.form.get('chosenNumberOfPanels');

        this.form.onSubmit = this.onSubmit.bind(this);
        this.parentForm.addControl('roof-yield', this.form);

        this.communityService.community$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((community) => (this.community = community));

        const model = makeFormModel(this.form, this.registration.product, []);
        this.form.reset(model);

        // The price indications are dependent on: roofPitch, roofOrientation and futureExpectedElectricityConsumptionPerYear
        // The give any context for price indications we also need: numberOfPanelsThatFitOnRoof
        // Here we watch for updates on these fields, and request a new price indication upon changes
        combineLatest([
            this.roofPitch$,
            this.roofOrientation$,
            this.futureExpectedElectricityConsumptionPerYear$,
            this.numberOfPanelsThatFitOnRoof$,
        ])
            .pipe(takeUntil(this.destroyed$), debounceTime(500))
            .subscribe((changes) => this.getPriceIndication(...changes));

        this.futureExpectedElectricityConsumptionPerYear$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(
                (consumption) =>
                    (this.futureExpectedElectricityConsumptionPerYear =
                        consumption),
            );
    }

    ngAfterViewInit(): void {
        this.digitalEventService.push('kosten en opbrengsten');
    }

    ngOnDestroy(): void {
        this.parentForm?.removeControl('roof-yield');
        super.ngOnDestroy();
    }

    onSubmit(): void {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            this.registration.product.suggestedNumberOfPanels =
                this.optimalPanels;
            applyFormValue(this.form, this.registration.product, []);
        }
    }

    getPriceIndication(
        roofPitch: RoofPitch,
        roofOrientation: RoofOrientation | null,
        futureExpectedElectricityConsumptionPerYear: number,
        numberOfPanelsThatFitOnRoof: number,
    ) {
        if (
            roofPitch &&
            futureExpectedElectricityConsumptionPerYear &&
            numberOfPanelsThatFitOnRoof
        ) {
            // Update our internal value so that we always provide the correct
            // context for the current price indications.
            this.numberOfPanelsThatFitOnRoof = numberOfPanelsThatFitOnRoof;

            this.solar
                .getPriceIndication(
                    roofPitch,
                    roofOrientation,
                    futureExpectedElectricityConsumptionPerYear,
                    this.registration.auction.code,
                    this.registration.auction.id,
                    this.registration.contact.zip,
                    this.winningOffersAvailableForRegistration,
                )
                .subscribe(
                    (value) => {
                        this.installations = value;
                        this.chosenNumberOfPanels.setValue(
                            this.registration.product.chosenNumberOfPanels ||
                                Math.min(
                                    this.suggestedNumberOfPanels,
                                    this.config.maxNumberOfPanels,
                                ),
                        );
                        this.showInstallationError = false;
                    },
                    () => {
                        this.installations = null;
                        this.showInstallationError = true;
                    },
                );
        } else {
            this.installations = null;
            this.showInstallationError = true;
        }
    }

    chooseMorePanels(): void {
        const desiredNumberOfPanels = Math.max(
            this.chosenNumberOfPanels.value + 1,
            this.config.minNumberOfPanels,
        );
        const newNumberOfPanels = Math.min(
            desiredNumberOfPanels,
            this.config.maxNumberOfPanels,
        );
        this.chosenNumberOfPanels.setValue(newNumberOfPanels);
    }

    chooseLessPanels(): void {
        const desiredNumberOfPanels = Math.min(
            this.chosenNumberOfPanels.value - 1,
            this.config.maxNumberOfPanels,
        );
        const newNumberOfPanels = Math.max(
            desiredNumberOfPanels,
            this.config.minNumberOfPanels,
        );
        this.chosenNumberOfPanels.setValue(newNumberOfPanels);
    }

    shouldShowStep(step: RoofStep): boolean {
        if (this.shouldShowAllSteps) {
            return true;
        }

        return this.registerStore.activeRoofStep === step;
    }

    private chosenNumberOfPanelsMinValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors => {
            return control.value >= this.config.minNumberOfPanels ||
                !this.registration ||
                (this.registration && this.registration.excluded)
                ? null
                : { min: true };
        };
    }
}
