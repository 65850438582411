<div class="mt-3 mb-4 d-none d-md-block" *ngIf="supplier">
    <img
        class="img-fluid w-75 mx-auto d-block"
        [attr.src]="supplierLogo | cmsImageSrc | async"
        [attr.alt]="supplierLogo | cmsImageAlt | async"
    />
</div>
<app-contact-options
    [communityCode]="communityCode"
    [contactPhoneCmsKey]="'contact-phone'"
    [showContactUsLink]="true"
    [showTitle]="false"
    [showFaqLink]="false"
    class="d-flex my-3"
    [useArrow]="true"
>
</app-contact-options>
