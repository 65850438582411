<form
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
    novalidate
    appfocusFirstInvalidField
    *ngIf="registration.id"
>
    <div class="d-flex">
        <div class="d-flex flex-column p-2">
            <h3
                class="nls_protected"
                [innerHTML]="
                    'proposal-header-personalized'
                        | cmsText
                            : [
                                  {
                                      name: personalizedGreeting
                                  }
                              ]
                        | async
                "
            ></h3>
        </div>
    </div>
    <hr class="my-3" />
    <app-register-warning
        *ngIf="!loading && inAcceptanceGracePeriod"
        warningKey="proposal-in-acceptance-grace-period-warning"
    >
    </app-register-warning>

    <div class="my-5 d-sm-block d-md-none">
        <app-supplier-logo [registration]="registration"></app-supplier-logo>
    </div>

    <app-carousel-component [imageSource]="supplierCarousel">
    </app-carousel-component>

    <app-proposal-offer [form]="form" [submitRequested]="submitRequested">
    </app-proposal-offer>
    <hr class="mt-3" />
    <app-register-proposal-advantage
        [registration]="registration"
        [useTopLevelTitle]="true"
        [premiumPanelsChosen]="true"
        [numberOfPanelsChosen]="numberOfPanelsChosen"
    >
    </app-register-proposal-advantage>
    <hr class="mt-5" />
    <app-register-proposal-installation
        [registration]="registration"
        [showProposalDetails]="true"
        [numberOfPanelsChosen]="numberOfPanelsChosen"
        [premiumPanelsChosen]="true"
    >
    </app-register-proposal-installation>
    <hr class="mt-5" />
    <app-proposal-notes-to-supplier [registration]="registration" [form]="form">
    </app-proposal-notes-to-supplier>
    <hr class="mt-5" />
    <h4
        class="mb-3"
        [innerHTML]="'proposal-next-steps-title' | cmsText | async"
    ></h4>
    <span
        [innerHTML]="
            'proposal-next-steps-content'
                | cmsText : [{ supplierName: registration.supplierName }]
                | async
        "
    ></span>
    <div class="mb-3">
        <app-content
            class="fw-bold"
            key="additional-information-label-header"
        ></app-content>
        <app-spnl-tooltip cmsKey="additional-information-label-tooltip">
        </app-spnl-tooltip>
        <app-content key="additional-information-label"></app-content>
    </div>
    <hr class="my-3" />
    <app-proposal-supplier-conditions
        [registration]="registration"
        [form]="form"
        [submitRequested]="submitRequested"
    >
    </app-proposal-supplier-conditions>
    <hr class="my-3" />
    <app-register-warning
        *ngIf="!loading && inAcceptanceGracePeriod"
        warningKey="proposal-in-acceptance-grace-period-warning"
    >
    </app-register-warning>
    <div *ngIf="!registration.auction.finished" class="row">
        <div class="col-md-12">
            <div class="button-wrapper btn-container-start">
                <button
                    type="button"
                    class="btn btn-outline-primary px-3 order-sm-first order-last align-self-start"
                    id="decline-offer-button"
                    (click)="openDeclinePopup()"
                    [innerHTML]="'decline-offer-button' | cmsText | async"
                ></button>

                <div class="px-3 order-sm-last order-first">
                    <button
                        id="nextButton"
                        type="submit"
                        class="btn btn-primary"
                    >
                        <span
                            [innerHTML]="
                                'accept-offer-button' | cmsText | async
                            "
                        ></span>
                        <i class="fa fa-chevron-right ms-2"></i>
                    </button>
                    <br />
                    <small
                        class="order-sm-last order-first"
                        [innerHTML]="
                            'proposal-number-of-acceptants'
                                | cmsText
                                    : [
                                          {
                                              numberOfAcceptants:
                                                  numberOfAcceptants
                                          }
                                      ]
                                | async
                        "
                    ></small>
                </div>
            </div>
        </div>
    </div>
</form>
