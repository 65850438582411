<div *ngIf="showPaymentTransferDetails">
    <hr class="my-3" />
    <h4 [innerHTML]="'payment-details-label' | cmsText | async"></h4>
    <div
        class="mb-2"
        [innerHTML]="'payment-details-text' | cmsText | async"
    ></div>

    <div class="row">
        <div class="col col-12 col-sm-6 col-lg-4">
            <label>{{
                'payment-details-account-holder-name' | cmsText | async
            }}</label>
        </div>
        <div class="col col-12 col-sm-6 col-lg-8">
            {{ paymentTransferDetails.accountHolderName }}
        </div>
    </div>
    <div class="row">
        <div class="col col-12 col-sm-6 col-lg-4">
            <label>{{ 'payment-details-iban' | cmsText | async }}</label>
        </div>
        <div class="col col-12 col-sm-6 col-lg-8">
            {{ paymentTransferDetails.iban }}
        </div>
    </div>
    <div class="row">
        <div class="col col-12 col-sm-6 col-lg-4">
            <label>{{ 'payment-details-reference' | cmsText | async }}</label>
        </div>
        <div class="col col-12 col-sm-6 col-lg-8">
            {{ paymentTransferDetails.paymentReference }}
        </div>
    </div>
    <div class="row">
        <div class="col col-12 col-sm-6 col-lg-4">
            <label>{{ 'payment-details-amount' | cmsText | async }}</label>
        </div>
        <div class="col col-12 col-sm-6 col-lg-8">
            &euro; {{ paymentTransferDetails.amount | number : '1.2' }}
        </div>
    </div>
</div>

<div>
    <hr class="my-3" />
    <form class="p-3" [formGroup]="form">
        <div class="mb-3">
            <h4
                class="text-center"
                for="paymentMethod"
                [innerHTML]="'payment-method-label' | cmsText | async"
            ></h4>
            <div class="row justify-content-center" id="paymentMethod">
                <div class="col mt-2" *ngFor="let m of paymentMethods">
                    <div class="text-center">
                        <label
                            class="w-100 shadow-none border rounded pt-2"
                            for="{{ m.value }}"
                        >
                            <picture>
                                <img
                                    [attr.src]="
                                        m.cmsKey + '-img' | cmsImageSrc | async
                                    "
                                    [attr.alt]="
                                        m.cmsKey + '-img' | cmsImageAlt | async
                                    "
                                    height="100"
                                />
                            </picture>
                            <h5
                                class="my-2"
                                [innerHTML]="m.cmsKey | cmsText | async"
                            ></h5>
                            <div>
                                <input
                                    formControlName="paymentMethod"
                                    type="radio"
                                    value="{{ m.value }}"
                                    id="{{ m.value }}"
                                />
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-3">
            <app-register-text-error *ngIf="showError" errorKey="payment-error">
            </app-register-text-error>
        </div>
        <div class="mb-3 text-center">
            <button
                id="paymentBtn"
                type="button"
                class="btn btn-primary px-5"
                (click)="submitBuckarooPost()"
                [innerHTML]="'payment-button' | cmsText | async"
                [disabled]="disabledButton"
            ></button>
            <app-loading-spinner
                *ngIf="showSpinner"
                cssClass="text-primary mx-1"
            >
            </app-loading-spinner>
        </div>
    </form>

    <form ngNoForm action="{{ buckarooFormAction }}" method="POST">
        <input
            type="hidden"
            *ngFor="let i of buckarooFormInputs"
            id="{{ i.id }}"
            name="{{ i.name }}"
            value="{{ i.value }}"
        />
        <button type="submit" [hidden]="true" #buckarooSubmitBtn></button>
    </form>
</div>
