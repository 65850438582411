import { Component } from '@angular/core';
import { DestroyableBase } from '@spnl/components/destroyable/destroyable.component';
import { RegisterStep } from '@spnl/model/register-step.enum';
import { Registration } from '@spnl/model/registration';
import {
    RegisterLocationService,
    Substep,
} from '@spnl/services/register-location.service';
import { RegisterSaveService } from '@spnl/services/register-save.service';
import { RegisterStoreService } from '@spnl/services/register-store.service';
import { RegistrationApiService } from '@spnl/services/registration-api.service';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-register-offer-declined',
    templateUrl: './offer-declined.component.html',
})
export class OfferDeclinedComponent extends DestroyableBase {
    get registration(): Registration {
        return this.registerStore.registration;
    }

    get cmsKeyForStatusProposalDeclined(): string {
        return this.auctionIsActive
            ? 'status-proposal-declined'
            : 'status-proposal-declined-inactive';
    }

    get auctionIsActive(): boolean {
        return !this.registration.auctionIsInactive;
    }

    constructor(
        private locationService: RegisterLocationService,
        private registrationApiService: RegistrationApiService,
        private registerSaveService: RegisterSaveService,
        private registerStore: RegisterStoreService,
    ) {
        super();
    }

    goToProposal(): void {
        this.locationService.goTo(RegisterStep.Proposal, new Substep.None());
    }

    revertDecision(): void {
        this.registrationApiService
            .revertDecision(this.registration)
            .pipe(takeUntil(this.destroyed$))
            .subscribe((_) => {
                this.locationService.goTo(
                    RegisterStep.Proposal,
                    new Substep.None(),
                );

                this.registration.decisionComplete = false;
                this.registration.proposalAccepted = null;
                this.registerSaveService.saveAndRehydrate();
            });
    }
}
