import { AssessmentStatus } from '@spnl/model/assessment-status';

export class CancelDelivery {
    readonly paymentPreference = 'RefundCustomer';

    constructor(
        public assessmentStatus: AssessmentStatus,
        public cancellationReason: string,
        public cancellationSubreason: string,
        public cancellationExplanation: string,
        public whoInitiatedCancellation: string,
        public wantsToBeInvolvedInFutureAuctions: boolean,
    ) {}
}
