<app-register-error errorKey="payment-depositfailed-error">
</app-register-error>
<span
    [innerHTML]="
        'payment-depositfailed-description'
            | cmsText : [{ supplierName: registration.supplierName }]
            | async
    "
>
</span>
