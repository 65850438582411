import { Component } from '@angular/core';
import { Supplier } from '@common/model/supplier.base';
import { Registration } from '@spnl/model/registration';
import { InstallationService } from '@spnl/services/installation.service';
import { RegisterStoreService } from '@spnl/services/register-store.service';

@Component({
    selector: 'app-register-order-details',
    templateUrl: './order-details.component.html',
})
export class OrderDetailsComponent {
    get registration(): Registration {
        return this.registerStore.registration;
    }

    get supplier(): Supplier {
        return this.registration.proposalMade
            ? this.registration.proposal.supplier
            : null;
    }

    get vatRate(): number {
        // TODO deduplicate vatRate calculation
        return 0;
    }

    get numberOfPanelsChosen(): number {
        return this.registration.product.chosenNumberOfPanels;
    }

    constructor(
        private registerStore: RegisterStoreService,
        private installationService: InstallationService,
    ) {}
}
