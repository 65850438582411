<div class="alert alert-info mt-1 d-flex align-items-stretch order-last">
    <i class="fa fa-info-circle me-3 mt-1"></i>
    <div>
        <span
            class="d-block"
            [innerHTML]="warningKey | cmsText : [cmsParams] | async"
        ></span>
        <ng-content></ng-content>
    </div>
</div>
