<div
    class="card bg-light shadow h-100 border-0 mb-1"
    *ngIf="registration && seminar"
>
    <div class="card-body">
        <h5 class="card-title">
            {{ seminar.location }}
            <i
                *ngIf="seminar.type === seminarType.Webinar"
                class="fa fa-laptop ms-2"
            ></i>
        </h5>
        <p class="card-text">
            <i class="fa fa-fw fa-calendar"></i>
            <span>{{
                seminar.dateTime | date : "EEEE, d MMMM y 'om' H:mm"
            }}</span>
            <span *ngIf="hasEndTime">
                {{ 'seminar-ends-at-text' | cmsText | async }}
                {{ seminarEndTime | date : 'H:mm' }}
            </span>
        </p>
        <p class="card-text seminar-comments" *ngIf="seminar.locationComments">
            {{ seminar.locationComments }}
        </p>
    </div>

    <div class="card-footer">
        <div
            *ngIf="registration.seminarId === seminar.id"
            class="alert alert-success"
        >
            {{ 'successfully-registered-for-seminar' | cmsText | async }}
        </div>
        <div class="d-grid">
            <button
                class="btn px-5"
                [disabled]="!active"
                *ngIf="registration.seminarId !== seminar.id"
                [ngClass]="active ? 'btn-primary' : 'btn-secondary'"
                (click)="participate(seminar.id)"
            >
                {{ 'register-for-seminar' | cmsText | async }}
            </button>
        </div>
        <div class="d-grid">
            <button
                class="btn btn-secondary m-1"
                [disabled]="!active"
                *ngIf="registration.seminarId === seminar.id"
                (click)="unsubscribe()"
            >
                {{ 'unsubscribe-from-seminar' | cmsText | async }}
            </button>
        </div>
    </div>
</div>
