import { Component, Input } from '@angular/core';
import { Registration, Salutation } from '@spnl/model/registration';

@Component({
    selector: 'app-cancel-overview-title',
    templateUrl: './cancel-overview-title.component.html',
})
export class CancelOverviewTitleComponent {
    @Input()
    registration: Registration;

    // Suppress linting, leaving this as is for legacy reasons (not worth the risk of refactoring)
    Salutation: typeof Salutation = Salutation;
}
