export abstract class NlInitialsFormatter {

    public static whitelist = ['Fred', 'Adr', 'Chr', 'Jac', 'Joh', 'Fr', 'IJ', 'Ph', 'St', 'Th', 'Tj'];

    public static formatInitials(initials: string) {
        if (initials == null || initials.length === 0) {
            return initials;
        }
        let changedInitials = '';
        initials = initials.replace(/[^A-Za-z.]/g, '');
        initials = initials.replace(/\.{2,}/g, '.');
        if (initials.charAt(0) === '.') {
            initials = initials.slice(1);
        }
        while (initials.length > 0) {
            const char = initials[0];
            initials = initials.slice(1);
            if (initials.length > 0) {
                if (initials[0] === '.') {
                    changedInitials = changedInitials + char.toUpperCase() + '.';
                    initials = initials.slice(1);
                    continue;
                }
                let hasWhiteListedChar = false;
                for (let i = initials.length; i > -1; i--) {
                    const tmp = this.returnWhiteListed(char + initials.substring(0, i + 1));
                    if (tmp.length > 0) {
                        hasWhiteListedChar = true;
                        changedInitials = changedInitials + tmp + '.';
                        initials = initials.slice(tmp.length - 1);
                        if (initials.charAt(0) === '.') {
                            initials = initials.slice(1);
                        }
                        break;
                    }
                }
                if (!hasWhiteListedChar) {
                    changedInitials = changedInitials + char.toUpperCase() + '.';
                }
            } else {
                changedInitials = changedInitials + char.toUpperCase() + '.';
            }
        }
        return changedInitials;
    }

    public static returnWhiteListed(str: string) {

        const index = this.whitelist.indexOf(str);
        if (index > -1) {
            return this.whitelist[index];
        }
        return '';
    }
}
