// NOTE: The API currently expects house number to be an integer value
// TODO: Change the validator to the commented-out version after this has been changed
// export const houseNrPattern = /^[a-zA-Z0-9]+(-?[a-zA-Z0-9]+)?$/;
export const houseNrPattern = /^[0-9]+$/;
export const houseNrSuffixPattern = /^[a-zA-Z0-9]+( ?-?\.?[a-zA-Z0-9]+)?$/;
// eslint-disable-next-line no-useless-escape
export const noSlashes = /[^\/\\]+$/;
export const insertionPattern = /^[a-zA-ZÀ-ÿ\s]+$/;
// eslint-disable-next-line no-misleading-character-class
export const streetPattern =
    /^([a-zA-ZÀ-ÿ\u0300-\u036f\u0100-\u017F0-9-.'"]+[.]?[a-zA-ZÀ-ÿ\u0300-\u036f0-9-.']?\s?&?)?[a-zA-ZÀ-ÿ0-9-.()\s"']+$/;
// eslint-disable-next-line no-useless-escape
export const namePattern = /^[a-zA-Z- \u00c0-\u00ff\\.\'\\s]{0,49}$/;
export const phoneExpression =
    /^(((0)[1-9]{2}[0-9][0-9][0-9]{5})|((\+31|0|0031)[1-9][0-9][0-9][0-9]{6}))$/;
export const cellPhoneExpression = /^(((\+31|0|0031)6){1}[0-9]{1}[0-9]{7})$/;
export const guidPattern =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
export const blockedCellPhoneNumbers = [
    '0612345678',
    '0698765432',
    '0611111111',
    '0622222222',
    '0633333333',
    '0644444444',
    '0655555555',
    '0666666666',
    '0677777777',
    '0688888888',
    '0699999999',
    '0600000000',
];
