import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Supplier } from '@common/model/supplier.base';
import { API_URL } from '@common/app/app.config';
import { OfferSupplier } from '@common/model/offersupplier.base';

@Injectable()
export class SupplierService {
    url: string;
    constructor(
        protected http: HttpClient,
        @Inject(API_URL) protected baseUrl: string,
    ) {
        this.url = this.baseUrl + 'suppliers/';
    }

    getSupplierById(id: string): Observable<Supplier> {
        return this.http.get<Supplier>(this.url + id);
    }

    getSupplierOffer(auctionId: string, supplierId: string) {
        const offerSupplierUrl = `${this.url}${supplierId}/offer-supplier-for-auction/${auctionId}`;
        return this.http.get<OfferSupplier>(offerSupplierUrl);
    }

    getSupplierOfferById(offerId: string) {
        const offerSupplierUrl = `${this.url}offer-supplier-byOfferId/${offerId}`;
        return this.http.get<OfferSupplier>(offerSupplierUrl);
    }

    getSupplierOfferBySupplierOfferId(supplierOfferId: string) {
        const offerSupplierUrl = `${this.url}offer-supplier-byId/${supplierOfferId}`;
        return this.http.get<OfferSupplier>(offerSupplierUrl);
    }
    getSupplierOfferBySupplierCode(auctionId: string, supplierCode: string) {
        const offerSupplierUrl = `${this.url}${supplierCode}/offer-supplier-for-auction-by-code/${auctionId}`;
        return this.http.get<OfferSupplier>(offerSupplierUrl);
    }
}
