import { Registration } from './registration';

export enum RegisterStep {
    Person = 'person',
    Product = 'product',
    Welcome = 'welcome',
    Proposal = 'proposal',
    DecisionMade = 'decisionmade',
    Overview = 'overview',
    Closed = 'closed',
    Cancelled = 'cancelled',
}

export namespace RegisterStep {
    export const values = [
        RegisterStep.Person,
        RegisterStep.Product,
        RegisterStep.Welcome,
        RegisterStep.DecisionMade,
        RegisterStep.Overview,
        RegisterStep.Proposal,
        RegisterStep.Closed,
        RegisterStep.Cancelled,
    ];

    export const getUri = (step: RegisterStep, registration: Registration) => {
        if (step === RegisterStep.DecisionMade) {
            return registration.proposalAccepted ? 'payment' : 'declined';
        }
        return step;
    };
}
