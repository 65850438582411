import {
    ExcludedReason,
    Product,
    RoofMaterial,
    RoofOrientation,
    RoofPitch,
    RoofSize,
    SolarPanelSituation,
} from '@spnl/model/registration';
import { RoofStep } from '@spnl/model/roof-step.model';

export class ExclusionContext {
    roofOrientation?: RoofOrientation;
    roofMaterial?: RoofMaterial;
    roofPitch?: RoofPitch;
    numberOfPanelsThatFitOnRoof?: number;
    isHomeOwner?: boolean;
    isHomeOwnerAssociationMember?: boolean;
}

export class ExclusionService {
    static getExclusionContextBasedOnProduct(
        product: Product,
    ): ExclusionContext {
        return {
            roofOrientation: product.roofOrientation,
            roofMaterial: product.roofMaterial,
            roofPitch: product.roofPitch,
            numberOfPanelsThatFitOnRoof: product.numberOfPanelsThatFitOnRoof,
            isHomeOwner: product.isHomeOwner,
            isHomeOwnerAssociationMember: product.isHomeOwnerAssociationMember,
        };
    }

    static getExcludedReason(context: ExclusionContext): ExcludedReason | null {
        if (RoofOrientation.isUnsupported(context.roofOrientation)) {
            return ExcludedReason.RoofOrientation;
        }

        if (
            RoofMaterial.isUnsupported(context.roofMaterial, context.roofPitch)
        ) {
            return ExcludedReason.RoofMaterial;
        }

        if (RoofSize.isUnsupported(context.numberOfPanelsThatFitOnRoof)) {
            return ExcludedReason.RoofSize;
        }

        if(context.isHomeOwner === false)
        {
            return ExcludedReason.NotHomeOwner;
        }

        if(context.isHomeOwnerAssociationMember === true)
        {
            return ExcludedReason.MemberOfHomeOwnerAssociation;
        }

        return null;
    }

    static getRoofStepForExcludedReason(
        reason: ExcludedReason,
    ): RoofStep | undefined {
        const substepMap = new Map<ExcludedReason, RoofStep>([
            [ExcludedReason.RoofMaterial, RoofStep.Type],
            [ExcludedReason.RoofOrientation, RoofStep.Type],
            [ExcludedReason.RoofSize, RoofStep.Size],
        ]);

        return substepMap.get(reason);
    }
}
