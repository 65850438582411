import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommunityService } from '../../../_common/services/community.service';
import { ContentService } from '../../../_common/services/content.service';

@Injectable({
    providedIn: 'root',
})
export class RegistrationButtonContentService {
    constructor(
        private communityService: CommunityService,
        private contentService: ContentService,
    ) {}

    getRegisterButtonCmsKey(): Observable<string> {
        return this.communityService.currentAuctionOpenForNewRegistrations$.pipe(
            map((openForSubscription) => {
                return openForSubscription
                    ? 'register-button-text'
                    : 'notify-button-text';
            }),
        );
    }

    getRegisterRoofButtonCmsKey(): Observable<string> {
        return this.communityService.currentAuctionOpenForNewRegistrations$.pipe(
            map((openForSubscription) => {
                return openForSubscription
                    ? 'roof-test-button'
                    : 'notify-button-text';
            }),
        );
    }

    getRoofButtonTitleCmsKey(): Observable<string> {
        return this.communityService.currentAuctionOpenForNewRegistrations$.pipe(
            map((openForSubscription) => {
                return openForSubscription
                    ? 'sign-up-title'
                    : 'notify-button-title';
            }),
        );
    }
}
