import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-error-alert',
    template: ` <div
        class="alert alert-danger mt-1 d-flex align-items-stretch order-last"
    >
        <i class="fa fa-exclamation-circle mr-3 mt-1"></i>
        <div [innerHTML]="cmsKey | cmsText | async"></div>
    </div>`,
})
export class ErrorAlertComponent {
    @Input()
    cmsKey = 'error-fallback-text';
}
