export enum AssessmentStatus {
    Pending = 'Pending',
    Performed = 'Performed',
    Approved = 'Approved',
}

export namespace AssessmentStatus {
    export const all: AssessmentStatus[] = [
        AssessmentStatus.Pending,
        AssessmentStatus.Performed,
        AssessmentStatus.Approved,
    ];
}
