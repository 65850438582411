import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RegisterStep } from '@spnl/model/register-step.enum';
import { Registration } from '@spnl/model/registration';
import { RoofStep } from '@spnl/model/roof-step.model';
import { take } from 'rxjs/operators';
import { RegisterLocationService, Substep } from './register-location.service';
import { RegisterSaveService } from './register-save.service';
import { RegisterStepService } from './register-step.service';
import { RegisterStoreService } from './register-store.service';
import { RegistrationService } from './registration.service';
import { RoofDetailsStepService } from './roof-details-step.service';

@Injectable()
export class RoofDetailsLocationService {
    constructor(
        private registrationService: RegistrationService,
        private registerStepService: RegisterStepService,
        private registerStore: RegisterStoreService,
        private registerLocationService: RegisterLocationService,
        private registerSaveService: RegisterSaveService,
        private router: Router,
    ) {}

    substepUpdatedByRoute(substep: string): void {
        this.registerStore.registrationLoaded$.pipe(take(1)).subscribe(() => {
            if (!substep) {
                // Explicitly setting activeRoofStep to null indicates that we don't use step functionality
                this.registerStore.activeRoofStep = null;
            } else {
                // The roof step is 0-5, but we update the URL starting from 1 - 6
                const candidateStep = Number.parseInt(substep, 10) - 1;
                if (
                    RoofStep.LivingSituation <= candidateStep &&
                    candidateStep <= RoofStep.Yield
                ) {
                    // User should only be able to navigate to the latest available step
                    const latestStep = RoofDetailsStepService.getLatestStep(
                        this.registerStore.registration.product,
                    );
                    if (latestStep < candidateStep) {
                        this.goToStep(latestStep);
                        return;
                    }
                    this.registerStore.activeRoofStep = candidateStep;
                    this.registerStore.finishSubmitting();

                    // TODO: if we navigated for the first time to the RoofDetailsComponent, we will log to analytics
                    // twice. Once in the RegisterLocationService and once here. Not a big deal, but we might as well
                    // fix it.
                    this.registerLocationService.logToAnalytics();
                    window.scrollTo(0, 0);
                }
            }
        });
    }

    goToNextStep(registration: Registration): void {
        const hasActiveRoofStep = this.registerStore.activeRoofStep !== null;
        const lastRoofStep =
            RoofDetailsStepService.allSteps[RoofStep.values.length - 1];
        if (
            !this.registerStepService.inRegistrationFlow(registration) ||
            !hasActiveRoofStep ||
            this.registerStore.activeRoofStep === lastRoofStep
        ) {
            if (registration.email == null) {
                // This will only happen after the rooftest-first flow. We do not want to
                // save a registration that has no personal information
                this.registrationService.saveToSessionStorage(registration);
                this.registerSaveService.noSaveAndContinue();
            } else {
                // Navigate away from the roof test
                this.registerSaveService.saveAndContinue();
            }
        } else {
            this.registrationService.saveToSessionStorage(registration);
            if (registration.hasValidContact && !registration.excluded) {
                // Also save product details in between roof steps in the background
                this.registrationService.update(registration).subscribe();
            }
            this.goToStep(this.registerStore.activeRoofStep + 1);
        }
    }

    goToPreviousStep(registration: Registration): void {
        const hasActiveRoofStep = this.registerStore.activeRoofStep !== null;
        const firstRoofStep = RoofDetailsStepService.allSteps[0];
        this.registrationService.saveToSessionStorage(registration);
        if (registration.hasValidContact && !registration.excluded) {
            this.registrationService.update(registration).subscribe();
        }

        if (
            !hasActiveRoofStep ||
            this.registerStore.activeRoofStep === firstRoofStep
        ) {
            // Navigate away from the roof test
            this.registerLocationService.goTo(
                RegisterStep.Person,
                new Substep.None(),
            );
        } else {
            this.goToStep(this.registerStore.activeRoofStep - 1);
        }
    }

    private goToStep(substep: RoofStep) {
        this.registerLocationService
            .buildRoute(RegisterStep.Product, substep)
            .subscribe((route) => {
                this.router.navigate(route, {
                    queryParamsHandling: 'preserve',
                });
            });
    }
}
