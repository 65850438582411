<app-hero
    [headerKey]="errorHeaderKey"
    heroImageKey="hero-image-secondary"
></app-hero>
<div class="fluid-container bg-highlight-one pt-2 pb-5">
    <div class="container">
        <div class="card shadow border-0 mb-1">
            <div class="card-body">
                <span
                    *ngIf="errorMessageKey"
                    [innerHTML]="errorMessageKey | cmsText | async"
                ></span>
            </div>
        </div>
    </div>
</div>
