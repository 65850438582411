<h3 class="my-3">
    {{ message.titleCmsKey | cmsText | async }}
</h3>

<hr class="my-3" />

<div class="alert" [ngClass]="message.bodyClass">
    <span [innerHTML]="message.bodyCmsKey | cmsText | async"></span>
</div>

<div class="mb-3 row mt-3" *ngIf="message.showBackButton && hasActiveStep">
    <div class="col-sm-4 text-start">
        <button
            type="button"
            class="btn btn-outline-secondary"
            (click)="goBack()"
        >
            {{ 'go-back-button' | cmsText | async }}
        </button>
    </div>
</div>
