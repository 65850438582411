export namespace RegisterConfig {
    export const minNumberOfPanels = 4;
    export const maxNumberOfPanels = 40;
    export const minRoofLength = 1;
    export const minRoofWidth = 1;
    export const maxRoofLength = 35;
    export const maxRoofWidth = 35;

    export const acceptanceGracePeriod = 2;
    export const paymentGracePeriod = 14;
}
