<h3 class="my-3">
    {{ 'cancel-overview-title' | cmsText | async }}
    <span *ngIf="registration.contact.salutation === Salutation.Mr">{{
        'salutation-mr-label' | cmsText | async | lowercase
    }}</span>
    <span *ngIf="registration.contact.salutation === Salutation.Mrs">{{
        'salutation-mrs-label' | cmsText | async | lowercase
    }}</span>
    {{ registration.contact.insertion
    }}{{ registration.contact.insertion ? ' ' : ''
    }}{{ registration.contact.lastName }}
</h3>

<hr class="my-3" />
