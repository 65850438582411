import { Component, Input } from "@angular/core";
import { Registration } from "@spnl/model/registration";

@Component({
    selector: 'app-proposal-personal-details',
    templateUrl: './proposal-personal-details.component.html',
})
export class ProposalPersonalDetailsComponent {
    @Input()
    registration: Registration;

    constructor() {}
}
