import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { excludedReason, shouldBeExcluded } from '@spnl/forms/roof-form-tools';
import { ExcludedReason } from '@spnl/model/registration';
import { RegisterStoreService } from '@spnl/services/register-store.service';

@Component({
    selector: 'app-roof-excluded-warning',
    templateUrl: './roof-excluded-warning.component.html',
})
export class RoofExcludedWarningComponent {
    @Input()
    form: UntypedFormGroup;

    get excludedReason(): ExcludedReason | null {
        return excludedReason(this.form);
    }

    get shouldBeExcluded(): boolean {
        return shouldBeExcluded(this.form);
    }

    get shouldShowAllRoofSteps(): boolean {
        return this.registerStore.shouldShowAllRoofSteps;
    }

    constructor(private registerStore: RegisterStoreService) {}
}
