<form
    [formGroup]="declineForm"
    (ngSubmit)="onDeclineOffer()"
    novalidate
    appfocusFirstInvalidField
>
    <div class="modal-header">
        <h5 class="modal-title">
            {{ 'decline-offer-modal-title' | cmsText | async }}
        </h5>
        <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="onDismiss()"
        ></button>
    </div>
    <div class="modal-body">
        <p>
            {{ 'decline-offer-modal-text' | cmsText | async }}
        </p>
        <div class="mb-3">
            <label>
                {{ 'decline-offer-reason-to-decline-text' | cmsText | async }}
            </label>
            <div class="form-check" *ngFor="let reason of reasonsToDecline">
                <input
                    type="radio"
                    class="form-check-input"
                    [id]="reason"
                    formControlName="reasonToDecline"
                    name="reasonToDecline"
                    [value]="reason"
                />
                <label class="custom-control-label" [for]="reason">
                    {{ reasonToDeclineKey(reason) | cmsText | async }}
                </label>
            </div>
            <div class="form-check custom-control-inline">
                <input
                    type="radio"
                    class="form-check-input"
                    id="otherReason"
                    formControlName="reasonToDecline"
                    name="reasonToDecline"
                    [value]="ReasonToDeclineOption.OtherReason"
                />
                <label class="custom-control-label" for="otherReason">
                    {{ 'proposal-decline-reason-other' | cmsText | async }}
                </label>
            </div>
            <div
                *ngIf="
                    reasonToDecline.value === ReasonToDeclineOption.OtherReason
                "
                class="mb-3"
            >
                <input
                    [ngClass]="{
                        'form-control': true,
                        'is-invalid': showErrorsForReasonToDeclineDescription
                    }"
                    id="other-text"
                    formControlName="reasonToDeclineDescription"
                />
                <app-register-input-errors
                    [shouldShowErrors]="showErrorsForReasonToDeclineDescription"
                    [field]="reasonToDeclineDescription"
                    label="proposal-decline-reason"
                >
                </app-register-input-errors>
            </div>
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-12 col-md-6 mt-md-0 mt-3 d-grid">
                    <button
                        type="button"
                        class="btn btn-outline-secondary"
                        (click)="onDismiss()"
                    >
                        {{ 'previous-step-button' | cmsText | async }}
                    </button>
                </div>
                <div class="col-12 col-md-6 mt-md-0 mt-3 d-grid">
                    <button
                        id="nextButton"
                        type="submit"
                        class="btn btn-danger"
                    >
                        {{ 'confirm-proposal-button' | cmsText | async }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>
