<ng-container [formGroup]="supplierConditions">
    <div class="form-check">
        <input
            class="form-check-input"
            type="checkbox"
            id="agreesToSupplierConditions"
            formControlName="agreesToSupplierConditions"
        />
        <label
            class="custom-control-label"
            for="agreesToSupplierConditions"
            [innerHTML]="
                supplierConditionsLabel
                    | cmsText
                        : [{ supplierName: this.registration.supplierName }]
                    | async
            "
        ></label>
    </div>
    <div
        *ngIf="
            agreesToSupplierConditions.invalid &&
            (agreesToSupplierConditions.dirty || submitRequested)
        "
        class="text-danger"
    >
        <span
            *ngIf="agreesToSupplierConditions.errors.required"
            [innerHTML]="'proposal-supplier-agreements-error' | cmsText | async"
        ></span>
    </div>
</ng-container>
