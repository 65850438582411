import { Injectable } from '@angular/core';
import { CommunityService } from '@common/services/community.service';
import { GoogleAnalyticsService } from '@common/services/google-analytics.service';
import { isNullOrUndefined } from '@common/util';

@Injectable()
export class FaqAnalyticsService {
    constructor(
        private analytics: GoogleAnalyticsService,
        private communityService: CommunityService,
    ) {}

    logQuestionViewed(question: string): void {
        this.analytics.event(
            { path: this.getPath() },
            { category: question, action: 'view' },
        );
    }

    logSearchTerm(term: string): void {
        if (isNullOrUndefined(term) || term === '') {
            return;
        }

        this.analytics.event(
            { path: this.getPath() },
            { category: `FAQ`, action: 'search', label: term },
        );
    }

    private getPath(): string {
        return `/${this.communityService.communityCode}/faq`;
    }
}
