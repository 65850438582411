import { AfterViewInit, Component } from '@angular/core';
import { PaymentStatus } from '@common/model/payment';
import { Registration } from '@spnl/model/registration';
import { DigitalEventService } from '@spnl/services/digital-event-queue.service';
import { RegisterStoreService } from '@spnl/services/register-store.service';
import { SolarApiService } from '@spnl/services/solar-api.service';
import { take } from 'rxjs/operators';
import { DeviceTypeService } from '@country/nl/services/device-type.service';

@Component({
    selector: 'app-register-overview-deposit-paid',
    templateUrl: './deposit-paid.component.html',
})
export class DepositPaidComponent implements AfterViewInit {
    isMobileDevice: boolean;

    get registration(): Registration {
        return this.registerStore.registration;
    }

    get faqItems(): string[] {
        return [
            'how-does-it-work',
            'if-i-have-extra-costs-after-inspection',
            'if-my-roof-is-not-suited-after-inspection',
        ];
    }

    get statusCmsKey(): string {
        return this.registration.paymentStatus === PaymentStatus.Successful
            ? 'status-depositpaid-payment-successful'
            : 'status-depositpaid-payment-pending';
    }

    constructor(
        private registerStore: RegisterStoreService,
        solarApiService: SolarApiService,
        private digitalEventService: DigitalEventService,
        private deviceTypeService: DeviceTypeService,
    ) {
        this.registerStore.registrationLoaded$.pipe(take(1)).subscribe(() => {
            solarApiService
                .getProposals(this.registration.id)
                .subscribe(
                    (installations) =>
                        (this.registerStore.installations = installations),
                );
        });
        this.isMobileDevice = this.deviceTypeService.isMobileDevice();
    }

    ngAfterViewInit(): void {
        this.digitalEventService.push('overview pagina');
    }
}
