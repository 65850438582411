<app-vwo-capturer></app-vwo-capturer>
<ng-container *ngIf="doneLoadingIdentity$ | async; else loading">
    <app-menu></app-menu>
    <router-outlet></router-outlet>
    <app-footer *ngIf="router.url !== '/landen'"></app-footer>
    <app-cms-keys-bar></app-cms-keys-bar>
</ng-container>
<ng-template #loading>
    <div class="fluid-container bg-highlight-one py-5">
        <div class="container">
            <div class="row">
                <div class="col-md-8 mb-3">
                    <div class="card shadow border-0 mb-1">
                        <div class="card-body mx-auto">
                            <i class="fa fa-spinner fa-spin fa-2x"></i>
                        </div>
                        <div class="card-body mx-auto">
                            {{ 'loading-message' | cmsText | async }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
