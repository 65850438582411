import {
    AfterViewInit,
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { RoofNotSuitableEvent } from '@common/analytics/events/roof-not-suitable.event';
import { AnalyticsService } from '@common/analytics/services/analytics.service';
import {
    applyFormValue,
    ControlWithErrors,
    makeFormModel,
    shouldShowErrorsFor,
} from '@common/infrastructure/form-tools';
import { DestroyableBase } from '@spnl/components/destroyable/destroyable.component';
import { RoofStepFormGroup } from '@spnl/forms/roof-form-tools';
import {
    ExcludedReason,
    Registration,
    SolarPanelSituation,
} from '@spnl/model/registration';
import { DigitalEventService } from '@spnl/services/digital-event-queue.service';
import {
    ExclusionContext,
    ExclusionService,
} from '@spnl/services/exclusion.service';
import { RegisterSaveService } from '@spnl/services/register-save.service';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-register-roof-living-situation',
    templateUrl: './roof-living-situation.component.html',
})
export class RoofLivingSituationComponent
    extends DestroyableBase
    implements OnInit, OnDestroy, AfterViewInit
{
    @Input()
    registration: Registration;

    @Input()
    parentForm: UntypedFormGroup;

    @Input()
    shouldShowAllSteps: boolean;

    @Input()
    startedWithProduct: boolean;

    form: RoofStepFormGroup;
    submitRequested = false;

    isHomeOwner: ControlWithErrors;
    isHomeOwnerAssociationMember: ControlWithErrors;
    currentSolarPanelSituation: ControlWithErrors;
    Decision = SolarPanelSituation;

    get showErrorsForIsHomeOwner(): boolean {
        return this.isHomeOwner.value === false;
    }

    get showErrorsForIsHomeOwnerAssociationMember(): boolean {
        return this.isHomeOwnerAssociationMember.value === true;
    }

    get showErrorsForSolarPanelSituation(): boolean {
        return shouldShowErrorsFor(this.currentSolarPanelSituation);
    }

    get showWarningForIsHomeOwnerAssociationMember(): boolean {
        return shouldShowErrorsFor(this.isHomeOwnerAssociationMember);
    }

    get showWarningForIsHomeOwner(): boolean {
        return shouldShowErrorsFor(this.isHomeOwner);
    }

    get showWarningForSolarPanelSituation(): boolean {
        return shouldShowErrorsFor(this.currentSolarPanelSituation);
    }

    constructor(
        private fb: UntypedFormBuilder,
        private digitalEventService: DigitalEventService,
        private registerSaveService: RegisterSaveService,
        private analyticsService: AnalyticsService,
        @Inject(DOCUMENT) private document: Document,
    ) {
        super();
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            isHomeOwnerAssociationMember: [null, Validators.required],
            isHomeOwner: [null, Validators.required],
            currentSolarPanelSituation: [null, Validators.required],
        }) as RoofStepFormGroup;

        this.isHomeOwnerAssociationMember = this.form.get(
            'isHomeOwnerAssociationMember',
        );
        this.isHomeOwner = this.form.get('isHomeOwner');
        this.currentSolarPanelSituation = this.form.get(
            'currentSolarPanelSituation',
        );

        this.form.onSubmit = this.onSubmit.bind(this);
        this.form.getExclusionContext = this.getExclusionContext.bind(this);
        this.parentForm.addControl('roof-current-situation', this.form);

        const model = makeFormModel(this.form, this.registration.product, []);
        this.form.reset(model);
    }

    ngAfterViewInit(): void {
        this.digitalEventService.push('woonsituatie');
    }

    onSubmit(): void {
        this.form.markAllAsTouched();

        this.submitRequested = true;
        if (this.form.valid) {
            applyFormValue(this.form, this.registration.product, []);
        }
    }

    ngOnDestroy(): void {
        this.parentForm?.removeControl('roof-current-situation');
        super.ngOnDestroy();
    }

    get excludedReason(): ExcludedReason | null {
        return ExclusionService.getExcludedReason(this.getExclusionContext());
    }

    onExcludeRegistration(excludedReason: string) {
        this.pushRoofNotSuitableAnalyticsEvent(excludedReason);

        if (this.startedWithProduct) {
            this.document.location.href = 'https://www.eigenhuis.nl/diensten/';
        } else {
            this.registerSaveService.excludeAndContinue(
                this.form,
                this.registration.product,
            );
        }
    }

    getExclusionContext(): ExclusionContext {
        return {
            isHomeOwner: this.isHomeOwner?.value,
            isHomeOwnerAssociationMember:
                this.isHomeOwnerAssociationMember?.value,
        };
    }

    pushRoofNotSuitableAnalyticsEvent(excludedReason: string): void {
        const event = RoofNotSuitableEvent.create(excludedReason);

        this.analyticsService.push(event);
    }
}
