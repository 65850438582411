import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { KenalyticsService } from '@common/services/kenalytics.service';
import { ContentService } from '@common/services/content.service';
import { CreatedRegistrationEvent } from '@common/analytics/events/process-goal.event';
import { AnalyticsService } from '@common/analytics/services/analytics.service';
import { RegistrationService } from '@spnl/services/registration.service';
import { switchMap, take } from 'rxjs/operators';
import { Command, CommandService } from '@common/services/command.service';
import { Registration } from '@spnl/model/registration';
import { RegistrationApiService } from '@spnl/services/registration-api.service';

@Component({
    selector: 'app-targeted-rollover-command',
    templateUrl: './targeted-rollover-command.component.html',
    styleUrls: ['./targeted-rollover-command.component.scss'],
})
export class TargetedRolloverCommandComponent implements OnInit, OnDestroy {
    id: string;
    targetAuction: string;

    busy = true;
    success = false;
    canRollover = true;
    error: string;
    process: Subscription;

    constructor(
        private commandService: CommandService,
        private route: ActivatedRoute,
        protected router: Router,
        private kenalyticsService: KenalyticsService,
        public contentService: ContentService,
        private analyticsService: AnalyticsService,
        private registrationService: RegistrationService,
        private registrationApiService: RegistrationApiService,
    ) {}

    ngOnInit(): void {
        try {
            this.id = this.route.snapshot.paramMap.get('registrationId');
            this.targetAuction =
                this.route.snapshot.paramMap.get('targetAuction');

            this.registrationApiService
                .rolloverToTargetAuction(this.id, this.targetAuction)
                .pipe(
                    take(1),
                    switchMap((registrationId) => {
                        this.registrationApiService
                            .checkForRolloverToTargetAuction(
                                this.id,
                                this.targetAuction,
                            )
                            .subscribe((rolledOverRegistrationId) => {
                                this.canRollover =
                                    rolledOverRegistrationId == null;
                            });

                        this.id = registrationId;
                        return this.registrationService.get(registrationId);
                    }),
                )
                .subscribe((newRegistration) => {
                    this.success = true;
                    this.busy = false;
                    this.handleRolloverAnalytics(newRegistration);
                });
        } catch (error) {
            this.handleError(error);
        }
    }

    private handleError(error: any) {
        this.busy = false;
        if (error instanceof HttpErrorResponse) {
            this.error = error.status + ': ' + error.error;
        } else {
            this.error = error.message;
        }
    }

    private handleRolloverAnalytics(registration: Registration) {
        this.kenalyticsService.postUtmCodes(registration.id);

        // Goal event
        const event = CreatedRegistrationEvent.create({ rollover: true });
        if (event?.event) this.analyticsService.push(event);
        this.commandService.logPageView(Command.Rollover, registration);
    }

    ngOnDestroy(): void {
        if (this.process) {
            this.process.unsubscribe();
        }
    }
}
