import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_URL } from '@common/app/app.config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RoofOrientation, RoofPitch } from '../model/registration';
import { SolarInstallations } from '../model/solar-installations';

@Injectable()
export class SolarApiService {
    url: string;
    constructor(
        protected http: HttpClient,
        @Inject(API_URL) protected baseUrl: string,
    ) {
        this.url = this.baseUrl + 'solar/';
    }

    getPriceIndication(
        pitch: RoofPitch,
        orientation: RoofOrientation | null,
        consumption?: number,
        auctionCode?: string,
        auctionId?: string,
        zipCode?: string,
        offersAvailable?: boolean,
    ): Observable<SolarInstallations> {
        let query = 'pitch=' + pitch;

        if (pitch !== RoofPitch.Flat) {
            query += '&orientation=' + orientation;
        }

        query += '&consumption=' + consumption;
        query += '&auctionCode=' + auctionCode;
        query += '&auctionId=' + auctionId;
        query += '&zipCode=' + zipCode;

        const endpoint = offersAvailable ? 'winning' : 'indications';

        return this.http
            .get<SolarInstallations>(this.url + endpoint + '?' + query)
            .pipe(map((response) => new SolarInstallations(response)));
    }

    getProposals(registrationId: string): Observable<SolarInstallations> {
        const query = 'registrationId=' + registrationId;

        return this.http
            .get<SolarInstallations>(this.url + 'proposals?' + query)
            .pipe(map((response) => new SolarInstallations(response)));
    }
}
