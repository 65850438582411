import { Component, OnInit } from '@angular/core';
import { CommunityService } from '@common/services/community.service';
import { PaymentMethod } from '@spnl/model/payment-method.enum';
import { Registration } from '@spnl/model/registration';
import { RegisterStoreService } from '@spnl/services/register-store.service';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-register-deposit-pending',
    templateUrl: './deposit-pending.component.html',
})
export class DepositPendingComponent implements OnInit {
    paymentMethod: PaymentMethod;

    get registration(): Registration {
        return this.registerStore.registration;
    }

    // This stops users from potentially making multiple IDeal payments while the deposit is pending
    get paymentAllowed(): boolean {
        return this.paymentMethod !== PaymentMethod.IDeal;
    }

    get statusCmsKey(): string {
        const baseKey = 'payment-status-depositpending';
        switch (this.paymentMethod) {
            case PaymentMethod.IDeal:
                return `${baseKey}-ideal`;
            case PaymentMethod.Transfer:
                return `${baseKey}-transfer`;
        }
    }

    get communityCode(): string {
        return this.communityService.communityCode;
    }

    get cmsTextExtraInfo(): any {
        return {
            community: this.communityCode,
            registrationId: this.registration.id,
            supplierName: this.registration.supplierName,
        };
    }

    constructor(
        private registerStore: RegisterStoreService,
        private communityService: CommunityService,
    ) {}

    ngOnInit(): void {
        this.registerStore.registrationLoaded$.pipe(first()).subscribe(() => {
            this.paymentMethod = this.registration.decision?.paymentMethod;
        });
    }
}
