<div *ngIf="!!isMobileDevice">
    <div class="fluid-container bg-highlight-one py-5">
        <div class="container">
            <div class="row">
                <div class="col-md-8 mb-3">
                    <div class="card shadow border-0 mb-1">
                        <div class="card-body mx-3">
                            <div>
                                <h1
                                    class="title-landing-text mb-5 mt-3 pb-0 display-2 lh-1"
                                >
                                    <span class="title-orange">
                                        {{
                                            'landing-page-title-start'
                                                | cmsText
                                                | async
                                        }}
                                    </span>
                                    {{
                                        'landing-page-title-end'
                                            | cmsText
                                            | async
                                    }}
                                </h1>
                                <br />
                                <img
                                    class="img-fluid w-100"
                                    [attr.src]="
                                        'hero-image-landing-mobile'
                                            | cmsImageSrc
                                            | async
                                    "
                                />
                                <div
                                    class="alert alert-info"
                                    role="alert"
                                    style="margin-top: -10%"
                                >
                                    <h2 class="fw-semibold">
                                        {{
                                            'landing-page-mobile-sub-title'
                                                | cmsText
                                                | async
                                        }}
                                    </h2>
                                    <p class="mt-4">
                                        {{
                                            'landing-page-mobile-text'
                                                | cmsText
                                                | async
                                        }}
                                    </p>
                                    <div class="row">
                                        <div class="col-12 mb-4">
                                            <a
                                                type="button"
                                                class="btn btn-primary btn-mobile-landing mt-3"
                                                [href]="getWhatsAppLink()"
                                                target="_blank"
                                            >
                                                <span
                                                    [innerHtml]="
                                                        'whatsapp-image'
                                                            | cmsText
                                                            | async
                                                    "
                                                ></span>
                                            </a>
                                        </div>
                                        <div class="col-12">
                                            <a
                                                type="button"
                                                class="btn btn-primary btn-mobile-landing"
                                                [href]="getEmailLink()"
                                                target="_blank"
                                            >
                                                <span
                                                    [innerHtml]="
                                                        'email-image'
                                                            | cmsText
                                                            | async
                                                    "
                                                ></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div class="container mt-5">
                                    <!-- main title -->
                                    <div class="row text-start mb-4">
                                        <h2 class="fw-semibold">
                                            <span>
                                                {{
                                                    'landing-page-main-body-title'
                                                        | cmsText
                                                        | async
                                                }}
                                            </span>
                                        </h2>
                                    </div>
                                    <!-- image slides -->
                                    <div class="row mt-3">
                                        <div class="col-5">
                                            <img
                                                class="img-fluid"
                                                [attr.src]="
                                                    'landing-page-house-full-image'
                                                        | cmsImageSrc
                                                        | async
                                                "
                                            />
                                        </div>
                                        <div class="col-7">
                                            <h5 class="my-3 fw-semibold">
                                                <span>
                                                    {{
                                                        'landing-page-house-title'
                                                            | cmsText
                                                            | async
                                                    }}
                                                </span>
                                            </h5>
                                            <p class="w-75">
                                                {{
                                                    'landing-page-house-description'
                                                        | cmsText
                                                        | async
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-5">
                                            <img
                                                class="img-fluid"
                                                [attr.src]="
                                                    'landing-page-users-full-image'
                                                        | cmsImageSrc
                                                        | async
                                                "
                                            />
                                        </div>
                                        <div class="col-7">
                                            <h5 class="my-3 fw-semibold">
                                                <span>
                                                    {{
                                                        'landing-page-users-title'
                                                            | cmsText
                                                            | async
                                                    }}
                                                </span>
                                            </h5>
                                            <p class="w-75">
                                                {{
                                                    'landing-page-users-description'
                                                        | cmsText
                                                        | async
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-5">
                                            <img
                                                class="img-fluid"
                                                [attr.src]="
                                                    'landing-page-smile-full-image'
                                                        | cmsImageSrc
                                                        | async
                                                "
                                            />
                                        </div>
                                        <div class="col-7">
                                            <h5 class="my-3 fw-semibold">
                                                <span>
                                                    {{
                                                        'landing-page-smile-title'
                                                            | cmsText
                                                            | async
                                                    }}
                                                </span>
                                            </h5>
                                            <p class="w-75">
                                                {{
                                                    'landing-page-smile-description'
                                                        | cmsText
                                                        | async
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!isMobileDevice">
    <div class="container-fluid">
        <div class="row row-gray-background px-5">
            <div class="container py-5">
                <div class="row align-items-center">
                    <!-- hero image -->
                    <div class="col me-2">
                        <img
                            class="img-fluid"
                            [attr.src]="
                                'hero-image-landing' | cmsImageSrc | async
                            "
                        />
                    </div>
                    <!-- content next to image -->
                    <div class="col ms-2">
                        <div style="display: grid">
                            <h1 class="title-landing-text mb-2 pb-0">
                                <span class="title-orange">
                                    {{
                                        'landing-page-title-start'
                                            | cmsText
                                            | async
                                    }}
                                </span>
                                {{ 'landing-page-title-end' | cmsText | async }}
                            </h1>
                            <h6 class="pt-2">
                                {{
                                    'landing-page-title-description'
                                        | cmsText
                                        | async
                                }}
                            </h6>
                        </div>
                        <div class="pt-3 mb-3">
                            <p>
                                {{
                                    'landing-page-title-main-body'
                                        | cmsText
                                        | async
                                }}
                            </p>
                        </div>
                        <!-- whatsapp and email buttons -->
                        <div class="row">
                            <div class="col-md-12 col-lg-6 col-xl-5 pb-2">
                                <a
                                    class="btn btn-primary btn-share rounded-3 text-decoration-none px-6"
                                    [href]="getWhatsAppLink()"
                                    target="_blank"
                                >
                                    <span
                                        [innerHtml]="
                                            'whatsapp-image' | cmsText | async
                                        "
                                    ></span>
                                </a>
                            </div>
                            <div class="col-md-12 col-lg-6 col-xl-7">
                                <a
                                    class="btn btn-primary btn-share rounded-3 text-decoration-none"
                                    [href]="getEmailLink()"
                                    target="_blank"
                                >
                                    <span
                                        [innerHtml]="
                                            'email-image' | cmsText | async
                                        "
                                    ></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row my-3 px-5">
            <div class="container">
                <!-- main title -->
                <div class="row text-center mb-4">
                    <h2>
                        <span>
                            {{
                                'landing-page-main-body-title' | cmsText | async
                            }}
                        </span>
                    </h2>
                </div>
                <!-- images -->
                <div class="row">
                    <div
                        class="col justify-content-md-center"
                        style="display: flex"
                    >
                        <img
                            class="img-fluid img-landing align-self-end"
                            style="height: 40%; margin-right: -10px"
                            [attr.src]="
                                'landing-page-house-circle-check'
                                    | cmsImageSrc
                                    | async
                            "
                        />
                        <img
                            class="img-fluid img-landing"
                            style="height: 100%"
                            [attr.src]="
                                'landing-page-house' | cmsImageSrc | async
                            "
                        />
                        <img
                            class="img-fluid img-landing"
                            style="height: 40%; margin-left: -20px"
                            [attr.src]="
                                'landing-page-house-face-smile'
                                    | cmsImageSrc
                                    | async
                            "
                        />
                    </div>
                    <div
                        class="col justify-content-md-center"
                        style="display: flex"
                    >
                        <img
                            class="img-fluid img-landing align-self-end me-3"
                            style="height: 40%"
                            [attr.src]="
                                'landing-page-users-circle'
                                    | cmsImageSrc
                                    | async
                            "
                        />
                        <img
                            class="img-fluid img-landing align-self-end"
                            style="height: 60%"
                            [attr.src]="
                                'landing-page-users' | cmsImageSrc | async
                            "
                        />
                        <img
                            class="img-fluid img-landing"
                            style="height: 40%; margin-left: -60px"
                            [attr.src]="
                                'landing-page-users-share-nodes'
                                    | cmsImageSrc
                                    | async
                            "
                        />
                    </div>
                    <div
                        class="col justify-content-md-center"
                        style="display: flex"
                    >
                        <img
                            class="img-fluid img-landing align-self-end"
                            style="height: 40%; margin-right: -30px"
                            [attr.src]="
                                'landing-page-users-circle'
                                    | cmsImageSrc
                                    | async
                            "
                        />
                        <img
                            class="img-fluid img-landing"
                            style="height: 40%"
                            [attr.src]="
                                'landing-page-smile-solar-panels'
                                    | cmsImageSrc
                                    | async
                            "
                        />
                        <img
                            class="img-fluid img-landing align-self-end"
                            style="height: 80%"
                            [attr.src]="
                                'landing-page-smile' | cmsImageSrc | async
                            "
                        />
                    </div>
                </div>
                <!-- titles -->
                <div class="row">
                    <div class="col">
                        <h5 class="my-3">
                            <span class="row justify-content-center">
                                {{
                                    'landing-page-house-title' | cmsText | async
                                }}
                            </span>
                        </h5>
                    </div>
                    <div class="col">
                        <h5 class="my-3">
                            <span class="row justify-content-center">
                                {{
                                    'landing-page-users-title' | cmsText | async
                                }}
                            </span>
                        </h5>
                    </div>
                    <div class="col">
                        <h5 class="my-3">
                            <span class="row justify-content-center">
                                {{
                                    'landing-page-smile-title' | cmsText | async
                                }}
                            </span>
                        </h5>
                    </div>
                </div>
                <!-- descriptions -->
                <div class="row">
                    <div
                        class="col"
                        style="display: flex; justify-content: center"
                    >
                        <p class="text-center w-75">
                            {{
                                'landing-page-house-description'
                                    | cmsText
                                    | async
                            }}
                        </p>
                    </div>
                    <div
                        class="col"
                        style="display: flex; justify-content: center"
                    >
                        <p class="text-center w-75">
                            {{
                                'landing-page-users-description'
                                    | cmsText
                                    | async
                            }}
                        </p>
                    </div>
                    <div
                        class="col"
                        style="display: flex; justify-content: center"
                    >
                        <p class="text-center w-75">
                            {{
                                'landing-page-smile-description'
                                    | cmsText
                                    | async
                            }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row row-blue-background">
            <!-- placeholder for the replacement for the footer
            needs to be checked with design why the footer looks different than the rest of our pages -->
            <hr style="height: 50px" />
        </div>
    </div>
</div>
