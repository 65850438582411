import { Component, Input } from '@angular/core';
import { Supplier } from '@common/model/supplier.base';
import { CommunityService } from '@common/services/community.service';
import { Registration } from '@spnl/model/registration';

@Component({
    selector: 'app-cancel-sidebar',
    templateUrl: './cancel-sidebar.component.html',
})
export class CancelSidebarComponent {
    @Input()
    registration: Registration;
    @Input()
    supplier: Supplier;

    get communityCode(): string {
        return this.communityService.communityCode;
    }

    constructor(private communityService: CommunityService) {}

    get supplierLogo(): string {
        return `proposal-logo-${this.supplier.code}`;
    }
}
