import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { API_URL, BUSINESS } from '@common/app/app.config';
import { combineLatest, from, Observable, of, throwError } from 'rxjs';
import { catchError, flatMap, map, switchMap, take, tap } from 'rxjs/operators';

import { AuthService } from '@common/services/auth.service';
import { CommunityService } from '@common/services/community.service';
import { RegistrationServiceBase } from '@common/services/registration.service.base';
import { Registration } from '@spnl/model/registration';
import { ContentService } from '@common/services/content.service';

export class BankAccountValidatorResponse {
    valid?: boolean;
    responseReceived: boolean;
}

@Injectable()
export class RegistrationService extends RegistrationServiceBase<Registration> {
    constructor(
        private router: Router,
        private contentService: ContentService,
        @Inject(BUSINESS) business: string,
        http: HttpClient,
        @Inject(API_URL) baseUrl: string,
        communityService: CommunityService,
        auth: AuthService,
    ) {
        super(business, http, baseUrl, communityService, auth);
    }

    get(id: string): Observable<Registration> {
        return super.get(id).pipe(
            map((dto) => {
                if (dto?.auction?.code) {
                    this.contentService.load(dto.auction.code);
                }

                return new Registration(dto);
            }),
            catchError((e) => {
                const unauthorizedStatuses = [401, 403, 404];
                if (
                    unauthorizedStatuses.find((status) => status === e?.status)
                ) {
                    // Most probably, the registration exists but you don't have access to it
                    return this.communityService.communityCode$.pipe(
                        take(1),
                        flatMap((communityCode) =>
                            from(
                                this.router.navigate([
                                    communityCode,
                                    'unauthorized',
                                    id,
                                ]),
                            ),
                        ),
                        map(() => new Registration()),
                    );
                }

                return throwError(e);
            }),
        );
    }

    add(registration: Registration): Observable<string> {
        return this.http
            .post<string>(this.url, registration, { observe: 'response' })
            .pipe(
                switchMap((response) => {
                    const registrationId = response.body;
                    return this.auth
                        .loginAsParticipant(response)
                        .pipe(switchMap((_) => of(registrationId)));
                }),
            );
    }

    get enableRegistrationFlow$(): Observable<boolean> {
        const result$ = combineLatest([
            this.communityService.community$,
            this.communityService.currentAuctionOpenForNewRegistrations$,
        ]);

        return result$.pipe(
            map(([community, currentAuctionOpenForNewRegistrations]) => {
                return (
                    !community.preventInterestedIndividuals ||
                    currentAuctionOpenForNewRegistrations
                );
            }),
        );
    }

    saveToSessionStorage(registration: Registration): Observable<Registration> {
        const result = this.getSessionStorageKey().pipe(
            tap((key) =>
                sessionStorage.setItem(key, JSON.stringify(registration)),
            ),
        );
        result.subscribe((_) => {
            // no-op subscription to make sure the tap will occur
            // even if no caller of saveToSessionStorage subs
        });
        return result.pipe(map((_) => registration));
    }

    loadFromSessionStorage(): Observable<Registration> {
        return super
            .loadFromSessionStorage()
            .pipe(map((dto) => (dto ? new Registration(dto) : null)));
    }
}
