export class Profile {
    id: string;
    registrations: ProfileRegistrationData[] = [];

    constructor(dto: any = {}) {
        Object.assign(this, dto);
    }
}

export class ProfileRegistrationData {
    id: string;

    constructor(dto: any = {}) {
        Object.assign(this, dto);
    }
}

export class ProfilePreference {
    business: string;
    community: string;
    registrationId: string;
    name: string;
    isOptIn: boolean;
    displayedPhrasing: string;

    constructor(dto: any = {}) {
        Object.assign(this, dto);
    }
}

export class ProfilePreferences {
    preferences: ProfilePreference[];

    constructor(dto: any = {}) {
        Object.assign(this, dto);
    }
}

export class GroupedPreferences {
    forProfile: ProfilePreference[];
    forBusiness: Record<string, ProfilePreference[]>;
    forRegistration: ProfilePreference[];

    constructor(dto: any = {}) {
        this.forProfile = [];
        this.forBusiness = {};
        this.forRegistration = [];
        Object.assign(this, dto);
    }
}
