import { Injectable } from '@angular/core';
import { Content } from '@common/model/content';
import { ContentFallbackServiceBase } from '@common/services/content-fallback.service.base';
import { cmsFallback } from '@spnl/cms-fallback';

@Injectable()
export class ContentFallbackService extends ContentFallbackServiceBase {
    getContentFallback(): Content[] {
        return cmsFallback;
    }
}
