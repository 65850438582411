<h6>{{ 'proposal-your-data-contact-header' | cmsText | async }}</h6>
<ul class="list-unstyled text-muted nls_protected">
    <li>
        <span class="d-flex">{{
            registration.contact | fullName | async
        }}</span>
        {{ registration.contact.street }}
        {{ registration.contact.houseNr }}
        <span *ngIf="registration.contact.houseNrSuffix">
            {{ registration.contact.houseNrSuffix }}
        </span>
    </li>
    <li>{{ registration.contact.zip }} {{ registration.contact.city }}</li>
    <li>{{ registration.contact.phone }}</li>
    <button
        *ngIf="!registration.auction.finished"
        class="btn btn-link text-primary p-0 fw-bold"
        id="changeContactDataBtn"
        (click)="editContactData()"
    >
        <i class="fa fa-arrow-right me-2" aria-hidden="true"></i>
        {{ 'edit-button' | cmsText | async }}
    </button>
</ul>
<hr class="my-3 d-block d-sm-none" />
