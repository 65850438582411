import { Component, Input, OnInit } from '@angular/core';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

import moment from 'moment';
import { Registration } from '../../model/registration';
import { Seminar, SeminarType } from '../../model/seminar';
import { RegistrationService } from '../../services/registration.service';
import { DestroyableBase } from '../destroyable/destroyable.component';
import { RegistrationApiService } from '@spnl/services/registration-api.service';

@Component({
    selector: 'app-seminar-card',
    templateUrl: './seminar-card.component.html',
})
export class SeminarCardComponent extends DestroyableBase implements OnInit {
    // TODO: Deduplicate this with SpBe (and others)

    @Input()
    seminar: Seminar;
    @Input()
    active = false;
    @Input()
    registration: Registration;

    seminarType = SeminarType;
    seminarEndTime: Date;

    get hasEndTime(): boolean {
        return (
            this.seminarEndTime !== null && this.seminarEndTime !== undefined
        );
    }

    constructor(
        private registrationService: RegistrationService,
        private registrationApiService: RegistrationApiService,
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.seminar && this.seminar.endTime) {
            const m = moment.duration(this.seminar.endTime);
            this.seminarEndTime = new Date(0, 0, 0, m.hours(), m.minutes());
        }
    }

    public participate(seminarId: string): void {
        this.registrationApiService
            .participateInSeminar(this.registration, seminarId)
            .pipe(
                takeUntil(this.destroyed$),
                switchMap((_) => {
                    return this.registrationService
                        .get(this.registration.id)
                        .pipe(
                            tap(
                                (theOne) =>
                                    (this.registration.seminarId =
                                        theOne.seminarId),
                            ),
                        );
                }),
            )
            .subscribe();
    }

    public unsubscribe(): void {
        this.registrationApiService
            .unsubscribeFromSeminar(this.registration)
            .pipe(
                takeUntil(this.destroyed$),
                switchMap((_) => {
                    return this.registrationService
                        .get(this.registration.id)
                        .pipe(
                            tap(
                                (theOne) =>
                                    (this.registration.seminarId =
                                        theOne.seminarId),
                            ),
                        );
                }),
            )
            .subscribe();
    }
}
