import { Component, Input } from '@angular/core';
import { RegisterStep } from '@spnl/model/register-step.enum';
import { Registration } from '@spnl/model/registration';
import { RegisterAnalyticsService } from '@spnl/services/register-analytics.service';
import {
    OnContinue,
    RegisterLocationService,
    Substep,
} from '@spnl/services/register-location.service';

@Component({
    selector: 'app-proposal-contact-data',
    templateUrl: './proposal-contact-data.component.html',
})
export class ProposalContactDataComponent {
    @Input()
    registration: Registration;

    constructor(
        private locationService: RegisterLocationService,
        private analytics: RegisterAnalyticsService,
    ) {}

    editContactData(): void {
        this.analytics.logEvent(this.registration, 'change-person-data');
        this.locationService.goTo(
            RegisterStep.Person,
            new Substep.None(),
            OnContinue.BackToReferrerStep,
        );
    }
}
