import { Component, OnInit } from '@angular/core';
import { Registration } from '@spnl/model/registration';
import { RegisterStateService } from '@spnl/services/register-state.service';
import { RegisterStoreService } from '@spnl/services/register-store.service';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-register-payment',
    templateUrl: './payment.component.html',
})
export class PaymentComponent implements OnInit {
    // Will be set to false when registration is loaded
    loading = true;

    get registration(): Registration {
        return this.registerStore.registration;
    }

    get auctionFinished(): boolean {
        return this.registration.auction?.finished;
    }

    get afterPaymentDeadline(): boolean {
        return this.registerStateService.paymentIsClosed(this.registration);
    }

    constructor(
        private registerStore: RegisterStoreService,
        private registerStateService: RegisterStateService,
    ) {}

    ngOnInit(): void {
        this.registerStore.registrationLoaded$
            .pipe(first())
            .subscribe(() => (this.loading = false));
    }
}
