import { UntypedFormGroup } from '@angular/forms';
import { ExcludedReason } from '@spnl/model/registration';
import {
    ExclusionContext,
    ExclusionService,
} from '@spnl/services/exclusion.service';

export interface RoofStepFormGroup extends UntypedFormGroup {
    onSubmit: () => void;
    getExclusionContext: () => ExclusionContext;
}

export function getExclusionContextOfVisibleSteps(
    form: UntypedFormGroup,
): ExclusionContext {
    const aggregateContext = {};
    for (const key of Object.keys(form.controls)) {
        const subForm = form.controls[key] as RoofStepFormGroup;
        const context = subForm.getExclusionContext
            ? subForm.getExclusionContext()
            : {};

        Object.assign(aggregateContext, context);
    }

    return aggregateContext;
}

export function excludedReason(form: UntypedFormGroup): ExcludedReason | null {
    return ExclusionService.getExcludedReason(
        getExclusionContextOfVisibleSteps(form),
    );
}

export function shouldBeExcluded(form: UntypedFormGroup): boolean {
    return excludedReason(form) !== null;
}
