import { Component, Input, Inject } from '@angular/core';
import { BUSINESS } from '@common/app/app.config';
import { SurveyLinkStatus } from './survey-link-status';
import { ContentService } from '@common/services/content.service';
import { takeFirst } from '@common/observable-helpers';

@Component({
    selector: 'app-register-survey-cta',
    templateUrl: './survey-cta.component.html',
    styleUrls: ['./survey-cta.component.scss'],
})
export class SurveyCtaComponent {
    @Input()
    registrationNumber: string;

    @Input()
    auctionCode: string;

    @Input()
    cmsKey: string;

    @Input()
    supplierName?: string;

    @Input()
    registrationStatus?: string;

    get isMobileDevice() {
        return window.innerWidth <= 1200 ? true : false;
    }

    get cmsTextVariables() {
        return {
            registrationNumber: this.registrationNumber ?? '',
            auctionCode: this.auctionCode ?? '',
            registrationStatus: this.registrationStatus ?? '',
            supplier: this.supplierName ?? '',
        };
    }

    private readonly defaultSurveyLink: string =
        'https://survey.usabilla.com/live/s/62bd8ddde41fea2a5d11694f';

    constructor(
        @Inject(BUSINESS) public business: string,
        private contentService: ContentService,
    ) {}

    redirectToSurveyPage() {
        const redirectUrl = this.isBLforNLdesign(this.business)
            ? this.getNlLinks()
            : this.getDefaultLinks();

        window.open(redirectUrl, '_blank');
    }

    isBLforNLdesign(business: string): boolean {
        const businessesWithNewDesign = ['hpnl', 'spnl', 'ennl'];

        return businessesWithNewDesign.includes(business);
    }

    getRegistartionStatusLink(): string {
        switch (this.registrationStatus.toLocaleLowerCase()) {
            case 'created':
            case 'confirmed':
            case 'undecided':
                return SurveyLinkStatus.Registration;
            case 'accepted':
                return SurveyLinkStatus.Acceptation;
            case 'declined':
            case 'cancelled':
            case 'excluded':
                return SurveyLinkStatus.Declined;
            default:
                return SurveyLinkStatus.Registration;
        }
    }

    getNlLinks(): string {
        return takeFirst(
            this.contentService.transform(this.getRegistartionStatusLink(), [
                this.cmsTextVariables,
            ]),
        );
    }

    getDefaultLinks(): string {
        return (
            this.defaultSurveyLink +
            '?registration=' +
            this.registrationNumber +
            '&auction=' +
            this.auctionCode +
            '&supplier=' +
            this.supplierName +
            '&status=' +
            this.registrationStatus
        );
    }
}
