import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
    selector: '[appFocusFirstExcludedField]',
})
export class FocusFirstExcludedFieldDirective {

    constructor(private el: ElementRef) { }

    @HostListener('submit')
    onFormSubmit() {
        const invalidElements = this.el.nativeElement.querySelectorAll('.excluded-scroll-selector');
        if (invalidElements.length > 0) {
            let firstInvalidElement = invalidElements[0];

            // take first visible element from list
            for (let i = 0; i < invalidElements.length; i++) {
                if (invalidElements[i].offsetParent !== null) {
                    firstInvalidElement = invalidElements[i];
                    break;
                }
            }

            setTimeout(() => {
                firstInvalidElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
            }, 100);
        }
    }
}
