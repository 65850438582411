import { RegisterConfig } from '@spnl/components/register/register.config';
import { SolarInstallation } from './solar-installation';
import { SolarPanelType } from './solar-panel-type';

export class SolarInstallations {
    standardPanels: SolarInstallation[];
    premiumPanels: SolarInstallation[];
    optimalStandardAmount: number;
    optimalPremiumAmount: number;
    standardSolarPanelType: SolarPanelType;
    premiumSolarPanelType: SolarPanelType;
    extraChargePerPanelForParallel: number;

    constructor(data: any = {}) {
        Object.assign(this, data);
    }

    for(premium: boolean, numberOfPanels: number): SolarInstallation {
        if (
            numberOfPanels < RegisterConfig.minNumberOfPanels ||
            numberOfPanels > RegisterConfig.maxNumberOfPanels
        ) {
            return null;
        }

        return premium
            ? this.premiumPanels[
                  numberOfPanels - RegisterConfig.minNumberOfPanels
              ]
            : this.standardPanels[
                  numberOfPanels - RegisterConfig.minNumberOfPanels
              ];
    }

    priceFor(premium: boolean, numberOfPanels: number): number {
        return this.for(premium, numberOfPanels)?.price;
    }

    capacityOf(premium: boolean, numberOfPanels: number): number {
        return this.for(premium, numberOfPanels)?.capacity;
    }

    savingOf(premium: boolean, numberOfPanels: number): number {
        return this.for(premium, numberOfPanels)?.firstYearSaving;
    }

    paybackTimeOf(premium: boolean, numberOfPanels: number): number {
        return this.for(premium, numberOfPanels)?.paybackTime;
    }

    co2ReductionKgOf(premium: boolean, numberOfPanels: number): number {
        return this.for(premium, numberOfPanels)?.co2ReductionKg;
    }

    co2ReductionOfAverageOf(premium: boolean, numberOfPanels: number): number {
        return this.for(premium, numberOfPanels)?.co2ReductionOfAverage;
    }

    switchToSingleMeterWhen(premium: boolean, numberOfPanels: number): boolean {
        return this.for(premium, numberOfPanels)?.switchToSingleMeter;
    }
}
