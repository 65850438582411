import { Component, Input, OnInit } from '@angular/core';
import { ContentService } from '@common/services/content.service';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SafeUrl } from '@angular/platform-browser';
import { SocialLinkGeneratorService } from '@common/services/social-link-generator.service';

@Component({
    selector: 'app-share-auction-social-media-cta',
    templateUrl: './share-auction-social-media-cta.component.html',
})
export class ShareAuctionSocialMediaCtaComponent implements OnInit {
    @Input()
    messageCmsKey: string;

    @Input()
    includeWhatsapp: boolean = false;

    @Input()
    includeEmail: boolean = false;

    @Input()
    whatsappCmsKey: string = '';

    @Input()
    emailBodyCmsKey: string = '';

    @Input()
    emailSubjectCmsKey: string = '';

    whatsappMessageBody: string = '';
    emailMessageLink: string = '';

    public whatsappLink: SafeUrl;

    constructor(
        private contentService: ContentService,
        private socialLinkGeneratorService: SocialLinkGeneratorService,
    ) {}

    public ngOnInit(): void {
        if (this.includeWhatsapp && !!this.whatsappCmsKey) {
            this.contentService
                .get(this.whatsappCmsKey)
                .pipe(filter((cmsValue) => cmsValue && cmsValue.value))
                .subscribe((cmsValue) => {
                    this.whatsappLink =
                        this.socialLinkGeneratorService.getMobileWhatsappLinkSafeUrl(
                            cmsValue.value,
                        );
                });
        }

        if (this.includeEmail && !!this.emailBodyCmsKey) {
            const bodyPromise = this.contentService.get(this.emailBodyCmsKey);
            const headerPromise = this.contentService.get(
                this.emailSubjectCmsKey,
            );

            combineLatest([bodyPromise, headerPromise])
                .pipe(
                    filter(
                        (cmsValueBody) =>
                            cmsValueBody[0] &&
                            cmsValueBody[0].value &&
                            cmsValueBody[1] &&
                            cmsValueBody[1].value,
                    ),
                )
                .subscribe(([cmsValueBody, cmsValueHeader]) => {
                    this.emailMessageLink =
                        this.socialLinkGeneratorService.getEmailLinkString(
                            cmsValueBody.value,
                            cmsValueHeader.value,
                        );
                });
        }
    }

    public getWhatsAppLink() {
        return this.whatsappLink;
    }
}
