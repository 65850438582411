import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { DestroyableBase } from '../destroyable/destroyable.component';

@Component({
    selector: 'app-tooltip-on-word',
    templateUrl: './tooltip-on-word.component.html',
})
export class TooltipOnWordComponent extends DestroyableBase {
    @Input() cmsKeyTooltipHeader: string;
    @Input() cmsKeyTooltipBody: string;
    @Input() cmsParamsTooltipBody = [];
    @Input() cmsKeyWord: string;
    @Input() cmsParamsWord = {};

    constructor(private modalService: NgbModal) {
        super();
    }

    openModal(content: any): void {
        const modal = this.modalService.open(content, {
            centered: true,
            ariaLabelledBy: 'tooltipModalLabel',
        });

        modal.shown.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            const nativeElement =
                document.querySelector<HTMLElement>('ngb-modal-window');
            nativeElement.focus();
        });
    }
}
