<h4 class="mb-3">{{ 'overview-faq-title' | cmsText | async }}</h4>

<ngb-accordion #accordion [closeOthers]="false" activeIds="">
    <ngb-panel *ngFor="let item of faqItems; let i = index">
        <ng-template ngbPanelTitle>
            <i class="fa fa-chevron-circle-down m-1"></i>
            <span class="ms-3">{{
                itemTitleCmsKey(item) | cmsText | async
            }}</span>
        </ng-template>
        <ng-template ngbPanelContent>
            <div>
                <span
                    [innerHTML]="itemTextCmsKey(item) | cmsText | async"
                ></span>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
