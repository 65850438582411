import { AfterViewInit, Component } from '@angular/core';
import { RegisterStep } from '@spnl/model/register-step.enum';
import { DigitalEventService } from '@spnl/services/digital-event-queue.service';
import {
    RegisterLocationService,
    Substep,
} from '@spnl/services/register-location.service';

@Component({
    selector: 'app-register-overview-interested-individual',
    templateUrl: './interested-individual.component.html',
})
export class InterestedIndividualComponent {
    get faqItems(): string[] {
        return ['next-scheme', 'how-does-it-work', 'yield'];
    }

    constructor(
        private locationService: RegisterLocationService,
    ) {}

    editPersonDetails(): void {
        this.locationService.goTo(RegisterStep.Person, new Substep.None());
    }
}
