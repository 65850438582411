import { Component, OnDestroy, OnInit } from '@angular/core';
import { isNullOrUndefined } from '@common/util';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Community } from '@common/model/community';
import { CommunityService } from '@common/services/community.service';
import { RegisterStep } from '@spnl/model/register-step.enum';
import { RoofStep } from '@spnl/model/roof-step.model';
import { RegistrationButtonContentService } from '../../../services/registration-button-content.service';
import { RegistrationService } from '../../../services/registration.service';

@Component({
    selector: 'app-cta-register-or-rooftest',
    templateUrl: './cta-register-or-rooftest.component.html',
})
export class CtaRegisterOrRooftestComponent implements OnInit, OnDestroy {
    private destroyed$: Subject<void> = new Subject<void>();

    public hasCommunity: boolean;
    public community: Community;
    public disableFlow: boolean;
    public showRoofTest: boolean;

    public roofButtonKey: string;
    public registerButtonKey: string;
    public roofTitleKey: string;

    initialStep = RoofStep.LivingSituation;

    // Suppress linting, leaving this as is for legacy reasons (not worth the risk of refactoring)
    RegisterStep: typeof RegisterStep = RegisterStep;

    constructor(
        private registrationService: RegistrationService,
        private registrationButtonContentService: RegistrationButtonContentService,
        private communityService: CommunityService,
    ) {}

    ngOnInit(): void {
        this.communityService.community$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((community) => {
                this.hasCommunity = !isNullOrUndefined(community.code);
                this.community = community;
            });

        this.communityService.currentAuctionOpenForNewRegistrations$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((openForRegs) => (this.showRoofTest = openForRegs));

        this.registrationService.enableRegistrationFlow$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((enableFlow) => (this.disableFlow = !enableFlow));

        this.registrationButtonContentService
            .getRegisterButtonCmsKey()
            .pipe(takeUntil(this.destroyed$))
            .subscribe((key) => (this.registerButtonKey = key));

        this.registrationButtonContentService
            .getRegisterRoofButtonCmsKey()
            .pipe(takeUntil(this.destroyed$))
            .subscribe((key) => (this.roofButtonKey = key));

        this.registrationButtonContentService
            .getRoofButtonTitleCmsKey()
            .pipe(takeUntil(this.destroyed$))
            .subscribe((key) => (this.roofTitleKey = key));
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
