<ng-container *ngIf="!loading">
    <h3 class="my-3">{{ 'cancelled-header' | cmsText | async }}</h3>
    <hr class="my-3" />

    <app-hero
        heroImageKey="hero-image-overview"
        heroImageClass="hero-image-medium mb-3"
    ></app-hero>

    <span [innerHTML]="'cancelled-text' | cmsText | async"></span>

    <app-register-warning
        *ngIf="!canRevertCancellation"
        [warningKey]="'cancelled-cannot-revert-cancellation-text'"
    >
    </app-register-warning>

    <hr class="my-3" />

    <div class="d-flex justify-content-between flex-wrap mt-3">
        <button
            *ngIf="canRevertCancellation"
            class="btn btn-primary px-5 mt-2 mb-2"
            id="revertCancellationBtn"
            type="button"
            (click)="revertCancellation()"
        >
            {{ 'cancelled-revert-cancellation-btn-text' | cmsText | async }}
        </button>
        <a
            class="btn btn-primary px-5 mt-2 mb-2"
            href="https://www.eigenhuis.nl/diensten/"
        >
            {{ 'cancelled-veh-navigation-text' | cmsText | async }}
        </a>
    </div>
</ng-container>

<app-loading-spinner *ngIf="loading" size="large"></app-loading-spinner>
