import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ControlWithErrors } from '@common/infrastructure/form-tools';
import { KenalyticsService } from '@common/services/kenalytics.service';
import { MonitoringService } from '@common/services/monitoring.service';
import { PendingChangesService } from '@common/services/pending-changes.service';
import { VwoService } from '@common/services/vwo.service';
import { isNullOrUndefined } from '@common/util';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DestroyableBase } from '@spnl/components/destroyable/destroyable.component';
import { RegisterStep } from '@spnl/model/register-step.enum';
import { Registration } from '@spnl/model/registration';
import { DigitalEventService } from '@spnl/services/digital-event-queue.service';
import { RegisterLocationService } from '@spnl/services/register-location.service';
import { RegisterSaveService } from '@spnl/services/register-save.service';
import { RegisterStateService } from '@spnl/services/register-state.service';
import { RegisterStoreService } from '@spnl/services/register-store.service';
import { RegistrationService } from '@spnl/services/registration.service';
import { SolarApiService } from '@spnl/services/solar-api.service';
import { take } from 'rxjs/operators';
import { ProposalDeclineModalComponent } from './proposal-decline-modal/proposal-decline-modal.component';
import { personalizedGreeting } from '@country/nl/helpers/contact.helpers';

@Component({
    selector: 'app-register-proposal',
    templateUrl: './proposal.component.html',
})
export class ProposalComponent
    extends DestroyableBase
    implements OnInit, OnDestroy, AfterViewInit
{
    // Race condition: we sometimes get the cms text this key is used for
    // before this value is set in ngOnInit, so we need a default value
    numberOfAcceptants = 0;

    form: UntypedFormGroup;
    submitRequested = false;

    // This will be set to false once the registration is loaded
    loading = true;

    get registration(): Registration {
        return this.registerStore.registration;
    }

    get numberOfPanelsChosen(): number {
        return this.numberOfPanels?.value;
    }

    get supplierLogo(): string {
        return `proposal-logo-${this.registration?.proposal?.supplier?.code}`;
    }

    get supplierCarousel(): string {
        return `proposal-carousel-${this.registration?.proposal?.supplier?.code}`;
    }

    get supplierLongWaitTime(): string {
        return `long-wait-time-label-${this.registration?.proposal?.supplier?.code}`;
    }

    get inAcceptanceGracePeriod(): boolean {
        return this.registerStateService.inAcceptanceGracePeriod(
            this.registration,
        );
    }
    get personalizedGreeting(): string {
        return personalizedGreeting(
            this.registration.contact.salutation,
            this.registration.contact.lastName,
            this.registration.contact.insertion,
        );
    }

    private get offer(): UntypedFormGroup {
        return this.form?.get('offer') as UntypedFormGroup;
    }
    private get notesToSupplierGroup(): UntypedFormGroup {
        return this.form?.get('notesToSupplier') as UntypedFormGroup;
    }
    private get supplierConditions(): UntypedFormGroup {
        return this.form?.get('supplierConditions') as UntypedFormGroup;
    }

    private get agreesToSupplierConditions(): ControlWithErrors {
        return this.supplierConditions?.get('agreesToSupplierConditions');
    }
    private get notesToSupplier(): ControlWithErrors {
        return this.notesToSupplierGroup?.get('notesToSupplier');
    }
    private get numberOfPanels(): ControlWithErrors {
        return this.offer?.get('numberOfPanels');
    }
    private get isInterestedInParellel(): ControlWithErrors {
        return this.offer?.get('isInterestedInParallelInstallation');
    }

    constructor(
        private registrationService: RegistrationService,
        private registerSaveService: RegisterSaveService,
        private registerStore: RegisterStoreService,
        private pendingChangesService: PendingChangesService,
        private modalService: NgbModal,
        private kenalyticsService: KenalyticsService,
        private vwoService: VwoService,
        private solarApiService: SolarApiService,
        private digitalEventService: DigitalEventService,
        private monitoringService: MonitoringService,
        private locationService: RegisterLocationService,
        private registerStateService: RegisterStateService,
    ) {
        super();
    }

    openDeclinePopup(): void {
        const modal = this.modalService.open(ProposalDeclineModalComponent);
        modal.componentInstance.registration = this.registration;
        modal.result
            .then(() => this.registerSaveService.saveAndContinue()) // Proposal declined
            .catch(() => {}); // Modal dismissed
    }

    ngAfterViewInit(): void {
        this.digitalEventService.push('persoonlijk aanbod pagina');
        this.vwoService.push(RegisterStep.Proposal);
    }

    ngOnInit(): void {
        this.form = new UntypedFormGroup({});

        this.monitoringService.logEvent('proposal-component-initializing', {
            id: this.registration?.id,
            url: location.href,
        });

        this.registerStore.registrationLoaded$.pipe(take(1)).subscribe(() => {
            this.monitoringService.logEvent(
                'proposal-component-registration-loaded',
                {
                    id: this.registration?.id,
                    url: location.href,
                },
            );

            // TODO: (SPNL-776) Remove/improve after issue resolved
            if (
                isNullOrUndefined(this.registration.id) ||
                this.registration.id === ''
            ) {
                this.locationService.goToErrorPage(RegisterStep.Proposal);
            } else {
                this.solarApiService
                    .getProposals(this.registration.id)
                    .subscribe(
                        (installations) =>
                            (this.registerStore.installations = installations),
                    );

                this.registrationService
                    .countAcceptants(this.registration.auction.id)
                    .subscribe(
                        (acceptants) => (this.numberOfAcceptants = acceptants),
                    );

                this.kenalyticsService.postPageView(
                    this.registration.id,
                    'register/proposal',
                );

                this.registerSaveService.setProposalVisited(
                    this.registration.id,
                );

                this.loading = false;
            }
        });
    }

    onSubmit(): void {
        this.submitRequested = true;
        if (this.form.valid) {
            this.registration.product.chosenSolarPanelType =
                this.registerStore.installations.premiumSolarPanelType;

            this.registration.product.chosenNumberOfPanels =
                this.numberOfPanels.value;
            this.registration.product.isInterestedInParallel =
                this.isInterestedInParellel.value;

            this.registrationService.update(this.registration).subscribe(
                () => {
                    this.registration.proposalAccepted = true;
                    this.registration.decisionComplete = true;
                    this.registration.decision.agreesToSupplierConditions =
                        this.agreesToSupplierConditions.value;
                    this.registration.decision.notesToSupplier =
                        this.notesToSupplier.value;
                    this.registerSaveService.saveAndContinue();
                },
                (error) => {
                    // do something
                    console.error(error);
                },
            );
        }
    }

    ngOnDestroy(): void {
        // Save the notes to the supplier if the user leaves the component
        if (this.notesToSupplier?.touched) {
            this.registration.decision.notesToSupplier =
                this.notesToSupplier.value;
            this.registrationService
                .update(this.registration)
                .subscribe(
                    () => (this.pendingChangesService.pendingChanges = false),
                );
        }
    }
}
