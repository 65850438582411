<div
    class="alert alert-danger mt-1 d-flex align-items-stretch order-last excluded-scroll-selector"
>
    <i class="fa fa-exclamation-circle me-3 mt-1"></i>
    <div>
        <span
            class="d-block"
            [innerHTML]="errorKey | cmsText : [cmsParams] | async"
        ></span>
        <ng-content></ng-content>
    </div>
</div>
