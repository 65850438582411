<app-hero
    headerKey="notfound-header"
    heroImageKey="hero-image-secondary"
></app-hero>
<div class="fluid-container bg-highlight-one pt-2 pb-5">
    <div class="container">
        <div class="card shadow border-0 mb-1">
            <div class="card-body">
                <div
                    [innerHTML]="
                        'registration-not-found-intro' | cmsText | async
                    "
                ></div>
                <div class="col-12 text-center">
                    <button
                        class="btn btn-primary mt-3 px-5"
                        (click)="requestParticipantLink()"
                        *ngIf="!sendLinkSuccess"
                        [disabled]="sendingLink"
                    >
                        <i class="fa fa-envelope me-2"></i>
                        {{ 'send-link-to-registration-btn' | cmsText | async }}
                        <i
                            *ngIf="sendingLink"
                            class="fa fa-spinner fa-spin ms-2 me-1"
                        ></i>
                    </button>
                    <div
                        class="alert alert-success mt-2"
                        *ngIf="sendLinkSuccess"
                    >
                        {{ 'send-participant-link-success' | cmsText | async }}
                    </div>
                    <app-register-error
                        errorKey="send-participant-link-error"
                        class="d-block mt-2"
                        *ngIf="sendLinkError"
                    >
                    </app-register-error>
                </div>
                <div
                    [innerHTML]="
                        'registration-not-found-outro' | cmsText | async
                    "
                    class="mt-3"
                ></div>
                <div>
                    <p>
                        <a [routerLink]="['/', communityCode, 'contact']">
                            {{
                                'registration-not-found-contact-link-text'
                                    | cmsText
                                    | async
                            }}
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
