import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-loading-spinner',
    templateUrl: './loading-spinner.component.html',
})
export class LoadingSpinnerComponent {
    @Input()
    size = 'small';

    @Input()
    cssClass: string;

    get cssClasses(): string {
        const base = 'fa fa-spinner fa-spin ';
        return this.size === 'large'
            ? `${base} fa-2x ${this.cssClass}`
            : `${base} ${this.cssClass}`;
    }
}
