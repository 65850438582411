import { Product, RoofPitch } from '@spnl/model/registration';
import { RoofStep } from '@spnl/model/roof-step.model';

export class RoofDetailsStepService {
    static get allSteps(): RoofStep[] {
        return RoofStep.values;
    }

    static getLatestStep(product: Product): RoofStep {
        if (product.electricityConsumptionPerYear) {
            return RoofStep.Yield;
        } else if (
            product.hasRoofObjects !== undefined &&
            product.hasRoofShadow !== undefined
        ) {
            return RoofStep.Energy;
        } else if (
            product.numberOfPanelsThatFitOnRoof ||
            (product.roofWidth && product.roofLength)
        ) {
            return RoofStep.Objects;
        } else if (
            product.roofPitch &&
            (product.roofPitch === RoofPitch.Flat || product.roofOrientation) &&
            product.roofMaterial &&
            product.roofAge &&
            product.numberOfStories
        ) {
            return RoofStep.Size;
        } else if (product.currentSolarPanelSituation &&
             !product.isHomeOwnerAssociationMember) {
            return RoofStep.Type;
        }
        else{
            return RoofStep.LivingSituation;
        }
    }
}
