<app-hero
    headerKey="user-changed-header"
    heroImageKey="hero-image-secondary"
></app-hero>
<div class="fluid-container bg-highlight-one pt-2 pb-5">
    <div class="container">
        <div class="card shadow border-0 mb-1">
            <div class="card-body">
                <div [innerHTML]="'user-changed-body' | cmsText | async"></div>
            </div>
        </div>
    </div>
</div>
