import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DestroyableBase } from '../destroyable/destroyable.component';
import { switchMap, take, takeUntil } from 'rxjs/operators';

import { RegisterEffectsService } from '@spnl/services/register-effects.service';
import { RegistrationService } from '@spnl/services/registration.service';

@Component({
    selector: 'app-register-redirect-preference-center',
    template: `
        <div class="mb-3">
            {{ 'loading-message' | cmsText | async }}
        </div>`,
    providers: [RegisterEffectsService],
})
export class RedirectToPreferenceCenterComponent
    extends DestroyableBase
    implements OnInit, OnDestroy
{
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private registrationService: RegistrationService
    ) {
        super();
    }

    ngOnInit(): void {
        this.route.params.pipe(take(1)).subscribe((params) => {
            const id = params['registration-id'];

            if (id) {
                this.redirectById(id);
            }
        });
    }

    private redirectById(id: string) {
        this.registrationService
            .get(id)
            .pipe(
                switchMap((r) =>
                    this.registrationService.saveToSessionStorage(r),
                ),
                takeUntil(this.destroyed$)
            )
            .subscribe(
                (_) => {
                    this.router.navigate(['veh/contact-voorkeuren']);
                }
            );
    }
}