import { AfterViewInit, Component } from '@angular/core';
import { RegisterStep } from '@spnl/model/register-step.enum';
import { Registration } from '@spnl/model/registration';
import { DigitalEventService } from '@spnl/services/digital-event-queue.service';
import {
    RegisterLocationService,
    Substep,
} from '@spnl/services/register-location.service';
import { RegisterStoreService } from '@spnl/services/register-store.service';

@Component({
    selector: 'app-register-welcome',
    templateUrl: './welcome.component.html',
})
export class WelcomeComponent implements AfterViewInit {
    get registration(): Registration {
        return this.registerStore.registration;
    }

    faqItems = [
        'why-participate',
        'supplier-qualification',
        'yield',
        'faq-prosumer-tariff',
    ];

    constructor(
        private registerLocationService: RegisterLocationService,
        private registerStore: RegisterStoreService,
        private digitalEventService: DigitalEventService,
    ) {}

    ngAfterViewInit(): void {
        if (this.registration.emailConfirmed){
            this.digitalEventService.push('welkomstpagina');
        }
    }

    get showSubscriptionCompletePage(): boolean {
        return !this.registration.emailConfirmed;
    }

    get showEmailConfirmedPage(): boolean {
        return this.registration.emailConfirmed;
    }

    goBack(): void {
        const previousRegisterStep = this.registerStore.previousStep
            ? this.registerStore.previousStep
            : RegisterStep.Product;

        this.registerLocationService.goTo(
            previousRegisterStep,
            new Substep.Latest(),
        );
    }
}
