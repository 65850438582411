import {
    AfterViewInit,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
    applyFormValue,
    ControlWithErrors,
    makeFormModel,
    shouldShowErrorsFor,
} from '@common/infrastructure/form-tools';
import { DestroyableBase } from '@spnl/components/destroyable/destroyable.component';
import { RoofStepFormGroup } from '@spnl/forms/roof-form-tools';
import {
    ExcludedReason,
    NumberOfStories,
    Registration,
    RoofAge,
    RoofMaterial,
    RoofOrientation,
    RoofPitch,
} from '@spnl/model/registration';
import { ContentTranslatorService } from '@spnl/services/content-translator.service';
import { DigitalEventService } from '@spnl/services/digital-event-queue.service';
import {
    ExclusionContext,
    ExclusionService,
} from '@spnl/services/exclusion.service';
import { RegisterSaveService } from '@spnl/services/register-save.service';
import { filter, map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-register-roof-type',
    templateUrl: './roof-type.component.html',
})
export class RoofTypeComponent
    extends DestroyableBase
    implements OnInit, OnDestroy, AfterViewInit
{
    @Input()
    registration: Registration;

    @Input()
    parentForm: UntypedFormGroup;

    @Input()
    shouldShowAllSteps: boolean;

    @Input()
    startedWithProduct: boolean;

    submitRequested = false;
    form: RoofStepFormGroup;

    roofMaterialWarningKey = null;
    roofAgeWarningKey = null;
    numberOfStoriesWarningKey = null;

    roofOrientations = [
        { value: RoofOrientation.East, textKey: 'roof-orientation-east' },
        {
            value: RoofOrientation.SouthEast,
            textKey: 'roof-orientation-south-east',
        },
        { value: RoofOrientation.South, textKey: 'roof-orientation-south' },
        {
            value: RoofOrientation.SouthWest,
            textKey: 'roof-orientation-south-west',
        },
        { value: RoofOrientation.West, textKey: 'roof-orientation-west' },
        {
            value: RoofOrientation.NorthWest,
            textKey: 'roof-orientation-north-west',
        },
        { value: RoofOrientation.North, textKey: 'roof-orientation-north' },
        {
            value: RoofOrientation.NorthEast,
            textKey: 'roof-orientation-north-east',
        },
    ];

    roofStories = [
        { value: NumberOfStories.SingleStory, showWarning: () => false },
        { value: NumberOfStories.DoubleStory, showWarning: () => false },
        {
            value: NumberOfStories.ThreeOrMore,
            warningKey: 'roof-stories-warning',
            showWarning: () => true,
        },
    ];

    roofMaterials = [
        {
            value: RoofMaterial.Tiles,
            textKey: 'roof-material-tiles',
            warningKey: null,
            showWarning: () => false,
        },
        {
            value: RoofMaterial.EPDM,
            textKey: 'roof-material-epdm',
            warningKey: 'roof-warning-only-flat',
            showWarning: () => this.roofPitch.value !== RoofPitch.Flat,
        },
        {
            value: RoofMaterial.Bitumen,
            textKey: 'roof-material-bitumen',
            warningKey: 'roof-warning-only-flat',
            showWarning: () => this.roofPitch.value !== RoofPitch.Flat,
        },
        {
            value: RoofMaterial.Mastic,
            textKey: 'roof-material-mastic',
            warningKey: 'roof-warning-only-flat',
            showWarning: () => this.roofPitch.value !== RoofPitch.Flat,
        },
        {
            value: RoofMaterial.ContainsAsbestos,
            textKey: 'roof-material-contains-asbestos',
            warningKey: null,
            showWarning: () => false,
        },
        {
            value: RoofMaterial.MuldenPanels,
            textKey: 'roof-material-mulden-panels',
            warningKey: null,
            showWarning: () => false,
        },
        {
            value: RoofMaterial.Sedum,
            textKey: 'roof-material-sedum',
            warningKey: null,
            showWarning: () => false,
        },
        {
            value: RoofMaterial.Slate,
            textKey: 'roof-material-slates',
            warningKey: null,
            showWarning: () => false,
        },
        {
            value: RoofMaterial.Zinc,
            textKey: 'roof-material-zinc',
            warningKey: null,
            showWarning: () => false,
        },
        {
            value: RoofMaterial.MetalPanels,
            textKey: 'roof-material-metal-panels',
            warningKey: null,
            showWarning: () => false,
        },
        {
            value: RoofMaterial.Other,
            textKey: 'roof-material-other',
            warningKey: 'roof-warning-other',
            showWarning: () => true,
        },
        {
            value: RoofMaterial.Unknown,
            textKey: 'roof-material-unknown',
            warningKey: 'roof-warning-other',
            showWarning: () => true,
        },
    ];

    roofAges = [
        {
            value: RoofAge.LessThan25Years,
            textKey: 'roof-age-less-than-25-years',
            showWarning: () => false,
        },
        {
            value: RoofAge.MoreThanOrEqualTo25Years,
            textKey: 'roof-age-more-than-or-equal-to-25-years',
            warningKey: 'roof-age-warning',
            showWarning: () => true,
        },
        {
            value: RoofAge.Unknown,
            textKey: 'roof-age-unknown',
            warningKey: 'roof-age-warning',
            showWarning: () => true,
        },
    ];

    roofPitch: ControlWithErrors;
    roofAge: ControlWithErrors;
    roofOrientation: ControlWithErrors;
    numberOfStories: ControlWithErrors;
    roofMaterial: ControlWithErrors;

    RoofPitch = RoofPitch;
    RoofMaterial = RoofMaterial;
    RoofAge = RoofAge;
    RoofOrientation = RoofOrientation;

    constructor(
        private fb: UntypedFormBuilder,
        private route: ActivatedRoute,
        private registerSaveService: RegisterSaveService,
        private digitalEventService: DigitalEventService,
        public contentTranslatorService: ContentTranslatorService,
    ) {
        super();
    }

    ngOnInit() {
        this.form = this.fb.group(
            {
                roofPitch: ['', Validators.required],
                roofAge: ['', [Validators.required]],
                roofOrientation: [null],
                numberOfStories: [null, [Validators.required]],
                roofMaterial: ['', [Validators.required]],
            },
            {
                validators: [this.roofOrientationRequiredValidator],
            },
        ) as RoofStepFormGroup;

        this.roofPitch = this.form.get('roofPitch');
        this.roofAge = this.form.get('roofAge');
        this.roofOrientation = this.form.get('roofOrientation');
        this.numberOfStories = this.form.get('numberOfStories');
        this.roofMaterial = this.form.get('roofMaterial');

        this.form.onSubmit = this.onSubmit.bind(this);
        this.form.getExclusionContext = this.getExclusionContext.bind(this);
        this.parentForm.addControl('roof-type', this.form);

        this.numberOfStories.valueChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe(
                (value) =>
                    (this.numberOfStoriesWarningKey = this.getWarningKey(
                        value,
                        this.roofStories,
                    )),
            );

        [this.roofPitch, this.roofMaterial].forEach((control) => {
            control.valueChanges
                .pipe(takeUntil(this.destroyed$))
                .subscribe(
                    (value) =>
                        (this.roofMaterialWarningKey = this.getWarningKey(
                            value,
                            this.roofMaterials,
                        )),
                );
        });

        this.roofAge.valueChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe(
                (value) =>
                    (this.roofAgeWarningKey = this.getWarningKey(
                        value,
                        this.roofAges,
                    )),
            );

        const model = makeFormModel(this.form, this.registration.product, []);
        this.form.reset(model);

        this.route.queryParams
            .pipe(
                takeUntil(this.destroyed$),
                map((params) => params['prefill-value']),
                filter((inclinationType) => inclinationType),
            )
            .subscribe((inclinationType) => {
                const pitch =
                    this.mapInclinationTypeToRoofPitch(inclinationType);
                if (pitch && !this.registration.product.roofPitch) {
                    this.roofPitch.setValue(pitch);
                }
            });
    }

    ngAfterViewInit() {
        this.digitalEventService.push('daktype');
    }

    private mapInclinationTypeToRoofPitch(inclinationType: string): RoofPitch {
        // The values at the left hand side are provided by the VEH when going from their site to ours
        switch (inclinationType) {
            case 'between-0-and-15':
                return RoofPitch.Flat;
            case 'between-0-and-30':
                return RoofPitch.SlightlyInclined;
            case 'between-30-and-70':
                return RoofPitch.Inclined;
            case 'greater-than-70':
                return RoofPitch.Sharp;
            default:
                return null;
        }
    }

    private getWarningKey(newVal: string, options: any[]): string {
        const item = options.find((val) => val.value === newVal);
        return item && item.showWarning() ? item.warningKey : null;
    }

    private roofOrientationRequiredValidator(
        group: UntypedFormGroup,
    ): ValidationErrors | null {
        const shouldBeRequired =
            group.controls.roofPitch?.value !== RoofPitch.Flat;
        return group.controls.roofOrientation.value === null && shouldBeRequired
            ? { roofOrientationRequired: true }
            : null;
    }

    public get roofMaterialErrorKey(): string {
        return this.roofMaterialWarningKey
            ? this.roofMaterialWarningKey
            : 'roof-material-error-unsupported-with-exclude-link';
    }

    public get roofMaterialWarning(): boolean {
        return (
            this.roofMaterialWarningKey &&
            this.excludedReason !== ExcludedReason.RoofMaterial
        );
    }

    get showErrorsForRoofPitch(): boolean {
        return shouldShowErrorsFor(this.roofPitch);
    }

    get showErrorsForRoofOrientation(): boolean {
        return (
            this.form.hasError('roofOrientationRequired') &&
            this.roofOrientation.touched
        );
    }

    get showErrorsForRoofMaterial(): boolean {
        return shouldShowErrorsFor(this.roofMaterial);
    }

    get showErrorsForRoofAge(): boolean {
        return shouldShowErrorsFor(this.roofAge);
    }

    get showErrorsForNumberOfStories(): boolean {
        return shouldShowErrorsFor(this.numberOfStories);
    }

    get excludedReason(): ExcludedReason | null {
        return ExclusionService.getExcludedReason(this.getExclusionContext());
    }

    onExcludeRegistration() {
        this.registerSaveService.excludeAndContinue(
            this.form,
            this.registration.product,
        );
    }

    getExclusionContext(): ExclusionContext {
        return {
            roofOrientation: this.roofOrientation?.value,
            roofMaterial: this.roofMaterial?.value,
            roofPitch: this.roofPitch?.value,
        };
    }

    onSubmit() {
        if (this.roofPitch.value === RoofPitch.Flat) {
            this.roofOrientation.setValue(null);
        }

        this.form.markAllAsTouched();

        if (this.form.valid) {
            applyFormValue(this.form, this.registration.product, []);
        }
    }

    // TODO: Deduplicate
    requiredWhen(predicate: () => boolean): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return this.form && predicate() && control.value === null
                ? { required: true }
                : null;
        };
    }

    ngOnDestroy(): void {
        this.parentForm?.removeControl('roof-type');
        super.ngOnDestroy();
    }
}
