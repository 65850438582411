<app-step-title titleKey="product-header"> </app-step-title>

<form
    [formGroup]="form"
    (ngSubmit)="onSubmitStep()"
    novalidate
    appfocusFirstInvalidField
    appFocusFirstExcludedField
>
    <h4 *ngIf="shouldShowAllSteps" class="mb-3">
        {{ 'roof-living-situation-title' | cmsText | async }}
    </h4>
    <app-register-roof-living-situation
        *ngIf="shouldShowStep(RoofStep.LivingSituation)"
        [registration]="registration"
        [parentForm]="form"
        [shouldShowAllSteps]="shouldShowAllSteps"
        [startedWithProduct]="startedWithProduct"
    >
    </app-register-roof-living-situation>

    <hr *ngIf="shouldShowAllSteps" />

    <h4 *ngIf="shouldShowAllSteps" class="mb-3">
        {{ 'roof-type-title' | cmsText | async }}
    </h4>
    <app-register-roof-type
        *ngIf="shouldShowStep(RoofStep.Type)"
        [registration]="registration"
        [parentForm]="form"
        [shouldShowAllSteps]="shouldShowAllSteps"
        [startedWithProduct]="startedWithProduct"
    >
    </app-register-roof-type>

    <hr *ngIf="shouldShowAllSteps" />
    <h4 *ngIf="shouldShowAllSteps" class="mb-3">
        {{ 'roof-size-title' | cmsText | async }}
    </h4>
    <app-register-roof-size
        *ngIf="shouldShowStep(RoofStep.Size)"
        [registration]="registration"
        [parentForm]="form"
        [startedWithProduct]="startedWithProduct"
    >
    </app-register-roof-size>

    <hr *ngIf="shouldShowAllSteps" />
    <h4 *ngIf="shouldShowAllSteps" class="mb-3">
        {{ 'roof-objects-title' | cmsText | async }}
    </h4>
    <app-register-roof-objects
        *ngIf="shouldShowStep(RoofStep.Objects)"
        [registration]="registration"
        [parentForm]="form"
    >
    </app-register-roof-objects>

    <hr *ngIf="shouldShowAllSteps" />
    <h4 *ngIf="shouldShowAllSteps" class="mb-3">
        {{ 'roof-energy-title' | cmsText | async }}
    </h4>
    <app-register-roof-energy
        *ngIf="shouldShowStep(RoofStep.Energy)"
        [registration]="registration"
        [parentForm]="form"
    >
    </app-register-roof-energy>

    <ng-container *ngIf="shouldShowRoofYield">
        <hr *ngIf="shouldShowAllSteps" />
        <h4 *ngIf="shouldShowAllSteps" class="mb-3">
            {{ 'roof-yield-title' | cmsText | async }}
        </h4>
        <app-register-roof-yield
            *ngIf="shouldShowStep(RoofStep.Yield)"
            [registration]="registration"
            [parentForm]="form"
        >
        </app-register-roof-yield>
    </ng-container>

    <hr class="my-3" />
    <app-roof-excluded-warning [form]="form"></app-roof-excluded-warning>
    <app-roof-form-buttons [form]="form"></app-roof-form-buttons>
</form>
