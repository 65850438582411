import { Component } from '@angular/core';
import { StatusMessage } from '@spnl/model/status-message';
import { RegisterStoreService } from '@spnl/services/register-store.service';

@Component({
    selector: 'app-register-status-message',
    templateUrl: './register-status-message.component.html',
})
export class RegisterStatusMessageComponent {
    get message(): StatusMessage {
        return this.store.statusMessage;
    }

    get hasActiveStep(): boolean {
        return !!this.store.activeStep;
    }

    constructor(private store: RegisterStoreService) {}

    goBack(): void {
        this.store.statusMessage = null;
    }
}
