<h3 class="my-3 nls_protected">
    {{
        'overview-page-of-contact-title'
            | cmsText : [{ fullName: registration.contact | fullName | async }]
            | async
    }}
</h3>
<hr class="my-3" />

<app-hero
    heroImageKey="hero-image-overview"
    heroImageClass="hero-image-medium mb-3"
></app-hero>

<span
    [innerHTML]="
        'overview-extra-info-buckaroo-depositpaid-text' | cmsText | async
    "
></span>

<button
    id="paymentSuccessBtn"
    type="button"
    class="btn btn-primary px-5 d-flex"
    (click)="goToOverview()"
>
    {{ 'payment-success-overview-btn' | cmsText | async }}
</button>

<app-register-survey-cta
    *ngIf="!isMobileDevice"
    [registrationNumber]="registration.number"
    [auctionCode]="registration.auction?.code"
    [registrationStatus]="registration.status"
    [supplierName]="registration.supplierName"
    cmsKey="survey-cta-body-completed"
>
</app-register-survey-cta>

<app-share-auction-social-media-cta
    *ngIf="isMobileDevice"
    [messageCmsKey]="'share-auction-social-media-message'"
    [includeWhatsapp]="true"
    [whatsappCmsKey]="'whatsapp-message-offer-accepted'"
    [includeEmail]="true"
    [emailSubjectCmsKey]="'email-message-header-offer-accepted'"
    [emailBodyCmsKey]="'email-message-body-offer-accepted'"
>
</app-share-auction-social-media-cta>
