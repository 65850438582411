import { Directive, ElementRef, Input, AfterViewInit, Component, ViewChild } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Viewer from 'viewerjs';

@Component({
    selector: 'app-image-viewer-directive-content',
    template: `<div class="modal-header">
        <h4 class="modal-title">
            {{title}}
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="dismissActiveModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
    <div class="w-100">
    <div style="height: 80vh" #imageList>
        <img id="image" class="invisible" [src]="url" [alt]="altName">
    </div>
    </div>
    </div>`
})
class IVModalContentComponent implements AfterViewInit {
    @Input() url: string;
    @Input() altName: string;
    @Input() title: string;

    @ViewChild('imageList')
    private imageListElement: ElementRef;

    private viewer: Viewer;

    constructor(public activeModal: NgbActiveModal) { }

    ngAfterViewInit(): void {
        this.viewer = new Viewer(this.imageListElement.nativeElement, {
            toolbar:
            {
                zoomIn: true,
                zoomOut: true,
                prev: false,
                next: false,
                rotateLeft: false,
                rotateRight: false
            },
            inline: true,
            backdrop: false,
        });
    }

    dismissActiveModal() {
        if (this.activeModal) {
            this.activeModal.close();
        }
    }

    ngOnDestroyed() {
        this.viewer.destroy();
    }
}



@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[imageViewerDirective]'
})
export class ImageViewerDirective implements AfterViewInit {

    @Input('imageViewerDirective')
    showImage: boolean;

    @Input()
    imageUrl: string;

    @Input()
    imageName: string;

    @Input()
    imageTitle: string;

    constructor(private el: ElementRef, private modalService: NgbModal) { }

    ngAfterViewInit(): void {
        if (this.showImage) {

            // CSS class imgviewer points the button
            const anchorBtn = this.el.nativeElement.querySelector('.imgviewer');

            if (anchorBtn) {
                anchorBtn.addEventListener('click', () => {
                    const modalRef = this.modalService.open(IVModalContentComponent, { size: 'xl' });
                    if (modalRef) {
                        const imageViewerModal = modalRef.componentInstance as IVModalContentComponent;
                        imageViewerModal.url = this.imageUrl;
                        if (this.imageName) {
                            imageViewerModal.altName = this.imageName;
                        }
                        if (this.imageTitle) {
                            imageViewerModal.title = this.imageTitle;
                        }
                    }
                });
            }
        }
    }
}
