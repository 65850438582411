import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ControlWithErrors } from '@common/infrastructure/form-tools';
import { Registration } from '@spnl/model/registration';

@Component({
    selector: 'app-proposal-supplier-conditions',
    templateUrl: './proposal-supplier-conditions.component.html',
})
export class ProposalSupplierConditionsComponent implements OnInit {
    @Input()
    form: UntypedFormGroup;

    @Input()
    registration: Registration;

    @Input()
    submitRequested: boolean;

    public supplierConditions = this.fb.group({
        agreesToSupplierConditions: ['', Validators.requiredTrue],
    });

    constructor(private fb: UntypedFormBuilder) {}

    ngOnInit(): void {
        this.form.addControl('supplierConditions', this.supplierConditions);
    }

    get agreesToSupplierConditions(): ControlWithErrors {
        return this.supplierConditions.get('agreesToSupplierConditions');
    }

    get supplierConditionsLabel(): string {
        return `proposal-supplier-agreements-conditions-${this.supplierCode}`;
    }

    private get supplierCode(): string {
        return this.registration.proposalMade
            ? this.registration.proposal.supplier.code
            : '';
    }
}
