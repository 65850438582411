/* eslint-disable @typescript-eslint/naming-convention */

import { removeNullishAndEmpty } from '@common/util';
import { AnalyticsEvent, EventName, AnalyticsEventTemplate } from './analytics.event';

type Template = AnalyticsEventTemplate<RoofNotSuitableEvent>;

export class RoofNotSuitableEvent implements AnalyticsEvent {
    event: EventName = 'roof_not_suitable';
    process_product: string;
    click_title: string;

    constructor(template: Template = {}) {
        Object.assign(this, removeNullishAndEmpty(template));
    }

    static create(click_title: string): RoofNotSuitableEvent {
        return new this({
            click_title,
        });
    }
}
