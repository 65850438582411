import { ApprovalStatus } from '@common/model/approval-status';
import { PaymentStatus } from '@common/model/payment';
import { RegistrationBase } from '@common/model/registration.base';
import { Supplier } from '@common/model/supplier.base';
import { RegisterConfig } from '@spnl/components/register/register.config';
import { BuyerPhase } from './buyer-phase.enum';
import { YesNoDontKnow } from './decision.model';
import { NextSteps } from './next-steps';
import { PaymentMethod } from './payment-method.enum';
import { RegistrationGroup } from './registration-group';
import { SolarPanelType } from './solar-panel-type';

export class Contact {
    salutation?: Salutation;
    firstName: string;
    insertion: string;
    lastName: string;

    zip?: string;
    houseNr?: string;
    houseNrSuffix?: string;
    street?: string;
    city?: string;
    province?: string;
    municipality?: string;
    longitude?: number;
    latitude?: number;

    cellPhone: string;
    phone: string;

    agreesToConditions: boolean;
    agreesToFurtherContact?: boolean;
    marketingChannel?: MarketingChannel;

    constructor(dto: any = {}) {
        Object.assign(this, dto);
    }

    isValid(): boolean {
        const hasValue = (x) => !!x && x.toString().length > 0;

        const hasAnyPhone = hasValue(this.phone) || hasValue(this.cellPhone);
        return (
            hasAnyPhone &&
            hasValue(this.salutation) &&
            hasValue(this.firstName) &&
            hasValue(this.lastName) &&
            hasValue(this.zip) &&
            hasValue(this.houseNr) &&
            hasValue(this.street) &&
            hasValue(this.city)
        );
    }
}

export enum Salutation {
    Mr = 'Mr',
    Mrs = 'Mrs',
}

export enum MarketingChannel {
    Letter = 'Letter',
    Email = 'Email',
    SocialMedia = 'SocialMedia',
    Newspaper = 'Newspaper',
    OnlineMedia = 'OnlineMedia',
    WordOfMouth = 'WordOfMouth',
    OnlineSearch = 'OnlineSearch',
    OnlineAdvertising = 'OnlineAdvertising',
    Other = 'Other',
}

export class Product {
    isHomeOwner?: boolean;
    isHomeOwnerAssociationMember?: boolean;
    communityMember?: CommunityMember;
    currentSolarPanelSituation?: SolarPanelSituation;

    roofPitch?: RoofPitch;
    roofOrientation?: RoofOrientation;
    roofMaterial?: RoofMaterial;
    roofAge?: RoofAge;
    numberOfStories?: NumberOfStories;

    knowsNumberOfPanelsThatFitOnRoof?: boolean;

    roofWidth?: number;
    roofLength?: number;
    isMoreRoofsAvailable?: boolean;

    numberOfPanelsThatFitOnRoof?: number;
    suggestedNumberOfPanels?: number;
    chosenNumberOfPanels?: number;
    chosenSolarPanelType?: SolarPanelType;

    hasRoofObjects: YesNoDontKnow;
    hasDormer?: boolean;
    hasRoofShadow: YesNoDontKnow;

    electricityConsumptionPerYear?: number;
    futureExpectedElectricityConsumptionPerYear?: number;
    howManyPeopleInHousehold?: number;

    isInterestedInParallel?: boolean;
    isInterestedInElectricCar?: boolean;
    isInterestedInAirconditioning?: boolean;
    isInterestedInElectricOven?: boolean;
    isInterestedInHeatPump?: boolean;
    doesUserKnowEnergyUsage?: boolean;

    constructor(dto: any = {}) {
        Object.assign(this, dto);
    }

    isValid(): boolean {
        const required = [
            this.roofPitch,
            this.roofMaterial,
            this.roofAge,
            this.numberOfStories,
            this.numberOfPanelsThatFitOnRoof?.toString(),
            this.hasRoofObjects?.toString(),
            this.hasRoofShadow?.toString(),
            this.electricityConsumptionPerYear?.toString(),
        ];

        if (this.roofPitch !== RoofPitch.Flat) {
            required.push(this.roofOrientation);
        }

        if (this.hasRoofObjects === YesNoDontKnow.Yes) {
            required.push(this.hasDormer?.toString());
        }

        return required.filter((x) => !x || x.length === 0).length === 0;
    }
}

export enum RoofPitch {
    Flat = 'Flat',
    SlightlyInclined = 'SlightlyInclined',
    Inclined = 'Inclined',
    Sharp = 'Sharp',
}

export enum RoofOrientation {
    North = 'North',
    NorthEast = 'NorthEast',
    East = 'East',
    SouthEast = 'SouthEast',
    South = 'South',
    SouthWest = 'SouthWest',
    West = 'West',
    NorthWest = 'NorthWest',
}

export namespace RoofOrientation {
    export function isUnsupported(orientation: RoofOrientation): boolean {
        const unallowedOrientations = [
            RoofOrientation.North,
            RoofOrientation.NorthEast,
            RoofOrientation.NorthWest,
        ];
        return unallowedOrientations.includes(orientation);
    }
}

export enum RoofMaterial {
    Tiles = 'Tiles',
    EPDM = 'EPDM',
    Bitumen = 'Bitumen',
    Mastic = 'Mastic',
    Glass = 'Glass',
    Thatch = 'Thatch',

    Slate = 'Slate',
    Sedum = 'Sedum',
    Zinc = 'Zinc',
    Felsen = 'Felsen',
    ContainsAsbestos = 'ContainsAsbestos',
    MuldenPanels = 'MuldenPanels',
    MetalPanels = 'MetalenPanels',

    Other = 'Other',
    Unknown = 'Unknown',
}

export namespace RoofMaterial {
    export function isUnsupported(material: RoofMaterial, pitch: RoofPitch) {
        const unallowed = [
            RoofMaterial.Glass,
            RoofMaterial.Thatch,
            RoofMaterial.Felsen,
            RoofMaterial.Zinc,
            RoofMaterial.Sedum,
            RoofMaterial.Slate,
            RoofMaterial.ContainsAsbestos,
            RoofMaterial.MetalPanels,
            RoofMaterial.MuldenPanels,
        ];
        const isUnallowed = unallowed.includes(material);

        const unallowedForPitch = {};
        unallowedForPitch[RoofPitch.Flat] = [];
        unallowedForPitch[RoofPitch.Inclined] = [
            RoofMaterial.EPDM,
            RoofMaterial.Bitumen,
            RoofMaterial.Mastic,
        ];
        unallowedForPitch[RoofPitch.Sharp] = [
            RoofMaterial.EPDM,
            RoofMaterial.Bitumen,
            RoofMaterial.Mastic,
        ];
        unallowedForPitch[RoofPitch.SlightlyInclined] = [
            RoofMaterial.EPDM,
            RoofMaterial.Bitumen,
            RoofMaterial.Mastic,
        ];
        const isUnallowedForPitch =
            !!pitch && unallowedForPitch[pitch].includes(material);

        return isUnallowed || isUnallowedForPitch;
    }
}

export enum RoofAge {
    Unknown = 'Unknown',
    LessThan15Years = 'LessThan15Years',
    MoreThanOrEqualTo15Years = 'MoreThanOrEqualTo15Years',
    LessThan25Years = 'LessThan25Years',
    MoreThanOrEqualTo25Years = 'MoreThanOrEqualTo25Years',
}

export enum NumberOfStories {
    SingleStory = 'SingleStory',
    DoubleStory = 'DoubleStory',
    ThreeOrMore = 'ThreeOrMore',
}

export namespace RoofSize {
    export function isUnsupported(numberOfPanelsThatFitOnRoof: number) {
        return (
            numberOfPanelsThatFitOnRoof !== null &&
            numberOfPanelsThatFitOnRoof !== undefined &&
            numberOfPanelsThatFitOnRoof < RegisterConfig.minNumberOfPanels
        );
    }
}

export class Proposal {
    numberOfPanels: number;
    premiumPanels: boolean;
    switchToSingleMeter: boolean;
    capacity: number;
    price: number;
    vat: number;
    firstYearSaving: number;
    paybackTime?: number;
    co2ReductionKg: number;
    co2ReductionOfAverage: number;
    consumptionCoverage: number;
    savingOver10Years: number;
    optimalNumberOfPanels?: number;
    supplier: Supplier;
    firstVisitedUtc?: Date;
    lastVisitedUtc?: Date;
    numberOfTimesVisited?: number;
    solarPanelType: SolarPanelType;

    constructor(dto: any = {}) {
        Object.assign(this, dto);
        this.supplier = new Supplier(dto.supplier);
    }
}

export class Decision {
    reasonToDecline?: string;
    reasonToDeclineDescription?: string;
    notesToSupplier?: string;
    agreesToSupplierConditions?: boolean;
    paymentMethod?: PaymentMethod;

    constructor(dto: any = {}) {
        Object.assign(this, dto);
    }
}

export class Delivery {
    // string representation of Nullable<DateTimeOffset> actually (but we don't need the extra fidelity yet
    // so we type it as a string in frontend for now)
    cancellationRequestedByConsumer: string;
    deliveryCancellationStatus: ApprovalStatus;
}

export class Registration extends RegistrationBase {
    contact: Contact;
    product: Product;
    proposal: Proposal;
    decision: Decision;
    delivery: Delivery;
    seminarId?: string;
    supplierId?: string;
    paymentStatus?: PaymentStatus;
    oldRequestId?: number;
    registrationGroup: RegistrationGroup;

    excluded: boolean;
    excludedReason?: ExcludedReason;

    constructor(dto: any = {}) {
        super();
        Object.assign(this, dto);
        this.contact = new Contact(dto.contact);
        this.product = new Product(dto.product);
        this.proposal = new Proposal(dto.proposal);
        this.decision = new Decision(dto.decision);
        this.registrationGroup = new RegistrationGroup(dto.registrationGroup);
    }

    get nextStep(): NextSteps {
        if (this.decisionComplete && !this.proposalAccepted) {
            return NextSteps.None;
        } else if (this.excludedOrCancelled) {
            return NextSteps.None;
        } else if (this.decisionComplete && this.proposalAccepted) {
            return NextSteps.AfterProposalAccepted;
        } else if (!this.subscriptionComplete) {
            return NextSteps.CompleteRegistration;
        } else if (!this.emailConfirmed) {
            return NextSteps.ConfirmRegistration;
        } else if (!this.proposalMade) {
            return NextSteps.WaitForAuction;
        }
        return NextSteps.None;
    }

    get supplierName(): string {
        return this.proposalMade ? this.proposal.supplier.name : '';
    }

    public get hasValidProduct(): boolean {
        return this.product.isValid();
    }

    public get hasRoofDetails(): boolean {
        if (this.cancelled) {
            return false;
        }
        return (
            this.product.knowsNumberOfPanelsThatFitOnRoof !== undefined ||
            this.product.numberOfPanelsThatFitOnRoof !== undefined ||
            this.product.roofPitch !== undefined ||
            this.product.roofOrientation !== undefined ||
            this.product.roofMaterial !== undefined ||
            this.product.chosenNumberOfPanels > 0 ||
            this.product.hasRoofObjects !== undefined ||
            this.product.hasDormer !== undefined ||
            this.product.hasRoofShadow !== undefined ||
            this.product.currentSolarPanelSituation !== undefined
        );
    }

    public get hasRoofOrientationDetails(): boolean {
        const hasOrientation = !!this.product.roofOrientation;
        const hasRoofPitch = !!this.product.roofPitch;
        const hasRoofPitchFlat =
            hasRoofPitch && this.product.roofPitch === RoofPitch.Flat;
        return hasOrientation && (!hasRoofPitch || !hasRoofPitchFlat);
    }

    public get excludedOrCancelled(): boolean {
        return this.excluded || this.cancelled;
    }

    public get hasValidContact(): boolean {
        const hasEmail = this.email != null && this.email.length > 0;
        return hasEmail && this.contact.isValid();
    }

    get isInInterestedIndividualFlow(): boolean {
        return !this.auction;
    }

    get isReadonly(): boolean {
        return this.decisionComplete || this.cancelled;
    }

    get hasExitedFlow(): boolean {
        return this.cancelled;
    }

    get offerIsDeclined(): boolean {
        return this.decisionComplete && !this.proposalAccepted;
    }

    get auctionIsInactive(): boolean {
        return this.community?.targetAuction?.finished;
    }

    get depositIsPaid(): boolean {
        return (
            this.decisionComplete &&
            this.proposalAccepted &&
            this.paymentStatus === PaymentStatus.Successful
        );
    }

    get noProposalOrDecisionMade(): boolean {
        return !this.proposalMade && !this.decisionComplete;
    }
}

export enum ExcludedReason {
    PostcodeOutOfRange = 'PostcodeOutOfRange',
    RoofDoesNotSupportSolarPanels = 'RoofDoesNotSupportSolarPanels',
    RoofOrientation = 'RoofOrientation',
    RoofMaterial = 'RoofMaterial',
    RoofSize = 'RoofSize',
    SolarPanelSituationNotAppropriate = 'SolarPanelSituationNotAppropriate',
    MemberOfHomeOwnerAssociation = 'MemberOfHomeOwnerAssociation',
    NotHomeOwner = 'NotHomeOwner',
}

export enum ReasonToDeclineOption {
    NoReason = 'NoReason',
    OtherReason = 'OtherReason',
    UnsuitableProducts = 'UnsuitableProducts',
    NoTrustInSupplier = 'NoTrustInSupplier',
    AlreadyHasPanels = 'AlreadyHasPanels',
    WantsAssessmentBeforeAccepting = 'WantsAssessmentBeforeAccepting',
    ReceivedBetterOffer = 'ReceivedBetterOffer',
    PersonalFinancialSituation = 'PersonalFinancialSituation',
    ProductIsTooExpensive = 'ProductIsTooExpensive',
    OfferUnclear = 'OfferUnclear',
    NoLongerInterestedInSolarPanels = 'NoLongerInterestedInSolarPanels',
}

export enum CommunityMember {
    No = 'No',
    Yes = 'Yes',
    Unknown = 'Unknown',
}

export enum SolarPanelSituation {
    NotApplicable = 'NotApplicable',
    NoCurrentSystemInstalled = 'NoCurrentSystemInstalled',
    WantToExpandCurrentSystem = 'WantToExpandCurrentSystem',
    WantToReplaceCurrentSystem = 'WantToReplaceCurrentSystem',
}
