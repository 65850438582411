import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-cancel-registration-confirm-modal',
    styles: [],
    template: ` <div class="modal-header">
            <h4 class="modal-title" ngbAutofocus>
                {{
                    'cancel-registration-confirm-modal-title' | cmsText | async
                }}
            </h4>
            <button
                class="btn-close"
                aria-label="Sluiten"
                (click)="modal.dismiss('stop-with-cancellation')"
            >
            </button>
        </div>
        <div class="modal-body">
            <app-content
                key="cancel-registration-confirm-modal-body"
            ></app-content>
        </div>
        <div class="modal-footer">
            <button
                class="btn btn-primary"
                (click)="modal.close('proceed-with-cancellation')"
            >
                {{
                    'cancel-registration-confirm-modal-button-go-ahead'
                        | cmsText
                        | async
                }}
            </button>
            <button
                class="btn btn-secondary"
                (click)="modal.dismiss('stop-with-cancellation')"
            >
                {{
                    'cancel-registration-confirm-modal-button-stop'
                        | cmsText
                        | async
                }}
            </button>
        </div>`,
})
export class CancelRegistrationConfirmModalComponent {
    constructor(public modal: NgbActiveModal) {}
}
