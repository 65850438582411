import { Pipe, PipeTransform } from '@angular/core';
import { CmsTextPipe } from '@common/pipes/cms-text.pipe';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Contact } from '../model/registration';

@Pipe({
    name: 'fullName',
})
export class FullNamePipe implements PipeTransform {
    constructor(private cmsText: CmsTextPipe) {}

    transform(contact: Contact): Observable<string> {
        const translationKey = `salutation-${contact.salutation?.toLowerCase()}-label`;
        return this.cmsText.transform(translationKey).pipe(
            map((salutation) => {
                if (salutation === translationKey) {
                    salutation = null;
                }

                return [salutation, contact.insertion, contact.lastName]
                    .filter((element) => element)
                    .join(' ');
            }),
        );
    }
}
