import { Component, Input } from '@angular/core';
import { Registration } from '@spnl/model/registration';

@Component({
    selector: 'app-registration-bar',
    templateUrl: './registration-bar.component.html',
})
export class RegistrationBarComponent {
    @Input()
    registration: Registration;
}
