export function personalizedGreeting(
    salutation: string,
    lastName: string,
    insertion?: string,
): string {
    const map = {
        Mr: 'Meneer',
        Mrs: 'Mevrouw',
    };
    insertion = insertion ? `${insertion}` : '';
    return `${map[salutation]} ${insertion} ${lastName}`.replace(/\s+/g,' ');
}
