import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ControlWithErrors } from '@common/infrastructure/form-tools';
import {
    PaymentStatus,
    PaymentTransferDetailsResponse,
    StartPaymentRequest,
    StartPaymentStatus,
} from '@common/model/payment';
import { PaymentService } from '@common/services/payment.service';
import { VwoService } from '@common/services/vwo.service';
import { DestroyableBase } from '@spnl/components/destroyable/destroyable.component';
import { PaymentMethod } from '@spnl/model/payment-method.enum';
import { RegisterSaveService } from '@spnl/services/register-save.service';
import { RegisterStoreService } from '@spnl/services/register-store.service';
import { combineLatest } from 'rxjs';
import { filter, first, switchMap, takeUntil } from 'rxjs/operators';
import { Registration } from '../../../model/registration';

@Component({
    selector: 'app-register-deposit',
    templateUrl: './deposit.component.html',
})
export class DepositComponent
    extends DestroyableBase
    implements OnInit, AfterViewInit {
    @ViewChild('buckarooSubmitBtn')
    buckarooBtn: ElementRef;

    form: UntypedFormGroup;

    paymentMethods: { value: string; cmsKey: string }[] = [];

    submitRequested = false;
    showError = false;
    disabledButton = true;
    showSpinner = false;

    buckarooFormAction = 'https://testcheckout.buckaroo.nl/html/';
    buckarooPayload: any;
    buckarooFormInputs: { id: string; name: string; value: string }[] = [];

    paymentTransferDetails: PaymentTransferDetailsResponse;
    public get showPaymentTransferDetails(): boolean {
        if (!this.paymentTransferDetails) {
            return false;
        }
        if (!this.paymentTransferDetails.iban) {
            return false;
        }
        if (!this.paymentTransferDetails.accountHolderName) {
            return false;
        }
        if (!this.paymentTransferDetails.paymentReference) {
            return false;
        }
        if (!this.paymentTransferDetails.amount) {
            return false;
        }
        return true;
    }

    get registration(): Registration {
        return this.registerStore.registration;
    }

    constructor(
        private fb: UntypedFormBuilder,
        private paymentService: PaymentService,
        private vwoService: VwoService,
        private registerSaveService: RegisterSaveService,
        private registerStore: RegisterStoreService,
    ) {
        super();
        this.form = this.fb.group({
            paymentMethod: ['', [Validators.required]],
        });
    }

    ngAfterViewInit(): void {
        this.vwoService.push('deposit');
    }

    ngOnInit(): void {
        this.paymentService
            .getPaymentMethods()
            .pipe(takeUntil(this.destroyed$))
            .subscribe(
                (methods) => {
                    methods.forEach((m) =>
                        this.paymentMethods.push({
                            value: m.toLowerCase(),
                            cmsKey: `payment-option-${m.toLowerCase()}`,
                        }),
                    );
                },
                () => {
                    this.showError = true;
                },
            );

        this.paymentMethod.valueChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe((_) => {
                if (this.paymentMethod.value) {
                    this.disabledButton = false;
                }
            });

        this.registerStore.registrationLoaded$
            .pipe(
                first(),
                filter(
                    (_) =>
                        this.registration.paymentStatus ===
                        PaymentStatus.Pending,
                ),
                switchMap((_) =>
                    combineLatest([
                        this.paymentService.getPaymentStatusForRegistration(
                            this.registration.id,
                        ),
                        this.paymentService.getPaymentTransferDetails(
                            this.registration.id,
                        ),
                    ]),
                ),
                takeUntil(this.destroyed$),
            )
            .subscribe(
                ([paymentStatusForRegistration, paymentTransferDetails]) => {
                    if (
                        paymentStatusForRegistration.status ===
                        PaymentStatus.Pending
                    ) {
                        this.paymentTransferDetails = paymentTransferDetails;
                    }
                },
            );
    }

    get paymentMethod(): ControlWithErrors {
        return this.form.get('paymentMethod') as ControlWithErrors;
    }

    async submitBuckarooPost() {
        this.submitRequested = true;
        this.disabledButton = true;
        this.showSpinner = true;

        if (!this.form.valid) {
            return false;
        }

        if (await this.setPaymentMethodOnRegistration()) {
            this.startPayment();
        } else {
            this.showError = true;
            return false;
        }
    }

    startPayment() {
        const request: StartPaymentRequest = {
            business: 'spnl',
            paymentMethod: this.paymentMethod.value,
            registrationId: this.registration.id,
        };

        this.paymentService
            .startPayment(request)
            .pipe(first())
            .subscribe(
                (res) => {
                    if (res) {
                        if (res.status === StartPaymentStatus.Ok) {
                            this.buckarooFormAction = res.buckarooUrl;
                            this.buckarooPayload = res.buckarooPayload;

                            this.prepareBuckarooFormInputs();
                            if (this.buckarooFormInputs.length > 0) {
                                this.finalizePostAndCloseModal();
                            } else {
                                this.showError = true;
                                return false;
                            }
                        }
                    }
                },
                () => {
                    this.showError = true;
                    return false;
                },
            );
    }

    private finalizePostAndCloseModal() {
        setTimeout(() => {
            this.buckarooBtn.nativeElement.click();
        }, 500);
    }

    private prepareBuckarooFormInputs() {
        if (this.buckarooPayload) {
            this.buckarooFormInputs = [];
            Object.keys(this.buckarooPayload).forEach((key) => {
                this.buckarooFormInputs.push({
                    id: key,
                    name: key,
                    value: this.buckarooPayload[key],
                });
            });
        }
    }

    private async setPaymentMethodOnRegistration(): Promise<boolean | void> {
        if (this.paymentMethod.value) {
            const method = this.getPaymentMethod(this.paymentMethod.value);
            return await this.registerSaveService.updatePaymentMethod(method);
        }
    }

    private getPaymentMethod(paymentMethod: string): PaymentMethod {
        // The BL shared API call to get available methods returns methods as lowercase strings
        // Therefore we need this to save the available method with the correct casing
        return Object.values(PaymentMethod).find(
            (m) => m.toLowerCase() === paymentMethod.toLowerCase(),
        );
    }
}
