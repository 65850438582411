<div class="container">
    <div class="row" *ngIf="!registration">
        <div class="col-12 col-md-10 col-lg-8 my-5">
            <p class="alert alert-warning">
                {{
                    'seminar-available-to-registrants-warning' | cmsText | async
                }}
            </p>
        </div>
    </div>
    <div class="row" *ngIf="registration && supplier && (seminars$ | async)">
        <div class="col-12 col-md-10 col-lg-8 my-5">
            <span
                [innerHTML]="
                    'seminar-introduction-text'
                        | cmsText : [{ winningSupplier: this.supplier.name }]
                        | async
                "
            ></span>
        </div>
    </div>
</div>
<div
    class="container-fluid bg-highlight-one py-5"
    *ngIf="registration && supplier && (seminars$ | async)"
>
    <div class="container">
        <div class="row">
            <app-seminar-card
                *ngFor="let seminar of upcomingSeminars"
                [active]="true"
                [seminar]="seminar"
                [registration]="registration"
                class="col-12 col-sm-6 col-md-4 mb-3"
            >
            </app-seminar-card>
            <span *ngIf="!upcomingSeminars">
                {{ 'seminar-no-upcoming' | cmsText | async }}
            </span>
        </div>
        <hr />
        <div class="row mt-3 p-1" *ngIf="pastSeminars">
            <app-seminar-card
                *ngFor="let seminar of pastSeminars"
                [seminar]="seminar"
                [registration]="registration"
                class="col-12 col-sm-6 col-md-4 mb-3"
            >
            </app-seminar-card>
        </div>
    </div>
</div>
