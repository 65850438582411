import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { PAYMENTS_API_URL, BUSINESS } from '@common/app/app.config';
import { PaymentStatusResponse, StartPaymentRequest, StartPaymentResponse, PaymentTransferDetailsResponse } from '@common/model/payment';

@Injectable()
export class PaymentService {
    private baseUrl = '';
    private business = '';

    constructor(
        protected http: HttpClient,
        @Inject(PAYMENTS_API_URL) baseUrl: string,
        @Inject(BUSINESS) business: string,
    ) {
        this.baseUrl = baseUrl;
        this.business = business;
    }

    // GET: payment-methods/{businessLine}
    getPaymentMethods(): Observable<string[]> {
        return this.http.get<string[]>(`${this.baseUrl}/payment-methods/${this.business}`);
    }

    //  GET: payment-status/{registrationId}
    getPaymentStatusForRegistration(registrationId: string): Observable<PaymentStatusResponse> {
        return this.http.get<PaymentStatusResponse>(`${this.baseUrl}/payment-status/${registrationId}`);
    }

    // POST: start-payment
    startPayment(startPaymentRequest: StartPaymentRequest): Observable<StartPaymentResponse> {
        // API will returnDictionary<string, string>
        return this.http.post<StartPaymentResponse>(`${this.baseUrl}/start-payment`, startPaymentRequest);
    }

    // get: payment-transfer-details
    getPaymentTransferDetails(registrationId: string): Observable<PaymentTransferDetailsResponse> {
        // API will returnDictionary<string, string>
        return this.http.get<PaymentTransferDetailsResponse>(`${this.baseUrl}/payment-transfer-details/${registrationId}`);
    }
}
