<app-hero [headerKey]="heroCardMessageKey"></app-hero>

<div class="fluid-container bg-highlight-one py-5">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <div class="card shadow border-0 mb-1">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <form
                                    *ngIf="showForm"
                                    class="mt-3"
                                    [formGroup]="form"
                                    (ngSubmit)="onSubmit()"
                                    novalidate
                                    appfocusFirstInvalidField
                                >
                                    <div
                                        [innerHTML]="
                                            'contact-form-summary'
                                                | cmsText
                                                    : [
                                                          {
                                                              faqUrl:
                                                                  '/' +
                                                                  communityCode +
                                                                  '/faq'
                                                          },
                                                          {
                                                              contactPhoneNumberLink:
                                                                  (contactPhoneNumberLink$
                                                                  | async)
                                                          }
                                                      ]
                                                | async
                                        "
                                    ></div>
                                    <hr class="my-3" />
                                    <h4 class="mb-3">
                                        {{
                                            'contact-form-title'
                                                | cmsText
                                                | async
                                        }}
                                    </h4>
                                    <div class="row">
                                        <div class="col-md-6 mb-3">
                                            <label for="salutation">
                                                {{
                                                    'salutation-title'
                                                        | cmsText
                                                        | async
                                                }}
                                            </label>
                                            <div class="form-check">
                                                <input
                                                    type="radio"
                                                    [ngClass]="{
                                                        'form-check-input': true,
                                                        'is-invalid':
                                                            shouldShowSalutationErrors
                                                    }"
                                                    id="Meneer"
                                                    formControlName="salutation"
                                                    value="Mr"
                                                />
                                                <label
                                                    class="custom-control-label"
                                                    for="Meneer"
                                                >
                                                    {{
                                                        'salutation-mr-label'
                                                            | cmsText
                                                            | async
                                                    }}
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input
                                                    type="radio"
                                                    [ngClass]="{
                                                        'form-check-input': true,
                                                        'is-invalid':
                                                            shouldShowSalutationErrors
                                                    }"
                                                    id="Mevrouw"
                                                    formControlName="salutation"
                                                    value="Mrs"
                                                />
                                                <label
                                                    class="custom-control-label"
                                                    for="Mevrouw"
                                                >
                                                    {{
                                                        'salutation-mrs-label'
                                                            | cmsText
                                                            | async
                                                    }}
                                                </label>
                                            </div>
                                            <app-register-input-errors
                                                [shouldShowErrors]="
                                                    shouldShowSalutationErrors
                                                "
                                                [field]="salutation"
                                                label="salutation"
                                            >
                                            </app-register-input-errors>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3 mb-3">
                                            <label for="initials">
                                                {{
                                                    'initials-label'
                                                        | cmsText
                                                        | async
                                                }}
                                            </label>
                                            <input
                                                (blur)="formatInitials()"
                                                class="form-control"
                                                [ngClass]="{
                                                    'is-invalid':
                                                        shouldShowInitialsErrors
                                                }"
                                                id="initials"
                                                formControlName="initials"
                                            />
                                            <app-register-input-errors
                                                [shouldShowErrors]="
                                                    shouldShowInitialsErrors
                                                "
                                                [field]="initials"
                                                label="initials"
                                            >
                                            </app-register-input-errors>
                                        </div>
                                        <div class="col-md-3 mb-3">
                                            <label for="insertion">
                                                {{
                                                    'insertion-label'
                                                        | cmsText
                                                        | async
                                                }}
                                            </label>
                                            <input
                                                class="form-control"
                                                id="insertion"
                                                [ngClass]="{
                                                    'is-invalid':
                                                        shouldShowInsertionErrors
                                                }"
                                                formControlName="insertion"
                                            />
                                            <app-register-input-errors
                                                [shouldShowErrors]="
                                                    shouldShowInsertionErrors
                                                "
                                                [field]="insertion"
                                                label="insertion"
                                            >
                                            </app-register-input-errors>
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label for="lastName">
                                                {{
                                                    'surname-label'
                                                        | cmsText
                                                        | async
                                                }}
                                            </label>
                                            <input
                                                class="form-control"
                                                id="lastName"
                                                [ngClass]="{
                                                    'is-invalid':
                                                        shouldShowLastNameErrors
                                                }"
                                                formControlName="lastName"
                                            />
                                            <app-register-input-errors
                                                [shouldShowErrors]="
                                                    shouldShowLastNameErrors
                                                "
                                                [field]="lastName"
                                                label="surname"
                                            >
                                            </app-register-input-errors>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 mb-3">
                                            <label for="email">
                                                {{
                                                    'email-label'
                                                        | cmsText
                                                        | async
                                                }}
                                            </label>
                                            <input
                                                class="form-control"
                                                id="email"
                                                [ngClass]="{
                                                    'is-invalid':
                                                        shouldShowEmailErrors
                                                }"
                                                formControlName="email"
                                            />
                                            <app-register-input-errors
                                                [shouldShowErrors]="
                                                    shouldShowEmailErrors
                                                "
                                                [field]="email"
                                                label="email"
                                            >
                                            </app-register-input-errors>
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label for="registrationNumber">
                                                {{
                                                    'registration-number-label'
                                                        | cmsText
                                                        | async
                                                }}
                                            </label>
                                            <input
                                                class="form-control"
                                                id="registrationNumber"
                                                [ngClass]="{
                                                    'is-invalid':
                                                        shouldShowRegistrationNumberErrors
                                                }"
                                                formControlName="registrationNumber"
                                            />
                                            <app-register-input-errors
                                                [shouldShowErrors]="
                                                    shouldShowRegistrationNumberErrors
                                                "
                                                [field]="registrationNumber"
                                                label="registration-number"
                                            >
                                            </app-register-input-errors>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 mb-3">
                                            <label for="question">
                                                {{
                                                    'question-label'
                                                        | cmsText
                                                        | async
                                                }}
                                            </label>
                                            <select
                                                class="form-select"
                                                name="question"
                                                [ngClass]="{
                                                    'is-invalid':
                                                        shouldShowQuestionErrors
                                                }"
                                                formControlName="question"
                                            >
                                                <option disabled selected value>
                                                    {{
                                                        'option-choose-one'
                                                            | cmsText
                                                            | async
                                                    }}
                                                </option>
                                                <option
                                                    *ngFor="
                                                        let item of questionItems
                                                    "
                                                    [ngValue]="
                                                        item.id
                                                            | cmsText
                                                            | async
                                                    "
                                                >
                                                    {{
                                                        item.id
                                                            | cmsText
                                                            | async
                                                    }}
                                                </option>
                                            </select>
                                            <app-register-input-errors
                                                [shouldShowErrors]="
                                                    shouldShowQuestionErrors
                                                "
                                                [field]="question"
                                                label="question"
                                            >
                                            </app-register-input-errors>
                                        </div>
                                    </div>

                                    <div
                                        class="mb-3 row"
                                        *ngIf="maximumUploadSize > 0"
                                    >
                                        <div class="col-sm-3">
                                            <label for="uploadFile">
                                                {{
                                                    'upload-file-label'
                                                        | cmsText : [cmsData]
                                                        | async
                                                }}
                                            </label>
                                        </div>

                                        <div class="col-sm-9">
                                            <input
                                                formControlName="uploadFile"
                                                type="file"
                                                ng2FileSelect
                                                (onFileSelected)="
                                                    onFileChange($event)
                                                "
                                                [accept]="
                                                    this.allowedUploadMimeTypes
                                                "
                                                [uploader]="uploader"
                                                multiple
                                            />
                                            <app-register-input-errors
                                                [shouldShowErrors]="
                                                    shouldShowUploadFileErrors
                                                "
                                                [field]="uploadFile"
                                                [cmsParams]="cmsData"
                                                label="upload"
                                            >
                                            </app-register-input-errors>

                                            <div
                                                class="mt-2"
                                                *ngFor="
                                                    let item of uploader.queue
                                                "
                                            >
                                                <span>{{
                                                    item.file.name
                                                }}</span>
                                                <button
                                                    type="button"
                                                    class="btn btn-danger ms-2"
                                                    (click)="
                                                        removeFileFromQueue(
                                                            item
                                                        )
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-trash"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {{
                                                        'contact-delete-attachment'
                                                            | cmsText
                                                            | async
                                                    }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6 mb-3">
                                            <label for="message">
                                                {{
                                                    'message-label'
                                                        | cmsText
                                                        | async
                                                }}
                                            </label>
                                            <textarea
                                                class="form-control"
                                                rows="5"
                                                id="message"
                                                formControlName="message"
                                            ></textarea>
                                            <app-register-input-errors
                                                [shouldShowErrors]="
                                                    shouldShowMessageErrors
                                                "
                                                [field]="message"
                                                label="message"
                                            >
                                            </app-register-input-errors>
                                        </div>
                                    </div>
                                    <hr class="my-3" />
                                    <div class="row">
                                        <div class="col-md-12">
                                            <app-register-error
                                                *ngIf="showError"
                                                errorKey="submit-contact-form-failed"
                                            >
                                            </app-register-error>
                                            <div
                                                class="button-wrapper btn-container-start"
                                            >
                                                <button
                                                    type="submit"
                                                    class="btn btn-primary px-5"
                                                    [disabled]="submitting"
                                                >
                                                    {{
                                                        'submit-contact-form'
                                                            | cmsText
                                                            | async
                                                    }}
                                                    <div *ngIf="submitting">
                                                        <app-loading-spinner
                                                            size="large"
                                                        >
                                                        </app-loading-spinner>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div
                                    *ngIf="showSuccess"
                                    class="alert alert-success"
                                    role="alert"
                                >
                                    {{
                                        'submit-contact-form-succeeded'
                                            | cmsText
                                            | async
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="d-grid">
                    <button
                        class="btn btn-primary mt-5 mt-md-0 rounded"
                        *ngIf="showRegistrationButton"
                        [routerLink]="'../register'"
                        [disabled]="
                            !(
                                this.registrationService.enableRegistrationFlow$
                                | async
                            )
                        "
                    >
                        <span
                            [innerHTML]="
                                this.registerButtonKey | cmsText | async
                            "
                        ></span>
                        <i class="fa fa-chevron-right ms-2"></i>
                    </button>
                </div>
                <app-contact-options
                    [communityCode]="communityCode"
                    [contactPhoneCmsKey]="'contact-phone'"
                    [showContactUsLink]="false"
                    [showFaqLink]="false"
                    class="d-flex my-3"
                    [useArrow]="true"
                >
                </app-contact-options>
            </div>
        </div>
    </div>
</div>
