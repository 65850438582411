<h5 [innerHTML]="'proposal-installation-content-title' | cmsText | async"></h5>
<div *ngIf="installation" class="card my-3 pb-3">
    <div class="row no-gutters">
        <div class="col-12 col-md-6 my-auto">
            <ul class="list-group list-group-flush">
                <li class="list-group-item border-0">
                    <i class="text-primary fa fa-check"></i>&nbsp;
                    <span
                        [innerHTML]="
                            'proposal-installation-inspection-label'
                                | cmsText
                                | async
                        "
                    ></span>
                    <app-spnl-tooltip
                        cmsKey="proposal-installation-inspection-tooltip"
                    ></app-spnl-tooltip>
                </li>
                <li class="list-group-item border-0">
                    <i class="text-primary fa fa-check"></i>&nbsp;
                    <span
                        [innerHTML]="
                            'proposal-installation-amount-of-panels-label'
                                | cmsText
                                    : [
                                          {
                                              numberOfPanels:
                                                  numberOfPanelsChosen,
                                              panelType: premiumPanelsChosen
                                                  ? 'premium'
                                                  : ''
                                          }
                                      ]
                                | async
                        "
                    ></span>
                    <app-spnl-tooltip
                        cmsKey="proposal-installation-amount-of-panels-tooltip"
                    >
                    </app-spnl-tooltip>
                </li>
                <li class="list-group-item border-0">
                    <i class="text-primary fa fa-check"></i>&nbsp;
                    <span
                        [innerHTML]="
                            'proposal-installation-transformer-label'
                                | cmsText
                                | async
                        "
                    ></span>
                    <app-spnl-tooltip
                        cmsKey="proposal-installation-transformer-tooltip"
                    ></app-spnl-tooltip>
                </li>
                <li class="list-group-item border-0">
                    <i class="text-primary fa fa-check"></i>&nbsp;
                    <span
                        [innerHTML]="
                            'proposal-installation-mounting-material-label'
                                | cmsText
                                | async
                        "
                    ></span>
                    <app-spnl-tooltip
                        cmsKey="proposal-installation-mounting-material-tooltip"
                    >
                    </app-spnl-tooltip>
                </li>
                <li class="list-group-item border-0">
                    <i class="text-primary fa fa-check"></i>&nbsp;
                    <span
                        [innerHTML]="
                            'proposal-installation-label' | cmsText | async
                        "
                    ></span>
                    <app-spnl-tooltip
                        cmsKey="proposal-installation-tooltip"
                    ></app-spnl-tooltip>
                </li>
                <li class="list-group-item border-0">
                    <i class="text-primary fa fa-check"></i>&nbsp;
                    <span
                        [innerHTML]="
                            'proposal-installation-monitoring-label'
                                | cmsText
                                | async
                        "
                    ></span>
                    <app-spnl-tooltip
                        cmsKey="proposal-installation-monitoring-tooltip"
                    ></app-spnl-tooltip>
                </li>
                <li class="list-group-item border-0">
                    <i class="text-primary fa fa-check"></i>&nbsp;
                    <span
                        [innerHTML]="
                            'proposal-installation-guarantees-label'
                                | cmsText
                                | async
                        "
                    ></span>
                    <app-spnl-tooltip
                        cmsKey="proposal-installation-guarantees-tooltip"
                    ></app-spnl-tooltip>
                </li>
                <li
                    *ngIf="showCommunityMembershipDisclaimer"
                    class="list-group-item border-0"
                >
                    <i class="text-primary fa fa-check"></i>&nbsp;
                    <span
                        [innerHTML]="
                            'proposal-installation-community-membership-label'
                                | cmsText
                                | async
                        "
                    ></span>
                    <app-spnl-tooltip
                        cmsKey="proposal-installation-community-membership-tooltip"
                    >
                    </app-spnl-tooltip>
                    <span
                        class="small d-flex pl-4"
                        [innerHTML]="
                            'proposal-installation-community-membership-text'
                                | cmsText
                                    : [
                                          {
                                              price:
                                                  (communityMembershipPrice
                                                  | cmsText
                                                  | async)
                                          }
                                      ]
                                | async
                        "
                    ></span>
                </li>
            </ul>
            <div class="col m-3" *ngIf="showProposalDetails">
                <ul class="fa-ul">
                    <li>
                        <a
                            href="{{
                                supplierProductSpecsFile | cmsText | async
                            }}"
                            (click)="analyticsEvent('product-specs-pdf-opened')"
                            target="_blank"
                        >
                            <i
                                class="fa-li fa fa-paperclip"
                                aria-hidden="true"
                            ></i>
                            <span
                                [innerHTML]="
                                    'proposal-product-specs-title'
                                        | cmsText
                                        | async
                                "
                            ></span>
                        </a>
                    </li>
                    <li>
                        <a
                            href="{{
                                'information-brochure-pdf' | cmsText | async
                            }}"
                            (click)="
                                analyticsEvent(
                                    'information-brochure-pdf-opened'
                                )
                            "
                            target="_blank"
                        >
                            <i
                                class="fa-li fa fa-paperclip"
                                aria-hidden="true"
                            ></i>
                            <span
                                [innerHTML]="
                                    'proposal-product-information-brochure'
                                        | cmsText
                                        | async
                                "
                            ></span>
                        </a>
                    </li>
                </ul>
                <app-tooltip-on-word
                    [cmsKeyWord]="'what-is-not-in-the-packet'"
                    [cmsKeyTooltipHeader]="'what-is-not-in-the-packet'"
                    [cmsKeyTooltipBody]="'what-is-not-in-the-packet-body'"
                >
                </app-tooltip-on-word>
            </div>
        </div>
        <div class="col-12 col-md-6 text-center my-auto border-left">
            <img
                class="rounded img-fluid"
                [attr.src]="supplierProductImage | cmsImageSrc | async"
                [attr.alt]="supplierProductImage | cmsImageAlt | async"
            />
        </div>
    </div>
</div>
