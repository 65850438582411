import { Component, OnDestroy, ElementRef } from '@angular/core';
import { ContentService } from '../../services/content.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-cms-keys-bar',
    templateUrl: './cms-keys-bar.component.html'
})
export class CmsKeysBarComponent implements OnDestroy {

    private cmsKeys: { key: string, url: string, val: string }[];
    private subscription: Subscription;

    showBar = false;

    constructor(contentService: ContentService, private elementRef: ElementRef) {
        this.cmsKeys = [];
        this.subscription = contentService.visibleCmsKeys.asObservable().subscribe(val => {
            this.cmsKeys.push(val);
            if (this.showBar !== contentService.showLinksToCms) {
                this.showBar = contentService.showLinksToCms;
            }
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    expose() {
        this.cmsKeys.forEach(cms => {
            this.getMatchingElements(cms.key).forEach((element: any) => {
                element.dataset['cmsmarked'] = 'true';
                element.insertAdjacentHTML(element.tagName === 'A' ? 'beforebegin' : 'beforeend',
                    `<a target="_blank" rel="noopener noreferrer" href="${cms.url}"><i class="ml-2 fa fa-link"></i></a>`);
            });
        });
    }

    private getMatchingElements(key: string) {
        const matchingElements = Array.prototype.slice.call(
            this.elementRef.nativeElement.parentElement.getElementsByTagName('*'))
            .filter((e: any) => (e.textContent.trim() === `{{${key}}}`.trim())
                && e.dataset['cmsmarked'] !== 'true' && (e.childElementCount === 0
                    || e.childElementCount === 1 && e.children[0].tagName === 'I'));
        return matchingElements;
    }
}
