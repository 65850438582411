import { environmentTest } from '../../_common/environments/environments';

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    apiUrl: null,
    commonApiUri: null,
    identityUri: null,
    customDomainIdentityUris: [null],
    cookiePolicyUrlMapping: {
        default:
            '',
    },
    googleApiKey: environmentTest.googleApiKey,
    allowLinksToCms: true,
    analyticsUri: null,
    paymentsApiUri: null,
    cmsUri: null,
    aiConnectionString: null
};
