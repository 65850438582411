<div class="card shadow my-3">
    <h5
        class="card-header border-0"
        [innerHTML]="'proposal-attachments-title' | cmsText | async"
    ></h5>
    <ul class="list-group list-group-flush">
        <li class="list-group-item">
            <ul class="fa-ul">
                <li>
                    <a
                        href="{{ supplierProductSpecsFile | cmsText | async }}"
                        target="_blank"
                    >
                        <i class="fa-li fa fa-paperclip" aria-hidden="true"></i>
                        <span
                            id="proposal-product-specs-title"
                            [innerHTML]="
                                'proposal-product-specs-title' | cmsText | async
                            "
                        ></span>
                    </a>
                </li>
            </ul>
        </li>
        <li class="list-group-item">
            <ul class="fa-ul">
                <li>
                    <a
                        href="{{ 'proposal-po-documents' | cmsText | async }}"
                        target="_blank"
                    >
                        <i class="fa-li fa fa-paperclip" aria-hidden="true"></i>
                        <span
                            [innerHTML]="
                                'proposal-po-documents-title' | cmsText | async
                            "
                        ></span>
                    </a>
                    <p class="mt-2 ms-2">
                        {{ 'proposal-po-documents-text' | cmsText | async }}
                    </p>
                </li>
            </ul>
        </li>
        <li class="list-group-item">
            <ul class="fa-ul">
                <li>
                    <a
                        href="{{
                            'proposal-more-work-documents' | cmsText | async
                        }}"
                        target="_blank"
                    >
                        <i class="fa-li fa fa-paperclip" aria-hidden="true"></i>
                        <span
                            [innerHTML]="
                                'proposal-more-work-documents-title'
                                    | cmsText
                                    | async
                            "
                        ></span>
                    </a>
                </li>
            </ul>
        </li>
        <li class="list-group-item">
            <ul class="fa-ul">
                <li>
                    <a
                        href="{{ supplierConditionsFile | cmsText | async }}"
                        target="_blank"
                    >
                        <i class="fa-li fa fa-paperclip" aria-hidden="true"></i>
                        <span
                            [innerHTML]="
                                'proposal-conditions-documents-title'
                                    | cmsText
                                    | async
                            "
                        ></span>
                    </a>
                </li>
            </ul>
        </li>
    </ul>
</div>
