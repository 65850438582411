import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';

import { WINDOW } from '@common/services/window.service';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class DevelopmentGuard {
    WHITELIST_DOMAINS = [
        'eigenhuis-zonnepanelen.ichoosr.com',
        'eigenhuis-zon.ichoosr.com',
        'collectieve-inkoop-zonnepanelen.eigenhuis.nl',
    ];

    constructor(
        @Inject(WINDOW) private window: Window,
        private router: Router,
    ) {}

    // Prevents routes from activating on production
    canActivate(_next: ActivatedRouteSnapshot): true | UrlTree {
        const isDevelopment = !environment.production;
        const isInWhitelist = this.WHITELIST_DOMAINS.includes(
            this.window.location.hostname,
        );
        return isDevelopment || isInWhitelist
            ? true
            : this.router.parseUrl('/error/');
    }
}
