<h3 class="my-3">{{ 'excluded-header' | cmsText | async }}</h3>
<hr class="my-3" />

<app-hero
    heroImageKey="hero-image-overview"
    heroImageClass="hero-image-medium mb-3"
></app-hero>

<span [innerHTML]="'excluded-intro-text' | cmsText | async"></span>
<hr class="my-3" />

<div class="d-flex justify-content-between flex-wrap">
    <button
        class="btn btn-primary px-5 mt-2 mb-2"
        id="revertExclusionBtn"
        type="button"
        (click)="revertExclusion()"
    >
        {{ 'excluded-revert-exclusion-text' | cmsText | async }}
    </button>
    <a
        class="btn btn-primary px-5 mt-2 mb-2"
        *ngIf="!registration.proposalMade"
        href="https://www.eigenhuis.nl/diensten/"
    >
        {{ 'excluded-veh-navigation-text' | cmsText | async }}
    </a>
</div>
