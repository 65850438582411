<hr class="my-3" />
<h4
    class="mb-3"
    [innerHTML]="
        'overview-next-steps-title'
            | cmsText : [{ supplierName: registration.supplierName }]
            | async
    "
></h4>

<span
    [innerHTML]="
        nextStepsCmsKey
            | cmsText
                : [
                      {
                          supplierName: registration.supplierName,
                          informDate: informDateExternal | date : 'EEEE, d MMMM'
                      }
                  ]
            | async
    "
></span>
