<form
    class="mt-3"
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
    novalidate
    appfocusFirstInvalidField
>
    <h4 class="mb-3">{{ 'cancel-form-header' | cmsText | async }}</h4>
    <div
        [innerHTML]="
            'cancel-form-description'
                | cmsText : [{ supplierName: supplier?.name }]
                | async
        "
    ></div>
    <div class="mb-3 my-4 radio">
        <div class="row">
            <div class="col">
                <label for="assessmentStatus">
                    {{
                        'cancel-form-assessment-status-label' | cmsText | async
                    }}
                </label>
                <div
                    class="form-check"
                    *ngFor="let status of assessmentStatuses"
                >
                    <input
                        type="radio"
                        name="assessmentStatus"
                        class="form-check-input"
                        [class.is-invalid]="
                            assessmentStatus.invalid &&
                            (assessmentStatus.dirty || submitRequested)
                        "
                        formControlName="assessmentStatus"
                        [id]="assessmentStatusKey(status)"
                        [value]="status"
                    />
                    <label
                        class="custom-control-label"
                        [for]="assessmentStatusKey(status)"
                    >
                        {{ assessmentStatusKey(status) | cmsText | async }}
                    </label>
                </div>
                <div
                    *ngIf="
                        assessmentStatus.invalid &&
                        (assessmentStatus.dirty || submitRequested)
                    "
                >
                    <span
                        *ngIf="assessmentStatus.errors.required"
                        class="small text-danger"
                    >
                        <i class="fa fa-exclamation-circle me-1"></i>
                        {{ 'cancel-form-required-field' | cmsText | async }}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="mb-3 my-4 radio">
        <div class="row">
            <div class="col">
                <label for="whoInitiatedCancellation">
                    {{
                        'cancel-form-who-initiated-cancellation-label'
                            | cmsText
                            | async
                    }}
                </label>
                <div
                    class="form-check"
                    *ngFor="
                        let whoInitiatedCancellation of whoInitiatedCancellations$
                            | async
                            | shuffle
                    "
                >
                    <input
                        type="radio"
                        name="whoInitiatedCancellation"
                        class="form-check-input"
                        [class.is-invalid]="
                            whoInitiatedCancellation.invalid &&
                            (whoInitiatedCancellation.dirty || submitRequested)
                        "
                        formControlName="whoInitiatedCancellation"
                        [id]="
                            whoInitiatedCancellationKey(
                                whoInitiatedCancellation
                            )
                        "
                        [value]="whoInitiatedCancellation"
                    />
                    <label
                        class="custom-control-label"
                        [for]="
                            whoInitiatedCancellationKey(
                                whoInitiatedCancellation
                            )
                        "
                    >
                        {{
                            whoInitiatedCancellationKey(
                                whoInitiatedCancellation
                            )
                                | cmsText
                                | async
                        }}
                    </label>
                </div>
                <div
                    *ngIf="
                        whoInitiatedCancellation.invalid &&
                        (whoInitiatedCancellation.dirty || submitRequested)
                    "
                >
                    <span
                        *ngIf="whoInitiatedCancellation.errors.required"
                        class="small text-danger"
                    >
                        <i class="fa fa-exclamation-circle me-1"></i>
                        {{ 'cancel-form-required-field' | cmsText | async }}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="mb-3">
        <div class="row">
            <div class="col">
                <label for="explanation">
                    {{ 'cancel-form-explanation-label' | cmsText | async }}
                </label>
                <div class="mb-2">
                    {{
                        'cancel-form-explanation-description' | cmsText | async
                    }}
                </div>
                <textarea
                    class="form-control"
                    rows="5"
                    id="explanation"
                    formControlName="explanation"
                ></textarea>
                <div
                    *ngIf="
                        explanation.invalid &&
                        (explanation.dirty || submitRequested)
                    "
                >
                    <span
                        *ngIf="explanation.errors.required"
                        class="small text-danger"
                    >
                        <i class="fa fa-exclamation-circle me-1"></i>
                        {{ 'cancel-form-required-field' | cmsText | async }}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="mb-3 my-4 radio">
        <div class="row">
            <div class="col">
                <label for="cancellationReason">
                    {{
                        'cancel-form-cancellation-reason-label'
                            | cmsText
                            | async
                    }}
                </label>
                <div class="mb-2">
                    {{
                        'cancel-form-cancellation-reason-description'
                            | cmsText
                            | async
                    }}
                </div>
                <div
                    class="form-check"
                    *ngFor="
                        let reason of cancellationReasons$
                            | async
                            | shuffle : { keepLastInPlace: true }
                    "
                >
                    <input
                        type="radio"
                        name="cancellationReason"
                        class="form-check-input"
                        [class.is-invalid]="
                            cancellationReason.invalid &&
                            (cancellationReason.dirty || submitRequested)
                        "
                        formControlName="cancellationReason"
                        [id]="cancellationReasonKey(reason)"
                        [value]="reason"
                    />
                    <label
                        class="custom-control-label"
                        [for]="cancellationReasonKey(reason)"
                    >
                        {{ cancellationReasonKey(reason) | cmsText | async }}
                    </label>
                </div>
                <div
                    *ngIf="
                        cancellationReason.invalid &&
                        (cancellationReason.dirty || submitRequested)
                    "
                >
                    <span
                        *ngIf="cancellationReason.errors.required"
                        class="small text-danger"
                    >
                        <i class="fa fa-exclamation-circle me-1"></i>
                        {{ 'cancel-form-required-field' | cmsText | async }}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="mb-3 my-4 radio">
        <div class="row">
            <div class="col">
                <label for="cancellationSubreason">
                    {{
                        'cancel-form-cancellation-subreason-label'
                            | cmsText
                            | async
                    }}
                </label>
                <div class="mb-2">
                    {{
                        'cancel-form-cancellation-subreason-description'
                            | cmsText
                            | async
                    }}
                </div>
                <div *ngIf="cancellationReason.value">
                    <div
                        class="form-check"
                        *ngFor="
                            let reason of cancellationSubreasons
                                | shuffle : { keepLastInPlace: keepLastInPlace }
                        "
                    >
                        <input
                            type="radio"
                            name="cancellationSubreason"
                            class="form-check-input"
                            [class.is-invalid]="
                                cancellationSubreason.invalid &&
                                (cancellationSubreason.dirty || submitRequested)
                            "
                            formControlName="cancellationSubreason"
                            [id]="cancellationSubreasonKey(reason)"
                            [value]="reason"
                        />
                        <label
                            class="custom-control-label"
                            [for]="cancellationSubreasonKey(reason)"
                        >
                            {{
                                cancellationSubreasonKey(reason)
                                    | cmsText
                                    | async
                            }}
                        </label>
                    </div>
                </div>
                <div *ngIf="!cancellationReason.value">
                    <span
                        *ngIf="cancellationSubreason.errors.required"
                        class="small text-warning"
                    >
                        <i class="fa fa-exclamation-circle me-1"></i>
                        {{
                            'cancel-form-cancellation-subreason-no-reason-selected'
                                | cmsText
                                | async
                        }}
                    </span>
                </div>
                <div *ngIf="!cancellationReasonHasSubreasons">
                    <span class="small">
                        <i class="fa fa-exclamation-circle me-1"></i>
                        {{
                            'cancel-form-cancellation-subreason-no-reason-available'
                                | cmsText
                                | async
                        }}
                    </span>
                </div>

                <div
                    *ngIf="
                        cancellationSubreason.invalid &&
                        (cancellationSubreason.dirty || submitRequested)
                    "
                >
                    <span
                        *ngIf="cancellationSubreason.errors.required"
                        class="small text-danger"
                    >
                        <i class="fa fa-exclamation-circle me-1"></i>
                        {{ 'cancel-form-required-field' | cmsText | async }}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="mb-3 my-4 radio">
        <div class="row">
            <div class="col">
                <label>
                    {{
                        'wants-to-be-involved-in-future-auctions-label'
                            | cmsText
                            | async
                    }}
                </label>
                <div class="form-check">
                    <input
                        type="radio"
                        name="wantsToBeInvolvedInFutureAuctions"
                        class="form-check-input"
                        formControlName="wantsToBeInvolvedInFutureAuctions"
                        id="wantsToBeInvolvedInFutureAuctionsYes"
                        [value]="true"
                    />
                    <label
                        class="custom-control-label"
                        for="wantsToBeInvolvedInFutureAuctionsYes"
                    >
                        {{
                            'wants-to-be-involved-in-future-auctions-yes'
                                | cmsText
                                | async
                        }}
                    </label>
                </div>
                <div class="form-check">
                    <input
                        id="wantsToBeInvolvedInFutureAuctionsNo"
                        type="radio"
                        name="wantsToBeInvolvedInFutureAuctions"
                        class="form-check-input"
                        formControlName="wantsToBeInvolvedInFutureAuctions"
                        [value]="false"
                    />
                    <label
                        class="custom-control-label"
                        for="wantsToBeInvolvedInFutureAuctionsNo"
                    >
                        {{
                            'wants-to-be-involved-in-future-auctions-no'
                                | cmsText
                                | async
                        }}
                    </label>
                </div>
                <div
                    *ngIf="
                        wantsToBeInvolvedInFutureAuctions.invalid &&
                        (wantsToBeInvolvedInFutureAuctions.dirty ||
                            submitRequested)
                    "
                >
                    <span
                        *ngIf="
                            wantsToBeInvolvedInFutureAuctions.errors.required
                        "
                        class="small text-danger"
                    >
                        <i class="fa fa-exclamation-circle me-1"></i>
                        {{ 'cancel-form-required-field' | cmsText | async }}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="mb-3">
        <div class="row">
            <div class="col">
                <div class="mb-2">
                    {{ 'cancel-form-submit-description' | cmsText | async }}
                </div>
                <div
                    *ngIf="showSubmitError"
                    class="alert alert-danger"
                    role="alert"
                >
                    {{ 'submit-cancel-form-failed' | cmsText | async }}
                </div>
                <button
                    type="submit"
                    class="btn btn-success"
                    [disabled]="submitting"
                >
                    {{ 'submit-cancel-form' | cmsText | async }}
                    <div *ngIf="submitting">
                        <app-loading-spinner size="large"></app-loading-spinner>
                    </div>
                </button>
            </div>
        </div>
    </div>
</form>
