import { Component, Input, OnInit } from '@angular/core';
import { ContentService } from '@common/services/content.service';
import { first } from 'rxjs/operators';

// Important to know
// **********************
// this.imageSource should be a CMS key of the type faqCategory. This will contain
// a header CMS Key (this.carouselHeader) which can be Markdown or text. It will also have
// 1-to-many itemKeys which should be taregtted to Image CMS keys.
//
// All of the inputs here have default values - but can be overridden for flexibility

@Component({
	selector: 'app-carousel-component',
	templateUrl: './carousel.component.html',
})
export class CarouselComponent implements OnInit  {

    @Input() showNavigationArrows = true;
    @Input() showNavigationIndicators = true;
    @Input() imageViewerDirective = false;
    @Input() wrap = false;
    @Input() animation = false;
    @Input() interval = 0;
    @Input() keyboard = false;
    @Input() carouselClass = "carousel-holder carousel carousel-dark slide";
    @Input() imageClass = "carousel-image rounded img-fluid";
    @Input() imageSource : string;

    images : Array<string>;
    carouselHeader: string;

    constructor(  private contentService: ContentService) {}

    ngOnInit(): void {
        if(this.imageSource)
        {
            this.contentService.get(this.imageSource)
            .pipe(first())
            .subscribe((list)=>{
                this.images = [];
                this.carouselHeader = list.value.title;
                for(var i in list.value.itemKeys)
                {
                    this.images.push(list.value.itemKeys[i]);
                }
            })
        }
	}
}
