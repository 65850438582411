import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommandComponent } from '@common/components/command/command.component';
import { CookiePolicyComponent } from '@common/components/cookie-policy/cookie-policy.component';
import { DisclaimerComponent } from '@common/components/disclaimer/disclaimer.component';
import { ErrorComponent } from '@common/components/error/error.component';
import { CancelComponent } from './components/cancel/cancel.component';
import { ContactPreferencesComponent } from './components/contact-preferences/contact-preferences.component';
import { ContactComponent } from './components/contact/contact.component';
import { ErrorStepComponent } from './components/error-step/error-step.component';
import { FaqComponent } from './components/faq/faq.component';
import { PrivacyStatementComponent } from './components/privacy-statement/privacy-statement.component';
import { CancelledComponent } from './components/register/cancelled/cancelled.component';
import { ClosedComponent } from './components/register/closed/closed.component';
import { DecisionMadeComponent } from './components/register/decision-made/decision-made.component';
import { OverviewComponent } from './components/register/overview/overview.component';
import { PersonComponent } from './components/register/person/person.component';
import { ProductComponent } from './components/register/product/product.component';
import { ProposalComponent } from './components/register/proposal/proposal.component';
import { RegisterRedirectComponent } from './components/register/register-redirect.component';
import { RegisterComponent } from './components/register/register.component';
import { WelcomeComponent } from './components/register/welcome/welcome.component';
import { SeminarComponent } from './components/seminar/seminar.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { UserChangedComponent } from './components/user-changed/user-changed.component';
import { DevelopmentGuard } from './guards/development-guard.guard';
import { ParticipantStatusGuard } from './guards/participant-status-guard.guard';
import { ValidCommunityGuardGuard } from './guards/valid-community-guard.guard';
import { RegisterRedirectRouteMatcher } from './routing/register-redirect.route';
import { RedirectToPreferenceCenterComponent } from './components/redirect-to-preference-center/redirect-to-preference-center-component';
import { TargetedRolloverCommandComponent } from '@spnl/components/targeted-rollover-command/targeted-rollover-command.component';
import { RedirectFromSocialComponent } from './components/redirect-from-social/redirect-from-social.component';
import { RedirectToSocialComponent } from './components/redirect-to-social/redirect-to-social.component';
import { TellAFriendLandingPageComponent } from './components/tell-a-friend-landing-page/tell-a-friend-landing-page.component';

const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: 'veh/register' },
    {
        // TODO (SPNL-542) Re-enable when register flow is production-ready
        path: '',
        canActivate: [DevelopmentGuard],
        children: [
            { path: 'faq/:id', component: FaqComponent },
            { path: 'faq', component: FaqComponent },
        ],
    },

    { path: 'error', component: ErrorComponent },
    { path: 'error/:id', component: ErrorComponent },
    { path: 'user-changed', component: UserChangedComponent },
    { path: 'cookie-policy', component: CookiePolicyComponent },
    { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
    { path: 'privacy-statement', component: PrivacyStatementComponent },
    { path: 'disclaimer', component: DisclaimerComponent },
    { path: 'landen', component: TellAFriendLandingPageComponent },
    {
        // English named route used by the New Horizons base system, we redirect
        // to a SPNL (Dutch) route with the actual page.
        path: 'veh/preferences/:registration-id',
        component: RedirectToPreferenceCenterComponent,
    },
    { path: 'ichoosr/cancel/:id', redirectTo: 'veh/cancel/:id' }, // Backwards compatibility for existing cancellation links
    {
        path: ':community',
        canActivate: [ValidCommunityGuardGuard],
        children: [
            {
                path: 'unauthorized/:id',
                component: UnauthorizedComponent,
            },
            {
                path: 'cancel/:id',
                component: CancelComponent,
            },
            {
                // TODO (SPNL-542) Re-enable when register flow is production-ready
                path: '',
                canActivate: [DevelopmentGuard],
                children: [
                    { path: '', redirectTo: 'register', pathMatch: 'full' },
                    { path: 'faq/:id', component: FaqComponent },
                    { path: 'faq', component: FaqComponent },
                    {
                        // this matches: 'register/:id' where id is a guid
                        //               'register/'
                        canActivate: [ParticipantStatusGuard],
                        matcher: RegisterRedirectRouteMatcher,
                        component: RegisterRedirectComponent,
                    },
                    {
                        path: 'register',
                        component: RegisterComponent,
                        children: [
                            { path: 'person', component: PersonComponent },
                            { path: 'welcome', component: WelcomeComponent },
                            { path: 'proposal', component: ProposalComponent },
                            {
                                path: 'declined',
                                component: DecisionMadeComponent,
                            },
                            {
                                path: 'payment',
                                component: DecisionMadeComponent,
                            },
                            { path: 'overview', component: OverviewComponent },
                            {
                                path: 'product/:substep',
                                component: ProductComponent,
                            },
                            { path: 'product', component: ProductComponent },
                            { path: 'closed', component: ClosedComponent },
                            {
                                path: 'cancelled',
                                component: CancelledComponent,
                            },
                        ],
                    },
                    {
                        path: 'do/:id/:command',
                        component: CommandComponent,
                    },
                    {
                        path: 'rollover/:registrationId/:targetAuction',
                        component: TargetedRolloverCommandComponent,
                    },
                    {
                        path: 'seminar',
                        component: SeminarComponent,
                    },
                    {
                        path: 'seminar/:id',
                        component: SeminarComponent,
                    },
                    {
                        path: 'contact-voorkeuren',
                        component: ContactPreferencesComponent,
                    },
                ],
            },
            { path: 'contact/:id', component: ContactComponent },
            { path: 'contact', component: ContactComponent },
            { path: 'cookie-policy', component: CookiePolicyComponent },
            {
                path: 'terms-and-conditions',
                component: TermsAndConditionsComponent,
            },
            { path: 'privacy-statement', component: PrivacyStatementComponent },
            { path: 'disclaimer', component: DisclaimerComponent },
            { path: 'error', component: ErrorComponent },
            { path: 'error/:id', component: ErrorComponent },
            { path: 'error-step/:step', component: ErrorStepComponent },
        ],
    },
    {
        path: 'delen/:social-source',
        component: RedirectFromSocialComponent,
    },
    {
        path: 'redirect-to-social/:social-target',
        component: RedirectToSocialComponent,
    },
    { path: '**', redirectTo: '/error/community' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
