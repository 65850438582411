import { Component, Input, OnInit } from '@angular/core';
import { ContentService } from '@common/services/content.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-hero',
    templateUrl: './hero.component.html',
})
export class HeroComponent implements OnInit {
    jumbotronStyles$: Observable<any>;

    @Input()
    headerKey: string;

    @Input()
    heroImageKey = 'hero-image-small';

    @Input()
    heroImageClass = 'hero-image-small';

    constructor(private content: ContentService) {}

    ngOnInit(): void {
        this.jumbotronStyles$ = this.content.value(this.heroImageKey).pipe(
            map((heroImageUrl) => {
                return {
                    'background-image': 'url("' + heroImageUrl + '")',
                    'background-size': 'cover',
                };
            }),
        );
    }
}
