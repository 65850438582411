import { PaymentStatus } from '@common/model/payment';
import { isNullOrUndefined } from '@common/util';
import { Registration } from './registration';

export class StepContext {
    get subscriptionComplete(): boolean {
        return (
            this.hasValidPersonDetails &&
            this.hasValidProduct &&
            this.registration.subscriptionComplete
        );
    }

    get hasMinimalPersonDetails(): boolean {
        const registration = this.registration;
        const hasEmail = registration.email?.length > 0;
        const hasPhone = registration.contact?.phone?.length > 0;
        const hasCellPhone = registration.contact?.cellPhone?.length > 0;
        const hasAddress = registration.contact?.zip?.length > 0;

        return hasEmail || hasPhone || hasCellPhone || hasAddress;
    }

    get hasValidPersonDetails(): boolean {
        const hasCommunityMember = !isNullOrUndefined(
            this.registration.product.communityMember,
        );

        return (
            this.registration.hasValidContact &&
            hasCommunityMember
        );
    }

    get hasValidProduct(): boolean {
        return this.registration.hasValidProduct;
    }

    get hasNoProposal(): boolean {
        return !this.registration.proposalMade;
    }

    get hasUndecidedProposal(): boolean {
        return (
            this.registration.proposalMade &&
            !this.registration.decisionComplete
        );
    }

    get hasDeclinedProposal(): boolean {
        return (
            this.subscriptionComplete &&
            this.registration.proposalMade &&
            this.registration.decisionComplete &&
            !this.registration.proposalAccepted
        );
    }

    get hasAcceptedProposal(): boolean {
        return (
            this.subscriptionComplete &&
            this.registration.proposalMade &&
            this.registration.decisionComplete &&
            this.registration.proposalAccepted
        );
    }

    get hasSuccessfulPayment(): boolean {
        return this.registration.paymentStatus === PaymentStatus.Successful;
    }

    get hasPendingPayment(): boolean {
        return this.registration.paymentStatus === PaymentStatus.Pending;
    }

    constructor(public registration: Registration) {}
}
