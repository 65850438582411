import { Component, OnInit } from '@angular/core';
import { Registration } from '@spnl/model/registration';
import { RegisterStateService } from '@spnl/services/register-state.service';
import { RegisterStoreService } from '@spnl/services/register-store.service';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-register-closed',
    templateUrl: './closed.component.html',
})
export class ClosedComponent implements OnInit {
    // Will be set to false after CMS keys are loaded
    loading = true;

    private _key: string;

    get registration(): Registration {
        return this.registerStore.registration;
    }

    get headerCmsKey(): string {
        return `closed-${this._key}-header`;
    }

    get statusCmsKey(): string {
        return `closed-${this._key}-status`;
    }

    get descriptionCmsKey(): string {
        return `closed-${this._key}-description`;
    }

    constructor(
        private registerStore: RegisterStoreService,
        private registerStateService: RegisterStateService,
    ) {}

    ngOnInit(): void {
        this.registerStore.registrationLoaded$.pipe(first()).subscribe(() => {
            this._key = this.getClosedKey();
            this.loading = false;
        });
    }

    getClosedKey(): string {
        if (this.registration.auction?.finished) {
            return 'auction-finished';
        }

        if (this.registerStateService.paymentIsClosed(this.registration)) {
            return 'payment';
        }

        if (
            this.registerStateService.acceptanceClosedNoDecision(
                this.registration,
            )
        ) {
            return 'acceptance';
        }

        return 'default';
    }
}
