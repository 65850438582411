import { Component } from "@angular/core";

@Component({
    selector: 'app-proposal-video',
    templateUrl: './proposal-video.component.html',
})
export class ProposalVideoComponent {

    constructor() {}
}
