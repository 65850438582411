<ng-template #tooltipContent let-d="dismiss">
    <div class="modal-header">
        <h5 class="modal-title" id="tooltipModalLabel">
            {{ 'explanation' | cmsText | async }}
        </h5>
        <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="d()"
        ></button>
    </div>
    <div
        class="modal-body"
        [innerHtml]="cmsKey | cmsText : [cmsParams] | async"
    ></div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-outline-primary"
            (click)="d()"
            id="tooltip-close-button"
        >
            {{ 'tooltip-close-button' | cmsText | async }}
        </button>
    </div>
</ng-template>
<button
    type="button"
    class="fa fa-question-circle text-secondary tooltip-icon"
    tabindex="-1"
    (click)="openModal(tooltipContent)"
></button>
