import { Component, Input } from '@angular/core';
import { YesNoDontKnow } from '@spnl/model/decision.model';
import { RegisterStep } from '@spnl/model/register-step.enum';
import { CommunityMember, Registration } from '@spnl/model/registration';
import { ContentTranslatorService } from '@spnl/services/content-translator.service';
import {
    OnContinue,
    RegisterLocationService,
    Substep,
} from '@spnl/services/register-location.service';

@Component({
    selector: 'app-register-overview-change-details',
    templateUrl: './overview-change-details.component.html',
})
export class OverviewChangeDetailsComponent {
    @Input()
    registration: Registration;

    CommunityMember = CommunityMember;

    constructor(
        public contentTranslatorService: ContentTranslatorService,
        private locationService: RegisterLocationService,
    ) {}

    editPersonDetails() {
        this.locationService.goTo(
            RegisterStep.Person,
            new Substep.None(),
            OnContinue.BackToReferrerStep,
        );
    }

    editRoofDetails() {
        this.locationService.goTo(
            RegisterStep.Product,
            new Substep.None(),
            OnContinue.BackToReferrerStep,
        );
    }

    get hasRoofShadow(): boolean {
        return this.registration.product.hasRoofShadow !== YesNoDontKnow.No;
    }

    get hasRoofObjects(): boolean {
        return this.registration.product.hasRoofObjects !== YesNoDontKnow.No;
    }
}
