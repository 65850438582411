import { Injectable } from '@angular/core';
import { Registration } from '@spnl/model/registration';
import { RegistrationService } from './registration.service';

@Injectable()
export class RegistrationStoreService {
    private registration: Registration = null;

    constructor(private registrationService: RegistrationService) {
        this.registrationService
            .loadFromSessionStorage()
            .subscribe((registration) => (this.registration = registration));
    }

    setCurrentRegistration(registration: Registration): void {
        this.registration = registration;
    }

    getCurrentRegistration(): Registration {
        return this.registration;
    }
}
