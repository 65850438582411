import { Injectable, Inject, InjectionToken, Optional } from '@angular/core';
import { environment } from '../../environments/environment';
import { AppConfig } from '../model/app-config';

export const APP_CONFIG: InjectionToken<AppConfig> = new InjectionToken<any>(
    'Application Configuration',
);

@Injectable()
export class AppConfigService {
    constructor(@Optional() @Inject(APP_CONFIG) appConfig: AppConfig) {
        if (appConfig) {
            environment.production = appConfig.production;
            environment.allowLinksToCms = appConfig.allowLinksToCms;
            environment.apiUrl = appConfig.apiUrl;
            environment.identityUri = appConfig.identityUri;
            environment.customDomainIdentityUris =
                appConfig.customDomainIdentityUris;
            environment.cookiePolicyUrlMapping =
                appConfig.cookiePolicyUrlMapping;
            environment.analyticsUri = appConfig.analyticsUri;
            environment.cmsUri = appConfig.cmsUri;
            environment.googleApiKey = appConfig.googleApiKey;
            environment.paymentsApiUri = appConfig.paymentsApiUri;
            environment.aiConnectionString = appConfig.aiConnectionString;
        }
    }
}
