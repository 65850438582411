import { Component, OnInit } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    UntypedFormControl,
    AbstractControl,
} from '@angular/forms';
import { RegistrationService } from '@spnl/services/registration.service';
import { Observable, combineLatest } from 'rxjs';
import {
    map,
    shareReplay,
    takeUntil,
    switchMap,
    delay,
    finalize,
} from 'rxjs/operators';
import {
    GroupedPreferences,
    ProfilePreference,
} from '@country/nl/models/profile';
import { CancelRegistrationConfirmModalComponent } from './cancel-registration-confirm-modal.component';
import { PreferencesService } from '@country/nl/services/preferences.service';
import { Registration } from '../../model/registration';
import { DestroyableBase } from '../destroyable/destroyable.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContentService } from '@common/services/content.service';
import { RegisterStoreService } from '@spnl/services/register-store.service';
import { CommunityService } from '@common/services/community.service';
import { CancelRegistration } from '@common/model/cancel-registration';
import { RegisterEffectsService } from '@spnl/services/register-effects.service';
import {
    RegisterLocationService,
    Substep,
} from '@spnl/services/register-location.service';
import { RegisterStepService } from '@spnl/services/register-step.service';
@Component({
    selector: 'app-contact-preferences',
    templateUrl: './contact-preferences.component.html',
    styleUrls: ['./contact-preferences.component.scss'],
    providers: [RegisterEffectsService],
})
export class ContactPreferencesComponent
    extends DestroyableBase
    implements OnInit
{
    private preferences$: Observable<GroupedPreferences>;

    showSavePreferenceError = false;
    public isBusy = false;

    public business: string;
    public communityCode: string;

    public form$: Observable<UntypedFormGroup>;
    public profilePreferenceForm$: Observable<UntypedFormGroup>;
    public businessPreferencesForm$: Observable<UntypedFormGroup>;

    public isNotCancelledFormControl = new UntypedFormControl();
    public registrationDataForm = new UntypedFormGroup({
        isNotCancelled: this.isNotCancelledFormControl,
    });

    get registration(): Registration {
        return this.registerStore.registration;
    }

    constructor(
        private registrationService: RegistrationService,
        private registerStore: RegisterStoreService,
        private communityService: CommunityService,
        private fb: UntypedFormBuilder,
        private modalService: NgbModal,
        private contentService: ContentService,
        private preferencesService: PreferencesService,
        private registerEffects: RegisterEffectsService,
        private registerLocationService: RegisterLocationService,
        private registerStepService: RegisterStepService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.registerEffects.loadRegistration();

        this.registerStore.registrationLoaded$.subscribe(() => {
            if (this.registration.decisionComplete)
                this.isNotCancelledFormControl.disable();
            else this.isNotCancelledFormControl.enable();
            this.syncCancellationToggleWithRegistration();

            this.preferences$ =
                this.preferencesService.getGroupedPreferencesForRegistration(
                    this.registration,
                );

            this.form$ = this.preferences$.pipe(
                map((preferenceGroup) =>
                    this.mapPreferencesToForm(preferenceGroup),
                ),
                shareReplay(1),
            );

            this.businessPreferencesForm$ = this.form$.pipe(
                map(
                    (form) =>
                        form.get('businessPreferences') as UntypedFormGroup,
                ),
            );
        });

        this.communityService.community$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((community) => (this.communityCode = community.code));
    }

    onChangePreference(
        preferenceName: string,
        formControl: UntypedFormControl,
        business: string,
    ): void {
        const cms = this.contentService.get(
            this.convertToCmsFormat(preferenceName + '-' + business),
        );
        const profilePreference = this.preferences$.pipe(
            map((preferences) =>
                preferences.forBusiness[business].find(
                    (pref) => pref.name === preferenceName,
                ),
            ),
        );

        combineLatest([cms, profilePreference])
            .pipe(
                switchMap(([displayText, preference]) =>
                    this.savePreference(
                        new ProfilePreference({
                            name: preference.name,
                            isOptIn: formControl.value,
                            business: preference.business,
                            community: preference.community,
                            registrationId: preference.registrationId,
                            displayedPhrasing: displayText.value,
                        }),
                    ),
                ),
            )
            .subscribe({
                error: () => {
                    this.showSavePreferenceError = true;
                    formControl.setValue(!formControl.value);
                },
            });
    }

    onUpdateCancellation($target: EventTarget): void {
        // The toggle is worded as an opt-in instead, which can be confusing
        // so we clarify the code by inverting the `checked` value to a
        // meaningful boolean variable:
        const wantsToBeCancelled = !(<HTMLInputElement>$target).checked;

        if (!wantsToBeCancelled) {
            this.changeCancellationTo(wantsToBeCancelled);
        } else {
            this.modalService
                .open(CancelRegistrationConfirmModalComponent)
                .result.then(
                    (_) => this.changeCancellationTo(wantsToBeCancelled),
                    (_) => this.syncCancellationToggleWithRegistration(),
                );
        }
    }

    convertToCmsFormat(key: string): string {
        return key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
    }

    private changeCancellationTo(wantsToBeCancelled: boolean) {
        const cancelRegistration: CancelRegistration = {
            cancelled: wantsToBeCancelled,
        };

        this.registrationService
            .cancelAsUser(cancelRegistration, this.registration.id)
            .pipe(
                delay(1000),
                finalize(() => (this.isBusy = false)),
                takeUntil(this.destroyed$),
            )
            .subscribe(
                () => {
                    this.registration.cancelled = wantsToBeCancelled;
                },
                () => {
                    this.showSavePreferenceError = true;
                },
            );
    }

    private mapPreferencesToForm(
        preferences: GroupedPreferences,
    ): UntypedFormGroup {
        const preferencesPerBusinessLines = Object.entries(
            preferences.forBusiness,
        ).reduce((allBusinessesGroup, [business, businessPreferences]) => {
            allBusinessesGroup.registerControl(
                business,
                this.mapBusinessPreferencesToForm(businessPreferences),
            );
            return allBusinessesGroup;
        }, this.fb.group({}));

        return this.fb.group({
            businessPreferences: preferencesPerBusinessLines,
        });
    }

    private mapBusinessPreferencesToForm(
        businessPreferences: ProfilePreference[],
    ): UntypedFormGroup {
        return businessPreferences.reduce(
            (businessGroup: UntypedFormGroup, preference) => {
                businessGroup.registerControl(
                    preference.name,
                    this.fb.control(preference.isOptIn, []),
                );
                return businessGroup;
            },
            this.fb.group({}),
        );
    }

    private savePreference(preference: ProfilePreference) {
        return this.preferencesService.savePreferencesForRegistration(
            this.registration,
            [preference],
        );
    }

    private syncCancellationToggleWithRegistration(): void {
        this.isNotCancelledFormControl.setValue(!this.registration.cancelled);
    }

    back(): void {
        const step = this.registerStepService.getLatestAllowedStep(
            this.registerStore.registration,
        );
        this.registerLocationService.goTo(step, new Substep.Latest());
    }

    //ugly fix for strict template , refactor and unification of this component (check if it can be sheared betwean BLS )
    public toFormControl(control: AbstractControl): UntypedFormControl {
        return control as UntypedFormControl;
    }

    public toFormGroup(control: AbstractControl): UntypedFormGroup {
        return control as UntypedFormGroup;
    }
}
