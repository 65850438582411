export enum SolarPanelType {
    Blue = 'Blue',
    Black270 = 'Black270',
    Black275 = 'Black275',
    Black280 = 'Black280',
    Black290 = 'Black290',
    Black295 = 'Black295',
    Black300 = 'Black300',
    Black305 = 'Black305',
    Black310 = 'Black310',
    Black315 = 'Black315',
    Black320 = 'Black320',
    Black325 = 'Black325',
    Black330 = 'Black330',
    Black335 = 'Black335',
    Black340 = 'Black340',
    Black345 = 'Black345',
    Black350 = 'Black350',
    Black355 = 'Black355',
    Black360 = 'Black360',
    Black365 = 'Black365',
    Black370 = 'Black370',
    Black380 = 'Black380',
    Black385 = 'Black385',
    Black390 = 'Black390',
    Black395 = 'Black395',
    Black400 = 'Black400',
    Black405 = 'Black405',
    Black410 = 'Black410',
    Black415 = 'Black415',
    Black420 = 'Black420',
    Black425 = 'Black425',
    Black430 = 'Black430',
    Black435 = 'Black435',
    Black440 = 'Black440',
    Black445 = 'Black445',
    Black450 = 'Black450',
    Black455 = 'Black455',
    Black460 = 'Black460',
    Black465 = 'Black465',
    Black470 = 'Black470',
    Black475 = 'Black475',
    Black480 = 'Black480',
    Black485 = 'Black485',
    Black490 = 'Black490',
    BlackLegacy = 'BlackLegacy',
}

export namespace SolarPanelType {
    export const wattPeakMappings = [
        { solarPanelType: SolarPanelType.BlackLegacy, wattPeak: 305 },
        { solarPanelType: SolarPanelType.Black270, wattPeak: 270 },
        { solarPanelType: SolarPanelType.Blue, wattPeak: 275 },
        { solarPanelType: SolarPanelType.Black280, wattPeak: 280 },
        { solarPanelType: SolarPanelType.Black290, wattPeak: 290 },
        { solarPanelType: SolarPanelType.Black295, wattPeak: 295 },
        { solarPanelType: SolarPanelType.Black300, wattPeak: 300 },
        { solarPanelType: SolarPanelType.Black305, wattPeak: 305 },
        { solarPanelType: SolarPanelType.Black310, wattPeak: 310 },
        { solarPanelType: SolarPanelType.Black315, wattPeak: 315 },
        { solarPanelType: SolarPanelType.Black320, wattPeak: 320 },
        { solarPanelType: SolarPanelType.Black325, wattPeak: 325 },
        { solarPanelType: SolarPanelType.Black330, wattPeak: 330 },
        { solarPanelType: SolarPanelType.Black335, wattPeak: 335 },
        { solarPanelType: SolarPanelType.Black340, wattPeak: 340 },
        { solarPanelType: SolarPanelType.Black345, wattPeak: 345 },
        { solarPanelType: SolarPanelType.Black350, wattPeak: 350 },
        { solarPanelType: SolarPanelType.Black355, wattPeak: 355 },
        { solarPanelType: SolarPanelType.Black360, wattPeak: 360 },
        { solarPanelType: SolarPanelType.Black365, wattPeak: 365 },
        { solarPanelType: SolarPanelType.Black370, wattPeak: 370 },
        { solarPanelType: SolarPanelType.Black380, wattPeak: 380 },
        { solarPanelType: SolarPanelType.Black385, wattPeak: 385 },
        { solarPanelType: SolarPanelType.Black390, wattPeak: 390 },
        { solarPanelType: SolarPanelType.Black395, wattPeak: 395 },
        { solarPanelType: SolarPanelType.Black400, wattPeak: 400 },
        { solarPanelType: SolarPanelType.Black405, wattPeak: 405 },
        { solarPanelType: SolarPanelType.Black410, wattPeak: 410 },
        { solarPanelType: SolarPanelType.Black415, wattPeak: 415 },
        { solarPanelType: SolarPanelType.Black420, wattPeak: 420 },
        { solarPanelType: SolarPanelType.Black425, wattPeak: 425 },
        { solarPanelType: SolarPanelType.Black430, wattPeak: 430 },
        { solarPanelType: SolarPanelType.Black435, wattPeak: 435 },
        { solarPanelType: SolarPanelType.Black440, wattPeak: 440 },
        { solarPanelType: SolarPanelType.Black445, wattPeak: 445 },
        { solarPanelType: SolarPanelType.Black450, wattPeak: 450 },
        { solarPanelType: SolarPanelType.Black455, wattPeak: 455 },
        { solarPanelType: SolarPanelType.Black460, wattPeak: 460 },
        { solarPanelType: SolarPanelType.Black465, wattPeak: 465 },
        { solarPanelType: SolarPanelType.Black470, wattPeak: 470 },
        { solarPanelType: SolarPanelType.Black475, wattPeak: 475 },
        { solarPanelType: SolarPanelType.Black480, wattPeak: 480 },
        { solarPanelType: SolarPanelType.Black485, wattPeak: 485 },
        { solarPanelType: SolarPanelType.Black490, wattPeak: 490 },
    ];

    export const getWattPeak = (solarPanelType: SolarPanelType) => {
        return SolarPanelType.wattPeakMappings.find(
            (m) => m.solarPanelType === solarPanelType,
        )?.wattPeak;
    };
}
