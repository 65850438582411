import { PipeTransform, Pipe } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({ name: 'currencyWithPostfix' })
export class CurrencyWithPostfixPipe
    extends CurrencyPipe
    implements PipeTransform
{
    // See: https://stackoverflow.com/a/64806953
    // For why these overloads are needed.
    override transform(
        value: number | string,
        currencyCode?: string,
        display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
        digitsInfo?: string,
        locale?: string,
    ): string | null;
    override transform(
        value: null | undefined,
        currencyCode?: string,
        display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
        digitsInfo?: string,
        locale?: string,
    ): null;
    override transform(
        value: number | string | null | undefined,
        currencyCode?: string,
        display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
        digitsInfo?: string,
        locale?: string,
    ): string | null;
    override transform(
        value: any,
        currencyCode: string = 'EUR',
        display:
            | 'code'
            | 'symbol'
            | 'symbol-narrow'
            | string
            | boolean = 'symbol-narrow',
        digitsInfo: string = '1.0-0',
    ) {
        if (!value || isNaN(Number(value))) {
            if (value !== 0 && value !== '0') {
                return null;
            }
        }

        const postFix = ',-';
        const numWithCurrency = super.transform(
            value,
            currencyCode,
            display,
            digitsInfo,
        );

        return `${numWithCurrency}${postFix}`;
    }
}
