import { Component, Input, OnInit } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import {
    applyFormValue,
    ControlWithErrors,
    shouldShowErrorsFor,
} from '@common/infrastructure/form-tools';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReasonToDeclineOption, Registration } from '@spnl/model/registration';

@Component({
    selector: 'app-proposal-decline-modal',
    templateUrl: './proposal-decline-modal.component.html',
})
export class ProposalDeclineModalComponent implements OnInit {
    @Input()
    registration: Registration;

    ReasonToDeclineOption: typeof ReasonToDeclineOption = ReasonToDeclineOption;
    declineForm: UntypedFormGroup;
    reasonsToDecline: ReasonToDeclineOption[] = [
        ReasonToDeclineOption.UnsuitableProducts,
        ReasonToDeclineOption.NoTrustInSupplier,
        ReasonToDeclineOption.AlreadyHasPanels,
        ReasonToDeclineOption.WantsAssessmentBeforeAccepting,
        ReasonToDeclineOption.ReceivedBetterOffer,
        ReasonToDeclineOption.PersonalFinancialSituation,
        ReasonToDeclineOption.ProductIsTooExpensive,
        ReasonToDeclineOption.OfferUnclear,
        ReasonToDeclineOption.NoLongerInterestedInSolarPanels,
    ];

    constructor(
        private modal: NgbActiveModal,
        private fb: UntypedFormBuilder,
    ) {}

    ngOnInit(): void {
        this.declineForm = this.fb.group({
            reasonToDecline: [null],
            reasonToDeclineDescription: ['', Validators.maxLength(500)],
        });
    }

    onDismiss(): void {
        this.modal.dismiss();
    }

    onDeclineOffer(): void {
        this.declineForm.markAllAsTouched();
        this.declineOffer();
        this.modal.close();
    }

    get reasonToDecline(): ControlWithErrors {
        return this.declineForm.get('reasonToDecline');
    }
    get reasonToDeclineDescription(): ControlWithErrors {
        return this.declineForm.get('reasonToDeclineDescription');
    }

    get showErrorsForReasonToDeclineDescription(): boolean {
        return shouldShowErrorsFor(this.reasonToDeclineDescription);
    }

    reasonToDeclineKey(reason: ReasonToDeclineOption): string {
        return 'proposal-decline-reason-' + reason.toLowerCase();
    }

    private declineOffer(): void {
        this.registration.decisionComplete = true;
        this.registration.proposalAccepted = false;
        applyFormValue(this.declineForm, this.registration.decision, []);
        if (!this.registration.decision.reasonToDecline) {
            this.registration.decision.reasonToDecline =
                ReasonToDeclineOption.NoReason;
        }
    }
}
