import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NlInitialsFormatter } from '@lang/nl/infrastructure/initials-formatter';
import { DevelopmentGuard } from '@spnl/guards/development-guard.guard';
import { RegistrationStoreService } from '@spnl/services/registration-store.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ContactComponentBase } from '../../../../_common/components/contact/contact.component.base';
import { emailExpression } from '../../../../_common/forms/custom-validators';
import {
    ControlWithErrors,
    shouldShowErrorsFor,
} from '../../../../_common/infrastructure/form-tools';
import { ContactFormBase } from '../../../../_common/model/contact-form.base';
import { CommunityService } from '../../../../_common/services/community.service';
import { ContactService } from '../../../../_common/services/contact.service';
import { ContentService } from '../../../../_common/services/content.service';
import { GoogleAnalyticsService } from '../../../../_common/services/google-analytics.service';
import { Registration } from '../../model/registration';
import { RegistrationButtonContentService } from '../../services/registration-button-content.service';
import { RegistrationService } from '../../services/registration.service';
import { insertionPattern, namePattern } from '@lang/nl/validation';

const formGroup = {
    salutation: ['', [Validators.required]],
    initials: ['', [Validators.required, Validators.maxLength(10)]],
    insertion: [
        '',
        [Validators.maxLength(10), Validators.pattern(insertionPattern)],
    ],
    lastName: [
        '',
        [
            Validators.required,
            Validators.maxLength(100),
            Validators.pattern(namePattern),
        ],
    ],
    email: [
        '',
        [
            Validators.required,
            Validators.pattern(emailExpression),
            Validators.maxLength(500),
        ],
    ],
};

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.css'],
})
export class ContactComponent
    extends ContactComponentBase<
        ContactFormBase,
        RegistrationService,
        Registration
    >
    implements OnInit, OnDestroy
{
    submitting = false;
    showRegistrationButton = true;
    registerButtonKey: string;
    destroyed$: Subject<void> = new Subject<void>();

    questionItems = [
        { id: 'contact-subject-new-offer' },
        { id: 'contact-subject-garantees' },
        { id: 'contact-subject-supplier' },
        { id: 'contact-subject-process-and-payment' },
        { id: 'contact-subject-change-data' },
        { id: 'contact-subject-other' },
    ];

    // Suppress linting, leaving this as is for legacy reasons (not worth the risk of refactoring)
    get cmsData() {
        return {
            maximumUploadSize:
                (this.maximumUploadSize / (1024 * 1024)).toString() + 'MB',
            allowedUploadMimeTypes: this.allowedUploadMimeTypes.join(', '),
        };
    }

    get initials(): ControlWithErrors {
        return this.form.get('initials');
    }
    get insertion(): ControlWithErrors {
        return this.form.get('insertion');
    }
    get lastName(): ControlWithErrors {
        return this.form.get('lastName');
    }
    get salutation(): ControlWithErrors {
        return this.form.get('salutation');
    }

    get shouldShowSalutationErrors(): boolean {
        return shouldShowErrorsFor(this.salutation);
    }
    get shouldShowInitialsErrors(): boolean {
        return shouldShowErrorsFor(this.initials);
    }
    get shouldShowInsertionErrors(): boolean {
        return shouldShowErrorsFor(this.insertion);
    }
    get shouldShowLastNameErrors(): boolean {
        return shouldShowErrorsFor(this.lastName);
    }
    get shouldShowEmailErrors(): boolean {
        return shouldShowErrorsFor(this.email);
    }
    get shouldShowRegistrationNumberErrors(): boolean {
        return shouldShowErrorsFor(this.registrationNumber);
    }
    get shouldShowQuestionErrors(): boolean {
        return shouldShowErrorsFor(this.question);
    }
    get shouldShowMessageErrors(): boolean {
        return shouldShowErrorsFor(this.message);
    }
    get shouldShowUploadFileErrors(): boolean {
        return shouldShowErrorsFor(this.uploadFile);
    }

    constructor(
        public registrationService: RegistrationService,
        private registrationButtonContentService: RegistrationButtonContentService,
        private registrationStore: RegistrationStoreService,
        content: ContentService,
        communityService: CommunityService,
        contactService: ContactService<ContactFormBase>,
        analytics: GoogleAnalyticsService,
        fb: UntypedFormBuilder,
        // TODO (SPNL-542) Remove below when register flow is production-ready
        private developmentGuard: DevelopmentGuard,
    ) {
        super(
            content,
            communityService,
            contactService,
            analytics,
            fb,
            formGroup,
            registrationService,
        );

        this.maximumUploadSize = 4 * 1024 * 1024;
        this.maximumNumberOfAttachments = 10;
        this.allowedUploadMimeTypes = [
            'image/jpg',
            'image/jpeg',
            'image/png',
            'application/pdf',
        ];
    }

    // Suppress linting, leaving this as is for legacy reasons (not worth the risk of refactoring)
    prepareContactFormBase() {
        const form = new ContactFormBase();

        form.senderName = this.insertion.value
            ? `${this.initials.value} ${this.insertion.value} ${this.lastName.value}`
            : `${this.initials.value} ${this.lastName.value}`;

        return form;
    }

    ngOnInit(): void {
        super.ngOnInit();

        const currentRegistration =
            this.registrationStore.getCurrentRegistration();
        if (currentRegistration) {
            this.showRegistrationButton = false;
            this.initials.setValue(this.convertFirstNameToInitials(currentRegistration.contact.firstName));
            this.insertion.setValue(currentRegistration.contact.insertion);
            this.lastName.setValue(currentRegistration.contact.lastName);
            this.salutation.setValue(currentRegistration.contact.salutation);
            this.email.setValue(currentRegistration.email);
            this.registrationNumber.setValue(currentRegistration.number);
        }

        // TODO (SPNL-542) Remove below when register flow is production-ready
        if (this.developmentGuard.canActivate(null) !== true) {
            this.showRegistrationButton = false;
        }

        this.registrationButtonContentService
            .getRegisterButtonCmsKey()
            .pipe(takeUntil(this.destroyed$))
            .subscribe((key) => (this.registerButtonKey = key));
    }

    formatInitials(): void {
        this.initials.setValue(
            NlInitialsFormatter.formatInitials(this.initials.value),
        );
    }

    convertFirstNameToInitials(firstName: string): string {
        if (!firstName) {
            return '';
        }
        firstName = firstName.trim();
        if (firstName.toUpperCase().startsWith('IJ')) {
            return 'IJ.';
        }
        let initial = firstName.charAt(0).toUpperCase();
        return initial + '.';
    }

    onSubmit(): void {
        this.submitting = true;
        this.form.markAllAsTouched();
        super.onSubmit();
        if (!this.form.valid) {
            this.submitting = false;
        }
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
